import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClientInput from "./ClientInput";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import api from "../../../Utils/Api";
import SubTaskForm from "./SubTaskForm";


const TaskEditInline = ({ task, active, close, ...props }) => {
  const redux = useSelector(state => ({
    vehicules: state.vehicules,
    miseDispo: state.miseDispo,
    typeCourse: state.typeCourse,
    typeReglement: state.typeReglement,
    task_status: state.task_status,
    task_types: state.task_types
  }));

  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(task.task_client_id);

  const [subtasks, setSubtasks] = useState([]);
  const [depart, setDepart] = useState({});
  const [arrival, setArrival] = useState({});
  const [ready, setReady] = useState(false);


  const selectedVehicule = {
    label: task.task_vehicule_type,
    value: task.task_vehicule_type_id
  };

  const selectClient = c => setClient(c.client_id);

  const submitForm = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let data = {};
    for (var [key, value] of formData.entries()) data[key] = value;

    setLoading(true);

    api.post("/api/v2/task/new", formData).then(res => {
      setLoading(false);
    });

  };



  const addSubtask = () => {
    const s = [...subtasks]
    s.push({
      type: "pickup",
      address: {},
      label: "",
      address_lat: "",
      address_lng: "",
      hour: "",
      contact: ""
    });

    setSubtasks(s);

  }


  const removeSubtask = taskIndex => {
    if (isNaN(taskIndex)) return false;

    const s = [...subtasks];


    s.splice(taskIndex, 1);

    setSubtasks(s)
    return true;
  };



  useEffect(() => {

    let startTask, endTask;
    let subTasks = [];

    Object.values(task.subtasks).forEach(item => {

      if (item.subtask_index === "999") endTask = item;
      else if (item.subtask_index === "-1") startTask = item;
      else subTasks.push(item);
    });

    // this.setState({
    //   task: task,
    //   startTask: startTask,
    //   endTask: endTask,
    //   subTasks: subTasks,
    //   ready: true
    // });

    setArrival(endTask);
    setDepart(startTask);
    setSubtasks(subTasks);
    setReady(true);

  }, [])

  return (
    <div className={"p-3 inline-edit-box"}>
      <form onSubmit={submitForm}>
        <div className={"row"}>
          <div className={"col-md-6 pl-3"}>
            <div className={"ui-rect"}>


            <input type="hidden" name="task_id" value={task.task_id} />
            <div className={"row"}>
              <div className={"col-md-4"}>
                <div className={"form-group"}>

                  <input title="N° BL Client" type="text" defaultValue={task.task_bl_client} name="bl_client" placeholder="N° BL Client" className={"form-control"} />
                </div>
              </div>

              <div className={"col-md-4"}>
                <div className={"form-group"}>


                  <ClientInput
                    title="Nom du client"
                    defaultValue={task.task_client_name}
                    type="text"
                    name="task_client"
                    className={"form-control"}
                    selectClient={selectClient}
                  />

                  <input type="hidden" name="task_client_id" value={client || ""} />
                </div>
              </div>

              <div className={"col-md-4"}>
                <div className={"form-group"}>

                  <Select
                    title="Mise à dispo"
                    defaultValue={redux.miseDispo.filter(item => item.value === task.task_mise_dispo)}
                    classNamePrefix={"custom-select"}
                    name="mise_dispo"
                    options={redux.miseDispo}
                    //defaultValue={{ label: "N/A", value: "na" }}
                    
                    placeholder="Mise à dispo"
                  />
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"form-group col-md-4"}>

                <Select
                  title="Type course"
                  defaultValue={redux.typeCourse.filter(item => item.value === task.task_type_course)}
                  classNamePrefix={"custom-select"}
                  name="type_course"
                  options={redux.typeCourse}
                  placeholder="Choisir type course"
                />
              </div>

              <div className={"form-group col-md-4"}>

                <Select
                title="Type véhicule"
                  defaultValue={selectedVehicule}
                  classNamePrefix={"custom-select"}
                  name="task_vehicule_type"
                  options={redux.vehicules}
                />
              </div>

              <div className={"form-group col-md-4"}>

                <Select
                  title="Status"
                  defaultValue={redux.task_status.filter(item => item.value === task.status)}
                  classNamePrefix={"custom-select"}
                  name="task_status"
                  options={redux.task_status}
                  placeholder="Choisir status"
                />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-4 form-group"}>

                <input
                  title="Info facture"
                  defaultValue={task.task_info_facture}
                  type={"text"}
                  name={"info_facture"}
                  className={"form-control"}
                  placeholder="Info facture"
                />
              </div>
              <div className={"col-md-4 form-group"}>

                <input
                  title="Achat"
                  defaultValue={task.task_tarif_achat}
                  type={"text"}
                  name={"tarif_achat"}
                  className={"form-control"}
                  placeholder="Achat"
                />
              </div>

              <div className={"col-md-4 form-group"}>

                <input
                  title="Vente"
                  defaultValue={task.task_tarif_vente}
                  type={"text"}
                  name={"tarif_vente"}
                  className={"form-control"}
                  placeholder="Vente"
                />
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-6 form-group"}>

                <textarea title="Observation" className={"form-control"} defaultValue={task.task_obs_vente} name="obs_vente" placeholder="Observation..."/>
              </div>
              <div className={"col-md-6 form-group"}>

                <textarea title="Instruction" className={"form-control"} defaultValue={task.task_instruction} name="instruction" placeholder="Instruction..." />
              </div>
            </div>


            <div className={"row"}>
                <div className={"form-group col-md-4"}>
                  <label>Immobilisation &nbsp;</label>
                  <input title="Immobilisation" type="checkbox" value="true" name={"task_immo"} defaultChecked={parseInt(task.task_immo)} />
                </div>
                <div className={"form-group col-md-4"}>
                  <label>Retour &nbsp;</label>
                  <input title="Retour" type="checkbox" value="true" name={"task_retour"} defaultChecked={parseInt(task.task_retour)} />
                </div>
                <div className={"form-group col-md-4"}>
                  <label>KM  Sup. &nbsp;</label>
                  <input title="KM Sup." type="checkbox" value="true" name={"task_km_sup"} defaultChecked={parseInt(task.task_km_sup)} />
                </div>
            </div>




            <div className={"row"}>
              <div className={"col-md-12 text-right"}>
                <button className={"btn mr-1 btn-outline-primary"} onClick={() => close()}>
                  Annuler
                </button>
                <button className={"cbtn"}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin className={"mr-2"} />
                  ) : (
                    <FontAwesomeIcon icon={faCheck} className={"mr-2"} />
                  )}
                  Sauvegarder
                </button>
              </div>
              </div>
            </div>
          </div>
          <div className={"col-md-6"}>

          {ready && (
            <>
          <SubTaskForm
              formType={"start"}
              taskType={"pickup"}
              expand={true}
              data={depart}
              idx={"startTask"}
            />

            {subtasks.map((subtask, key) => (
              <SubTaskForm

                removeItem={removeSubtask}
                formType={"point"}
                taskType={subtask.type}
                data={subtask}
                key={key + 1}
                idx={key}
              />
            ))}

            <div className={"row my-3"}>
              <div className={"col-md-12"}>
                <button
                  type="button"
                  className={"cbtn float-right"}
                  onClick={addSubtask}
                >
                  Ajouter un point
                </button>
              </div>
            </div>

            <SubTaskForm
              formType={"end"}
              taskType={"pickup"}
              idx={"endTask"}
              expand={true}
              data={arrival}
            />

            </>
          )}

          </div>
        </div>
      </form>
    </div>
  );
};

export default TaskEditInline;
