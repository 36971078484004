import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const moment = require("moment");

const TaskDetailInline = ({task, showPhotos, active, ...props}) => {



  return (
    <>
        <div className={"m-1 animateRow row " + (!active && "hideRow")}>

                <div className={"col-md-2 task-detail-wrap"}>
                  <h3>Détail</h3>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Reference</span>

                    <span className={"detail-value"}>{task.task_name}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Ajouté par</span>
                    <span className={"detail-value"}>{task.task_created_by}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Date d'ajout</span>

                    <span className={"detail-value"}>{task.task_created_at}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Agent</span>

                    <span className={"detail-value"}>
                      {task.task_agent && (
                        <Link className={"cbtn-inline"} to={"/manager/agents/" + task.task_agent_id}>
                          {task.task_agent} <FontAwesomeIcon icon={faExternalLinkAlt} className={"ml-1"} />
                        </Link>
                      )}
                    </span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Affecté par</span>
                    <span className={"detail-value"}>{task.task_assigned_by}</span>

                    {/* <span className={"detail-value"}>Commercial Agent</span> */}
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Date affectation</span>
                    <span className={"detail-value"}>{task.task_assigned_at}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Derniere modification</span>
                    <span className={"detail-value"}>{task.task_updated_at}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Status</span>
                    <span className={"detail-value"}>{String(task.task_status).toUpperCase()}</span>
                  </div>

                  <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Document</span>
                    <span className={"detail-value"}>{task.task_docs_recu ? "Documents reçu" : "Non reçu" } <br />{task.task_docs_comment}{task.task_date_doc_recu}</span>
                  </div>

                  {/* { */}
                  {/*   task.task_comment !== null ? ( */}
                  {/*     <div className={"task-detail-single"}> */}
                  {/*       <span className={"detail-label"}>Commentaire</span> */}

                  {/*       <span className={"detail-value"}>{task.task_comment}</span> */}
                  {/*       <span className={"detail-value"}>{task.task_commentator} | {task.task_comment_date}</span> */}
                  {/*     </div> */}
                  {/*   ): null */}
                  {/* } */}
                 


                </div>

                <div className={"col-md-2 task-detail-wrap task-history"}>
                  <h3>Historique</h3>

                  {task.task_history.map((hist, i) => (
                    <div key={`task_hist_${i}`} className={"task-detail-single"}>
                      <span className={"detail-label"}>{hist.history_action}</span>
                      <span className={"detail-value"}>{hist.history_by + " - " + hist.history_date}</span>
                    </div>
                  ))}
                </div>

                <div className={"col-md-2 task-detail-wrap task-history"}>
                  <h3> Commentaire </h3>
                  {
                    task.task_comment !== null ? (
                      <div>
                      <div className={"task-detail-single"}>
                        <span className={"detail-label"}>Commentaire</span>
                        <span className={"detail-value"}>{task.task_comment}</span>
                      </div>
                      <div className={"task-detail-single"}>
                        <span className={"detail-label"}>Ajouté par</span>
                        <span className={"detail-value"}>{task.task_commentator}</span>
                      </div>
                      <div className={"task-detail-single"}>
                        <span className={"detail-label"}>Date</span>
                        <span className={"detail-value"}>{task.task_comment_date}</span>
                      </div>
                     </div>
                    ): null
                  }
                  </div>


                {/* <div className={"col-md-2 task-detail-wrap task-history"}> */}
                {/*   <h3> Tentatives </h3> */}

                {/*   {task.subtasks.tentatives.map((tentative, i) => ( */}
                {/*     <div key={`task_hist_${i}`} className={"task-detail-single"}> */}
                {/*       <span className={"detail-label"}><b>Tentative : {tentative.number} </b> */}
                {/*         <br/>Motif:  {tentative.motif} */}
                {/*         <br/>Note:  {tentative.note} */}  
                {/*         <br/>à {tentative.created_at} */}
                {/*       </span> */}
                      
                {/*     </div> */}
                {/*   ))} */}
                {/* </div> */}

              <div className={"col-md-2 task-detail-wrap task-history"}>
              <h3> Tentatives </h3>

              {Object.values(task.subtasks).map((subtask) => (
                <div key={`subtask_${subtask.key}`}>
                  {subtask.tentatives.map((tentative, i) => (
                    <div key={`task_hist_${i}`} className={"task-detail-single"}>
                      <span className={"detail-label"}>
                        <b>Tentative:</b>
                        <br />
                        Motif: {tentative.motif}
                        <br />
                        Note: {tentative.note}
                        <br />
                        {tentative.agent !== null && (
                          <div>
                            Coursier: {tentative.agent}
                            <br />
                          </div>
                            )}
                        Created at: {tentative.created_at}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>

                {/* <div className={"col-md-2 task-detail-wrap task-history"}> */}
                {/*   <h3> Commentaire </h3> */}
                {/*   { */}
                {/*     task.task_comment !== null ? ( */}
                {/*       <div> */}
                {/*       <div className={"task-detail-single"}> */}
                {/*         <span className={"detail-label"}>Commentaire</span> */}
                {/*         <span className={"detail-value"}>{task.task_comment}</span> */}
                {/*       </div> */}
                {/*       <div className={"task-detail-single"}> */}
                {/*         <span className={"detail-label"}>Ajouté par</span> */}
                {/*         <span className={"detail-value"}>{task.task_commentator}</span> */}
                {/*       </div> */}
                {/*       <div className={"task-detail-single"}> */}
                {/*         <span className={"detail-label"}>Date</span> */}
                {/*         <span className={"detail-value"}>{task.task_comment_date}</span> */}
                {/*       </div> */}
                {/*      </div> */}
                {/*     ): null */}
                {/*   } */}
                {/*   </div> */}


                <div className={"col-md-4 task-detail-wrap task-history"}>
                  <h3 className={"text-right"}>Points</h3>
                  <ul className={"timeline"}>
                    {Object.keys(task.subtasks)
                      .reverse()
                      .map((subtaskKey, i) => (
                        <li
                          className={"subtask-status-" + task.subtasks[subtaskKey].subtask_status}
                          key={`task_points_${i}`}
                        > 
                          <span>
                            <b>{subtaskKey == 'a_-1'? 'Départ-': subtaskKey == 'a_999'? 'Arrivée-' : ''}</b>
                          </span>
                          <span>
                            <b>
                              {!!task.subtasks[subtaskKey].subtask_label &&
                                task.subtasks[subtaskKey].subtask_label + " - "}{" "}
                            </b>
                            {task.subtasks[subtaskKey].subtask_address +
                              " - " +
                              task.subtasks[subtaskKey].subtask_address_commune}
                          </span>
                          {!!task.subtasks[subtaskKey].subtask_last_update && (
                            <span className={"subtask-status-detail"}>
                              <a href="#" onClick={e => showPhotos(subtaskKey)}>
                                {" "}
                                <b>
                                  {task.subtasks[subtaskKey].subtask_photos && (
                                    <>{task.subtasks[subtaskKey].subtask_photos.length} photos</>
                                  )}
                                </b>
                              </a>{" "}
                              {/* {moment(task.subtasks[subtaskKey].subtask_last_update)
                                .fromNow()
                                .toString()} */
                                task.subtasks[subtaskKey].subtask_last_update}<br/>
                                {task.subtasks[subtaskKey].subtask_comment && <><b>Note:</b>{task.subtasks[subtaskKey].subtask_comment} </>}
                            </span>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </>
    )
}
export default TaskDetailInline;
