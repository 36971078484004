import React from "react";

import Select from 'react-select';

class Pagination extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            current: 1,
            show: [],
            max: 0
        }

    }

    componentDidUpdate(prev, props) {
        if(this.state.current !== this.props.data.current) {
            this.setState({
                current: this.props.data.current
            })
        } 

        if(this.state.max !== this.props.data.pages) {
            this.setState({
                max: this.props.data.pages
            })
        } 


    }

    clickNext = () => {
        if(this.props.data.current < this.state.max) {
            this.props.handleClick(parseInt(this.props.data.current)+1)
        }

    }

    clickPrev = () => {
        if(this.props.data.current > 1) {
            this.props.handleClick(parseInt(this.props.data.current)-1)
        }
    }


    render() {
        const handleClick = (i) => {
            console.log(i);
            this.props.handleClick(i.value)
        }
        const {pages, data, ...props} = this.props;

        return (

            <ul className={"pagination"}>
                
                <li className={"label prev"+(this.props.data.current <= 1 ? " disabled" : "")} onClick={this.clickPrev}>Précedent</li>
                <Select
                    onChange={handleClick}
                    classNamePrefix={"custom-select"}
                    options={[...Array(data.pages)].map((item, idx) => ({label: idx+1, value: idx+1 }))}
                    value={{label: data.current, value: data.current}}
                />

                <li className={"label next"+ (this.props.data.current < this.state.max ? "" : " disabled" )} onClick={this.clickNext}>Suivant</li>

            </ul>
        )
        

    }


    

}

export default Pagination ;