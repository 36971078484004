import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';
import Select from 'react-select';
import PayCCP from './../Payment/PayCCP';



function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
  };
}


 class FillCredit extends Component {

 	constructor(props) {
		 super(props);
		
 		this.state = {
			error: false,
			amount: 0,
			step: 1,
			method: null
		}

 	}


 	componentDidMount() {

		 
	 }
	
	submitForm(e) {
		this.setState({
			error: false
		});

		e.preventDefault();

		let form = new FormData(e.target);

		let data = {}; 

		for(let [key, value] of form.entries()) {
			data[key] = value;
		}

		if(data.method.length === 0) {
			this.setState({
				error: "Selectionnez une méthode de paiement."
			})
			return;
		} 

		if( typeof parseInt(data.amount) !== 'number' || isNaN(parseInt(data.amount)) ||  parseInt(data.amount) < 300) {
			
			this.setState({
				error: "Le montant doit être supérieur à 300DZD"
			})
			return;
		}


		
		this.setState({
			step: data.method,
			amount: data.amount,
			method: data.method
		})

	}

 	
	render() {

		return(
			<React.Fragment>
			
			<div className={"container-header nmb"}>
				Rechanger mon solde
            </div>

			<div className={"row"}>
				<div className={"col-md-12 mt-3"}>
					{this.state.step === 1 && (<>
						<p>Rechargez votre compte pour profitez de nos services </p>
						<div className={" col-md-6 offset-3"}>
						{this.state.error && (<div className={"alert alert-danger"}>{this.state.error}</div>)}


						<form onSubmit={e => this.submitForm(e)} action="" method="post" >

						<div className={"form-group"}>
						<div className={"row no-gutters"}>
							<label>Entrez le montant que vous voulez recharger à votre compte :</label>
							<div class="input-group col-md-6">
								<input name="amount" type="text" defaultValue={"500"} className={"form-control"} /> 
								<div class="input-group-append">
									<span class="input-group-text">DZD</span>
								</div>
							</div>
						</div>
						</div>


						<div className={"form-group"}>
							<label>Séléctionnez une méthode de paiement :</label>

							<div className={"row no-gutters"}>
								<Select
								name="method"
								className={"col-md-6"}
									options={[
										{'label': 'Virement CCP', value: 'vir_ccp'},
										{'label': 'Virement Bancaire', value: 'vir_bank'},
										{'label': 'Chèque', value: 'check'}
									]}
								/>
							</div>

						</div>

						


					<br />
					
					<button className={"btn btn-primary float-right"}>Continuer</button>
					</form>


					</div>
					</>)}

					{this.state.step === "vir_ccp" && (
						<PayCCP 
							amount={this.state.amount}
							method={this.state.method}
						/>
					)}

					{this.state.step === "vir_bank" && (
						<PayCCP 
							amount={this.state.amount}
							method={this.state.method}
						/>
					)}
					
				</div>	
			
			</div>

			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FillCredit);