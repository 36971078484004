import React from "react"
import {connect} from "react-redux"
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import { Link } from 'react-router-dom';
import CanvasJSReact from "../../Canvas/canvasjs.react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTasks, faCoins, faChartLine, faUserTie, faThumbtack } from '@fortawesome/free-solid-svg-icons'

import api from '../../Utils/Api';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}


const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}


class AgentOverview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			status: null,
			fullname: null,
			tasks: null,
			lastonline: null,
			dashboard :null

		}

		props.isLoading();
	}

	componentDidMount() {
		console.log('here')
		const that = this;
		

		api.get('/api/user/'+this.props.match.params.userid+'/overview?token='+this.props.user.token)
		.then(function(response) {
			console.log('dash => '+ response.data)
			if(response.data.success) {
				that.setState({...response.data.data, 'loaded': true})
				// console.log('dash => '+ response.data)
				// that.setState({
				// 	dashboard: response.data.dashboard
				// })
			}
				

			
		}).then(r => that.props.doneLoading()); 
	}

    render() {
		
		if(this.state.loaded){
		let that = this
		var CanvasJS = CanvasJSReact.CanvasJS;
		var CanvasJSChart = CanvasJSReact.CanvasJSChart;
		CanvasJS.addColorSet("Tasks",
				[
					"#5cb85c",
					"#fd8c00",               
				]);
		CanvasJS.addColorSet("Collect",
				[
					"#5cb85c",
					"#d9534f",               
				]);
		const Tasks = {
			colorSet: "Tasks",
			backgroundColor:'transparent',
			
			
			subtitles: [{
				text: that.state.dashboard.tasks.total +" Tasks au total",
				verticalAlign: "center",
				fontSize: 20,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			data: [{				
					 
					  type: "doughnut",
					  
					  dataPoints: [
						  { label: "Complited",  y: this.state.dashboard.tasks.total -  this.state.dashboard.tasks.pending },
						  { label: "Pending", y: this.state.dashboard.tasks.pending },
					  ]
			 }]
		}

		const Collect = {
			colorSet: "Collect",
			backgroundColor:'transparent',
			
			
			subtitles: [{
				text: that.state.dashboard.collect.TM +" DZD Total montant a collecter",
				
				fontSize: 20,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			data: [{				
					 
					  type: "pie",
					  
					  dataPoints: [
						  { label: "Collecté",  y:  this.state.dashboard.collect.TM -  this.state.dashboard.collect.TD },
						  { label: "Due", y: this.state.dashboard.collect.TD },
					  ]
			 }]
		}
        return (
		<div>
            <div className={"col-md-12"}>
			
  				<h4><span className={"status-ball status-"+ this.state.status_id} /> Coursiers </h4>


				<div className={"row"}>
					<div className={"col-md-4"}>
						{this.state.status_id === 4 && this.state.status_current &&
						(<div>Current task : <Link to={"/task/"+this.state.status_current}>#{this.state.status_current}</Link></div>)
						}
						
						<div>{this.state.fullname}</div>
					</div>
				
				</div>

				<div className="row">
					<div className="col-md-6">
					<span className="dashboard-title"><FontAwesomeIcon icon={faTasks} className={"dashboard-icon"} />Tasks</span>
					<CanvasJSChart options = {Tasks} />
					</div>
					<div className="col-md-6">
					<span className="dashboard-title"><FontAwesomeIcon icon={faCoins} className={"dashboard-icon"} />Collect</span>
					<CanvasJSChart options = {Collect} />
					</div>
				</div>

				
  				


  			</div>

        </div>
		)}

		return ""
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentOverview)