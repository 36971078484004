import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import UserDetailContainer from './../Containers/UserDetailContainer';
import AgentList  from './../Components/Admin/AgentList';
import ManagerList from '../Components/Admin/ManagerList';
import ManagerNew from '../Components/Admin/ManagerNew';
import CommercialList from '../Components/Admin/CommercialList';
import CommercialNew from '../Components/Admin/CommercialNew';


function mapStateToProps(state) {
  return { auth: state.auth };
};


class UserRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/users/agents' component={AgentList} />
						<Route exact path='/users/agents/:id' component={AgentList} />
						<Route exact path='/users/managers' component={ManagerList} />
						<Route exact path='/users/commercial' component={CommercialList} />
						<Route exact path='/users/managers/new' component={ManagerNew} />
						<Route exact path='/users/commercial/new' component={CommercialNew} />
						<Route exact path='/users/:userid/edit' component={UserDetailContainer} />
						<Route exact path='/users/:userid/personal' component={UserDetailContainer} />
						<Route exact path='/users/:userid/security' component={UserDetailContainer} />
						<Route exact path='/users/:userid/history' component={UserDetailContainer} />
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(UserRouter)