import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';
import Select from 'react-select';
import { Trans } from "react-i18next";


let REMOTE_URL = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://salim.tictechs.tech';



function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
  };
}


 class PayBill extends Component {

 	constructor(props) {
		 super(props);
		
 		this.state = {}

		this.submitForm = this.submitForm.bind(this)

        props.isLoading();
 	}


 	componentDidMount() {

        const that = this;

        api.get('/api/bill/'+this.props.match.params.billid+'/proceed')
        .then(function(response) {

            console.log(response.data)
;
            if(response.data.success)  {
                that.setState({...response.data});

            }


            
                that.props.doneLoading();
        })
		 
	 }
	
	submitForm(e) {
		e.preventDefault();

		// let form = new FormData(e.target);

		// let data = {}; 

		// for(let [key, value] of form.entries()) {
		// 	data[key] = value;
		// }


	}

 	
	render() {

		return(
			<React.Fragment>


            <div className={"container-header"}>
                <Trans i18nKey="label_bill_payment" />
            </div>



            <div className="col-md-12 ctable mb-5">
                    
                    
                        
            <div className={"row head-titles ctable-row"}>
                    <div className={"col-md-3"}>
                        Agent
                    </div>
                    <div className={"col-md-3"}>
                        Durée
                    </div>
                    <div className={"col-md-3"}>
                        Expiration
                    </div>
                    <div className={"col-md-3"}>
                        <Trans i18nKey="label_amount" />
                    </div>
                </div>

                {this.state.data && this.state.data.map(bill => (

                    <div className={"row ctable-row"}>
                        <div className={"col-md-3"}>
                            {bill.agent.username}
                        </div>

                        <div className={"col-md-3"}>
                            {bill.quantity}
                        </div>

                        <div className={"col-md-3"}>
                            {bill.agent.expiry}
                        </div>

                        <div className={"col-md-3"}>
                            {bill.amount} DZD
                        </div>

                    </div>

                ))}

                {this.state.data && (

                    <div className={"row mt-2"}>

                        <div className={"offset-md-6 col-md-3"}>Total :</div>

                        <div className={"col-md-3"}>
                            {this.state.total} DZD
                        </div>

                    </div>

                )}

                
            </div>



						<form encType="multipart/form-data" action={REMOTE_URL+"/api/bill/"+this.props.match.params.billid+'/pay'} method="post" >
						
						<input type="hidden" value={this.props.user.token} name="token" />

						<div className={"form-group"}>
							<label>Nom :</label>
							<input defaultValue={this.state.user && this.state.user.lastname} name="last_name" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Prénom :</label>
							<input defaultValue={this.state.user && this.state.user.firstname} name="first_name" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Raison Social :</label>
							<input name="raison" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Adresse :</label>
							<input name="address" type="text" className={"form-control col-md-5"} /> 
						</div>

						<div className={"form-group"}>
							<label>Numéro de téléphone :</label>
							<input defaultValue={this.state.user && this.state.user.phone} name="phone" type="text" className={"form-control col-md-5"} /> 
						</div>

                        <div className={"form-group"}>
							<label>Séléctionnez une méthode de paiement :</label>

							<div className={"row no-gutters"}>
								<Select
								name="method"
								className={"col-md-5"}
									options={[
										{'label': 'Virement CCP', value: 'vir_ccp'},
										{'label': 'Virement Bancaire', value: 'vir_bank'},
										{'label': 'Chèque', value: 'check'}
									]}
								/>
							</div>

						</div>

						<div className={"form-group"}>
							<label>Reçu de virement :</label>
							<input name="image" type="file" className={"form-control col-md-5"} /> 
						</div>



					<br />
					
					<button className={"btn btn-primary float-right"}>Continuer</button>

					</form>


			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PayBill);