import React, { Component } from "react";
import { connect } from "react-redux";
import { setAuthAction, doneLoading, startLoading, setOverlay } from "./../Redux/actions/index";
import TaskEdit from "../Components/Task/TaskEdit";
import api from "../Utils/Api";
import SubTaskForm from "../Components/Task/subcomponents/SubTaskForm";
import TaskForm from "../Components/Task/subcomponents/TaskForm";

const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.loading
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: x => dispatch(setOverlay(x)),
    isLoading: e => dispatch(startLoading(e))
  };
}

class TaskEditContainer extends Component {
  constructor(props) {
    super(props);

    // this.props.isLoading({message: "Loading task information", animate:true});
    this.state = {
      ready: false,
      subTasks: [],
      startTask: {
        address: {}
      },
      endTask: {
        address: {}
      },
      client: {}
    };
  }

  submitTask(e, ref) {
    e.preventDefault();
    let that = this;

    this.props.isLoading({
      message: "Updating task",
      animate: true
    });

    const formdata = new FormData(ref);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = this.props.user;

    console.log(data);

    api
      .post("/api/task/edit", data)
      .then(function(response) {
        if (response.data.success) {
          that.props.history.push("/task");

          that.props.setOverlay({
            message: "Task successfully updated !",
            label: "success",
            button: {
              type: "close",
              label: "Dismiss",
              link: "javascript:void(0)"
            }
          });
        } else {
          if (response.data.errcode === 101) {
            that.props.setAuth(response.data);
          }
        }

        that.props.doneLoading();
      })
      .catch(err => that.props.doneLoading());
  }

  componentDidMount() {
    let that = this;

    this.props.isLoading();

    this.loadTask(that.props.match.params.taskid);
  }

  loadTask = taskid => {
    let that = this;

    api.get("/api/v2/task/fetch?id=" + taskid).then(response => {
      if (response.data.success) {
        let startTask, endTask;
        let subTasks = [];

        const task = response.data.tasks[0];
        Object.values(task.subtasks).forEach(item => {
          // return;
          if (item.subtask_index === "999") endTask = item;
          else if (item.subtask_index === "-1") startTask = item;
          else subTasks.push(item);
        });

        this.setState({
          task: task,
          startTask: startTask,
          endTask: endTask,
          subTasks: subTasks,
          client: {
            client_id: task.task_client_id,
            client_name: task.task_client_name
          },
          ready: true
        });
      } else {
        if (response.data.errcode === 101) {
          that.props.setAuth(false);
        }
      }

      that.props.doneLoading();
    });
  };

  selectClient = client => this.setState({ client: client });

  removeSubtask = taskIndex => {
    if (isNaN(taskIndex)) return false;

    const { subTasks } = this.state;

    // const marker = subTasks[taskIndex].marker;
    // if (marker) this.map.removeObject(marker);

    subTasks.splice(taskIndex, 1);

    this.setState(
      {
        subTasks
      },
      () => {
        // this.traceRoutes();
      }
    );

    return true;
  };

  addSubtask = () => {
    const { subTasks } = this.state;

    subTasks.push({
      type: "pickup",
      address: {},
      label: "",
      address_lat: "",
      address_lng: "",
      hour: "",
      contact: ""
    });

    this.setState({ subTasks });
  };

  render() {
    if (!this.state.ready) return <></>;

    const handlers = {
      selectClient: this.selectClient
    };

    return (
      <div>
        <TaskForm isDuplicate={this.props.match.params.duplicate} data={this.state.task} client={this.state.client} handlers={handlers} pinOnMap={this.state.pinOnMap}>

          <SubTaskForm
            isDuplicate={this.props.match.params.duplicate}
            handleAddressSelect={this.handleAddressSelect}
            selectedClient={this.state.client}
            pinToggle={this.pinToggle}
            formType={"start"}
            taskType={"pickup"}
            expand={true}
            data={this.state.startTask}
            idx={"startTask"}
          />

          {this.state.subTasks.map((subtask, key) => (
            <SubTaskForm
              isDuplicate={this.props.match.params.duplicate}
              handleAddressSelect={this.handleAddressSelect}
              selectedClient={this.state.client}
              removeItem={this.removeSubtask}
              pinToggle={this.pinToggle}
              formType={"point"}
              taskType={subtask.type}
              data={subtask}
              key={key + 1}
              idx={key}
            />
          ))}

          <div className={"row my-3"}>
            <div className={"col-md-12"}>
              <button type="button" className={"cbtn float-right"} onClick={this.addSubtask}>
                Ajouter un point
              </button>
            </div>
          </div>

          <SubTaskForm
            isDuplicate={this.props.match.params.duplicate}
            handleAddressSelect={this.handleAddressSelect}
            selectedClient={this.state.client}
            pinToggle={this.pinToggle}
            formType={"end"}
            taskType={"pickup"}
            idx={"endTask"}
            expand={true}
            data={this.state.endTask}
          />
        </TaskForm>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskEditContainer);
