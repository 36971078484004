import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router'
import * as helpers from '../../Utils/Helpers';
import { closeFlash, setFlash, setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";
import { faCheck, faTimes,  faCommentAlt,  faTag, faList, faChevronRight, faFileInvoiceDollar, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    isLoading: (p) => dispatch(startLoading(p)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}



class ManagerNew extends Component {

	constructor(props) {

		super(props);


		this.props.doneLoading();

		this.handleSubmit = this.handleSubmit.bind(this);

		this.func = _.debounce(this.handleInputChangeF.bind(this), 250);
		const that = this;

		this.handleInputChange = e => { 
			e.persist();
			this.func(e);
		}
		
		//this.handleInputChange = this.handleInputChange.bind(this);
		this.passwordRef = React.createRef();
		this.checkUsername = _.debounce(this.checkUsername.bind(this), 250);
		this.checkEmail = _.debounce(this.checkEmail.bind(this), 250);

		this.state = {
			
			form: {
				username: "",
				password: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				role: 5
			},
			validUsername: true,
			validEmail: true,
			processingUsername: false,
			processingEmail: false,

		};
	}

	checkUsername() {
		
		const that = this;
		const username = this.state.form.username;
		
		if(username.length < 4)
			return that.setState({validUsername: false})
		
		this.setState({
			processingUsername: true
		});

		api.post('/api/user/availability', { username: username})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validUsername: true})
			} else {
				that.setState({validUsername: false})
			}

			that.setState({
				processingUsername: false
			});
		})
	}

	checkEmail() {
		
		const that = this;
		const email = this.state.form.email;
		
		if(!helpers.validateEmail(email))
			return that.setState({
				validEmail: false
			})
		
		if(email.indexOf(" ") !== -1)
			return that.setState({validEmail: false})
		
		this.setState({
			processingEmail: true
		});

		api.post('/api/user/availability', { email: email})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validEmail: true})
			} else {
				that.setState({validEmail: false})
			}

			that.setState({
				processingEmail: false
			});
		})
	}

	handleInputChangeF(e) {

		let s = this.state;
		const event = Object.assign({}, e);
		const that = this;

		s.form[event.target.name] = event.target.value;

		this.setState({...s}, function() {

			if(event.target.name === 'username') {
			
				that.checkUsername()

			} else if(event.target.name === 'email') {
				
				that.checkEmail()
			
			}

		});

	}

	handleSubmit(e) {
		e.preventDefault();

		let that = this;

		this.props.isLoading();

		const data = {
			form: this.state.form,
			role: 'manager'
		}

		console.log(data);

		api.post('/api/user/register', data)
		.then(function(response) {
			
			that.props.closeFlash();

			if(response.data.success) {

				

				that.props.history.push('/users/managers');
				
				that.props.setFlash({
					active: true,
					message: "Account successfuly created !",
					classes: "success",
					style: {}
				});

				
			} else {

				that.props.setFlash({
					active: true,
					message: response.data.error,
					classes: "warning",
					style: {}
				});

			}

			that.props.doneLoading();
		})


		

	}

	render() {
		return (
			

			

			<div>

			<div className={"container-header"}>
				<Trans i18nKey="create_new_account" />
			</div>
			

			{/* {(this.props.auth ? <Redirect to="/account" /> : "")} */}

			<span className={"mb-4"}>{this.state.messageSpan}</span>
			
			<div className={"row"}>
			<div className={"col-md-4 mr-5"}>


			<form onSubmit={this.handleSubmit}  name="loginForm">
				
				
					<div className={"form-group availability-check"}>
						<label><b><Trans i18nKey="user_username" />*</b> </label>
						<input required  className={"form-control" + (!this.state.validUsername ? ' invalid' : '')} type="text" name="username" onChange={this.handleInputChange} />
						
						<div className={"availability-check-indicator-wrap"}>
							<span className={"spinner-border availability-check-indicator" + (this.state.processingUsername ? " show" : "")}></span>
							
							<span 
							className={"validity-indicator"+ 
							(
								this.state.form.username.length > 0 && !this.state.processingUsername 
								? this.state.validUsername ? " valid" :  " invalid" 
								: ""
							)}>
								<FontAwesomeIcon icon={this.state.form.username.length > 0 && !this.state.processingUsername && this.state.validUsername ? faCheck : faTimes} />
							</span>
						</div>
					</div>

					<div className={"form-group"}>
						<label><b><Trans i18nKey="user_password" />*</b> </label>
						<input required className="form-control" type="password" name="password" onChange={this.handleInputChange} />
					</div>

					<div className={"form-group availability-check"}>
						<label><b><Trans i18nKey="user_email" />* </b></label>
						<input required className={"form-control" + (!this.state.validEmail ? ' invalid' : '')} type="text" name="email" onChange={this.handleInputChange}  />
						

						<div className={"availability-check-indicator-wrap"}>
							<span className={"spinner-border availability-check-indicator" + (this.state.processingEmail ? " show" : "")}></span>
							
							<span 
								className={"validity-indicator"+ 
								(
									this.state.form.email.length > 0 && !this.state.processingEmail 
									? this.state.validEmail ? " valid" :  " invalid" 
									: ""
								)}>
									<FontAwesomeIcon icon={this.state.form.email.length > 0 && !this.state.processingEmail && this.state.validEmail ? faCheck : faTimes} />
							</span>

						</div>

					</div>


					<div className={"form-group"}>
						<label><b><Trans i18nKey="user_firstname" />*</b> </label>
						<input  className="form-control" type="text" name="firstName" onChange={this.handleInputChange}  />
					</div>

					<div className={"form-group"}>
						<label><b><Trans i18nKey="user_lastname" /> *</b></label>
						<input  className="form-control" type="text" name="lastName" onChange={this.handleInputChange} />
					</div>

					
					<div className={"form-group"}>
						<label><b><Trans i18nKey="user_phone" />*</b> </label>
						<input  placeholder={"213777441133"} required className="form-control" type="text" name="phone" onChange={this.handleInputChange} />
					</div>



					<div className={"c-form-row form-group"}>
						<input disabled={! (this.state.validEmail && this.state.validUsername)} className={"btn btn-block btn-primary"} type="submit" value="Create account" />
					</div>

					



					<div className="clear"></div>
			
			</form>
			</div>
			{/* <div className={"col-md-5 offset-md-1"}>
				<h4>Sign in using your Google account.</h4>
			</div> */}
			</div>

			
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerNew)