import axios from "axios"
import { connect } from "react-redux";
import store from "./../Redux/store/index"

import * as CONSTANTS from "./Constants";

const state = store.getState();




let remote = CONSTANTS.REMOTE_API;



if(state.auth && state.user.token)
    axios.defaults.headers.common['X-STS-Token'] = state.user.token; 

const api = axios.create({
    baseURL: remote    
});

export default api