import React from "react"
import { Link } from "react-router-dom"
import ReactDatePicker from "react-datepicker";
import api from '../../Utils/Api';



const moment = require("moment");


export default class AgentActivity extends React.Component {

	constructor(props){
        super(props)

        this.state = {
			activity: [],
			dateDebut: '',
			dateFin: '',
		}
        
    }


	componentDidMount() {
		//this.props.isLoading();
		let that = this
		api.get('/api/user/'+this.props.id+'?tab=personal&token='+this.props.token)
		.then(function(response) {

			if(response.data.success) {
				that.setState({
					activity: response.data.activity
				})

			}
		});
	}

	filter = (name, event) => {
		this.setState({
			[name] : event
		})
		setTimeout(() => {
			this.loadLog()
		}, 1);
		// let that = this
		// api.get('/api/user/'+this.props.id+'?tab=personal&token='+this.props.token + '&' + name +'=' + event)
		// .then(function(response) {

		// 	if(response.data.success) {
		// 		that.setState({
		// 			activity: response.data.activity
		// 		})

		// 	}
		// });
	}
	loadLog = () => {
		let that = this
		api.get('/api/user/'+this.props.id+'?tab=personal&token='+this.props.token + '&' + 'dateDebut=' + that.state.dateDebut + '&' + 'dateFin=' + that.state.dateFin)
		.then(function(response) {

			if(response.data.success) {
				that.setState({
					activity: response.data.activity
				})

			}
		});
	}

	formatDate = date => (date ? new Date(date) : undefined);
    render() {
        return <div>
            <div className={"col-md-6"}>
				<div className='row'>
					<div className="col-md-6">
						<ReactDatePicker
							dateFormat={"yyyy-MM-dd"}
							placeholderText={"Selectionnez une date début"}
							timeFormat={"HH:mm"}
							showTimeSelect={false}
							className={"form-control"}
							selected={this.formatDate(this.state.dateDebut)}
							onChange={e =>
								this.filter('dateDebut', moment(new Date(e)).format("YYYY-MM-DD"))
							}
							wrapperClassName={"col-md-12"}
						/>
					</div>
					<div className="col-md-6">
						<ReactDatePicker
							dateFormat={"yyyy-MM-dd"}
							placeholderText={"Selectionnez une date fin"}
							timeFormat={"HH:mm"}
							showTimeSelect={false}
							selected={this.formatDate(this.state.dateFin)}
							selectsEnd
							startDate={this.formatDate(this.state.dateDebut)}
							endDate={this.formatDate(this.state.dateFin)}
							minDate={this.formatDate(this.state.dateDebut)}
							className={"form-control"}
							onChange={e =>
								this.filter('dateFin',moment(new Date(e)).format("YYYY-MM-DD"))
							}
							wrapperClassName={"col-md-12"}
						/>
					</div>
					
				
				</div>
  				<h4>Recent activity</h4>

  				{this.state.activity.map((v, i) =>(

  				<div className={"row act-row"}>
  					<div className={"col-md-6"}>
  						{v.datetime}
  					</div>
  					<div className={"col-md-6"}>
  						{v.action} 
  					</div>
					{/* <div className={"col-md-4"}>
						<Link 
							to={{
								pathname:'/task/'+v.task.task_id,
							}}
						>
						<abbr title="cliquez pour plus de détails">{v.task.task_name}</abbr>
  						
						</Link>
  					</div> */}
  				</div>
  				))}

				{this.state.activity.length === 0 && 
					<div>
						Aucune donnée 

					</div>
				}


  			</div>

        </div>
    }
}