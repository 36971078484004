import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import AgentList from './../Components/Admin/AgentList';
import AgentNew from './../Components/Admin/AgentNew';
import AgentContainer from '../Containers/AgentContainer';
import ManagerDashboard from '../Components/Dashboard/ManagerDashboard';
import AgentMap from '../Components/Admin/AgentMap';
import FillCredit from '../Components/Plan/FillCredit';
import ManagerDashboardV2 from '../Components/Dashboard/ManagerDashboardV2';



function mapStateToProps(state) {
  return { auth: state.auth };
};


class ManagerRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}

							<Route exact path='/manager/fill' component={FillCredit} />
							{/* <Route exact path='/manager/dashboard' component={ManagerDashboard} /> */}
							<Route exact path='/manager/dashboard' component={ManagerDashboardV2} />
							<Route exact path='/manager/agents' component={AgentList} />
							<Route exact path='/manager/agents/new' component={AgentNew} />
							<Route exact path='/manager/agents/map' component={AgentMap} />
							<Route path='/manager/agents/:userid' component={AgentContainer} />
							
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(ManagerRouter)