import React from "react";
import ClientAddressRow from "./ClientAddressRow";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { startLoading, doneLoading } from "../../Redux/actions";
import Select from "react-select";
import { closeFlash, setFlash } from './../../Redux/actions/index';
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import ReactDatePicker from "react-datepicker";

class ReductionsList extends React.Component {
  constructor(props) {
    super(props);
    
   
    this.state = {
      reductionsLoading: true,
      isEditModal:false,
      reductions: [],
      showAlert: false,
      warning: '',
      type:'',
      selectedException: null,
      isModalOpen: false,
      newName: '',
      newPercentage: 0,
      newStart : new Date(),
      newEnd : new Date()
    };
  }

  handleAddExceptionClick = () => {
    this.setState({ isModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      newName: '',
      newPercentage: 0,
    });
  };

  handleInputChange = (e) => {
    const value = e.target.name === "newPercentage" ? Number(e.target.value) : e.target.value; // Convert newTarif to a number
    this.setState({ [e.target.name]: value });
  };

  handleFormSubmit = () => {
    // Call the existing addNewException function with the new exception details
    const { newName, newStart, newEnd, newPercentage,} = this.state;
    this.addNewReduction(newName, newStart, newEnd, newPercentage);
    // Close the modal after submitting
    this.setState({ isModalOpen: false });
    this.setState({newName :'', newPercentage : 0, newStart : '', newEnd : ''});
  };


  openEditModal = (exception) => {
    this.setState({ isEditModal: true, selectedException: exception });
  };

  
   loadReductions = () => {
     api.get(`/api/client/reduction/fetch`).then(res => {
       console.log(res);
      this.setState({
        reductionsLoading: false,
        reductions: res.data.reductions
      });
    });
  };

  
  componentDidMount() {
    this.loadReductions();
  }


  addNewReduction = (nom,startDate, endDate, percentage) => {
    const data = {
      "name":nom,
      "start":startDate,
      "end":endDate,
      "percentage":percentage
    };
    api.post(`/api/client/reduction/add`,data).then(res => {
      console.log(res);
      this.setState({
        reductionsLoading: false,
      });
      this.loadReductions();
    });
  }


    deleteReduction = (id) => {
    const data = {
      "id":id
    };
    api.post(`/api/client/reduction/delete`,data).then(res => {
      console.log(res);
      this.setState({
        reductionsLoading: false,
      });
      this.loadReductions();
    });
   }

  render() {

   return (
      <>
        <React.Fragment>
          <div className={"container-header"}>
            Reductions
            <button onClick={this.handleAddExceptionClick} className="btn btn-primary" style={{ float: 'right' }}>Ajouter reduction</button>
          </div>
        <div className="ctable mb-3  ui-rect np">
          <div className={"row head-titles py-2 mx-2"} style={{ background: 'white' ,  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
              <div className={"col-md-2"}>nom</div>
              <div className={"col-md-2"}>date debut</div>
              <div className={"col-md-2"}>date fin</div>
              <div className={"col-md-2"}>pourcentage</div>
              <div className={"col-md-4 text-right"}>Actions</div>
            </div>

            {this.state.reductions.map((reduction, idx) => (
            <div key={idx} className={"row ctable-row"}>
              <div className={"col-md-2"}>{reduction.name}</div>
              <div className={"col-md-2"}>{reduction.start}</div>
              <div className={"col-md-2"}>{reduction.end}</div>
              <div className={"col-md-2"}>{reduction.reduction} %</div>
              
              <div className={"col-md-4 action-col"}>
                 <button className={"btn btn-sm btn-secondary ml-2 p-2"}
                  onClick={() => this.deleteReduction(reduction.id)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>

        
          <Modal
          show={this.state.isModalOpen}
          handleClose={this.handleModalClose}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>Ajouter une nouvelle exception</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <input
                type="text"
                name="newName"
                placeholder="nom ..."
                value={this.state.newName}
                className={"form-control mb-2"}
                onChange={(e) => this.setState({ newName: e.target.value })}
              />

              <input
                type="number"
                name="newPercentage"
                placeholder="Tapez le pourcentage de la reduction ..."
                value={this.state.newPercentage}
                className={"form-control mb-2"}
                onChange={(e) => this.setState({ newPercentage: e.target.value })}
                min="0"
              />

            <div className={"dates-wrap"}>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                placeholderText={"Date début"}
                timeFormat={"HH:mm"}
                showTimeSelect={false}
                className={"form-control mb-2"}
                selected={this.state.newStart}
                onChange={(date) => this.setState({ newStart: date })}
                selectsStart
                startDate={this.state.newStart}
                endDate={this.state.newEnd}
                wrapperClassName={"col-md-12"}
              />
            </div>

            <div className={"dates-wrap"}>
              <ReactDatePicker
                dateFormat={"yyyy-MM-dd"}
                placeholderText={"Date fin"}
                timeFormat={"HH:mm"}
                showTimeSelect={false}
                className={"form-control mb-2"}
                selected={this.state.newEnd}
                onChange={(date) => this.setState({ newEnd: date })}
                selectsEnd
                startDate={this.state.newStart}
                endDate={this.state.newEnd}
                wrapperClassName={"col-md-12"}
              />
            </div>




           </Modal.Body>
          <Modal.Footer>
            <button 
              className={"btn"}
              onClick={this.handleModalClose}>Annuler</button>
            <button 
              onClick={this.handleFormSubmit}
              className={"btn btn-primary"}>OK</button>
          </Modal.Footer>
        </Modal>

        </React.Fragment>
      </>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    isLoading: m => dispatch(startLoading(m)),
    doneLoading: m => dispatch(doneLoading()),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
};
export default connect(null, mapDispatch)(ReductionsList);
