import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading, loadData } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import './ClientInterfaceStyle.css';
import { faSearch, faTimes, faShippingFast, faListAlt, faFileInvoiceDollar,  faTimesCircle, faSpinner, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
import delivery from '../../../delivery.png';
import Facture from '../../../Facture.png';
import List from '../../../List.png';
import { Link, NavLink } from 'react-router-dom';
import CommandeInterface from '../Commande/CommandeInterface'
import { BagPlus, CardList } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import * as CONSTANTS from "../../Utils/Constants";
import api from "../../Utils/Api";
import * as actions from '../../Redux/actions/index';
import {ExportCSV}  from '../Rapport/ExportCSV';


const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e)),
      setFlash: (f) => dispatch(actions.setFlash(f)),
      closeFlash: (f) => dispatch(actions.closeFlash(f))
    };
  }


class ClientInterface extends Component {

    constructor(props) {
        super(props);

        // this.props.isLoading({
        //   message: "Loading tasks",
        //   animate: true
        // });
    
        this.state = {
            filters: {
                search_bordereau: ''
            },
            showImport: false,
            isFilters:false
        };
    }
    updateFilter = (event, name) => {
        let value = "";
        
        value = event.target ? event.target.value : event.value;
        console.log('val=>'+value)
        console.log('name=>'+name)
        this.setState(
        {
            filters: {
            [name]: value
            }
        }
        );
    };

    loadData = () => {
       
    }
    submitForm = (e) => {
        e.preventDefault();
        //this.setState({ error: "", notImported: [], loading: true });
        this.setState({loading: true})
        const data = new FormData(e.target);
        api.post('api/bordereau/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            if(res.data.error){
              this.setState({showImport: false, loading: false })
            }
            
            if(res.data.success){
              this.props.setFlash({
                active: true,
                message: "Action done",
                classes: "success",
                style: {}
              })
              this.setState({showImport: false, loading: false })
            }
            window.location.reload();

    
        })
        .catch(err => {
            this.setState({ error: "Une erreur est survenu, veuillez contactez l'administrateur.", loading: false });
        })
      }

    render(){
        var today = new Date(),
        now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        return(
            <div class="container-fluid">
                <div className="col-md-12 my-5">
                    <div className='row'>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {/* <form class="form" > */}
                                <div class="input-group">
                                    <input 
                                        class="form-control p-4" 
                                        type="text" 
                                        name="search_bordereau" 
                                        onChange={e => this.updateFilter(e, 'search_bordereau')} 
                                        placeholder="Recherche par code bordereau ou par numéro du contact..." 
                                        aria-label="Search" style={{paddingLeft: "20px", borderRadius: "40px", backgroundColor: '#fff'}} 
                                        id="mysearch">

                                    </input>
                                    <div class="input-group-addon mt-2"  style={{marginLeft: "-50px", zIndex: 3, borderRadius: "40px", backgroundColor: "transparent", border:"none"}}>
                                        <button 
                                            class="btn btn-link" 
                                            style={{borderRadius: "20px"}} 
                                            id="search-btn"
                                            onClick={e => this.loadData(e)}
                                        >
                                                <FontAwesomeIcon className={"ml-2"} icon={faSearch} />
                                        </button>
                                    </div>
                                </div>
                            {/* </form> */}
                        </div>
                        
                        
                    </div>

                </div>

                <div className="col-md-12">
                    <div className="row">                     
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <div className="col-md-12">
                                <div className="row">
                                                            
                                    <div className="col-md-4 my-2">
                                        <div className="shadow ui-rect " >
                                            <div class="d-flex justify-content-end">
                                                <div className='home-box-title text-light rounded' >
                                                    &nbsp;Nouvelle Commande&nbsp;
                                                </div>
                                            </div>
                                            
                                            {/* <h3 className="bg-primary text-white text-center py-2 mx-2">Nouvelle Commande</h3> */}
                                            <br/>
                                            {/* <img src={delivery} style={{width: "100px", height: "100px"}} alt="logo" /> */}
                                            <FontAwesomeIcon icon={faShippingFast} size="3x" color="#2a2727"/>
                                            {/* <BagPlus size={60}/>*/}
                                            <br/>
                                            <div className=" ">
                                                <br/>
                                                <h5><b>Ajouter une commande</b></h5>
                                                
                                                <p class="text-muted">Commandez en cliquant sur le bouton Commander !!</p>
                                            </div>
                                            <br/>
                                            <button  class="btn btn-primary rounded-pill shadow " onClick={() => this.setState({ showNewTask: true })}>Commander</button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <div className="ui-rect shadow" >
                                            <div class="d-flex justify-content-end">
                                                <div className='home-box-title text-light rounded' >
                                                    &nbsp;
                                                    {this.props.user.role === 'client' && "Mes Commandes"}
                                                    {this.props.user.role !== 'client' && "Commandes"}
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <br/>
                                            <FontAwesomeIcon icon={faListAlt} size="3x" color="#2a2727"/>
                                            {/* <CardList size={55}/> */}
                                            <div className=" ">
                                                <br/>
                                                <h5><b> Importation des commandes</b></h5>
                                                {/* <p class="text-muted">Consulter vos commades en cliquant sur Mes Commandes !!</p> */}
                                                <p class="text-muted">Importez une liste des commandes par un ficher excel  </p>
                                            </div>
                                           
                                            <br/>
                                            {/* {this.props.user.role === 'client' && <Link to="/commande"><button  class="btn btn-primary rounded-pill shadow" >Mes Commandes</button></Link>}
                                            {this.props.user.role !== 'client' && <Link to="/commande"><button  class="btn btn-primary rounded-pill shadow" >Commandes</button></Link>} */}
                                            <button type="button" class="btn btn-primary rounded-pill shadow" onClick={() => this.setState({ showImport: true })} >
                                                Importer
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-4 my-2">
                                            <div className="ui-rect shadow" >
                                                <div class="d-flex justify-content-end">
                                                    <div className='home-box-title text-light rounded' >
                                                        &nbsp;
                                                        {this.props.user.role === 'client' && "Mes Factures"}
                                                        {this.props.user.role !== 'client' && "Factures"}
                                                        &nbsp;
                                                    </div>
                                                </div>
                                                <br/>
                                                
                                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="3x" color="#2a2727"/>
                                                <br/>
                                                <div className=" ">
                                                    <br/>
                                                    <h5><b>Liste factures </b></h5>
                                                    <p class="text-muted">Consulter vos factures en cliquant sur le bouton ci-dessous !!</p>
                                                </div>
                                                <br/>
                                                {this.props.user.role === 'client' &&  <Link to="/mesfactures">
                                                    <button  class="btn btn-primary rounded-pill shadow">
                                                        Mes Factures
                                                    </button>
                                                </Link>}

                                                {this.props.user.role !== 'client' &&  <Link to="/mesfactures">
                                                    <button  class="btn btn-primary rounded-pill shadow">
                                                        Factures
                                                    </button>
                                                </Link>}
                                            </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        
                    </div>
                </div>

                <CommandeInterface 
                    from={true} 
                    filters={this.state.filters} 
                />
                
                {this.state.showNewTask && (
                    <div className={`ui-overlay open`}>
                        <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
                        <button
                            onClick={() => this.setState({ showNewTask: false })}
                            className={"ui-overlay-close btn btn-danger btn-sm"}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>

                        <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} />
                        
                        </div>
                    </div>
                    )}

                    <Modal show={this.state.showImport} centered>
                        <Modal.Header >
                            <Modal.Title>Importer une liste de nouveaux du nouveaux bordereaux</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <a  href={CONSTANTS.REMOTE_API+'/File/template_bordereau.xlsx'} target={"_blank"} className={"btn btn-sm btn-info"}>Télécharger le fichier template</a>
                            <br/><br/>
                            
                            <form  onSubmit={this.submitForm}>
                            <input type="file" name="bordereau_file" className={""} />
                            
                            <button disabled={this.state.loading} type="submit" className={"btn btn-success float-right"}>
                                {this.state.loading ? <FontAwesomeIcon icon={faSpinner}  spin={true} /> : (<><FontAwesomeIcon icon={faArrowUp} className={"mr-2"} />Envoyer</>)}
                            </button>
                            </form>
                                        </Modal.Body>
                                        <Modal.Footer>
                            <button className={"btn btn-danger"}  onClick={e => this.setState({showImport: false})}>
                                Annuler
                            </button>

                        </Modal.Footer>
                    </Modal>
                
            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClientInterface));
  
