import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import FactureListContainer from '../Containers/FactureListContainer';
import FactureInterface from '../Components/Facture/FactureInterface'



function mapStateToProps(state) {
  return { auth: state.auth };
};


class FacturesRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/factures' component={FactureListContainer} />
						<Route exact path='/mesfactures' component={FactureInterface} />
						
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(FacturesRouter)