import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const TaskDetailModal = ({show, close, task, ...props}) => {

    return (<div className={"ui-overlay open"}>
    <div className={"ui-overlay-content task-detail col-md-6 offset-md-3"}>
      <button
        onClick={close}
        className={"ui-overlay-close btn btn-danger btn-sm"}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <h5>Détail</h5>

      <div className={"task-detail-wrap"}>

        <div className={"row"}>
            <div className={"col-md-6"}>
                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Reference</span>

                    <span className={"detail-value"}>
                        {task.task_name}
                    </span>
                </div>

                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Contact Client</span>

                    <span className={"detail-value"}>
                        {task.task_client_phone}
                    </span>
                </div>

                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Contact point départ</span>

                    <span className={"detail-value"}>
                    {!!task.subtasks["a_999"] && (
                        task.subtasks["a_999"].subtask_contact_phone+ " - "+ task.subtasks["a_999"].subtask_contact
                    )}
                    </span>
                </div>


                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Commentaire</span>

                    <span className={"detail-value"}>
                        {task.task_obs_vente}
                    </span>
                </div>

            </div> 


            
            


            <div className={"col-md-6"}>
                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Mise à dispo</span>

                    <span className={"detail-value"}>
                        {task.task_mise_dispo && task.task_mise_dispo !== "na" && task.task_mise_dispo}
                    </span>
                </div>

                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Type de course</span>

                    <span className={"detail-value"}>
                        {task.task_type_course}
                    </span>
                </div>

                <div className={"task-detail-single"}>
                    <span className={"detail-label"}>Type réglement</span>

                    <span className={"detail-value"}>
                        {task.task_type_reglement}
                    </span>
                </div>

                
            </div>
        </div>
        </div>
      </div>
  </div>);

}
  
  

export default TaskDetailModal;
