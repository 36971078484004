import React from "react";
import api from "../../../Utils/Api";
import _ from "lodash";

class ClientInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "idle",
      active: false,
      suggestions: [],
      inputValue: this.props.defaultValue || ""
    };
  }

  selectSuggetion = suggestion => {
    if(this.props.selectClient)
      this.props.selectClient(suggestion);

    this.setState({
      suggestions: [],
      inputValue: suggestion.client_name
    });
  };

  handleOnChange = e => {};

  inputFocus = e => this.setState({ status: "focused" });

  inputBlur = () => this.setState({ status: "idle" });

  clearInput = () => this.selectSuggetion({ client_name: "" });

  searchSuggestions = e => {
    const value = e.target.value;

    this.setState({
      inputValue: value,
      status: "search",
      suggestions: []
    });

    if (this.apiSearchTimeout) clearTimeout(this.apiSearchTimeout);

    if (value.length > 0) {
      //this.apiSearch.cancel();

      this.apiSearchTimeout = setTimeout(() => this.apiSearch(value), 250);
    } else {
      this.setState({
        status: "idle",
        suggestions: []
      });
    }
  };

  apiSearch = _.debounce(
    value =>
      api.post("/api/client/search", { client: value }).then(response => {
        if (response.data.success) {
          this.setState({
            status: "suggest",
            suggestions: response.data.result
          });
        } else {
          this.setState({
            status: "suggest",
            suggestions: []
          });
        }
      }),
    200
  );

  render() {
    return (
      <>
        <div onBlur={this.inputBlur} className={"geo-wrapper"} style={{ position: "relative" }}>
          <input
            required
            {...this.props}
            value={this.state.inputValue}
            onChange={this.searchSuggestions}
            onFocus={this.inputFocus}
            placeholder="Client"
            autoComplete={"off"}
          />

          {this.state.inputValue.length > 0 && (
            <span onClick={this.clearInput} className={"clear-geo-input"}>
              x
            </span>
          )}

          {this.state.suggestions.length > 0 ? (
            <div className={"geo-suggestions-wrapper" + (this.state.status !== "idle" ? " active" : "")}>
              <ul>
                {this.state.suggestions.map(suggestion => (
                  <li onClick={e => this.selectSuggetion(suggestion)}>{suggestion.client_name}</li>
                ))}

                <li
                  onClick={e =>
                    this.selectSuggetion({
                      client_name: this.state.inputValue,
                      client_address: "",
                      client_id: 0
                    })
                  }
                >
                  Add new "<b>{this.state.inputValue}</b>"
                </li>
              </ul>
            </div>
          ) : this.state.inputValue.length > 0 ? (
            <div className={"geo-suggestions-wrapper" + (this.state.status !== "idle" ? " active" : "")}>
              <ul>
                {this.state.status === "search" ? (
                  <li>Searching...</li>
                ) : (
                  <li
                    onClick={e =>
                      this.selectSuggetion({
                        client_name: this.state.inputValue,
                        client_address: "",
                        client_id: 0
                      })
                    }
                  >
                    Add new "<b>{this.state.inputValue}</b>"
                  </li>
                )}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default ClientInput;
