import React from "react";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import * as CONSTANTS from "./../../../Utils/Constants";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import { connect } from "react-redux";
import TaskDetailInline from "./TaskDetailInline";
import TaskEditInline from "./TaskEditInline";


class TaskRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: {},
      showDetails: !!this.props.show,
      showPhotos: false,
      selectedIdx: false,
      edit: false
    };
  }

  toggleTask = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  showPhotos = idx => this.setState({ showPhotos: true, selectedIdx: idx });


  hidePhotos = () => this.setState({ showPhotos: false, selectedIdx: false });


  changeColorBadge = (item) =>{
    if(item == 1){
      return "badge badge-success badge-pill";
    }else if(item == 0){
      return "badge badge-danger badge-pill";
    }
    return  "badge badge-light badge-pill";
  }
  changeColorBadgeDoc = (item) =>{
    if(item == 'true'){
      return "badge badge-success badge-pill";
    }
      return "badge badge-danger badge-pill";
    
  }
  changeColorSubTask = (item) =>{
    if(item == 'done')
      return 'text-success'
    if(item == 'started')
      return 'text-warning'
    return 'text-muted'
  }


  render() {
    const { task, idx, toggleOverlay } = this.props;

    const points = Object.keys(task.subtasks).filter(item => item !== "a_-1" && item !== "a_999");

    const isFuture =
      Boolean(task.subtasks["a_-1"]) &&
      parseInt(moment(task.subtasks["a_-1"].subtask_start_datetime).format("D")) > parseInt(moment().format("D"));
    const isPast =
      Boolean(task.subtasks["a_-1"]) &&
      parseInt(moment(task.subtasks["a_-1"].subtask_start_datetime).format("D")) < parseInt(moment().format("D"));

    const showSubtaskType = (type) => {


      if(type)
        return String(type).substr(0, 1);


      return "";

    }  
    

    return (
      <>
        {this.state.showPhotos && (
          <>
            <div className={this.state.showPhotos ? "ui-overlay open" : "ui-overlay"}>
              <div className={"ui-overlay-content photos-overlay col-md-5"}>
                <button
                  data-package-id="0"
                  onClick={() => this.hidePhotos()}
                  className={"ui-overlay-close btn btn-danger btn-sm"}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className={"task-photos-wrap"}>
                  {task.subtasks[this.state.selectedIdx].subtask_photos &&
                    task.subtasks[this.state.selectedIdx].subtask_photos.map(photo => (
                      <div className={"task-photos-element"}>
                        <a href={CONSTANTS.REMOTE_API + "/" + photo} target={"_blank"}>
                          <img src={CONSTANTS.REMOTE_API + "/" + photo} />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        <React.Fragment>
          <div
            // onClick={this.toggleTask}
            data-row-index={idx}
            className={
              "row task-row no-gutters " +
              (!!this.state.showDetails && " active-row ") +
              (isFuture && " future-task ") +
              (isPast && " past-task ")
            }
          >
              <div className={"col-md-12"}>
                  <div className={"row table-row mt-2"}>
                    {/* ----------------to display Date --------------- */}
                    <div className={"col-md-1"}>
                      {!!task.subtasks["a_-1"] && moment(task.subtasks["a_-1"].subtask_start_datetime).format("DD/MM/YY")}<br/>
                      {task.task_name}
                    </div>

                    {/* -----------------to display Code ------------ */}
                    {/* <div className={"col-md-2"}> {task.task_name} </div> */}

                    {/*------------- to display Client  ------------*/}
                    <div className={"col-md-1"}><b>{task.task_client_name} </b> </div>

                    {/*---------- to display Trajet  ------------*/}
                    {/* <div className={"col-md-3 col-trajet"}>
                      <span>{!!task.subtasks["a_-1"] && <b>{task.subtasks["a_-1"].subtask_address_commune} </b>}</span>

                      <span className={"task-points"}>
                        {Boolean(points.length) && (
                          <>
                            {points.length + " points"}
                            <div className={"task-points-detail"}>
                              <ul>
                                {points.map((point, k) => (
                                  <li key={k}>
                                    {showSubtaskType(task.subtasks[point].subtask_type)} <b>{task.subtasks[point].subtask_address_commune}</b>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </>
                        )}
                      </span>

                      <span>{!!task.subtasks["a_999"] && <b>{task.subtasks["a_999"].subtask_address_commune}</b>}</span>
                    </div> */}
                    <div className="col-md-1">
                      <span class={this.changeColorSubTask(task.subtasks["a_-1"].subtask_status)}>{!!task.subtasks["a_-1"] && <b>{task.subtasks["a_-1"].subtask_address_commune} </b>}</span>
                    </div>
                    <div className="col-md-1">
                                {points.map((point, k) => (
                                  <li key={k} class={this.changeColorSubTask(task.subtasks[point].subtask_status)}>
                                    {showSubtaskType(task.subtasks[point].subtask_type)} <b>{task.subtasks[point].subtask_address_commune}</b>
                                  </li>
                                ))}
                    </div>
                    <div className="col-md-1">
                      <span class={this.changeColorSubTask(task.subtasks["a_999"].subtask_status)}>{!!task.subtasks["a_999"] && <b>{task.subtasks["a_999"].subtask_address_commune}</b>}</span>
                    </div>

                    {/*------------- to display Type of Vehicule-------------  */}
                    <div className={"col-md-1"}>{task.task_vehicule_type}</div>

                    {/* --------------to display Task hours --------------*/}
                    <div className={"col-md-1 agent-col"}>
                      <span className={"task-hours"}>
                        Chr
                        <b>{!!task.task_heure_charge && moment(task.task_heure_charge).format("HH:mm")}</b>
                      </span>
                      <span className={"task-hours"}>
                        Dsp
                        <b>{!!task.task_heure_dispo && moment(task.task_heure_dispo).format("HH:mm")}</b>
                      </span>
                    </div>
                      
                      {/* --------------to display Ajouer par  ------------*/}
                    <div className={"col-md-1 "}>
                      <span>{task.task_created_by}</span>
                    </div>
                      {/*------------ to display agent name and statut of task (assigned or not) ---------- */}
                    <div className={"col-md-2"}>
                      <span>
                        {task.task_agent && (
                          <b>
                            <Link to={"/manager/agents/"+task.task_agent_id+"/track"}>
                              {task.task_agent}
                            </Link>
                            <br />
                          </b>
                        )}
                      </span>
                      <span className={"task-status d-inline status-id-" + task.task_status_id}>
                        {String(task.task_status).toLowerCase()}
                      </span>

                      {task.status && (<span className={"task-status d-block pr-1"}>{task.status}</span>)}
                    </div>



                      {/*---------- to display arry of button DMDAD -------------- */}
                      <div className ={"col col-md-2 action-col"} style={{align: 'center'}}>
                            <div className="d-inline mr-4">
                              <span><button className="btn btn-sm btn-primary rounded-0" onClick={this.toggleTask} title="Détails"><small>D</small></button></span>
                                
                              <span>
                                {(this.props.user.role === "manager" || this.props.user.role === "commercial") && (

                                  <button
                                    onClick={() => this.setState({ edit: !this.state.edit })}
                                    className="btn btn-sm btn-primary rounded-0"
                                    title="Modifier"
                                  >
                                    <small>M</small>
                                  </button>
                                )}
                              </span>

                              <span>
                                  <Link className="btn btn-sm btn-primary rounded-0" to={"/task/" + task.task_id + "/duplicate"} title="Dupliquer"><small>D</small></Link>
                              </span>

                              <span>
                                  {(this.props.user.role === "manager" || this.props.user.role === "dispatcher") && (
                                    <button className="btn btn-sm btn-primary rounded-0" onClick={() => toggleOverlay(task.task_id)} title="Assigner">
                                      <small>A</small>
                                    </button>
                                  )}
                              </span>

                              <span>
                                  {(this.props.user.role === "manager" || this.props.user.role === "operations") && (
                                    <button className="btn btn-sm btn-primary rounded-0" onClick={() => this.props.toggleDocOverlay(task.task_id)} title="Document"><small>D</small></button>
                                  )}
                              </span>
                              </div>
                              <div className="d-inline mr-4" >
                                <span class={this.changeColorBadge(task.task_retour)}>&nbsp;</span>
                              </div> 

                              <div className="d-inline " >
                                <span class={this.changeColorBadgeDoc(task.task_docs_recu)}>&nbsp;</span>
                              </div> 
                            
                      </div>
                      {/* -----------Retour----------- */}
                     
                      
                  </div>

                  <TaskDetailInline showPhotos={this.showPhotos} task={task} active={this.state.showDetails} />
                  {this.state.edit && (
                    <TaskEditInline close={() => this.setState({ edit: false })} task={task} active={this.state.edit} />
                  )}
              </div>


          </div>
        </React.Fragment>
      </>
    );
  }
}

const mapState = state => ({ user: state.user });

export default connect(mapState)(TaskRow);
