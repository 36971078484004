import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faPlus } from '@fortawesome/free-solid-svg-icons'
import {  Trans } from "react-i18next";

const moment = require('moment');


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(setAuthAction(user)),
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}

class AgentTasks extends Component {

    constructor(props) {
		super(props);
		this.state = {
        
            agents: [],
			status: [],
			types: [],
            ready: false, 
            tasks: [],
            current: 1,
            pages: 0,
            filters: {
                type: "",
                status: "",
                name: ""
            }
				
			
		}

		this.props.isLoading();

		
        this.loadTasks = this.loadTasks.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        
    }
    


    loadTasks(p = this.state.current) {

		let nstate = this.state;
		let that = this;

		const crits = "agent="+this.props.match.params.userid
					  +"&type="+this.state.filters.type
					  +"&status="+this.state.filters.status
					  +"&name="+this.state.filters.name

		api.get('/api/task/fetch?token='+this.props.user.token+'&page='+p+'&'+crits)
		.then(function(response) {
			if(response.data.success) {
			
				that.setState({tasks: response.data.tasks, pages: response.data.pages, rows: nstate.rows})
			} else {
				if(response.data.errcode === 101) {
					//logout
				}
			}

			that.props.doneLoading();

		});
    }
    
    updateFilter(event, name) {
		let that = this;
		const value = event.target ? event.target.value : event.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadTasks(1);
		}); 


		

    }
    

    selectPage(p) {
		this.props.isLoading();

		this.setState({current: p});

		this.loadTasks(p);
	}


    componentDidMount() {

        const that = this;


        this.loadTasks();

        api.get('/api/task/form?token='+this.props.user.token)
		.then(function(response) {
			that.setState({
				agents: [{label: 'All', value:0}, ...response.data.agents], 
				types: [{label: 'All', value:0}, ...response.data.types], 
				status: [{label: 'All', value:0}, ...response.data.status]
			}, function() {
				console.log(that.state);
			})
		});
    }

    render() {
        return (
            <React.Fragment>
            <div className={"row"}>
                <div className={"form-group col-md-3"}>
                    <label>Task type</label>
                    <Select
                    options={this.state.types}
                    placeholder="Types"
                    onChange={e => this.updateFilter(e, 'type')}
                    />
                </div>

                <div className={"form-group col-md-3"}>
                    <label>Status</label>
                    <Select
                    options={this.state.status}
                    placeholder="Status"
                    onChange={e => this.updateFilter(e, 'status')}
                    />
                </div>
            </div>

            <div className={"row"}>

            <div className={"col-md-12"}>
                <ul className={"pagination"}>
                    <li className={"label"}>Pages</li>
                    {[...Array(this.state.pages)].map((v, i) =>
                        (<li key={i} onClick={e => this.selectPage(i+1)} className={this.state.current === i+1 ? 'active' : ""}><a href="javascript:void(0)">{i+1}</a></li>))}

                </ul>

                <div className={"clear mb-6"}></div>
            </div>


            </div>

            <div className={"col-md-12"}>
            <div className={"d-none d-sm-block"}>
				<div className={"row head-titles ctable-row"}>

					<div className={"col-md-1"}>ID</div>

					<div className={"col-md-2"}><Trans i18nKey="label_username" /></div>

					<div className={"col-md-2"}><Trans i18nKey="label_payment" /></div>

					<div className={"col-md-2"}><Trans i18nKey="label_amount" /></div>

					<div className={"col-md-3"}><Trans i18nKey="label_datetime" /></div>

					<div className={"col-md-1"}><Trans i18nKey="label_status" /></div>

					<div className={"col-md-1"}><Trans i18nKey="label_details" /></div>

				</div>


			</div>

            {this.state.tasks.map((task, i) => 
				
                (<React.Fragment key={i}>
                    <div className={"row "}>
                    <div className={"col-md-12"}>
    
                    <div className={"row ctable-row"}>
                        
                        
                            <div className={"col-md-1"}>
                                {task.task_id} <Link to={'/task/'+task.task_id}>Edit</Link>
                            </div>
                            <div className={"col-md-2"}>
                                {task.task_type}
                            </div>
    
    
                            <div className={"col-md-2"}>
                                {task.task_name}
                            </div>
    
                            <div className={"col-md-2"}>
                                <span className={(moment(task.task_end_date).diff(moment(), 'days') < 0 && task.task_status_id < 4 ) ? "label-danger" : ""}>
                                
                                {task.task_end_date}
                                </span>
                            </div>
    
                            <div className={"col-md-3"}>
                                {task.task_address}
                            </div>
                            <div className={"col-md-1"}>
                                <span className={"task-status status-id-"+task.task_status_id}><Trans i18nKey={"status_"+task.task_status.toLowerCase()} /></span>
                            </div>
                            <div className={"col-md-1"}>
    
                            <button data-row-index={i} className="btn btn-sm btn-info" ><FontAwesomeIcon icon={faPlus}  /> Info</button>
                            
                            </div>
    
                        
                    </div>
                    
                </div>
                </div>
                </React.Fragment>
    
                ))}
                </div>



            </React.Fragment>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AgentTasks)