import React from "react";
import { Link } from "react-router-dom";
import api from "../../Utils/Api";
import {ExportCSV}  from '../Rapport/ExportCSV';
import {  Trans } from "react-i18next";


import {
  faTimes,
  faPen,
  faListAlt,
  faPowerOff,
  faDollarSign,
  faUndo,
  faLink,
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { startLoading, doneLoading, setAuthAction } from "../../Redux/actions";
import Select from "react-select";
import Pagination from "../../Utils/Pagination";
import Modal from 'react-bootstrap/Modal';
import { ModalTemplate } from "../Modal/ModalTemplate";

class ClientList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      byID:{
        up: false,
        down: false,
        sort: true,
      },
      byclient:{
        up: false,
        down: false,
        sort: true,
      },
      byAdress:{
        up: false,
        down: false,
        sort: true,
      },
        clients: [],
        total: 0,
        current: 1,
        pages: 1,
        limit: 10,
        filters: {
          client: "",
          byID: "",
          byclient: "",
          byAdress: ""
        },
        isModal: false,
        currentBordereau: [], 
        selectedClient: null,
        selectedClientName: null,
        showAlert: false,
        message: '',
        nameClass:'',
        reductions: [],
        selectedReductionId:'',
        selectedClientsIds : [],
        applyReductionModalIsOpen :false,
        clientIdToApplyReduction : '',
        clientId : -1
      };

      this.props.isLoading();
    }

    componentDidMount() {
      this.loadClients();
      this.loadReductions();
    }

    buildURI = () => {
      const { filters } = this.state;
      return `/api/v2/client/fetch?client=${filters.client}&byID=${filters.byID}&byclient=${filters.byclient}&byAdress=${filters.byAdress}&page=${this.state.current}&limit=${this.state.limit}`;
    };

    loadClients = (p = this.state.current) => {
      api
        .get(this.buildURI())
        .then(res => {
          if (res.data.success) this.setState({ clients: res.data.clients, total: res.data.total, pages: res.data.pages });

          this.props.doneLoading();
        });
    };

   selectAll = (type) => {
    if(type === 'select'){
      this.setState({
        allSelected:true,
        selectedClientsIds : this.state.clients.map(client => client.client_id)
      });
    }else{
      this.setState({
        selectedClientsIds: [],
        allSelected:false
      })
    }
  }


  loadReductions = () => {
    api.get(`/api/client/reduction/fetch`).then(res => {
      console.log(res);
      if(res.data.success){
        this.setState({
          reductions: res.data.reductions.map(reduction => ({
            label: reduction.name,
            value: reduction.id,
          }))
        });
      }
    });
  };

    handleApplyReduction = () => {
      //const { selectedReductionId, clientId} = this.state;
      this.appllyReduction();
      
      this.setState({ applyReductionModalIsOpen: false });
    };

    appllyReduction = () => {
      const data = {
        "reduction_id":this.state.selectedReductionId,
        "clients": this.state.clientId != -1 ? [this.state.clientId] : this.state.selectedClientsIds
      };
      api.post(`/api/client/reduction/subscribe`,data).then(res => {
        console.log(res);
        this.setState({selectedReductionId :'', clientId: -1});
        this.loadClients();
      });
    }

    cancelReduction = (clientId) => {
      const data = {
        "clients": [clientId]
      };
      api.post(`/api/client/reduction/unsubscribe`,data).then(res => {
        console.log(res);
        this.loadClients();
      });
    }

  approveClient = (clientId) => {
    const data = {
      "id":clientId
    };
    api.post(`/api/client/approve`,data).then(res => {
      console.log(res);
      this.loadClients();
    });
  }

  cancelReductionBulk = () => {
    const data = {
      "clients": this.state.selectedClientsIds
    };
    api.post(`/api/client/reduction/unsubscribe`,data).then(res => {
      console.log(res);
      this.loadClients();
    });

  }

  appllyReductionBulk = () => {
    const data = {
      "clients": this.state.selectedClientsIds,
      "reduction_id" : this.state.selectedReductionId
    };
    
    api.post(`/api/client/reduction/subscribe`,data).then(res => {
      console.log(res);
      this.loadClients();
    });
  }

  addToSelected = (id) => {
  this.setState((prevState) => ({
    selectedClientsIds: [...prevState.selectedClientsIds, id]
  }), () => {
    console.log(this.state.selectedClientsIds);
  });
}

  removeFromSelected = (id) => {
  this.setState((prevState) => ({
    selectedClientsIds: prevState.selectedClientsIds.filter(clientId => clientId !== id)
  }), () => {
    console.log(this.state.selectedClientsIds);
  });
  }

    
  handleApplyReductionClick = (id) => {
    console.log(id);
      this.setState({
      applyReductionModalIsOpen: true,
      clientId: id
      }, () => {
    console.log(this.state.clientId);
  });

    };




    changeLimit = e => {
      this.props.isLoading();
      this.setState(
        {
          limit: e.value,
          current: 1
        },
        () => this.loadClients()
      );
    };

    selectPage = p => {
      this.props.isLoading();

      this.setState({ current: p }, () => this.loadClients(p));

      
    };

    updateFilter(e, name) {
      let that = this;

      const value = e.target ? e.target.value : e.value;

      this.setState(
        {
          filters: {
            ...this.state.filters,
            [name]: value
          }
        },
        function() {
          that.props.isLoading();
          that.loadClients(1);
        }
      );
    }

    sortData = (name) => {
      let action = ''
      if (name === 'byID'){
        if(this.state.byID.sort){
          this.setState({ 
            byID : {
              up: true,
              sort: false
            }
          })
          action = 'up'
        }else if (this.state.byID.up){
          this.setState({ 
            byID: {
              up: false,
              down: true
            }
          })
          action = 'down'
        }else{
          this.setState({ 
            byID:{
              up: true,
              down: false
            }
          })
          action = 'up'
        }
        this.setState({
          byclient:{
            sort:true,
            up: false,
            down: false
          },
          byAdress:{
            sort:true,
            up: false,
            down: false
          }
        })
      }
      if (name === 'byclient'){
        if(this.state.byclient.sort){
          this.setState({ 
            byclient : {
              up: true,
              sort: false
            }
          })
          action = 'up'
        }else if (this.state.byclient.up){
          this.setState({ 
            byclient: {
              up: false,
              down: true
            }
          })
          action = 'down'
        }else{
          this.setState({ 
            byclient:{
              up: true,
              down: false
            }
          })
          action = 'up'
        }
        this.setState({
          byID:{
            sort:true,
            up: false,
            down: false
          },
          byAdress:{
            sort:true,
            up: false,
            down: false
          }
        })
      }

      if (name === 'byAdress'){
        if(this.state.byAdress.sort){
          this.setState({ 
            byAdress : {
              up: true,
              sort: false
            }
          })
          action = 'up'
        }else if (this.state.byAdress.up){
          this.setState({ 
            byAdress: {
              up: false,
              down: true
            }
          })
          action = 'down'
        }else{
          this.setState({ 
            byAdress:{
              up: true,
              down: false
            }
          })
          action = 'up'
        }
        this.setState({
          byID:{
            sort:true,
            up: false,
            down: false
          },
          byclient:{
            sort:true,
            up: false,
            down: false
          }
        })
      }

      let that = this;
      
      this.setState(
        {
          filters: {
            ...this.state.filters,
            byID: '',
            byclient: '',
            byAdress: '',
            [name]: action
          }
        },
        function() {
          that.props.isLoading();
          that.loadClients(1);
        }
      );
      
      
    }

    userDisEnb = (id, action) => {
      let data = {status: action}
      api.post("/api/disable/user/"+id, data).then(result => {
        window.location.reload(false);
      })
    }

    userDisEnbNotif = (id, action) => {
      let data = {status: action}
      api.post("/api/disable/notif/client/"+id, data).then(result => {
        window.location.reload(false);
      })
    }

    getCurrentBill = (id , name) => {


      api.post("/api/facture/current/client/"+id).then(result => {
        if(result.data.success){
         
          this.setState({
            currentBordereau: result.data.bordereaux,
            isModal: true,
            selectedClient: id,
            selectedClientName: name
          })
        }
      })

      
    }

    deleteBordereau = (index) => {
      var array = this.state.currentBordereau; 
      if (index !== -1) {
          array.splice(index, 1);
          this.setState({
            currentBordereau: array
          })
      }
    }

    somme = () => { 
      const sum = this.state.currentBordereau.reduce((accumulator, object) => {
        return accumulator + object.tarif;
      }, 0);
      return sum
    }

    generateBill = () => {
      var ids = this.state.currentBordereau.map(function(item) {return item.id;});
      
      const data = {bordereaux: ids}
      api.post("/api/facture/client/"+this.state.selectedClient, ids).then(result => {
        if(result.data.success){
         
          this.setState({
            isModal: false,
            showAlert:true,
            message: 'Facturation done',
            nameClass: 'alert alert-success'
          })
         
        }
      })
    }
    handleShow = (isShow) => {
      console.log('here ')
      this.setState({
        showAlert: isShow
      })
    }

    handleModalClose = () => {
      this.setState({
        applyReductionModalIsOpen: false,
        selectedReductionId: '',
        //clientIdToApplyReduction: ''
      });
    };


    render() {
    var today = new Date(),
      now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    return (
      <>
        <div className={"container-header"}>
          Clients
          <div>
            {!!this.props.user &&
              (this.props.user.role === "admin" || this.props.user.role === "manager" )&& 
              (
                <Link to={"/client/reductions"} className={"cbtn float-right"}>
                  Reductions
                </Link>
              )}

           {!!this.props.user &&
            (this.props.user.role === "manager" ||
              this.props.user.role === "admin" ||
              this.props.user.role === "sup ops" ||
              this.props.user.role === "superviseur" ||
              this.props.user.role === "commercial") && (
                <Link to={"/client/new"} className={"cbtn float-right"}>
                  Nouveau Client
                </Link>
              )}
              
              {!!this.props.user &&
              (this.props.user.role === "admin" || this.props.user.role === "manager" )&& this.state.selectedClientsIds.length != 0 &&
              (
                  <button  className={"cbtn  float-right"} onClick={e => this.cancelReductionBulk()}>
                        Annuler reductions
                  </button>
              )}

              {!!this.props.user &&
              (this.props.user.role === "admin" || this.props.user.role === "manager" )&& this.state.selectedClientsIds.length != 0 &&
              (
                <button  className={"cbtn  float-right"} 
                  onClick={ () => this.handleApplyReductionClick(-1) }>
                        Appliquer une reduction
                  </button>
              )}


          </div>
        </div>
        <div className={"row form ui-rect"}>
          <div className={"col-md-3"}>
            <label>Recherche</label>
            <input
              placeholder="Recherche par Nom, Code client .."
              type={"text"}
              name="client"
              onChange={(e) => this.updateFilter(e, "client")}
              className={"form-control"}
            />
          </div>
          <div class="col-md-9 text-right">
            <ExportCSV
              csvData={this.state.clients}
              fileName={"Clients" + now}
              classBtn={"btn btn-md mt-4 btn-outline-success "}
            />
          </div>
        </div>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-6 items-found"}>
            <p>
              <b>{this.state.total}</b> clients trouvés
            </p>
          </div>

          <div className={"col-md-6 per-page-wrap"}>
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>
        </div>

        <div className="ctable mb-3 ui-rect np">
          <div className={"row head-titles ctable-row task-row"}>
            <div className={"col-md-1"}>
              {!this.state.allSelected ? <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('select')}> 
                <strong>Select All</strong>
              </botton> 
                  : 
                  <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('clear')}> 
                    <strong>Clear All</strong>
                  </botton>
              }  

            </div>

            <div className={"col-md-1"}>
              <button type="button" class="btn" onClick={(e) => this.sortData('byclient')}>
                <b>Client</b>
              </button>
            </div>

            <div className={"col-md-1"}>
              <button type="button" class="btn" onClick={(e) => this.sortData('byAdress')}>
                <b>Adresse</b>
              </button>
            </div>
            <div className={"col-md-1"}>Raison commerciale</div>
            <div className={"col-md-2"}>Téléphone/Email</div>

            <div className={"col-md-1"}>Type client</div>
            <div className={"col-md-1"}>Type facturation</div>
            <div className={"col-md-1"}>Reduction</div>
            <div className={"col-md-1"}>Status</div>
            {this.props.user.role !== "gestion" && (
              <div className={"col-md-2 action-col"}>
                Actions
              </div>
            )}
          </div>

          {this.state.clients.map((client, idx) => (
            <div key={idx} className={"row ctable-row"}>
              {/* <div className={"col-md-1"}>{client.client_id}</div> */}
              <div className={"col-md-1"}>
                {this.state.selectedClientsIds.includes(client.client_id) == true ? <input
                  name={"apply"}
                  type="checkbox" 
                  //className="text-right ml-2"
                  checked
                  onChange={(e) => this.removeFromSelected(client.client_id)}
                /> :

              <input
                name={"apply"}
                type="checkbox" 
                //className="text-right ml-2"
                  onChange={(e) => this.addToSelected(client.client_id)}
                />
                }



              </div>
                          <div className={"col-md-1"}>{client.client_name}</div>
              <div className={"col-md-1"}>{client.client_address}</div>
              <div className={"col-md-1"}>{client.client_raison_commercial}</div>
              <span className={"col-md-2"}>
                {client.client_phone}
                <br />
                <p class="text-primary">{client.client_email}</p>
              </span>
              <div className={"col-md-1"}>{client.client_type}</div>
              <div className={"col-md-1"}>{client.facture_type}</div>
              <div className={"col-md-1"}>{client.client_reduction}</div>
              <div className={"col-md-1"}>{client.approved == true ? "apprové" : "non apprové"}</div>
              {this.props.user.role !== "gestion" && (
                <div className={"col-md-2 action-col"}>
                  <div className="btn-group dropleft">
                    <button
                      type="button"
                      className="btn custom-action-btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-flip="false"
                    >
                      Actions
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button
                        type="button"
                        onClick={(e) => this.getCurrentBill(client.client_id, client.client_name)}
                        className="dropdown-item"
                      >
                        <FontAwesomeIcon icon={faListAlt} /> Factures en cours
                      </button>

                      {(this.props.user.role === "admin" || this.props.user.role === "manager" )&& client.approved != true &&(
                        <button
                        type="button"
                        onClick={(e) => this.approveClient(client.client_id)}
                        className="dropdown-item"
                      >
                        <FontAwesomeIcon icon={faCheck} /> Approuver
                      </button>


                      )}

                      {(this.props.user.role === "admin"  || this.props.user.role === "manager")&& (
                        <Link to={"/client/detail/" + client.client_id} className="dropdown-item">
                          <FontAwesomeIcon icon={faPen} /> Modifier
                        </Link>
                      )}

                      {(this.props.user.role === "admin" || this.props.user.role === "manager")&& (
                        <Link to={"/client/tarifs/" + client.client_id} className="dropdown-item">
                          <FontAwesomeIcon icon={faDollarSign} /> Tarif
                        </Link>
                      )}
                      

                      {client.client_status === 'active' && (
                        <button
                          type="button"
                          onClick={(e) => this.userDisEnb(client.user_id, 0)}
                          className="dropdown-item danger"
                        >
                          <FontAwesomeIcon icon={faPowerOff} /> <Trans i18nKey="label_off" />
                        </button>
                      )}
                      {client.client_status !== 'active' && (
                        <button
                          type="button"
                          data-user-id={client.user_id}
                          onClick={(e) => this.userDisEnb(client.user_id, 'active')}
                          className="dropdown-item success"
                        >
                          <FontAwesomeIcon icon={faPowerOff} /> <Trans i18nKey="label_on" />
                        </button>
                      )}

                      {client.client_notif_status === 'active' && (
                        <button
                          type="button"
                          onClick={(e) => this.userDisEnbNotif(client.user_id, 0)}
                          className="dropdown-item danger"
                        >
                          <FontAwesomeIcon icon={faPowerOff} /> Notif <Trans i18nKey="label_off" />
                        </button>
                      )}
                      {client.client_notif_status !== 'active' && (
                        <button
                          type="button"
                          data-user-id={client.user_id}
                          onClick={(e) => this.userDisEnbNotif(client.user_id, 'active')}
                          className="dropdown-item success"
                        >
                          <FontAwesomeIcon icon={faPowerOff} /> Notif <Trans i18nKey="label_on" />
                        </button>
                      )}
                      {(this.props.user.role == "admin" || this.props.user.role == "manager")&& client.have_reduction == true && (
                        <button
                        type="button"
                        onClick={()=>this.cancelReduction(client.client_id)}
                        className="dropdown-item"
                      >
                        <FontAwesomeIcon icon={faUndo} /> Annuler reduction
                      </button>


                      )}
                      {(this.props.user.role == "admin" || this.props.user.role == "manager" )&& client.have_reduction == false && (
                        <button
                        type="button"
                        onClick={ () => this.handleApplyReductionClick(client.client_id) }
                      className="dropdown-item"
                    >
                      <FontAwesomeIcon icon={faLink} /> Appliquer reduction
                    </button>
                    )}

                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>


      <Modal show={this.state.isModal} onHide={() => this.setState({isModal: false})} centered >
            <Modal.Header closeButton >
              <Modal.Title>À facturer <b>{this.state.selectedClientName}</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.currentBordereau.length > 0 ? <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Code</th>
                    <th scope="col">Tarif</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.currentBordereau.map((bordereau , index) => (<tr>
                    <th scope="row">{index + 1}</th>
                    <td>{bordereau.code}</td>
                    <td>{bordereau.tarif}</td>
                    <td>
                      <button type="button"
                        onClick={e => this.deleteBordereau(index)}
                        className={"btn btn-outline  btn-sm"}>

                          <FontAwesomeIcon icon={faTimes} />


                      </button>
                    </td>
                  </tr>))}

                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td  > {this.somme()} DA</td>
                    <td></td>
                  </tr>

                </tbody>
              </table>
              :
              <div className="text-center">
                Rien à facturer
              </div>
            }
            </Modal.Body>
            <Modal.Footer>
              <button className={"btn "}  onClick={e => this.setState({isModal: false})}>
                Annuler
              </button>
             {this.state.currentBordereau.length > 0 && <button type='submit' className={"btn btn-primary"} onClick={e => this.generateBill()} >
                Facturer
              </button>}
            </Modal.Footer>
          </Modal>

         <Modal
          show={this.state.applyReductionModalIsOpen}
          handleClose={this.handleModalClose}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>Appliquer une reduction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Select a reduction
            <Select
              //name="reductions"
              //classNamePrefix={"custom-select"}
              onChange={e => this.setState({selectedReductionId : e.value})}
              // className={"per-page nm"}
              options={this.state.reductions}
              //value={{ label: this.state.limit, value: this.state.limit }}
            />

            
           </Modal.Body>
          <Modal.Footer>
            <button 
              className={"btn"}
              onClick={this.handleModalClose}>Annuler</button>
            <button 
              onClick={this.handleApplyReduction}
              className={"btn btn-primary"}>OK</button>
          </Modal.Footer>
        </Modal>


      <ModalTemplate
        message={this.state.message}
        nameClass={this.state.nameClass}
        showAlert={this.state.showAlert}
        handleChange={this.handleShow}
      />
    </>
  );
}
 

  }

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    filters: state.filters
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: e => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
