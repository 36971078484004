import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import ClientDashboard from '../Components/Dashboard/ClientDashboard';




function mapStateToProps(state) {
  return { auth: state.auth };
};


class ManagerRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}

					<Route exact path={'/dashboard'} component={ClientDashboard} />
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(ManagerRouter)