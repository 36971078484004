import React, { useState } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";

import wilayas from "../../../assets/wilayas.json";
import communes from "../../../assets/communes.json";

class ClientAddressRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: !!this.props.open,
      showConfirmDelete: false
    };
  }

  deleteAddress = () => {
    if (!this.state.showConfirmDelete) {
      this.setState({ showConfirmDelete: true });
      return;
    }

    this.props.deleteAddress(this.props.idx);
  };

  render() {
    const { address, idx } = this.props;

    const wilayaNameTmp = wilayas.wilayas.filter(item => item.code === address.address_wilaya)
    const wilayaVal = wilayaNameTmp.length > 0 ? {label: wilayaNameTmp[0].nom , value: address.address_wilaya} : null;


    return (
      <div className={"client-address-row"}>
        <div className={"client-address-header"}>
          <div>{address.address_label}</div>
          <div>
            <button
              onClick={() => this.setState({ open: !this.state.open })}
              className={"cbtn small"}
              type={"button"}
            >
              <FontAwesomeIcon
                icon={this.state.open ? faChevronUp : faChevronDown}
              />
            </button>
            <button
              onClick={this.deleteAddress}
              className={"cbtn small danger"}
              type={"button"}
            >
              {this.state.showConfirmDelete ? "Confirmer" : "Supprimer"}
            </button>
          </div>
        </div>

        {this.state.open && (
          <div className={"client-address-body"}>
            <div className={"row"}>
              <div className={"form-group col-md-4"}>
                <label>Libellé</label>
                <input
                  onChange={e =>
                    this.props.updateAddressField(idx, "address_label", e)
                  }
                  value={address.address_label}
                  id={`address[${idx}][address_label]`}
                  name={`address[${idx}][address_label]`}
                  type={"text"}
                  className={"form-control"}
                />
              </div>

              <div className={"form-group col-md-4"}>
                <label>Wilaya</label>
                <Select
                  onChange={e => {
                    this.setState({ selectedWilayaId: e.value });
                    this.props.updateAddressField(idx, "address_wilaya", e);
                  }}
                  placeholder={"Wilaya"}
                  value={wilayaVal}
                  classNamePrefix={"custom-select"}
                  name={`address[${idx}][address_wilaya]`}
                  options={wilayas.wilayas.map(item => ({
                    label: item.nom,
                    value: item.code
                  }))}
                />
              </div>

              <div className={"form-group col-md-4"}>
                <label>Commune</label>
                <Select
                  placeholder={"Commune"}
                  classNamePrefix={"custom-select"}
                  value={{value: address.address_commune, label: address.address_commune}}
                  name={`address[${idx}][address_commune]`}
                  onChange={e =>
                    this.props.updateAddressField(idx, "address_commune", e)
                  }
                  options={communes.communes
                    .filter(
                      item => item.wilaya_id === address.address_wilaya
                    )
                    .map(item => ({ label: item.nom, value: item.nom }))}
                />
              </div>
            </div>

            <div className={"form-group"}>
              <label>Address</label>
              <input
                onChange={e =>
                  this.props.updateAddressField(idx, "address_address", e)
                }
                value={address.address_address}
                name={`address[${idx}][address_address]`}
                type={"text"}
                className={"form-control"}
              />
            </div>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <div className={"form-group"}>
                  <label>Numéro contact</label>
                  <input
                    onChange={e =>
                      this.props.updateAddressField(
                        idx,
                        "address_contact_phone",
                        e
                      )
                    }
                    value={address.address_contact_phone}
                    name={`address[${idx}][address_contact_phone]`}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
              </div>

              <div className={"col-md-6"}>
                <div className={"form-group"}>
                  <label>Nom contact</label>
                  <input
                    onChange={e =>
                      this.props.updateAddressField(
                        idx,
                        "address_contact_name",
                        e
                      )
                    }
                    value={address.address_contact_name}
                    name={`address[${idx}][address_contact_name]`}
                    type={"text"}
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>

            {address.mustSave && (
              <button
                type="button"
                disabled={address.isSaving}
                onClick={() => this.props.saveAddress(idx)}
                className={"cbtn success"}
              >
                Sauvegarder
              </button>
            )}
            {address.isSaving && (
              <FontAwesomeIcon
                style={{ marginLeft: 15 }}
                spin={true}
                icon={faSpinner}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ClientAddressRow;
