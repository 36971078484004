import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import { faCube,faBox, faFile, faFileInvoice, faTimes, faImage} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
import * as CONSTANTS from "../../Utils/Constants";


const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  }


class CollectInterfaceRow extends Component {

    constructor(props) {
        super(props);

        // this.props.isLoading({
        //   message: "Loading tasks",
        //   animate: true
        // });
    
        this.state = {
            showPhotos: false,
            showTentative: false,
            factureIconColor:this.props.data.facture? "#585858": "#D3D3D3",
            bordereauIconColor: this.props.data.file? "#585858": "#D3D3D3",
            // photoIconColor: this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos? "#585858": "#D3D3D3",
        };
    }

    getDocument = (action) => {
        let url = CONSTANTS.REMOTE_API + 'File/';
        if(action == 'bordereau' && this.props.data.fileA4){
            url += 'Bordereau/' + this.props.data.fileA4;
            window.open(url, '_blank').focus();
        }
        if(action == 'facture' && this.props.data.facture){
            url += 'Facture/' + this.props.data.facture;
            window.open(url, '_blank').focus();
        }

    }

    render(){
        return(
            
            
                            <div class="table-row-accueil">

                                <div class="col col-3">
                                    {this.props.data.bordereau_code}
                                </div>
                                
                                <div class={ "col col-3" }><p class="font-weight-bold" style={{fontSize: "17px"}}>{this.props.data.tarif} DZD</p></div>
                                <div class={ "col col-3" }>
                                    {(this.props.data.collect !== 0 &&  this.props.data.due === '0' &&  !this.props.data.remise) && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Collecté <br/></div>}
                                    {(this.props.data.collect !== 0 &&  this.props.data.due === '0' &&  this.props.data.remise) && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Remis <br/></div>}
                                    <p class="font-weight-bold" style={{fontSize: "17px"}}>{this.props.data.collect - ((this.props.data.collect * 1.5)/100)}  DZD</p>
                                </div> 
                                <div class="col col-3">
                                {(this.props.data.isDone  && this.props.data.due != 0 )&& <div style={{ border: 'solid 1px #d9534f' , borderRadius : '3px', textAlign: 'center' , color: '#d9534f' , width: '50px'}}> A collecté </div>}
                                {(this.props.data.isDone && this.props.data.due == 0 && !this.props.data.remise) && <div style={{ border: 'solid 1px #f0ad4e' , borderRadius : '3px', textAlign: 'center' , color: '#f0ad4e' , width: '50px'}}> A remettre </div>}
                                {(this.props.data.isDone && this.props.data.due == 0 && this.props.data.remise) && <div style={{ border: 'solid 1px #5cb85c' , borderRadius : '3px', textAlign: 'center' , color: '#5cb85c' , width: '50px'}}> Done </div>}
                                     
                                </div>
                            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectInterfaceRow));
  
