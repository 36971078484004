import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import UserDetailContainer from '../Containers/UserDetailContainer';
import CommandeInterface from '../Components/Commande/CommandeInterface'


function mapStateToProps(state) {
  return { auth: state.auth };
};


class CommandeRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/Commande' component={CommandeInterface} />
						{/* <Route exact path='/users/:userid/personal' component={UserDetailContainer} />
						<Route exact path='/users/:userid/security' component={UserDetailContainer} />
						<Route exact path='/users/:userid/history' component={UserDetailContainer} /> */}
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(CommandeRouter)