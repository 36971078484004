import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import BordereauListContainer from '../Containers/BordereauListContainer';
import TaskListContainer from '../Containers/TaskListContainer';
import TaskNewContainer from '../Containers/TaskNewContainer';
import TaskHistory from '../Components/Task/TaskHistory';



function mapStateToProps(state) {
  return { auth: state.auth };
};


class BordereauRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
						<Route exact path='/bordereau' component={BordereauListContainer} />
						<Route exact path='/bordereau/:clientid' component={BordereauListContainer} />
						{/* <Route exact path='/task/:id' component={TaskListContainer} />
						<Route exact path='/task/new' component={TaskNewContainer} />
						<Route exact path='/task/:taskid/history' component={TaskHistory} />
						<Route exact path='/task/:taskid/' component={TaskEditContainer} />
						<Route exact path='/task/:taskid/:duplicate'  component={TaskEditContainer} /> */}
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(BordereauRouter)