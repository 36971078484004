// To deploy in local
//export const REMOTE_API = "http://127.0.0.1:8000/";

//export const REMOTE_API = "https://api.express.witrack.digital/";
export const REMOTE_API = "https://api.mars.witrack.digital/";
// export const REMOTE_API = "http://127.0.0.1:8000/";

// to test site express
//export const REMOTE_API = "https://api.express.witrack.digital/";

// export const REMOTE_SOCKET = "https://ws.mars.witrack.digital";
// export const REMOTE_STATIC = "https://api.mars.witrack.digital/";
export const REMOTE_SOCKET = "https://ws.sts.tictechs.tech";
export const REMOTE_STATIC = "https://api.sts.tictechs/";
// export const COMPANY_NAME = "Express";
// export const APP_NAME = "WiTrack";
export const COMPANY_NAME = "Mars Express";
export const APP_NAME = "WiTrack";
//export const COMPANY_NAME = "Anderson";


// export const REMOTE_API = "http://localhost/sts-sym/public/";//"https://api.sts.tictechs.tech/";
