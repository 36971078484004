import React from "react";
import ClientAddressRow from "./ClientAddressRow";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { startLoading, doneLoading } from "../../Redux/actions";
import Select from "react-select";
import { closeFlash, setFlash } from './../../Redux/actions/index';
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';

class ClientTarifs extends React.Component {
  constructor(props) {
    super(props);
    
   
    this.state = {
      exceptionsLoading: true,
      isEditModal:false,
      exceptions: [],
      showAlert: false,
      warning: '',
      type:'',
      montant: 0,
      selectedException: null,
      isModalOpen: false,
      newDepart: '',
      newArrive: '',
      newTarif: 0,
      zones: [],
    };
  }

  handleAddExceptionClick = () => {
    this.setState({ isModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({
      isModalOpen: false,
      newDepart: '',
      newArrive: '',
      newTarif: 0 
    });
  };

  handleInputChange = (e) => {
    const value = e.target.name === "newTarif" ? Number(e.target.value) : e.target.value; // Convert newTarif to a number
    this.setState({ [e.target.name]: value });
  };

  handleFormSubmit = () => {
    // Call the existing addNewException function with the new exception details
    const { newDepart, newArrive, newTarif } = this.state;
    this.addNewException(newDepart, newArrive, newTarif);
    // Close the modal after submitting
    this.setState({ isModalOpen: false });
    this.setState({newDepart :'', newArrive : '', newTarif : 0});
  };


  openEditModal = (exception) => {
    this.setState({ isEditModal: true, selectedException: exception });
  };

  
   loadExceptions = () => {
    const clientid = parseInt(this.props.match.params.id);

     api.get(`/api/client/tarifs/fetch/${clientid}`).then(res => {
       console.log(res);
      this.setState({
        exceptionLoading: false,
        exceptions: res.data.tarifs
      });
    });
  };

  
  componentDidMount() {
    this.loadExceptions();

    api.get('/api/form/zones').then(result => {
      console.log(result.data);
      if(result.data.success){
        this.setState({
          zones: result.data.zones
        })
      }
    })

  }


  addNewException = (depart, arrive, tarif) => {
    const clientid = parseInt(this.props.match.params.id);
    const data = {
      "depart":depart,
      "arrive":arrive,
      "tarif":tarif
    };
    api.post(`/api/client/tarifs/add/${clientid}`,data).then(res => {
      console.log(res);
      this.setState({
        exceptionLoading: false,
      });
      this.loadExceptions();
    });
  }


    deleteException = (depart, arrive) => {
    const clientid = parseInt(this.props.match.params.id);
    const data = {
      "depart":depart,
      "arrive":arrive
    };
    api.post(`/api/client/tarifs/delete/${clientid}`,data).then(res => {
      console.log(res);
      this.setState({
        exceptionLoading: false,
      });
      this.loadExceptions();
    });
   }

  render() {

    const zones = Object.entries(this.state.zones).map(([key, i]) => ({
      label: key,
      value: key
    }))


   return (
      <>
        <React.Fragment>
          <div className={"container-header"}>
            Grille tarifaire
            <button onClick={this.handleAddExceptionClick} className="btn btn-primary" style={{ float: 'right' }}>Add Exception</button>
          </div>
        <div className="ctable mb-3  ui-rect np">
          <div className={"row head-titles py-2 mx-2"} style={{ background: 'white' ,  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
              <div className={"col-md-3"}>Depart</div>
              <div className={"col-md-3"}>Arrive</div>
              <div className={"col-md-3"}>Tarif</div>
              <div className={"col-md-3 text-right"}>Actions</div>
            </div>

            {this.state.exceptions.map((exception, idx) => (
            <div key={idx} className={"row ctable-row"}>
              <div className={"col-md-3"}>{exception.depart}</div>

              <div className={"col-md-3"}>{exception.arrive}</div>

              <div className={"col-md-3"}>{exception.tarif} DA</div>
              
              <div className={"col-md-3 action-col"}>
                <button className={"btn btn-sm btn-primary ml-2 p-2"}
                  onClick={() => {
                    this.setState({ montant: exception.tarif });
                    this.openEditModal(exception);
              }}
                >
                  Modifier
                </button>
                <button className={"btn btn-sm btn-secondary ml-2 p-2"}
                  onClick={() => this.deleteException(exception.depart, exception.arrive)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>

        <Modal show={this.state.isEditModal} centered>
          <Modal.Header >
            <Modal.Title>modifier une exception </Modal.Title>
          </Modal.Header>

            <Modal.Body>
              {/* Input field to edit the 'tarif' */}
              <input
                type="number"
                name="montant"
                placeholder="Tapez le montant à changer ..."
                value={this.state.montant}
                className={"form-control"}
                onChange={(e) => this.setState({ montant: e.target.value })}
                min="0"
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className={"btn"}
                onClick={() => this.setState({ isEditModal: false })}
              >
                Annuler
              </button>
              <button
                className={"btn btn-primary"}
                onClick={() => {
                  if (this.state.selectedException) {
                    this.addNewException(
                      this.state.selectedException.depart,
                      this.state.selectedException.arrive,
                      this.state.montant
                    );
                  }
                  this.setState({ isEditModal: false, selectedException: null });
                }}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
          show={this.state.isModalOpen}
          handleClose={this.handleModalClose}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>Ajouter une nouvelle exception</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Départ:</label>
              <Select name="newDepart" onChange={e => this.setState({newDepart : e.label})} options={zones}>
                {/* Add options for Depart dropdown */}
              </Select>
            </div>
            <div>
              <label>Arrivée:</label>
              <Select name="newArrive" onChange={e => this.setState({newArrive : e.label})} options={zones}>
                {/* Add options for Arrive dropdown */}
              </Select>
            </div>
            <div>
              <label>Tarif:</label>
              <input type="number" className={"form-control"} name="newTarif" value={this.state.newTarif} onChange={(e) => this.setState({ newTarif: e.target.value })}   />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button 
              className={"btn"}
              onClick={this.handleModalClose}>Annuler</button>
            <button 
              onClick={this.handleFormSubmit}
              className={"btn btn-primary"}>OK</button>
          </Modal.Footer>
        </Modal>

        </React.Fragment>
      </>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    isLoading: m => dispatch(startLoading(m)),
    doneLoading: m => dispatch(doneLoading()),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
};
export default connect(null, mapDispatch)(ClientTarifs);
