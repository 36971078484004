import React , { useEffect, useState }from "react";
import Modal from 'react-bootstrap/Modal';
import { faTimes , faCoins, faHandPointLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {render} from 'react-dom';
import Alert from 'react-bootstrap/Alert'



export const  ModalTemplate = ({message, nameClass, showAlert, handleChange, ...props}) => {
  
  useEffect(() => { 
    setShow(showAlert);
  }, [showAlert])

  

  const [show, setShow] = useState(true);
  const [error, setError] = useState('');

  
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => { 
    if(show === false){
      handleChange(false)
    }
    
  }, [show])
  
    return (
      <>
        {/* <button type="button" class="btn btn-primary" onClick={handleShow} ><FontAwesomeIcon className={"ml-2"} icon={faCoins} color="#E5C100 "/>Collecter</button> */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div class={nameClass} role="alert">
              {message}
            </div>
          </Modal.Body>
         
        </Modal>
      </>
    );
}