import React from "react";
import Select from "react-select";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";

import wilayas from "../../../../assets/wilayas.json";
import communes from "../../../../assets/communes.json";
import moment from "moment";
import api from "../../../Utils/Api";
import { Link } from "react-router-dom";
import 'moment/locale/fr';

const comms = communes.communes.map(item => ({ label: item.nom+" ("+item.code_postal+")", value: item.nom, code: item.code_postal }))

class SubTaskForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: !this.props.expand,
      location: this.props.data ? this.props.data.location : {},
      pinMode: false,
      addressSuggestions: [],
      selectedWilayaId: "",
      addressesLoading: true,
      searchAddressesTerm: "",
      address: {},
      commune_id: this.props.data.subtask_address_commune_id,
      pickup_time: this.props.data.subtask_start_datetime || undefined
    };
  }

  updateCommune = commune_id => this.setState({commune_id: commune_id})


  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse });
    return false;
  };

  // selectOnMap = () => {
  //   this.setState({ pinMode: true });
  //   this.props.pinToggle(this.onMapSelect, this.props.idx);
  // };

  onMapSelect = location => {

    this.setState({ pinMode: false });
  };

  removeTask = () => {
    this.props.removeItem(this.props.idx);
  };

  loadAddresses = (val = "", cb) =>
    api.get(`/api/client/search/address?q=${val}`).then(res => {

      this.setState({
        addressesLoading: false,
        addressSuggestions: res.data.data
      });

      if(typeof cb === "function")
        cb(res.data.data);
    });

  searchAddresses = e => {

    this.setState({ searchAddressesTerm: e.target.value, address: {...this.state.address, address_id: null, address_label: e.target.value} })

  };

  handleFocus = () => this.setState({ showSuggestions: true })
  handleBlur = () => this.setState({ showSuggestions: false })

  handleAddressSelect = (address) => {

    this.setState({ showSuggestions: false, address: address, selectedWilayaId: address.address_wilaya })
  }

  componentDidMount() {
    this.loadAddresses("", (res) => {

      this.setState({
        addressesLoading: false
      });

      if(this.props.data.subtask_address_id) {



        const param =  res.filter(item => item.address_id === this.props.data.subtask_address_id);

        if(param[0])
          this.handleAddressSelect( param[0] )
      }

    });
  }


  updateAddress = (e) => {
    const {address} = this.state;
    address.address_address = e.target.value;
    address.address_id = null
    this.setState({address});
  }

  render() {
    const { data = {}, taskData } = this.props;



    const wilayaNameTmp = wilayas.wilayas.filter(item => item.code === this.state.address.address_wilaya);
    const wilayaName = wilayaNameTmp.length > 0 ? wilayaNameTmp[0].nom : undefined;
    const wilayaValue = this.state.address.address_wilaya




    const defaultType = this.props.formType === "end" ? "Déchargement" : this.props.formType === "start" ? "Chargement" : this.props.data.subtask_type

    return (
      <>

        <div className={"subtask-group ui-rect"}>
          <span className={"subtask-header"}>
            {this.props.formType === "start"
              ? "Départ"
              : this.props.formType === "end"
              ? "Arrivé"
              : "Point " + (parseInt(this.props.idx) + 1)}

            <button
              type="button"
              className={"cbtn-inline float-right"}
              onClick={this.toggleCollapse}
            >
              {this.state.collapse ? "Afficher" : "Réduire"}
            </button>

            {!isNaN(this.props.idx) && (
              <button
                type="button"
                className={" cbtn-inline danger float-right"}
                onClick={this.removeTask}
              >
                Supprimer
              </button>
            )}
          </span>

          {!this.props.isDuplicate && this.props.data.subtask_id && (
            <input
              value={this.props.data.subtask_id}
              type="hidden"
              name={`subtask[${this.props.idx}][subtask_id]`}
              className={"form-control"}
            />
          )}
          <div
            className={
              "subtask-body pt-3 " + (this.state.collapse && "collapse")
            }
          >
            <div className={"col-md-12"}>
              <div className={"row"}>
                <div className={"col-md-12 mb-2"}>

                <div className={"form-group"}>
                  {/* <label>Type</label> */}
                  <Select
                    placeholder={"Type point"}
                    classNamePrefix={"custom-select"}
                    name={`subtask[${this.props.idx}][task_type]`}
                    defaultValue={{label: defaultType , value: defaultType}}
                    options={[
                      {label: "Chargement", value: "Chargement" },
                      {label: "Déchargement", value: "Déchargement" }
                    ]}
                    placeholder="choisir type"
                  />
                </div>

                </div>
              </div>
              <div className={"row"}>
                <div className={"form-group col-md-6 addresses-top-wrap"}>
                  {/* <label>
                    Lieu de chargement

                    {this.state.addressesLoading && (
                      <FontAwesomeIcon
                        style={{ marginLeft: 15 }}
                        spin={true}
                        icon={faSpinner}
                      />
                    )}

                  </label> */}


                  <input
                    autoComplete={"off"}
                    defaultValue={this.props.data.subtask_label}
                    type="text"
                    name={`subtask[${this.props.idx}][address_label]`}
                    className={"form-control"}
                    placeholder="Lieu de chargement"
                  />

                  {/* <div className={"addresses-content "+(this.state.showSuggestions ? "open" : "hide")}>
                    <ul>
                      {this.state.addressSuggestions.map(item => {
                        if (
                          item.address_label.toUpperCase().indexOf(
                            this.state.searchAddressesTerm.toUpperCase()
                          ) > -1
                        )
                          return <li onClick={() => this.handleAddressSelect(item)}>{item.address_label}</li>;
                      })}

                      {this.state.addressesLoading && <li>Recherche...</li>}
                    </ul>
                  </div> */}
                </div>

                <div className={"form-group col-md-6"}>
                  {/* <label>Address</label> */}
                  <input
                    // onChange={e => this.updateAddress(e)}
                    defaultValue={data.subtask_address}
                    type="text"
                    name={`subtask[${this.props.idx}][address_address]`}
                    className={"form-control"}
                    placeholder="Address"
                  />
                </div>

                <input
                  type="hidden"
                  name={`subtask[${this.props.idx}][task_location]`}
                  defaultValue={JSON.stringify(data.address)}
                />

                <input
                  type="hidden"
                  name={`subtask[${this.props.idx}][address_id]`}
                  value={this.state.address.address_id}
                />

              </div>

              <div className={"row"}>
                {/* <div className={"form-group col-md-4"}>
                  <label>Wilaya</label>
                  <Select
                    onChange={e => this.setState({ selectedWilayaId: e.value })}
                    placeholder={"Wilaya"}
                    classNamePrefix={"custom-select"}
                    name={`subtask[${this.props.idx}][address_wilaya]`}
                    value={{label: wilayaName , value: wilayaValue}}
                    options={wilayas.wilayas.map(item => ({
                      label: item.nom,
                      value: item.code
                    }))}
                  />
                </div> */}

                <div className={"form-group col-md-6"}>
                  {/* <label><b>Commune *</b></label> */}
                  <Select
                    placeholder={"Commune"}
                    classNamePrefix={"custom-select"}
                    name={`subtask[${this.props.idx}][address_commune]`}
                    onChange={e => this.updateCommune(e.code)}
                    defaultValue={comms.filter(item => item.value === data.subtask_address_commune)}
                    options={comms}
                  />

                  <input type="hidden" name={`subtask[${this.props.idx}][address_commune_id]`} value={this.state.commune_id} />
                </div>

                {this.props.idx === "startTask" && (
                  <React.Fragment>

                    <div className={"form-group col-md-3"}>
                      {/* <label><b>Date *</b></label> */}
                      <Datetime
                        locale={"fr"}
                        inputProps={{
                          name: `subtask[${this.props.idx}][task_date]`,
                          required: true
                        }}
                        timeFormat={false}
                        dateFormat={"YYYY-MM-DD"}
                        viewMode={"days"}
                        defaultValue={moment(this.props.data.subtask_start_datetime).format('YYYY-MM-DD') || moment()}
                    />
                    </div>
                    <div className={"form-group col-md-3"}>
                      {/* <label><b>Heure *</b></label> */}
                      <Datetime
                        inputProps={{
                          name: `subtask[${this.props.idx}][task_hour]`,
                          required: true
                        }}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        viewMode={"time"}
                        defaultValue={this.props.data.subtask_start_datetime ? moment(this.props.data.subtask_start_datetime).format('HH:mm') : undefined}
                    />
                    </div>
                </React.Fragment>
                )}


              </div>

              <div className={"row"}>


                <div className={"form-group col-md-6"}>
                  {/* <label>Contact name</label> */}
                  <input
                    defaultValue={data.subtask_contact}
                    type="text"
                    name={`subtask[${this.props.idx}][task_contact_name]`}
                    className={"form-control"}
                    placeholder="Contact name"
                  />
                </div>

                <div className={"form-group col-md-6"}>
                  {/* <label>Téléphone</label> */}
                  <input
                    defaultValue={data.subtask_contact_phone}
                    type="text"
                    name={`subtask[${this.props.idx}][task_contact_number]`}
                    className={"form-control"}
                    placeholder="Téléphone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubTaskForm;
