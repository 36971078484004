import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select from 'react-select';

import * as actions from '../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes, faInfo, faTrash, faFileAlt,faPowerOff, faThumbtack ,faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import {  Trans } from "react-i18next";
import Pagination from '../../Utils/Pagination';
import * as CONSTANTS from "../../Utils/Constants";
import Modal from 'react-bootstrap/Modal';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class AgentRow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: ''
			},
			tableIsLoading: true,
			limit: 10,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1,
			runSheet: '',
			showModel : false,
			bordereau: [],
			value: ''
		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents =_.debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
		
		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);

		//console.log('this is the param '+this.props.match.params.id)
	}

	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}
		
		if(!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/'+this.state.selectedUser+'/renew', data)
		.then(function(response) {
			if(response.data)
				console.log(response.data)
		})
		
	}
	changeLimit(e) {

		this.props.isLoading();

		
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())

		

	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({current: p});
		this.loadAgents(p);
	}

	componentDidUpdate(prev) {
		const that = this;

		if(this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {
				if(data.type && data.type === 'user_status')
					that.loadAgents(); 
			});
		}

		

	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		// if(this.props.match.params.id){
		// 	let that = this 
		// 	that.setState({
		// 		filters:{
		// 			name: this.props.match.params.id
		// 		}
		// 	})
		// }

		// if(this.props.socket) {
		// 	this.props.socket.on('test', function (data) {
		// 		console.log('event: ', data)
		// 		if(data.type && data.type === 'user_status')
		// 			that.loadAgents(); 
		// 	});
		// }



		// const that = this;

		// this.loadAgents();

		// this.agentInterval = setInterval(function() {
			
		// 	if(that.getTimestamp() - that.state.lastUpdate > 15) {
				
		// 		that.loadAgents();
		// 	}
		// }, 1500)

	}
	componentWillUnmount() {
		clearInterval(this.agentInterval)

		if(this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			const crits = "name="+that.state.filters.name
						  +"&status="+that.state.filters.status
						  +"&limit="+that.state.limit

			api.get('/api/users?role=4&token='+that.props.user.token+'&page='+p+'&'+crits)
			.then(function(response) {
				if(response.data.success) {
					
					that.setState(response.data);
				} else {
					//that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 
	userDeleteBtn(id) {
		
		this.setState({
			selectedUser: id
		})
		

		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = { 
			user: this.props.user
		}

		that.toggleOverlay();

		that.props.isLoading();

		
		api.post('/api/user/delete/'+this.state.selectedUser, data)
		.then(function(response) {
			
			

			if(response.data.success) {
				//that.loadAgents();
				that.props.setFlash({
					active: true,
					message: "User has been succesfully deleted.",
					classes: "success",
					style: {}
				})
			} else {
				that.props.doneLoading();
				that.props.setFlash({
					active: true,
					message: "Failed to delete agent : "+response.data.error,
					classes: "warning",
					style: {}
				})
			}
		});
		
	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadAgents(1);
		});
		
	}



	getDocument = (agent_id, type) => {
		this.props.isLoading();



		api.get("/api/v2/task/fetch?page=" + 1 + "&" + 'agent='+ agent_id + '&'+ type +'=true&status=3').then((response) => {
			if (response.data.success) {
			  this.setState({
				runSheet: response.data.file,
				
			  });
			  let folder = (type === 'runSheet') ? 'RunSheet/' : 'Manifeste/';
        let url = CONSTANTS.REMOTE_API + 'File/' + folder;
        if(response.data.show == true){
          let alg_url = url + response.data.to_alger_file;
          let oran_url = url + response.data.to_oran_file;
          let constantine_url = url + response.data.to_constantine_file;
          url +=  response.data.file;
          window.open(alg_url, '_blank');
          window.open(oran_url, '_blank');
          window.open(constantine_url, '_blank');
          window.open(url, '_blank');
        }else{
          url += response.data.file;
          window.open(url, '_blank');
        }
			} else {
			  if (response.data.errcode === 101) {
				this.props.setAuth(false);
			  }
			}
	  
			this.props.doneLoading();
			// console.log(this.state.tasks);
		  });
	}

	userDisEnb = (id, action) => {
		//this.props.isLoading();
		let data = {status: action}
		api.post("/api/disable/user/"+id, data).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
			}else{
				//this.props.doneLoading();
				this.props.setFlash({
					active: true,
					message: result.data.error,
					classes: "warning",
					style: {}
				})
			}
			window.location.reload(false);
		})
	}
	addCode = (e) => {
		const value = e.target ? e.target.value : e.value;
		this.setState({
			value:value
		})
		

		if(!this.state.bordereau.includes(value) && value.length === 14){
			this.setState(prev => ({
				bordereau: [...prev.bordereau,  value],
				value:''
			}))
		}
		
	}

	assign = (id) => {
		api.post("/api/assignBatch/agent/"+id, this.state.bordereau).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
				this.setState({
					showModel: false,
					bordereau: []
				})
			}else{
				//this.props.doneLoading();
				this.setState({
					showModel: false,
					message: result.data.error,
					classes: "warning",
					bordereau: []
				})

			}

		})
	}
	deleteCode = (item) =>{
		let array =  this.state.bordereau
		const index = array.indexOf(item);
		if (index > -1) {
			array.splice(index, 1);
			this.setState({
				bordereau: array
			})
		}

	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const toggleOverlayRenew = this.toggleOverlayRenew;
		const showOverlay = this.state.showOverlay;
		const showOverlayRenew = this.state.showOverlayRenew
		const data = this.state;
		return(

				<div className={"ctable-row row task-row"} key={this.props.key}>

						<div className={"col-md-2"}>


							<div className={"agent-list-profile"}>

								
								<div className={"col-profile-ct"}>

									<span className={"agent-list-photo task-status status-id-"+this.props.agent.role}>
										{this.props.agent.role.substring(0, 2)}
										<span 
											title={this.props.agent.status_last ? moment.unix(this.props.agent.status_last-120).fromNow().toString() : ""}
											className={"user-status user-status-"+this.props.agent.status_id}>{this.props.agent.status}
										
										</span>
									</span>
									<span className={"agent-list-username"}>
										<span>{this.props.agent.fullname}</span>
										{this.props.agent.status_id === 4 && (
											<span className={"agent-list-task-id"}><Link to={'/task/'+this.props.agent.task_id}><Trans i18nKey="label_task" /> #{this.props.agent.task_id}</Link></span>
										)}

										{this.props.agent.status_id === 5 && (
											<span className={"status-id-5"}>Online</span>
										)}

										{this.props.agent.status_id === 1 && this.props.agent.status_last > 0 && (
											<span className={"status-id-1"}><abbr title={moment.unix(this.props.agent.status_last-120).format('YYYY-MM-DD HH:mm').toString()}>{moment.unix(this.props.agent.status_last-120).fromNow().toString()}</abbr></span>
										)}
									</span>

								</div>

								{window.innerWidth < 570 && (
									<div className={"agent-profile-action"}>
										<span className={"btn btn-primary btn-sm"} onClick = {e => this.setState({showModel: true})}> Assigner </span>
										<span className={"btn btn-primary btn-sm"} onClick = {e => this.getDocument(this.props.agent.userid, 'runSheet')}> Runsheet </span>
										<span className={"btn btn-primary btn-sm"} onClick = {e => this.getDocument(this.props.agent.userid, 'manifest')}> Manifeste </span>
										<Link to={'/manager/agents/'+this.props.agent.userid}><span className={"btn btn-primary btn-sm"}><Trans i18nKey="label_details" /></span></Link>
										{/* <span
											data-user-id={user.userid}
											onClick={e => this.userDeleteBtn(user.userid)}
											className={"cp btn btn-danger btn-sm ml-1"}><Trans i18nKey="label_delete" />
										</span> */}
										<span
											data-user-id={this.props.agent.userid}
											onClick={e => this.userDisEnb(this.props.agent.userid)}
											className={"cp btn btn-danger btn-sm ml-1"}>
												<Trans i18nKey="label_off" />
										</span>
									</div>
								)}
								

							</div>

						</div>
						
						<div className={"col-md-2"}>
							{this.props.agent.username}
						</div>
						{/* <div className={"col-md-2"}>
							{this.props.agent.agent_type}
						</div> */}

						<div className={"col-md-1"}>
							{this.props.agent.phone}
						</div>

						<div className={"col-md-4"}>
							{this.props.agent.email}
						</div>
						
						<div className={"col-md-3 action-col"}>
							<button className={"custom-action-btn  mr-3"} onClick = {e => this.setState({showModel: true})}>
								<FontAwesomeIcon icon={faThumbtack} />
								Assigner 
							</button>
							<button className={"custom-action-btn  mr-3"} onClick = {e => this.getDocument(this.props.agent.userid, 'runSheet')}>
								<FontAwesomeIcon icon={faFileAlt} />
								Runsheet
							</button>
							<button className={"custom-action-btn  mr-3"} onClick = {e => this.getDocument(this.props.agent.userid, 'manifest')}>
								<FontAwesomeIcon icon={faFileAlt} />
								Manifeste
							</button>
							<Link to={'/manager/agents/'+this.props.agent.userid} className={"custom-action-btn"}>
								<FontAwesomeIcon icon={faInfo} />
								<Trans i18nKey="label_details" />
							</Link>
							{/* <button type="button"
							data-user-id={user.userid}
							onClick={e => this.userDeleteBtn(user.userid)}
							className={"custom-action-btn danger ml-3"}>
								
								<FontAwesomeIcon icon={faTrash} />
								<Trans i18nKey="label_delete" />
							
							</button> */}
							{this.props.agent.status_account === 'active' && <button type="button"
							data-user-id={this.props.agent.userid}
							onClick={e => this.userDisEnb(this.props.agent.userid, 0)}
							className={"custom-action-btn danger ml-3"}>
								
								<FontAwesomeIcon icon={faPowerOff} />
								<Trans i18nKey="label_off" />
							
							</button>}
							{this.props.agent.status_account !== 'active' && <button type="button"
							data-user-id={this.props.agent.userid}
							onClick={e => this.userDisEnb(this.props.agent.userid, 'active')}
							className={"custom-action-btn success ml-3"}>
								
								<FontAwesomeIcon icon={faPowerOff} />
								<Trans i18nKey="label_on" />
							
							</button>}
						</div>
						
							
						<Modal show={this.state.showModel} centered>
							<Modal.Header >
							<Modal.Title>Assigner les bordereaux a <b>{this.props.agent.username}</b> </Modal.Title>
							</Modal.Header>
							<Modal.Body>
							<div className={"form-group col-md-12"}>
								<label><b>Veuillez entrer les codes bordereaux à assigner  *</b></label>
								<input
									type="text"
									value={this.state.value}
									name={`bordereau`}
									className={"form-control"}
									onChange={e => this.addCode(e)}
								/>
							</div>
							{this.state.bordereau.map(item => (
								<div className='badge badge-light mr-2 my-2 shadow-sm'>
									<span style={{fontSize: 15}} >{item} </span> <button className="btnem" onClick={e => this.deleteCode(item)}><FontAwesomeIcon  icon={faTimesCircle} color="#d9534f"/></button>
									
								</div>
							))

							}

							</Modal.Body>
							<Modal.Footer>
							<button className={"btn btn-danger"}  onClick={e => this.setState({showModel: false, bordereau: []})}>
								Annuler
							</button>
							{this.state.bordereau.length > 0  && <button type='submit' className={"btn btn-success"} onClick={e => this.assign(this.props.agent.userid)} >
								Assigner 
							</button>}
							</Modal.Footer>
						</Modal>	
						
				</div>



		)
	}
}



const AgentListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AgentRow {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	

export default connect(mapStateToProps, mapDispatchToProps)(AgentListConsumer)
