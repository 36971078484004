import api from './Api';


function getNotifications(token = this.props.user.token) {
	
	
	const that = this;

	return api.get('/api/notification/fetch?token='+token)
	.then(function(response) {

		if(response.data.success) {

			if(response.data.hash !== that.props.notificationsHash) {


				
				that.props.updateNotifications(response.data)


			}
				
		} else {
			//if(response.data.errcode === 101)
				that.props.setAuth(false);
		}
	});

	
}

export default getNotifications;
