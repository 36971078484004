import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class ManagerSideMenu extends Component {

	

	componentDidMount() {
		
	}

	render() {

		if(this.props.auth)
		return (
			
			<ul className={"sideMenu-list"}>
				<li>
					
					<NavLink  to={"/manager/agents"}>Agents</NavLink>
					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/manager/agents"}>My Agents</NavLink></li>
						<li><NavLink exact to={"/manager/agents/new"}>Add new Agent</NavLink></li>
					</ul>
				</li>
				
				<li>
					<NavLink to={"/task"}>Tasks</NavLink> 

					<ul className={"submenu"}>
						<li><NavLink strict exact to={"/task"}>View tasks </NavLink></li>
						<li><NavLink strict exact to={"/task/new"}>Create new task</NavLink></li>
					</ul>

				</li>
				<li>
					<Link to={"/settings/config"}>Settings</Link> 

					<ul className={"submenu d-none"}>
						<li><Link to={"/settings/item"}>Items</Link></li>
						<li><Link to={"/settings/agent"}>Agents </Link></li>
						<li><Link to={"/settings/location"}>Locations</Link></li>
					</ul>

				</li>


				<li><Link to={"/items"}>Users</Link></li>
				<li><Link to={"/items"}>Roles</Link></li>
				
				<li><Link to={"/items"}>Security</Link></li>
				
			</ul>

		)
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(ManagerSideMenu)
/*
<div className="list-group">
					<Link className="list-group-item list-group-item-action" to={"/items"}>Items</Link>
					<Link className="list-group-item list-group-item-action" to={"/packages"}>Packages</Link>
					<Link className="list-group-item list-group-item-action" to={"/roles"}>Roles</Link>
					<Link className="list-group-item list-group-item-action" to={"/security"}>Security</Link>
					<Link className="list-group-item list-group-item-action" to={"/config"}>Configuration</Link>

				</div>
				*/