import React from "react"


import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faBoxes, faFileInvoiceDollar, faDonate, faHandPointLeft ,faCaretUp, faCaretDown, faMoneyBill, faMoneyBillAlt} from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import { Trans } from "react-i18next";


import {  startLoading, doneLoading } from '../../Redux/actions/index';
import Select from "react-select";
import { Item } from "../../Utils/MoreInfoDialog";
import { Link } from "react-router-dom";
import CanvasJSReact from "../../Canvas/canvasjs.react";
import Modal from 'react-bootstrap/Modal';
import { Button, Container, Card, Row, Col} from 'react-bootstrap';
import {ExportCSV}  from '../Rapport/ExportCSV';
import dateFormat from 'dateformat';

import ChartistGraph from "react-chartist";

// var data = {
// 	labels: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10'],
// 	series: [
// 	  [1, 2, 4, 8, 6, -2, -1, -4, -6, -2]
// 	]
//   };

//   var options = {
// 	high: 10,
// 	low: -10,
// 	axisX: {
// 	  labelInterpolationFnc: function(value, index) {
// 		return index % 2 === 0 ? value : null;
// 	  }
// 	}
//   };

//   var type = 'Bar'
const moment = require("moment");

const mapStateToProps = state => {
	return {
		user: state.user
	}
} 

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}

class ClientDashboard extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			data: {},
			isReady: false

		}

		
		props.isLoading();
	}

	componentDidMount() {
		
		const that = this;

		api.get('/api/client/'+this.props.user.userid+'/dashboard')
		.then(function(response) {
			if(response.data.success){
				that.setState({
					data: response.data.data,
					isReady: true
				})
				
			}
			that.props.doneLoading()		
				
		})
	}


	numberDisplay = (nbr) => {
		let number = parseInt(nbr);
		return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(number)
	  }


    render() {
		var curDateMonth = new Date();
        var prvDateMonth = new Date()
        prvDateMonth.setMonth(prvDateMonth.getMonth() - 1)

		var CanvasJS = CanvasJSReact.CanvasJS;
		var CanvasJSChart = CanvasJSReact.CanvasJSChart;
		CanvasJS.addColorSet("greenShades",
                [
                "#FF8233",
                "#FF924D",
                "#FFA166",
                "#FFB180",
                "#FFC199"                
                ]);
		const dailySent = {
			animationEnabled: true,	
			// title:{
			// 	text: "Number of New Customers"
			// },
			axisY : {
				gridThickness: 0,
				title: "Nombre d'envois",
				titleFontColor: '#c0c0c0',
				lineColor:'#c0c0c0',
				labelFontColor: '#c0c0c0',
				
			},
			axisX : {
				lineColor:'#c0c0c0',
				labelFontColor: '#c0c0c0'
			},
			
			data: [{
				type: "spline",
				name: "30 derniers jours",
				showInLegend: true,
				dataPoints:this.state.data.dailySent
			}]
	}

	const topWilaya = {
		animationEnabled: true,
		colorSet: "greenShades",
		//exportEnabled: true,
		//theme: "dark2", // "light1", "dark1", "dark2"
		// title:{
		// 	text: "Trip Expenses"
		// },
		axisY : {
			gridThickness: 0,
			title: "Nombre d'envois",
			titleFontColor: '#c0c0c0',
			lineColor:'#c0c0c0',
			labelFontColor: '#c0c0c0',
			
		},
		axisX : {
			lineColor:'#c0c0c0',
			labelFontColor: '#c0c0c0'
		},
		data: [{
			type: "column",
			indexLabel: "{label}: {y}",
					
			//startAngle: -90,
			dataPoints: this.state.data.topWilaya
		}]
	}

		// const mapPick = Object.entries(this.state.delaisPick).map(([key,val]) =>({
		// 	y: val,
		// 	//label: 'Zone '+key
		// 	label: key
		// }))
		// const mapDeliv = Object.entries(this.state.delaisDeliv).map(([key,val]) =>({
		// 	y: val,
		// 	//label: 'Zone '+key
		// 	label: key
		// }))
		// const mapTotal = Object.entries(this.state.delaisTotal).map(([key,val]) =>({
		// 	y: val,
		// 	//label: 'Zone '+key
		// 	label: key
		// }))


		
		// CanvasJS.addColorSet("fireShades",
		// 		[
		// 			"#ffb703",
		// 			"#023047",               
		// 		]);

		// const delaisPick = {
		// 	colorSet: "fireShades",
		// 	backgroundColor:'transparent',
			
		// 	height:250,
		// 	axisY: {
		// 		title: "Délais d’enlèvement/ jours"
		// 	},
		// 	legend: {
		// 		fontSize: 15
		// 	},
		// 	data: [{
		// 		showInLegend: true,
		// 		legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
		// 		type: "column",
		// 		dataPoints: mapPick,
		// 	}]
		//  }

		// const delaisDeliv = {
		// 	colorSet: "fireShades",
		// 	backgroundColor:'transparent',
			
		// 	height:250,
		// 	axisY: {
		// 		title: "Délais de livraison/ jours"
		// 	},
		// 	legend: {
		// 		fontSize: 15
		// 	},
		// 	data: [{
		// 		showInLegend: true,
		// 		legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
		// 		type: "column",
		// 		dataPoints: mapDeliv,
		// 	}]
		// }
		
		// const delaisTotal = {
		// 	colorSet: "fireShades",
		// 	backgroundColor:'transparent',
			
		// 	height:250,
		// 	axisY: {
		// 		title: "Délais de livraison/ jours",
				
		// 	},
		// 	legend: {
		// 		fontSize: 15
		// 	},
		// 	data: [{
		// 		showInLegend: true,
		// 		fontSize: 15,
		// 		legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
		// 		type: "column",
		// 		dataPoints: mapTotal,
		// 	}]
		//  }

		//  const bordereau = {
		// 	colorSet: "fireShades",
		// 	backgroundColor:'transparent',
			
		// 	height:250,
		// 	axisY: {
		// 		title: "Délais de livraison/ jours"
		// 	},

		// 	subtitles: [{
		// 		text: this.state.bordereau.total +" Bordereau au total",
		// 		fontSize: 15,
		// 		fontFamily: 'Calibri',
		// 		dockInsidePlotArea: true
		// 	}],
		// 	legend: {
		// 		fontSize: 15
		// 	},
		// 	data: [{
		// 		showInLegend: true,
		// 		//legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
		// 		type: "pie",
		// 		legendText: "{label}",
		// 		dataPoints: [
		// 			{ label: "Pending",  y:  this.state.bordereau.total -  this.state.bordereau.fait },
		// 			{ label: "Fait", y: this.state.bordereau.fait },
		// 		],
		// 	}]
		//  }
		//  const factures = {
		// 	colorSet: "fireShades",
		// 	backgroundColor:'transparent',
			
		// 	height:250,
		// 	axisY: {
		// 		title: "Délais de livraison/ jours"
		// 	},

		// 	subtitles: [{
		// 		text: this.state.factures.total +" Factures au total",
		// 		fontSize: 15,
		// 		fontFamily: 'Calibri',
		// 		dockInsidePlotArea: true
		// 	}],
		// 	legend: {
		// 		fontSize: 15
		// 	},
			
		// 	data: [{
		// 		showInLegend: true,
		// 		//legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
		// 		legendText: "{label}",
		// 		type: "pie",
		// 		dataPoints: [
		// 			{ label: "Payé",  y:  this.state.factures.payed },
		// 			{ label: "Non payé", y:this.state.factures.total -  this.state.factures.payed },
		// 		]
				
		// 	}]
		//  }
		
		
        return <div>
            {/* <div className={"container-header nmb"}>
				<h3>Dashboard</h3>
			</div> */}
			<Container fluid>
				<Row>
					<Col lg="3" sm="6">
						<Card className="card-stats">
						<Card.Body>
							<Row>
							<Col xs="5">
								<div className="icon-big text-center icon-warning">
								{/* <FontAwesomeIcon icon={faMoneyBillAlt} color="#87CB16" size="1x"/> */}
								<i class="bi bi-send fa-lg"    style={{ color: '#28397B'}}></i>
								{/* <i className="nc-icon nc-chart text-warning"></i> */}
								</div>
							</Col>
							<Col xs="7">
								<div className="numbers">
								<p className="card-category">Total Colis Crées</p>
								<Card.Title as="h2"><b> {this.state.data.nombre} Colis </b> </Card.Title>
								<Card.Title as="h2"><b>{ this.numberDisplay(this.state.data.collect)  + ' DZD'} </b></Card.Title>
								</div>
							</Col>
							</Row>
						</Card.Body>
						<Card.Footer>
							<hr></hr>
							<div className="stats">
							<i className="fas fa-redo mr-1"></i>
							Ce Mois
							</div>
						</Card.Footer>
						</Card>
					</Col>
					<Col lg="3" sm="6">
						<Card className="card-stats">
						<Card.Body>
							<Row>
							<Col xs="5">
								<div className="icon-big text-center icon-warning">
								<i class="bi bi-hourglass-split  fa-lg"   style={{ color: '#FFE135'}}></i>
								</div>
							</Col>
							<Col xs="7">
								<div className="numbers">
								<p className="card-category">Total Colis En cours</p>
								<Card.Title as="h2"> <b>{this.state.data.nombreCurrent} Colis </b></Card.Title>
								<Card.Title as="h2"><b>{ this.numberDisplay(this.state.data.collectCurrent)  + ' DZD'} </b></Card.Title>
								</div>
							</Col>
							</Row>
						</Card.Body>
						<Card.Footer>
							<hr></hr>
							<div className="stats">
							<i className="far fa-calendar-alt mr-1"></i>
							En cours de livraison
							</div>
						</Card.Footer>
						</Card>
					</Col>
					<Col lg="3" sm="6">
						<Card className="card-stats">
						<Card.Body>
							<Row>
							<Col xs="5">
								<div className="icon-big text-center icon-warning">
								<i class="bi bi-check-circle fa-lg"  style={{ color: '#87CB16'}}></i>
								</div>
							</Col>
							<Col xs="7">
								<div className="numbers">
								<p className="card-category">Total Colis Livrés</p>
								<Card.Title as="h2"><b> {this.state.data.nombreEnd} Colis  </b></Card.Title>
								<Card.Title as="h2"><b>{ this.numberDisplay(this.state.data.collectEnd)  + ' DZD'}</b></Card.Title>
								</div>
							</Col>
							</Row>
						</Card.Body>
						<Card.Footer>
							<hr></hr>
							<div className="stats">
							<i className="far fa-clock-o mr-1"></i>
							Ce Mois
							</div>
						</Card.Footer>
						</Card>
					</Col>
					<Col lg="3" sm="6">
						<Card className="card-stats">
						<Card.Body>
							<Row>
							<Col xs="5">
								<div className="icon-big text-center icon-warning">
								<i class="bi bi-arrow-return-left  fa-lg"  style={{ color: '#f44336'}}></i>
								</div>
							</Col>
							<Col xs="7">
								<div className="numbers">
								<p className="card-category">Total Colis Retournés</p>
								<Card.Title as="h2"><b>{this.state.data.nombreReturned} Colis  </b></Card.Title>
								<Card.Title as="h2"><b>{ this.numberDisplay(this.state.data.collectReturned)  + ' DZD'}  </b></Card.Title>
								
								</div>
							</Col>
							</Row>
						</Card.Body>
						<Card.Footer>
							<hr></hr>
							<div className="stats">
							<i className="fas fa-redo mr-1"></i>
							Ce Mois
							</div>
						</Card.Footer>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="8">
						<Card>
						<Card.Header>
							<Card.Title as="h4">Envois Par Jours</Card.Title>
							<p className="card-category">30 derniers jours</p>
						</Card.Header>
						<Card.Body>
							<div className="ct-chart" id="chartHours">
							<CanvasJSChart options = {dailySent} />
							{/* <ChartistGraph data={data} options={options} type={type} /> */}
								{/* <ChartistGraph /> */}
							{/* <ChartistGraph
								data={{
								labels: [
									"9:00AM",
									"12:00AM",
									"3:00PM",
									"6:00PM",
									"9:00PM",
									"12:00PM",
									"3:00AM",
									"6:00AM",
								],
								series: [
									[287, 385, 490, 492, 554, 586, 698, 695],
									[67, 152, 143, 240, 287, 335, 435, 437],
									[23, 113, 67, 108, 190, 239, 307, 308],
								],
								}}
								type="Line"
								options={{
								low: 0,
								high: 800,
								showArea: false,
								height: "245px",
								axisX: {
									showGrid: false,
								},
								lineSmooth: true,
								showLine: true,
								showPoint: true,
								fullWidth: true,
								chartPadding: {
									right: 50,
								},
								}}
								responsiveOptions={[
								[
									"screen and (max-width: 640px)",
									{
									axisX: {
										labelInterpolationFnc: function (value) {
										return value[0];
										},
									},
									},
								],
								]}
							/> */}
							</div>
						</Card.Body>
						<Card.Footer>
							{/* <div className="legend">
							<i className="fas fa-circle text-info"></i>
							Open <i className="fas fa-circle text-danger"></i>
							Click <i className="fas fa-circle text-warning"></i>
							Click Second Time
							</div> */}
							<hr></hr>
							<div className="stats">
							<i className="fas fa-history"></i>
							{/* Updated 3 minutes ago {moment('2012620', "YYYYMMDD h:mm:ss").fromNow()} */}
							Nombre d'envois par jours sur un intervalle de 30 jours 
							</div>
						</Card.Footer>
						</Card>
					</Col>
					<Col md="4">
						<Card>
						<Card.Header>
							<Card.Title as="h4">Top Communes Par Envois</Card.Title>
							{/* <p className="card-category">Last Campaign Performance</p> */}
						</Card.Header>
						<Card.Body>
							<div
							className="ct-chart ct-perfect-fourth"
							id="chartPreferences"
							>
								<CanvasJSChart options = {topWilaya} />
							{/* <ChartistGraph
								data={{
								labels: ["40%", "20%", "40%"],
								series: [40, 20, 40],
								}}
								type="Pie"
							/> */}
							</div>
							{/* <div className="legend">
							<i className="fas fa-circle text-info"></i>
							Open <i className="fas fa-circle text-danger"></i>
							Bounce <i className="fas fa-circle text-warning"></i>
							Unsubscribe
							</div> */}
							<hr></hr>
							<div className="stats">
							<i className="far fa-clock"></i>
							Top 5 des Communes par rapport au nombre d'envois
							</div>
						</Card.Body>
						</Card>
					</Col>
					</Row>
		</Container>

		 	<div className = "row">
				<div className='col-md-12'>
					<div className='row'>
					
					</div> 

				</div>

			</div>

			{/* <div className={"ui-rect np"}>

			
			<div className={"row pb-5"}>

                

				<div className={"col-md-4"}>

					<span className={"dashboard-title"}>Délais d’enlèvement </span>
					<div className={"row"}>
						
						<CanvasJSChart options = {delaisPick} />
					</div>
				</div>


				<div className={"col-md-4 "}>
					<span className={"dashboard-title"}>Délais de livraison</span>

					<div className={"row"} >
						<CanvasJSChart options = {delaisDeliv} />
					</div>

				</div>

				<div className={"col-md-4 "}>
					<span className={"dashboard-title"}>Délais total</span>

					<div className={"row"} >
						<CanvasJSChart options = {delaisTotal} />
					</div>

				</div>
				
				

			</div>
			

			

			</div>
			<div className="ui-rect np  my-3">
							<div className="row pb-5">
								<div className="col-md-4">
									<span className="dashboard-title">Bordereau</span>
										<CanvasJSChart options={bordereau} />
									
								</div>

								<div className="col-md-4 ">
									<span className="dashboard-title">Factures</span>
									<div className="row">
											<CanvasJSChart options={factures} />
										
									</div>
									
								</div>
								<div className="col-md-4 ">
									<span className="dashboard-title">Collect</span>
									<div className="row">
											<CanvasJSChart options={collect} />
										
									</div>
									
								</div>
							</div>
			</div>
 */}


        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard)