import React, {useState, Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { faAngleDown,faAngleUp, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";





class FacturesDetail extends Component {
    constructor(props) {
        super(props);
    
    
        this.state = {
            isDetailsShown: false
        };
        
    }

    showDetails = () => {
        let bool = this.state.isDetailsShown 
        this.setState({
        isDetailsShown: bool? false :true
        })
        
    }
    changeColorSubTask = (item) =>{
      if(item == 'done')
        return 'text-success'
      if(item == 'started')
        return 'text-warning'
      return 'text-muted'
    }

    render(){
        return(
            <div className="col-md-12 py-3">
              <div className="row">
                <div  className="col-md-4">
                  <hr/>
                  <h4>Liste des bordereau inclus dans la facture</h4>
                  <div className="row">
                    <div className="col-md-6">
                      Code
                    </div>
                    <div className="col-md-6">
                      Montant
                    </div>
                  </div>
                  <hr style={{borderTop: '1px', borderTop:'dotted'}}/>
                  {this.props.bordereaux.map(bordereau => (
                    <div className="row">
                    <div className="col-md-6">
                      {bordereau.code}
                    </div>
                    <div className="col-md-6">
                      {bordereau.tarif}
                    </div>
                  </div>
                    // <div>
                    //   {bordereau.code}
                    // </div>
                  ))} 

                  {this.props.bordereaux.length === 0 && (
                    <div className={"col-md-12 text-center my-3"}>
                      Cette facture ne contient aucune charge pour l'instant
                    </div>
                  )}
                </div>

                <div  className="col-md-8">

                  <hr/>
                  <h4 className='text-center'>Historique du paiement</h4>
                  <div className ='row'>
                    <div className='col-md-2'>
                      date
                    </div>
                    <div className='col-md-2'>
                      Methode
                    </div>
                    <div className='col-md-4'>
                      Référence
                    </div>
                    <div className='col-md-2'>
                      récepteur
                    </div>
                    <div className='col-md-2'>
                      Montant
                    </div>
                    
                  </div>
                  <hr style={{borderTop: '1px', borderTop:'dotted'}}/>
                  {this.props.payment.map(payment => (
                    <div className ='row'>
                      <div className='col-md-2'>
                        {payment.payment_date}
                      </div>
                      <div className='col-md-2'>
                      {payment.methode}
                      </div>
                      <div className='col-md-4'>
                      {payment.ref}
                      </div>
                      <div className='col-md-2'>
                      {payment.staff}
                      </div>
                      <div className='col-md-2'>
                      {payment.montant} DZD
                      </div>
                     </div>
                  ))} 

                  {this.props.payment.length === 0 && (
                    <div className={"col-md-12 text-center my-3"}>
                      Pas d'historique de paiment
                    </div>
                  )}
                </div>
              </div>
            </div>
              
        )
    }


}export default FacturesDetail
