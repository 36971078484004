let initialState;

const { user, auth } = localStorage.user_session ? JSON.parse(localStorage.user_session) : { user: {}, auth: false };

const filters = {
  account_status: [
    { label: "Tous", value: "all" },
    { label: "Désactivé", value: "disabled" },
    { label: "Activé", value: "" },
    { label: "Supprimé", value: "deleted" }
  ],
  result_per_page: [{ label: "60", value: 60 }, { label: "100", value: 100 }, { label: "150", value: 150 }, { label: 'all', value: 0 }],
  wilayas: [],
  agents: [],
  managers: []
};

initialState = {
  sidebarHidden: true,
  filters: filters,
  auth: auth,
  user: user,
  notifications: [],
  notificationsHash: "",
  taskList: [],
  agentsList: [],
  flashMessage: {
    active: false,
    message: "",
    style: {},
    classes: ""
  },
  loading: false,
  loadingMessage: {
    message: "Loading",
    animate: false
  },
  overlay: {
    enabled: false,
    message: "Testing",
    label: false,
    button: {
      type: "button",
      label: "Test button label",
      link: "http://google.com"
    }
  },
  clients: [],
  vehicules: [],
  addresses: [],
  taskStatus: [],
  typeReglement: [{ label: "Cheque", value: "CHEQUE" }, { label: "Espece TTC", value: "ESPECE" }],
  typeCourse: [
    { label: "CHQ", value: "CHQ" },
    { label: "STR TTC", value: "STR" },
    { label: "FCT", value: "FC-T" },
    { label: "Autre", value: "Autre" }
  ],
  miseDispo: [{ label: "N/A", value: "na" }, { label: "1/2 J", value: "demi_jour" }, { label: "1 J", value: "1j" }],
  task_types: [{ label: "Chargement", value: "Chargement" }, { label: "Déchargement", value: "Dechargement" }],
  task_status: [
    { label: "Aucun", value: "none" },
    { label: "Dérangement", value: "Dérangement" },
    { label: "Annulée", value: "Annulée" },
    { label: "Reportée", value: "Reportée" },
    { label: "Non disponible", value: "Non disponible" }
  ]
};

const userReducer = function(state = initialState, action) {

  if(action.type === "SET_SIDEBAR") {
    let newState = {
      ...state,
      sidebarHidden: !action.payload
    }
    return newState
  }

  if (action.type === "DONE_LOADING") {
    let newState = {
      ...state,
      loading: false,
      loadingMessage: {
        message: "Loading",
        animate: false
      }
    };

    return newState;
  }

  if (action.type === "IS_LOADING") {
    let newState = {
      ...state,
      loading: true,
      loadingMessage: {
        message: action.payload ? action.payload.message : state.loadingMessage.message,
        animate: action.payload ? action.payload.animate : state.loadingMessage.animate
      }
    };

    return newState;
  }

  if (action.type === "SET_AUTH") {
    const userSession = {
      userid: action.payload.userid,
      username: action.payload.username,
      role: action.payload.role,
      plan_type: action.payload.plan_type,
      plan_credit: action.payload.plan_credit,
      plan_used_agents: action.payload.plan_used_agents,
      token: action.payload.token,
      picture: action.payload.picture
    };

    let newState = {
      ...state,
      auth: action.payload.success,
      user: userSession
    };
    localStorage.setItem("user_session", JSON.stringify({ auth: action.payload.success, user: userSession }));

    if (!action.payload.stay) window.location.href = "/login";

    return newState;
  }

  if (action.type === "SET_OVERLAY") {
    if (action.payload) {
      console.log(action.payload);
      let newState = {
        ...state,
        overlay: {
          enabled: true,
          message: action.payload.message,
          button: action.payload.button,
          label: action.payload.label ? action.payload.button : false
        }
      };

      return newState;
    } else {
      let newState = {
        ...state,
        overlay: {
          enabled: false
        }
      };
      return newState;
    }
  }

  if (action.type === "UPDATE_NOTIFICATIONS") {
    let newState = {
      ...state,
      notifications: action.payload.notifications,
      notificationsHash: action.payload.hash
    };

    return newState;
  }

  if (action.type === "READ_NOTIFICATIONS") {
    let newState = {
      ...state,
      notifications: state.notifications.map(x => ({ ...x, read: true }))
    };

    return newState;
  }

  if (action.type === "SET_FLASH") {
    let newState = {
      ...state,
      flashMessage: action.payload
    };

    return newState;
  }

  if (action.type === "CLOSE_FLASH") {
    let newState = {
      ...state,
      flashMessage: {
        ...state.flashMessage,
        active: false
      }
    };

    return newState;
  }

  if (action.type === "UPDATE_DATA") {
    let newState = {
      ...state,
      agentsList: action.payload.agents
    };

    return newState;
  }

  if (action.type === "LOAD_DATA") {
    console.log(action);
    let newState = {
      ...state,
      ...action.payload
    };

    return newState;
  }

  return state;
};

export default userReducer;
