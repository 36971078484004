import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import RegisterBoxApp from '../Components/Login/RegisterBoxApp';


function mapStateToProps(state) {
  return { auth: state.auth };
};


class RegisterAppRouter extends Component {

	
	render() {
		return(
				<Switch>

					{/* {(!this.props.auth ? <Redirect to="/login" /> : "")} */}
						<Route exact path='/registerapp' component={RegisterBoxApp} />
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(RegisterAppRouter)