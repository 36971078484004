import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setAuthAction, doneLoading, startLoading, setOverlay } from '../Redux/actions/index';
import TaskNew from '../Components/Bordereau/TaskNew';
import api from '../Utils/Api';



const mapStateToProps = state => {
	return {
		user: state.user,
		loading: state.loading
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: (x) => dispatch(setOverlay(x)),
    isLoading: (e) => dispatch(startLoading(e))
  };
}


const axios = require('axios');

class BordereauNewContainer extends Component {

	constructor(props) {
		super(props);

		//	this.props.isLoading({message: "Loading", animate:true});

		
		this.state = {
			agents: [],
			types: [],
			
		}

		
		this.handlers = {
			submitTask: this.submitTask.bind(this),
		}
		this.refs = {
			taskForm: React.createRef()
		}
		
		

	}


	submitTask(e, ref) {
		e.preventDefault();
		let that = this;

		this.props.isLoading({
			message:"Adding task",
			animate:true
		});

		const formdata = new FormData(ref);

		
		let data = {};

		for(var [key, value] of formdata.entries()) 
			data[key] = value
		
		data['user'] = this.props.user;
			//formd.push({ ...{key}: value})
			console.log(data);

		api.post('/api/task/new', (data))
		.then((response) => {

			if(response.data.success) {

				// window.ga('send', 'event', 'task', 'add'); // Google Analytics

				
				if(this.props.inline && this.props.afterPost) {
					this.props.afterPost();
				} else {
					this.props.history.push('/task');
				}

				this.props.setOverlay({
					message: 'Task successfully added !',
					label: "success",
					button: {
						type: "close",
						label: "Dismiss",
						link: "javascript:void(0)"
					}
				});
			} else {
				if(response.data.errcode === 101) {
					this.props.setAuth(response.data)
				}
			}

			this.props.doneLoading();


			
		}).catch(err => this.props.doneLoading());
		
	}


	
	componentDidMount() {
		
		
		api.get('/api/task/form?token='+this.props.user.token)
		.then((response) => {
			this.setState({agents: response.data.agents, types: response.data.types, status: response.data.status})
		});

		
	}


	render() {
		
		
		return(

			<TaskNew
        taskId={this.props.taskId}
        bordereau = {this.props.bordereau}
				isDuplicate={this.props.isDuplicate}
				inline={this.props.inline}
				afterPost={this.props.afterPost}
				data={this.state}
				handlers={this.handlers}
				refs={this.refs}
			/>
		)
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(BordereauNewContainer)
