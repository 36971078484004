import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { useTranslation, Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(setAuthAction(user)),
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}


class AgentDetail extends Component {


	constructor(props) {
		super(props);
		
		this.state = {
			info: {
				userid: 0,
				username: "",
				password: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				station: "",
				stationid: "",
				activity: []
			}, 
			stations: []
		}

		this.props.isLoading();

		
		this.handleInput = this.handleInput.bind(this);
		this.formSubmit = this.formSubmit.bind(this);
	}


	

	handleInput(e) {
		let nstate = this.state;

		_.set(nstate, 'info.'+e.target.name, e.target.value);
		this.setState(nstate);
		
	}

	handleSelect = (event, name) => {
		let value = event.target ? event.target.value : event.value;
    let label = event.target ? event.target.value : event.label;
    console.log(event);
    console.log(name);
    //let that = this
		this.setState({
			info:{
				...this.state.info,
				[name]: label,
				stationid: value
			}
		})
		console.log('info' + JSON.stringify(this.state.info))
	}


	formSubmit(e) {
		e.preventDefault();

		this.props.isLoading();
		let that = this;

		const data = {
			user: this.props.user,
			agent: this.state.info
		}

		api.post('/api/user/edit/'+data.agent.userid, data)
		.then(function(response) {

			that.props.doneLoading();

		})


	}

	componentDidMount() {
		const that = this;

		
		api.get('/api/user/'+this.props.match.params.userid+'?tab=personal&token='+this.props.user.token)
		.then(function(response) {

			if(!response.data.success) {
				that.props.doneLoading();
				that.props.setAuth(response.data);
				return true;
			}

            that.setState({info: response.data, ready: true})
			

			that.props.doneLoading();
			
		});
        api.get('/api/form/station').then(result => {
            if(result.data.success){
                that.setState({
                    stations:result.data.stations
                })
            }
        })

	}


	render() {

		return(
			<div>

	
			<div className={"col-md-6"}>
				<form method="#" onSubmit={this.formSubmit}>
				<div className={"form-group"}>
					<label><Trans i18nKey="user_username" /></label>
					<input type="text" className={"form-control"} name="username" value={this.state.info.username} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_password" /></label>
					<input type="password" className={"form-control"} name="password" value={this.state.info.password} onChange={this.handleInput}/>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_firstname" /></label>
					<input type="text" className={"form-control"} name="firstName" value={this.state.info.firstName} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_lastname" /></label>
					<input type="text" className={"form-control"} name="lastName" value={this.state.info.lastName} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_email" /></label>
					<input type="text" className={"form-control"} name="email" value={this.state.info.email} onChange={this.handleInput} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="user_phone" /></label>
					<input type="text" className={"form-control"} name="phone" value={this.state.info.phone} onChange={this.handleInput} />
				</div>

				{this.state.info.role === 'commercial' && <div className={"form-group"}>
						<label>Station</label>
						<Select
							classNamePrefix={"custom-select"}
							className={"custom-select-c "}
							options={this.state.stations}
							value = {
								this.state.stations.filter(option => 
								   option.label === this.state.info.station)
							}
							placeholder={"Station"}
							name="station"
							onChange={e => this.handleSelect(e, 'station')}
							required={true}
						/>
				</div>}

		

  				<div className={"mt-5"}>
	  				<button className={"btn btn-success float-right"}>
	  				<Trans i18nKey="button_save" />
	  				</button>
  				</div>
  				</form>
  			</div>

  			
  			</div>

			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentDetail)
