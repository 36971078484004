import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoginBox from './Login/LoginBox';
import HomeView from './Home/HomeView';
import UserRouter from './../Routers/UserRouter';
import TaskRouter from './../Routers/TaskRouter';
import SettingsRouter from './../Routers/SettingsRouter';
import ManagerRouter from './../Routers/ManagerRouter';
import AdminRouter from './../Routers/AdminRouter';
import UserAccount from './User/UserAccount';
import TaskListContainer from '../Containers/TaskListContainer';
import Register from './../Components/User/Register';
import NewPlan from './User/NewPlan';
import ClientList from './Client/ClientList';
import ClientNew from './Client/ClientNew';
import ClientDetail from './Client/ClientDetail';
import RapportRouter from '../Routers/RapportRouter';
import BordereauRouter from '../Routers/BordereauRouter';
import AccueilRouter from '../Routers/AccueilRouter';
import CommandeRouter from '../Routers/CommandeRouter';
import FacturesRouter from '../Routers/FacturesRouter';
import CollectRouter from '../Routers/CollectRouter';
import LoginBoxApp from './Login/RegisterBoxApp';
import RegisterAppRouter from '../Routers/RegisterAppRouter';
import ClientRouter from '../Routers/ClientRouter';
import tarifTestContainer from '../Containers/tarifTestContainer';
import ClientTarifs from './Client/ClientTarifs';
import ReductionsList from './Client/ReductionsList';
import DebtsList from './Collect/DebtsList';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}
class AppRouter extends Component {

	render() {
		return(
			<div>
				<Switch>
					<Route exact path='/login' component={LoginBox} />
		      	  	<Route exact path='/' component={HomeView} />

		      	  	<Route path='/manager' component={ManagerRouter} />
					<Route path='/settings' component={SettingsRouter} />
					<Route path='/users' component={UserRouter} />

					<Route exact path='/client' component={ClientList} />
					<Route exact path='/client/new' component={ClientNew} />
					<Route exact path='/client/detail/:id' component={ClientDetail} />
					<Route exact path='/client/tarifs/:id' component={ClientTarifs} />
					<Route exact path='/client/reductions' component={ReductionsList} />
					<Route exact path='/debts' component={DebtsList} />

					<Route path='/account' component={UserAccount} />
					<Route path='/plan' component={NewPlan} />
					{/* <Route path='/register' component={Register} /> */}
					<Route path='/register' component={LoginBox} />

					<Route path='/agent/tasks' component={TaskListContainer} />

					

					<Route path='/task' component={TaskRouter} />
					<Route path='/bordereau' component={BordereauRouter}/>

					<Route path='/rapport' component={RapportRouter} /> 

					
					<Route path='/payments' component={AdminRouter} />
					<Route path='/bill' component={AdminRouter} />

					<Route path='/accueil' component={AccueilRouter} />
					<Route path='/Commande' component={CommandeRouter} />

					<Route path='/factures' component={FacturesRouter} />
					<Route path='/mesfactures' component={FacturesRouter} />

					<Route path='/collect' component={CollectRouter} />
					<Route path='/mescollectes' component={CollectRouter} />
					<Route path='/dashboard' component={ClientRouter} />
					
					<Route path='/registerapp' component={RegisterAppRouter} />
					<Route exact path='/tarification' component={tarifTestContainer} /> 

					

				</Switch>
			</div>

			
		)
	}
}


export default withRouter(connect(mapStateToProps)(AppRouter));
