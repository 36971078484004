import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select from 'react-select';

import * as actions from './../../Redux/actions/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTimes, faInfo, faTrash, faFileAlt,faPowerOff } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import SocketContext from '../../Utils/socket-context';

import {  Trans } from "react-i18next";
import Pagination from '../../Utils/Pagination';
import * as CONSTANTS from "../../Utils/Constants";
import Modal from 'react-bootstrap/Modal';
import AgentRow from './AgentRow';

import {ExportCSV}  from '../Rapport/ExportCSV';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(actions.setAuthAction(user)),
		isLoading: e => dispatch(actions.startLoading(e)),
		doneLoading: () => dispatch(actions.doneLoading()),
		setFlash: (f) => dispatch(actions.setFlash(f)),
    	closeFlash: (f) => dispatch(actions.closeFlash(f))
	}
}

const moment = require("moment");


class AgentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			filters: {
				status: '',
				name: ''
			},
			tableIsLoading: true,
			limit: 10,
			pages: 1,
			current: 1,
			showOverlay: false,
			selectedUser: 0,
			lastUpdate: 0,
			showOverlayRenew: false,
			renewDuration: 1,
			runSheet: '',
			showModel : false

		}

		this.props.isLoading();

		this.loadAgents = this.loadAgents.bind(this);
		this.loadAgents =_.debounce(this.loadAgents, 300)
		this.selectPage = this.selectPage.bind(this);
		this.userDeleteBtn = this.userDeleteBtn.bind(this);
		this.toggleOverlay = this.toggleOverlay.bind(this);
		this.toggleOverlayRenew = this.toggleOverlayRenew.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.agentInterval = null;
		this.updateFilter = this.updateFilter.bind(this);
		
		this.changeLimit = this.changeLimit.bind(this);

		this.submitRenewal = this.submitRenewal.bind(this);

		console.log('this is the param '+this.props.match.params.id)
	}

	changeDuration(e) {
		this.setState({
			renewDuration: e.value
		})
	}
	submitRenewal(e) {
		e.preventDefault();
		const that = this;

		const data = {
			userid: this.state.selectedUser,
			duration: this.state.renewDuration
		}
		
		if(!data.duration || parseInt(data.duration) === 0)
			return;

		api.post('/api/user/'+this.state.selectedUser+'/renew', data)
		.then(function(response) {
			if(response.data)
				console.log(response.data)
		})
		
	}
	changeLimit(e) {

		this.props.isLoading();

		
		this.setState({
			limit: e.value,
			current: 1
		}, () => this.loadAgents())

		

	}

	selectPage(p) {
		this.props.isLoading();
		this.setState({current: p});
		this.loadAgents(p);
	}

	componentDidUpdate(prev) {
		const that = this;

		if(this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {
				if(data.type && data.type === 'user_status')
					that.loadAgents(); 
			});
		}

		

	}

	getTimestamp() {
		var now = new Date();
		return parseInt(now.getTime()/1000)
	}

	componentDidMount() {

		if(this.props.match.params.id){
			let that = this 
			that.setState({
				filters:{
					name: this.props.match.params.id
				}
			})
		}

		if(this.props.socket) {
			this.props.socket.on('test', function (data) {
				console.log('event: ', data)
				if(data.type && data.type === 'user_status')
					that.loadAgents(); 
			});
		}



		const that = this;

		this.loadAgents();

		this.agentInterval = setInterval(function() {
			
			if(that.getTimestamp() - that.state.lastUpdate > 15) {
				
				that.loadAgents();
			}
		}, 1500)

	}
	componentWillUnmount() {
		clearInterval(this.agentInterval)

		if(this.props.socket) {
			this.props.socket.off('test')
		}
	}

	toggleOverlay(e) {
		this.setState({showOverlay: !this.state.showOverlay});
	}
	toggleOverlayRenew(e, user) {

		this.setState({
			showOverlayRenew: !this.state.showOverlayRenew,
			selectedUser: user ? user : this.state.selectedUser
		});
	}


	loadAgents(p = this.state.current) {
		const that = this;
		

		this.setState({
			lastUpdate: this.getTimestamp()
		}, function() {

			const crits = "name="+that.state.filters.name
						  +"&status="+that.state.filters.status
						  +"&limit="+that.state.limit

			api.get('/api/users?role=4&token='+that.props.user.token+'&page='+p+'&'+crits)
			.then(function(response) {
				if(response.data.success) {
					
					that.setState(response.data);
				} else {
					//that.props.setAuth(response.data);
				}

				that.props.doneLoading();
			});
		});

		
	}
 
	userDeleteBtn(id) {
		
		this.setState({
			selectedUser: id
		})
		

		this.toggleOverlay();

	}

	deleteUser(e) {

		let that = this;
		const data = { 
			user: this.props.user
		}

		that.toggleOverlay();

		that.props.isLoading();

		
		api.post('/api/user/delete/'+this.state.selectedUser, data)
		.then(function(response) {
			
			

			if(response.data.success) {
				//that.loadAgents();
				that.props.setFlash({
					active: true,
					message: "User has been succesfully deleted.",
					classes: "success",
					style: {}
				})
			} else {
				that.props.doneLoading();
				that.props.setFlash({
					active: true,
					message: "Failed to delete agent : "+response.data.error,
					classes: "warning",
					style: {}
				})
			}
		});
		
	}

	updateFilter(e, name) {

		let that = this;

		const value = e.target ? e.target.value : e.value;


		this.setState({
			filters: {
				...this.state.filters, 
				[name]: value
			}
		}, function() {
			that.props.isLoading();
			that.loadAgents(1);
		});
	}

	getRunSheet = (agent_id) => {
		this.props.isLoading();

		api.get("/api/v2/task/fetch?page=" + 1 + "&" + 'agent='+ agent_id + '&runSheet=true&status=3').then((response) => {
			if (response.data.success) {
			  this.setState({
				runSheet: response.data.runSheet,
				
			  });
			  let url = CONSTANTS.REMOTE_API + 'File/RunSheet/';
			  url +=  response.data.runSheet;
              window.open(url, '_blank').focus();
			} else {
			  if (response.data.errcode === 101) {
				this.props.setAuth(false);
			  }
			}
	  
			this.props.doneLoading();
			// console.log(this.state.tasks);
		  });
	}

	userDisEnb = (id, action) => {
		//this.props.isLoading();
		let data = {status: action}
		api.post("/api/disable/user/"+id, data).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
			}else{
				//this.props.doneLoading();
				this.props.setFlash({
					active: true,
					message: result.data.error,
					classes: "warning",
					style: {}
				})
			}
			window.location.reload(false);
		})
	}

	render() {
		const toggleOverlay = this.toggleOverlay;
		const toggleOverlayRenew = this.toggleOverlayRenew;
		const showOverlay = this.state.showOverlay;
		const showOverlayRenew = this.state.showOverlayRenew
		const data = this.state;

		var today = new Date(),
		now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		return(
			<div>


			{!this.props.auth ? <Redirect to="/login" /> :  ""}

			<div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
					<button onClick={e => { toggleOverlay(e); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>

					Are you sure you want to delete this user?<br />
					This action is irreversible
					<div className={"delete-buttons"}>
					<button  onClick={e => { toggleOverlay(e); } } className={"btn btn-info"}>No</button><button onClick={this.deleteUser} className={"btn btn-danger ml-2"}>Delete</button>
					</div>
				</div>
			</div>


			<div className={showOverlayRenew ? "ui-overlay open" : "ui-overlay"}>
				<div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
					<button onClick={e => { toggleOverlayRenew(e, false); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
					<FontAwesomeIcon icon={faTimes} />
					</button>
					<h5>Renew/extend agent subscription</h5>
					<Select
						options={[
							{label: "1 month - 1800 DZD", value: 1},
							{label: "2 months - 3500 DZD", value: 2},
							{label: "3 months - 4500 DZD", value: 3},
							{label: "6 months - 8000 DZD", value: 6}
						]}
					/>
					<small>This will be added to an existing bill or creates a new one.</small>
					<div className={"delete-buttons"}>
					<button  onClick={e => { this.submitRenewal(e) } } className={"btn btn-success"}>Submit</button>
					</div>
				</div>
			</div>


			<div className={"container-header"}>
				Coursiers
				
				<span>
					<Link to={"/manager/agents/new"}  className={"cbtn float-right ml-1"}>Ajouter un nouveau Coursiers</Link>
					{/* <Link to={"/manager/agents/map"}  className={"cbtn float-right"}><Trans i18nKey="button_map_view" /></Link> */}
				</span>
			</div>
				
			<div className={"clear"}></div>



			<div className={"row form ui-rect"}>
				
				<div className={"col-md-3"}>
					<label><Trans i18nKey="label_status" /></label>
					<Select
					classNamePrefix={"custom-select"}
					options={[
						{label: <Trans i18nKey="label_agents_all" />, value: ''},
						{label: <Trans i18nKey="agent_online" />, value: 'online'},
						{label: <Trans i18nKey="agent_offline" />, value: 'offline'},
						{label: <Trans i18nKey="agent_onduty" />, value: 'onduty'}
					]}
					placeholder={<Trans i18nKey="label_status" />}
					onChange={e => this.updateFilter(e, 'status')}
					/>
				</div>

				

				<div className={"form-group col-md-3"}>
					<label><Trans i18nKey="label_name" /></label>
					<input onChange={e => this.updateFilter(e, 'name')} value={this.state.filters.name} name="name" type="text" className={"form-control"} />
				</div>

				<div class="col-md-6 text-right">
					<ExportCSV csvData={this.state.users} fileName={'Agents' + now} classBtn = {'btn btn-md mt-4 btn-outline-success '}/>
				</div>
				

			</div>



			
			<div className={"row ui-rect my-4"}>
                <div className={"col-md-6 items-found"}>
                    <p>
                        <b>{this.state.total}</b> Coursiers trouvés
                    </p>
                </div>
                
                <div className={"col-md-6 per-page-wrap"}>
                    <div className={"per-page-content"}>
                        <p>Résultat par page</p> 
                        <Select
                            classNamePrefix={"custom-select"}
                            onChange={this.changeLimit}
                            className={"per-page nm"}
                            options={this.props.filters.result_per_page}
                            value={{label: this.state.limit, value: this.state.limit}}
                        />
                    </div>
                    <Pagination
                        data={this.state}
                        handleClick={this.selectPage} 
                    />
                    
                </div>

            </div>



			

			<div className={"row ui-rect np"}>

			


			<div className="col-md-12 ctable">
			
			
				<div className={"row head-titles ctable-row"}>
					<div className={"col-md-2"}>
						{/* <Trans i18nKey="label_agent" /> */}Coursiers
					</div>
					<div className={"col-md-2"}>
						<Trans i18nKey="user_username" />
					</div>
					{/* <div className={"col-md-2"}>
						Type
					</div> */}
					<div className={"col-md-1"}>
						<Trans i18nKey="user_phone" />
					</div>
					<div className={"col-md-4"}>
						<Trans i18nKey="user_email" />
					</div>

					<div className={"col-md-3"}>
						
					</div>
				</div>


				{this.state.users.map((user, j) => (


						<AgentRow
							agent={user}
							key = {j}
						/>	


				))
				}

				{this.state.users.length === 0 && (<div className={"col-md-12 text-center my-3"}><Trans i18nKey={"empty_agents"} /></div>)
				}




			</div>

			</div>

			
			
			</div>
		)
	}
}



const AgentListConsumer = props => (
	<SocketContext.Consumer>
		{socket => <AgentList {...props} socket={socket} />}
	</SocketContext.Consumer>
  )
	

export default connect(mapStateToProps, mapDispatchToProps)(AgentListConsumer)
