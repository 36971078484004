import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import { faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
// import CollectInterfaceRow from './CollectInterfaceRow';
import CollectInterfaceRow from "./CollectInterfaceRow";
import { loadCommonData, buildUriParams } from "../../Utils/Helpers";
import api from "../../Utils/Api";
import {ExportCSV}  from '../Rapport/ExportCSV';



const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  }

  
const moment = require("moment");

class CollectInterface extends Component {

    constructor(props) {
        super(props);

        this.props.isLoading({
          message: "Chargement de vos Collectes",
          animate: true
        });
    
        this.state = {
            managers: [],
            tasks: [],
            agents: [],
            status: [],
            types: [],
            clients: [],
            bordereaux: [],
            collects: [],
            rows: [],
            current: 1,
            pages: 1,
            limit: 60,
            filters:  props.filters ?   props.filters  :  {} ,
            total: 0
        };

        this.loadBordereau()
    }
    componentWillReceiveProps(nextProps) {
      if(this.props.from){
        this.setState({ filters: nextProps.filters });  
        setTimeout(() => {
          this.loadBordereau()
        }, 1);
      }
       
    }

    updateFilter = (event, name) => {
      let value = "";
      
      value = event.target ? event.target.value : event.value;
      console.log(value)
      this.setState(
      {
          filters: {
          [name]: value
          }
      }
      );
      setTimeout(() => {
        this.loadBordereau()
      }, 1);
  };

    loadBordereau = (p = this.state.current, e, redirect=false) => {
        if(e)
          e.preventDefault()
        
        
        let params =''
        // console.log('is filter => '+ this.props.isFilter)
        // if(this.props.from){
        //   console.log('here => '+this.props.filters.code_bordereau)
        //   this.props.isLoading();
        //  params = buildUriParams(this.props.filters);
        // }else{
          
        //   this.props.isLoading();
        //   params = buildUriParams(this.state.filters);
        // }
        this.props.isLoading();
          params = buildUriParams(this.state.filters);
    
        
        console.log(params);
        const crits = "limit=" + this.state.limit + params;
    
    
        api.get("/api/collect/fetch?page=" + p + "&" + crits).then((response) => {
          if (response.data.success) {
            this.setState({
              pages: response.data.pages,
              total: response.data.total,
              collects: response.data.collects,
            });
          } else {
            if (response.data.errcode === 101) {
              this.props.setAuth(false);
            }
          }
    
          this.props.doneLoading();
          // console.log(this.state.tasks);
        });
        if(redirect){
          this.props.history.push("/task");
        }
        //
        return false;
      };

    render(){
      var today = new Date(),
      now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        return(
            <div class="container-fluid">
                <div className="col-md-12 my-5">
                    {this.props.from != true && <div className='row'>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <form class="form" >
                                <div class="input-group">
                                    <input 
                                      class="form-control p-4" 
                                      type="text" placeholder="Recherche par code bordereau..." 
                                      aria-label="Search" style={{paddingLeft: "20px", borderRadius: "40px", backgroundColor: '#fff'}} id="mysearch"
                                      name="code_bordereau" 
                                      onChange={e => this.updateFilter(e, 'code_bordereau')}
                                      >
                                      
                                    </input>
                                    <div class="input-group-addon mt-2" style={{marginLeft: "-50px", zIndex: 3, borderRadius: "40px", backgroundColor: "transparent", border:"none"}}>
                                        <button class="btn btn-link" type="submit" style={{borderRadius: "20px"}} id="search-btn"><FontAwesomeIcon className={"ml-2"} icon={faSearch} /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-2">
                            <ExportCSV csvData={this.state.collects} fileName={'Collects' +now} classBtn = {'btn btn-sm btn-success mt-2'}/>
                        </div>
                        
                    </div>}

                </div>
                
                {/* <h2>Responsive Tables Using LI <small>Triggers on 767px</small></h2> */}
                <div class="ui-rect np">
                    {/* <div class="col-md-12 mb-2">
                        <div class="row">
                            <h4 class="col-md-4">resultat colis</h4>
                            <div class="col-md-7"></div>
                            <button type="button" style={{float:'right', backgroundColor:"#0275d8", color: "white"}} class="btn col-md-1" onClick={() => this.setState({ showNewTask: true })}>Nouveau colis</button>
                        </div>
                    </div> */}
                    
                    <div class="responsive-table">
                        
                            {this.props.user.role === "client" ?
                              <div class="table-header shadow">
                                  <div class="col col-3 text-white font-weight-bold">Code Bordereau</div>
                                  <div class="col col-3 text-white font-weight-bold">Montant</div>
                                  <div class="col col-3 text-white font-weight-bold">Collect</div>
                                  <div class="col col-3 text-white font-weight-bold">Status</div>
                              </div>
                              :
                              <div class="table-header shadow">
                                  <div class="col col-2 text-white font-weight-bold">Code Bordereau</div>
                                  <div class="col col-1 text-white font-weight-bold">Client</div>
                                  <div class="col col-5 text-white font-weight-bold">Trajet</div>
                                  <div class="col col-2 text-white font-weight-bold">Montant</div>
                                  <div class="col col-2 text-white font-weight-bold">Documents</div>
                              </div>
                            }
                            {this.state.total> 0 ? 
                                this.state.collects.map(collect =><CollectInterfaceRow data={collect}/>)
                            :
                            <div class="d-flex justify-content-center" style={{float: 'center'}}><h4>Aucune Collecte trouvée</h4></div>
                            }
                            
                    </div>
                </div>
                {this.state.showNewTask && (
                    <div className={`ui-overlay open`}>
                        <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
                        <button
                            onClick={() => this.setState({ showNewTask: false })}
                            className={"ui-overlay-close btn btn-danger btn-sm"}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>

                        {/* <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} /> */}
                        <BordereauNewContainer />
                        </div>
                    </div>
                    )}
                
            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectInterface));
  
