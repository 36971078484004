
export function startLoading(payload) {
  return { type: "IS_LOADING", payload }
};

export function doneLoading(payload) {
  return { type: "DONE_LOADING", payload }
};

export function setAuthAction(payload) {
  return { type: "SET_AUTH", payload }
};

export function setOverlay(payload) {
  return { type: "SET_OVERLAY", payload }
};

export function updateNotifications(payload) {
  return { type: "UPDATE_NOTIFICATIONS", payload }
};

export function readNotifications(payload) {
  return { type: "READ_NOTIFICATIONS", payload }
};

export function setFlash(payload) {
  return { type: "SET_FLASH", payload }
};

export function closeFlash(payload) {
  return { type: "CLOSE_FLASH", payload }
};

export function updateData(payload) {
  return { type: "UPDATE_DATA", payload }
};

export function loadData(payload) {
  return { type: "LOAD_DATA", payload }
};

export function setSidebarVisible(payload) {
  console.log(payload);
  
  
  return { type: "SET_SIDEBAR", payload }
}





