import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import Datetime from 'react-datetime';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCommentAlt,   faTag, faList, faMapMarker, faPhone, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import '../../../react-datetime.css';
import {  Trans } from "react-i18next";

const moment = require('moment');

class TaskNew extends Component {

	constructor(props) {
		super(props);

		this.taskForm = React.createRef();
		this.submitTask = this.submitTask.bind(this);
		this.handelSelect = this.handelSelect.bind(this);

		this.state = {
			agent: {
				id: 0,
				name: ""
			},
			type: {
				id: 0,
				name: ""
			},
			status: {
				id: 0,
				name: ""
			}
		}

	}

	componentDidMount() {
		if(this.props.data.ready) {
			this.setState({
				agent: {
				id: this.props.data.task.task_agent_id,
				name: this.props.data.task.task_agent
				},
				type: {
					id: this.props.data.task.task_type_id,
					name: this.props.data.task.task_type
				},
				status: {
					id: this.props.data.task.task_status_id,
					name: this.props.data.task.task_status
				}
			})
		}
	}

	submitTask(e) {
		this.props.handlers.submitTask(e, this.taskForm.current);
	}

	handelSelect(e, f) {
		this.setState({[f]: { id:e.value, name: e.label}})
	}

	render() {
		//const refs = this.props.refs;
		const data = this.props.data;


		return(
			<React.Fragment>
			<div className={"container-header"}>
				<Trans i18nKey="task_edit" />
				<Link to={'/task/'+data.task.task_id+'/history'} className={"btn float-right btn-primary"} >History</Link>
			</div>

			<form ref={this.taskForm} onSubmit={this.submitTask}>
			<div className={"row"}>
			<div className={"col-md-6 taskForm"}>

				<input type="hidden" name="taskid" value={data.task.task_id} />

				<div className={"form-group"}>
					<label><Trans i18nKey="task_name" /><FontAwesomeIcon icon={faTag} className={"form-label-icon"} /> </label>
					<input defaultValue={data.task.task_name} type="text" name="task_name" className={"form-control"} />
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="task_type" /><FontAwesomeIcon icon={faList} className={"form-label-icon"} /></label>
					<input type="hidden" value={this.state.type.id} name="task_type" />
					<Select
					value={{label: this.state.type.name, value: this.state.type.id}}
					onChange={e => this.handelSelect(e, 'type')}
					options={data.types}
					isLoading={data.types.length === 0}
					/>

				</div>


				<div className={"form-group"}>
					<div className={"row"}>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="label_name" /><FontAwesomeIcon icon={faUser} className={"form-label-icon"} /></label>
						<input defaultValue={data.task.task_client_name} type="text" name="task_client_name" className={"form-control"} />
						</div>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="user_phone" /><FontAwesomeIcon icon={faPhone} className={"form-label-icon"} /></label>

						<input defaultValue={data.task.task_client_phone} type="text" name="task_client_phone" className={"form-control"} />
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="label_address" /> <FontAwesomeIcon icon={faMapMarker} className={"form-label-icon"} /></label>
					<input disabled defaultValue={data.task.task_address} type="text" name="task_address" className={"form-control"} />
				</div>



				<div className={"form-group"}>
					<div className={"row"}>

						<div className={"col-md-6"}>
						<label>Start <FontAwesomeIcon icon={faTable} className={"form-label-icon"} /></label>
						{data.ready ?
						<Datetime defaultValue={ data.task.task_start_date ? moment(data.task.task_start_date).format('YYYY-MM-DD h:mm A') : new Date() } dateFormat="YYYY-MM-DD" inputProps={{ name:"task_start_date", className:"form-control" }} />
						: ""}
						</div>

						<div className={"col-md-6"}>
						<label><Trans i18nKey="label_end" /> <FontAwesomeIcon icon={faTable} className={"form-label-icon"} /></label>
						{data.ready ?
						<Datetime defaultValue={ data.task.task_end_date ? moment(data.task.task_end_date).format('YYYY-MM-DD h:mm A') : new Date() } dateFormat="YYYY-MM-DD" inputProps={{ name:"task_end_date", className:"form-control" }} />
						: ""}
						</div>

					</div>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="special_instructions" /> <FontAwesomeIcon icon={faCommentAlt} className={"form-label-icon"} /></label>
					<textarea defaultValue={data.task.task_instruction} type="text" className={"form-control"} name="instruction" />
				</div>

				<div className={"form-group"}>
					<button type="submit" className={"btn btn-block btn-success float-right"}><Trans i18nKey="label_edit" /></button>
				</div>


			</div>

			<div className={"col-md-6 taskForm"}>
				<div className={"form-group"}>
					<label><Trans i18nKey="task_assign_to_agent" /><FontAwesomeIcon icon={faUserTag} className={"form-label-icon"} /></label>
					<input type="hidden" value={this.state.agent.id} name="task_agent" />
					<Select
					value={{label: this.state.agent.name, value: this.state.agent.id}}
					onChange={e => this.handelSelect(e, 'agent')}
					options={data.agents}
					isLoading={data.agents.length === 0}
					/>
				</div>

				<div className={"form-group"}>
					<label><Trans i18nKey="current_status" /></label>
					<input type="hidden" value={this.state.status.id} name="task_status" />
					<Select
					value={{label: this.state.status.name, value: this.state.status.id}}
					onChange={e => this.handelSelect(e, 'status')}
					options={data.status}
					isLoading={data.status.length === 0}
					/>
				</div>
			</div>


			</div>

			</form>


			</React.Fragment>
		)
	}
}


export default TaskNew
