import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import './ClientInterfaceStyle.css';
import { faPrint, faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
import CommandeRow from './CommandeRow';
import { loadCommonData, buildUriParams } from "../../Utils/Helpers";
import api from "../../Utils/Api";
import {ExportCSV}  from '../Rapport/ExportCSV';
import * as CONSTANTS from "../../Utils/Constants"
import Pagination from "../../Utils/Pagination";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";


const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
      filters_pagination: state.filters,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  }

  
const moment = require("moment");

class CommandeInterface extends Component {

    constructor(props) {
        super(props);

        this.props.isLoading({
          message: "Chargement de vos Commandes",
          animate: true
        });
    
        this.state = {
            managers: [],
            tasks: [],
            agents: [],
            status: [],
            types: [],
            clients: [],
            bordereaux: [],
            rows: [],
            zones: [],
            bordereauxOnAction : [],
            current: 1,
            pages: 1,
            limit: 60,
            filters:  props.filters ?   props.filters  :  {} ,
            total: 0
        };

        this.loadBordereau()

        this.handlers = {
          fillBOnAction: this.fillBOnAction,
        };
    }
    componentWillReceiveProps(nextProps) {
      if(this.props.from){
        this.setState({ filters: nextProps.filters });  
        setTimeout(() => {
          this.loadBordereau()
        }, 1);
      }
       
    }
    componentDidMount(){
      let that = this
      api.get('/api/form/zones').then(result => {
        if(result.data.success){
          that.setState({
            zones: result.data.zones
          })
        }
      })
    }

    updateFilter = (event, name,isDate=false) => {
      let value = "";

      if (isDate) {
        value = event;
      } else {
        value = event.target ? event.target.value : event.value;
      }
      // value = event.target ? event.target.value : event.value;
      console.log(value)
      this.setState(
      {
          filters: {
            ...this.state.filters,
            [name]: value
          }
      }
      );
      setTimeout(() => {
        this.loadBordereau()
      }, 1);
  };

    loadBordereau = (p = this.state.current, e, redirect=false) => {
        if(e)
          e.preventDefault()
        
        
        let params =''
        // console.log('is filter => '+ this.props.isFilter)
        // if(this.props.from){
        //   console.log('here => '+this.props.filters.code_bordereau)
        //   this.props.isLoading();
        //  params = buildUriParams(this.props.filters);
        // }else{
          
        //   this.props.isLoading();
        //   params = buildUriParams(this.state.filters);
        // }
        this.props.isLoading();
          params = buildUriParams(this.state.filters);
    
        
        console.log(params);
        const crits = "limit=" + this.state.limit + params;
    
    
        api.get("/api/bordereau/fetch?page=" + p + "&" + crits).then((response) => {
          if (response.data.success) {
            this.setState({
              pages: response.data.pages,
              total: response.data.total,
              bordereaux: response.data.bordereaux,
            });
          } else {
            if (response.data.errcode === 101) {
              this.props.setAuth(false);
            }
          }
    
          this.props.doneLoading();
          // console.log(this.state.tasks);
        });
        if(redirect){
          this.props.history.push("/task");
        }
        //
        return false;
      };

    fillBOnAction = (event, id) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let tab = this.state.bordereauxOnAction
        if (value && this.state.bordereauxOnAction.indexOf(id) === -1){ 
          tab.push(id)
          this.setState({
            bordereauxOnAction: tab
          })
        }else if(!value){
          const index = tab.indexOf(id);
          if (index > -1) {
            tab.splice(index, 1);
            this.setState({
              bordereauxSelected: tab
            })
          }
        }
        console.log(this.state.bordereauxOnAction)
    }


    applyAction = () => {

      if(this.state.bordereauxOnAction.length === 0)
        return

      api.post("/api/bordereau/actions?action=printA4", this.state.bordereauxOnAction).then(result => {

        if(result.data.success){
          let url = CONSTANTS.REMOTE_API + result.data.file;
          window.location = url;
        }else{
          this.setState({
            showRecu: false,
            bordereauxOnAction: []
          })
        }
      })
    }

    selectPage = p => {
      this.props.isLoading();
      this.setState({ current: p });
      this.loadBordereau(p);
    };

    changeLimit = e => {
      this.props.isLoading();
      this.setState(
        {
          limit: e.value,
          current: 1
        },
        () => this.loadBordereau()
      );
    };

    formatDate = date => (date ? new Date(date) : undefined);
  
    
    render(){
      var today = new Date(),
      now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

      let that = this
      const zones = Object.entries(that.state.zones).map(([key, i]) => ({
        label: key,
        value: key
      }))

      const stateGlobal = [
        {label: "All", value: " "},
        {label: "Waiting", value: "null"},
        {label: "Done", value: "done"},
      ];
  
      const retour = [
        {label: "All", value: " "},
        {label: "Retour", value: "R"},
        {label: "Non Retour", value: "NotR"},
      ];
  
        return(
            <div class="container-fluid">
                {/* <div className="col-md-12 my-5">
                    {this.props.from != true && <div className='row'>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <form class="form" >
                                <div class="input-group">
                                    <input 
                                      class="form-control p-4" 
                                      type="text" 
                                      placeholder="Recherche par code bordereau ou par numéro du contact..." 
                                      aria-label="Search" style={{paddingLeft: "20px", borderRadius: "40px", backgroundColor: '#fff'}} id="mysearch"
                                      name="search_bordereau" 
                                      onChange={e => this.updateFilter(e, 'search_bordereau')}
                                      >
                                      
                                    </input>
                                    <div class="input-group-addon mt-2" style={{marginLeft: "-50px", zIndex: 3, borderRadius: "40px", backgroundColor: "transparent", border:"none"}}>
                                        <button class="btn btn-link" type="submit" style={{borderRadius: "20px"}} id="search-btn"><FontAwesomeIcon className={"ml-2"} icon={faSearch} /></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-2">
                            <ExportCSV csvData={this.state.bordereaux} fileName={'Bordereaux' +now} classBtn = {'btn btn-sm btn-success mt-2'}/>
                        </div>
                        
                    </div>}

                </div> */}
                 {this.props.from != true && 
                    <div class="ui-rect np">
                      <div className="col-md-12">
                        <div className="row">
                              <div className="col-md-3">
                                    <form class="form" >
                                      <div class="input-group">
                                          <input 
                                            class="form-control p-4" 
                                            type="text" 
                                            placeholder="Recherche par code bordereau " 
                                            aria-label="Search" style={{paddingLeft: "20px", borderRadius: "40px", backgroundColor: '#fff'}} id="mysearch"
                                            name="code_bordereau" 
                                            onChange={e => this.updateFilter(e, 'code_bordereau')}
                                            >
                                            
                                          </input>
                                          {/* <div class="input-group-addon mt-2" style={{marginLeft: "-50px", zIndex: 3, borderRadius: "40px", backgroundColor: "transparent", border:"none"}}>
                                              <button class="btn btn-link" type="submit" style={{borderRadius: "20px"}} id="search-btn"><FontAwesomeIcon className={"ml-2"} icon={faSearch} /></button>
                                          </div> */}
                                      </div>
                                  </form>
                              </div>  
                            <div className="col-md-3">
                                  <form class="form" >
                                    <div class="input-group">
                                        <input 
                                          class="form-control p-4" 
                                          type="text" 
                                          placeholder="Recherche par numéro Contact" 
                                          aria-label="Search" style={{paddingLeft: "20px", borderRadius: "40px", backgroundColor: '#fff'}} id="mysearch"
                                          name="num_contact" 
                                          onChange={e => this.updateFilter(e, 'num_contact')}
                                          >
                                          
                                        </input>
                                        {/* <div class="input-group-addon mt-2" style={{marginLeft: "-50px", zIndex: 3, borderRadius: "40px", backgroundColor: "transparent", border:"none"}}>
                                            <button class="btn btn-link" type="submit" style={{borderRadius: "20px"}} id="search-btn"><FontAwesomeIcon className={"ml-2"} icon={faSearch} /></button>
                                        </div> */}
                                    </div>
                                </form>
                            </div>  

                              <div className={"col-md-3 dateClient"}>
                        
                                    <ReactDatePicker
                                      dateFormat={"yyyy-MM-dd"}
                                      placeholderText={"Date début (creation)"}
                                      timeFormat={"HH:mm"}
                                      showTimeSelect={false}
                                      className={"dateClient col-md-12 "}
                                      selected={this.formatDate(this.state.filters.date_debut)}
                                      onChange={e =>
                                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut", true)
                                      }
                                      selectsStart
                                      startDate={this.formatDate(this.state.filters.date_debut)}
                                      endDate={this.formatDate(this.state.filters.date_fin)}
                                      wrapperClassName={"col-md-12"}
                                    />
                              </div>
                              <div className={"col-md-3 dateClient"}>
                                  <ReactDatePicker
                                    timeFormat={"HH:mm"}
                                    dateFormat={"yyyy-MM-dd"}
                                    placeholderText={"Date fin (creation)"}
                                    showTimeSelect={false}
                                    className={"dateClient col-md-12"}
                                    selected={this.formatDate(this.state.filters.date_fin)}
                                    onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                                    selectsEnd
                                    startDate={this.formatDate(this.state.filters.date_debut)}
                                    endDate={this.formatDate(this.state.filters.date_fin)}
                                    minDate={this.formatDate(this.state.filters.date_debut)}
                                    wrapperClassName={"col-md-12"}
                                  />
                              </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-3">
                                  <Select
                                    placeholder={"Zone Départ"}
                                    classNamePrefix={"selectClient"}
                                    name='zone_depart'
                                    onChange={e => this.updateFilter(e, 'zone_depart')}
                                    // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                                    options={ [{ label: "All", value: 0 }, ...zones]}
                                  />
                            </div>
                            <div className="col-md-3">
                                  <Select
                                    placeholder={"Zone Arrivé"}
                                    classNamePrefix={"selectClient"}
                                    name='zone_arrive'
                                    onChange={e => this.updateFilter(e, 'zone_arrive')}
                                    // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                                    options={ [{ label: "All", value: 0 }, ...zones]}
                                    size={5}
                                  />
                            </div>

                            <div className={"col-md-3 dateClient"}>
                                    
                                    <ReactDatePicker
                                      dateFormat={"yyyy-MM-dd"}
                                      placeholderText={"Date début (Delivered)"}
                                      timeFormat={"HH:mm"}
                                      showTimeSelect={false}
                                      className={"dateClient col-md-12"}
                                      selected={this.formatDate(this.state.filters.date_debut_end)}
                                      onChange={e =>
                                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut_end", true)
                                      }
                                      selectsStart
                                      startDate={this.formatDate(this.state.filters.date_debut_end)}
                                      endDate={this.formatDate(this.state.filters.date_fin_end)}
                                      wrapperClassName={"col-md-12"}
                                    />
                              </div>
                              <div className={"col-md-3 dateClient"}>
                                <ReactDatePicker
                                  timeFormat={"HH:mm"}
                                  dateFormat={"yyyy-MM-dd"}
                                  placeholderText={"Date fin (Delivered)"}
                                  showTimeSelect={false}
                                  className={"dateClient col-md-12"}
                                  selected={this.formatDate(this.state.filters.date_fin_end)}
                                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin_end", true)}
                                  selectsEnd
                                  startDate={this.formatDate(this.state.filters.date_debut_end)}
                                  endDate={this.formatDate(this.state.filters.date_fin_end)}
                                  minDate={this.formatDate(this.state.filters.date_debut_end)}
                                  wrapperClassName={"col-md-12"}
                                />
                              </div>

                            {/* <div className={"col-md-3 dateClient"}>
                    
                                  <ReactDatePicker
                                    dateFormat={"yyyy-MM-dd"}
                                    placeholderText={"Date début (Assignation)"}
                                    timeFormat={"HH:mm"}
                                    showTimeSelect={false}
                                    className={"dateClient col-md-12"}
                                    selected={this.formatDate(this.state.filters.date_debut_assign)}
                                    onChange={e =>
                                      this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut_assign", true)
                                    }
                                    selectsStart
                                    startDate={this.formatDate(this.state.filters.date_debut_assign)}
                                    endDate={this.formatDate(this.state.filters.date_fin_assign)}
                                    wrapperClassName={"col-md-12"}
                                  />
                            </div>
                            <div className={"col-md-3 dateClient"}>
                              <ReactDatePicker
                                timeFormat={"HH:mm"}
                                dateFormat={"yyyy-MM-dd"}
                                placeholderText={"Date fin (Assignation)"}
                                showTimeSelect={false}
                                className={"dateClient col-md-12"}
                                selected={this.formatDate(this.state.filters.date_fin_assign)}
                                onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin_assign", true)}
                                selectsEnd
                                startDate={this.formatDate(this.state.filters.date_debut_assign)}
                                endDate={this.formatDate(this.state.filters.date_fin_assign)}
                                minDate={this.formatDate(this.state.filters.date_debut_assign)}
                                wrapperClassName={"col-md-12"}
                              />
                            </div> */}
                            {/* <div className="col-md-6 text-right">
                                <ExportCSV csvData={this.state.bordereaux} fileName={'Bordereaux' +now} classBtn = {'btn btn-md btn-success mt-2'}/>
                            </div> */}

                        </div>
                        <br/>
                        <div className="row">
                              <div className="col-md-3">
                                    <Select
                                      //defaultValue={[{label: "Waiting", value: "null"}]}
                                      placeholder={"Status global"}
                                      //classNamePrefix={"custom-select"}
                                      name='state_global'
                                      classNamePrefix={"selectClient"}
                                      onChange={e => this.updateFilter(e, 'state_global')}
                                      options={stateGlobal}
                                    />
                              </div>
                              <div className="col-md-3">
                                    <Select
                                      //defaultValue={[{label: "Waiting", value: "null"}]}
                                      placeholder={"Select Retour"}
                                      classNamePrefix={"selectClient"}
                                      name='retour'
                                      onChange={e => this.updateFilter(e, 'retour')}
                                      options={retour}
                                    />
                              </div>
                             
                              <div className="col-md-6 text-right">
                                  <ExportCSV csvData={this.state.bordereaux} fileName={'Bordereaux' +now} classBtn = {'btn btn-md btn-success mt-2'}/>
                              </div> 
                              
                        </div>
                        {/* <br/>
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <ExportCSV csvData={this.state.bordereaux} fileName={'Bordereaux' +now} classBtn = {'btn btn-md btn-success mt-2'}/>
                            </div> 

                        </div> */}
                      </div>
                      
                    
                    </div>
                }
                <br/>
                {/* <h2>Responsive Tables Using LI <small>Triggers on 767px</small></h2> */}
                <div class="ui-rect np">
                    {/* <div class="col-md-12 mb-2">
                        <div class="row">
                            <h4 class="col-md-4">resultat colis</h4>
                            <div class="col-md-7"></div>
                            <button type="button" style={{float:'right', backgroundColor:"#0275d8", color: "white"}} class="btn col-md-1" onClick={() => this.setState({ showNewTask: true })}>Nouveau colis</button>
                        </div>
                    </div> */}
                    <div className="row mb-1">
                      <div className="col-md-9"> 
                       <b> {this.state.total} </b>  Bordereaux trouvés
                      </div>
                      <div className={"col-md-3 per-page-wrap"}>
                          <div className={"per-page-content"}>
                            <p>Résultat par page</p>
                            <Select
                              classNamePrefix={"custom-select"}
                              onChange={this.changeLimit}
                              className={"per-page nm"}
                              options={this.props.filters_pagination.result_per_page}
                              value={{ label: this.state.limit, value: this.state.limit }}
                            />
                          </div>
                          <Pagination data={this.state} handleClick={this.selectPage} />
                      </div>
                    </div>
                    
                    <div class="responsive-table">
                        
                            {this.props.user.role === "client" ?
                              <div class="table-header shadow">
                                  <div class="col col-2 text-white font-weight-bold">Code Bordereau</div>
                                  <div class="col col-5 text-white font-weight-bold">Trajet</div>
                                  <div class="col col-1 text-white font-weight-bold">Montant</div>
                                  <div class="col col-1 text-white font-weight-bold">Collect</div>
                                  <div class="col col-2 text-white font-weight-bold">Documents</div>
                                  <div class="col col-1 text-white font-weight-bold"> 
                                    <button class="btn btn-outline-light font-weight-bold"  onClick={() => this.applyAction()}>Imprimer <FontAwesomeIcon className={"ml-2"} icon={faPrint} /></button>
                                  </div>
                              </div>
                              :
                              <div class="table-header shadow">
                                  <div class="col col-2 text-white font-weight-bold">Code Bordereau</div>
                                  <div class="col col-1 text-white font-weight-bold">Client</div>
                                  <div class="col col-5 text-white font-weight-bold">Trajet</div>
                                  <div class="col col-2 text-white font-weight-bold">Montant</div>
                                  <div class="col col-2 text-white font-weight-bold">Documents</div>
                              </div>
                            }
                            {this.state.total> 0 ? 
                                this.state.bordereaux.map(bordereau =><CommandeRow data={bordereau}  handlers={this.handlers} filters={this.state.filters}  />)
                            :
                            <div class="d-flex justify-content-center" style={{float: 'center'}}><h4>Aucune commande trouver</h4></div>
                            }
                            
                    </div>
                </div>
                {this.state.showNewTask && (
                    <div className={`ui-overlay open`}>
                        <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
                        <button
                            onClick={() => this.setState({ showNewTask: false })}
                            className={"ui-overlay-close btn btn-danger btn-sm"}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>

                        {/* <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} /> */}
                        <BordereauNewContainer />
                        </div>
                    </div>
                    )}
                
            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(CommandeInterface));
  
