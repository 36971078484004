import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import CollectListContainer from '../Containers/CollectListContainer';
import CollectInterface from '../Components/Collect/CollectInterface'




function mapStateToProps(state) {
  return { auth: state.auth };
};


class CollectRouter extends Component {

	
	render() {
		return(
				<Switch>

					{(!this.props.auth ? <Redirect to="/login" /> : "")}
					<Route exact path='/collect' component={CollectListContainer} />
					<Route exact path='/mescollectes' component={CollectInterface} />
						
						
				</Switch>


			
		)
	}
}

export default connect(mapStateToProps)(CollectRouter)