import React from "react"


import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faTag, faUser, faChartLine, faUserTie, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import { Trans } from "react-i18next";


import {  startLoading, doneLoading } from './../../Redux/actions/index';
import Select from "react-select";
import { Item } from "../../Utils/MoreInfoDialog";
import { Link } from "react-router-dom";
import CanvasJSReact from "../../Canvas/canvasjs.react";
import Modal from 'react-bootstrap/Modal';
import { Button, Container} from 'react-bootstrap';
import {ExportCSV}  from '../Rapport/ExportCSV';
import dateFormat from 'dateformat';


const moment = require("moment");

const mapStateToProps = state => {
	return {
		user: state.user
	}
} 

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}

class ManagerDashboard extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			agents: {},
			tasks: {},
			agentLog: {},
			webUser:0,
			agent_list: [],
			topClient:{},
			topClientMonthBefor: {},
			agentNotInApp: {},
			filters: {},
			showDiv: false,
			showPop: false,
			agentExport: []
		}

		this.preparDataForExport();
		props.isLoading();
	}

	preparDataForExport = () =>{
		Object.entries(this.state.agentNotInApp).map(([key,val]) =>(
			this.setState({
				agentExport: [...this.state.agentExport, val]	
			})
		))
	}
	componentDidMount() {
		
		const that = this;

		api.get('/api/user/'+this.props.user.userid+'/dashboard?token='+this.props.user.token)
		.then(function(response) {
			if(response.data.success){
				that.setState({
					agents: response.data.agents,
					tasks: response.data.tasks,
					agentLog: response.data.agentLog,
					webUser: response.data.webUser,
					topClient: response.data.topClient,
					topClientMonthBefor:  response.data.topClientMonthBefor,
					agentNotInApp: response.data.agentNotInApp
				})

				Object.entries(that.state.agentNotInApp).map(([key,val]) =>(
					that.setState({
						agentExport: that.state.agentExport.concat({Code_Agent:key,Agent_UserName:val})
					})
				))
			}
			
				
		}).then(() => that.props.doneLoading());
		api.get('/api/rapport/form').then(function(response){
			if(response.data){
				that.setState({
					agent_list: [{ label: "All", value: '' },...response.data.agents]
				})
			}
		})
	}

	updateFilter = (event, name) =>{
		let value = event.target ? event.target.value : event.value;
		this.setState(
			{
				filters:{
					[name]: value
				}
			}
		)
	}

	setTrue = (count) => {
		count = true;
	}

	toggleMe = () =>{
		let that = this.state.showDiv
		this.setState({
			showDiv: that? false:true
		})
	}
	PopUp = () =>{
		let that = this.state.showPop
		this.setState({
			showPop: that? false:true
		})
	}
	

    render() {
		function compareDataPointYDescend(dataPoint1, dataPoint2) {
			return dataPoint2.y - dataPoint1.y;
		}
		
		var CanvasJS = CanvasJSReact.CanvasJS;
		var CanvasJSChart = CanvasJSReact.CanvasJSChart;
		CanvasJS.addColorSet("blueShades",
				[
					
					"#023e8a",
					"#0077b6",
					"#0096c7",
					"#00b4d8"                
				]);
		CanvasJS.addColorSet("fireShades",
				[
					"#ffb703",
					"#023047",               
				]);
		CanvasJS.addColorSet("statuAgent",
				[
					"#80b918",
					"#fdc500",               
				]);
		CanvasJS.addColorSet("statuTask",
				[
					"#168aad",
					"#52b69a",               
				]);
		const task = {
			colorSet: "statuTask",
			backgroundColor:'#ffffff',
			
			height:150,
			subtitles: [{
				text: this.state.tasks.total +" Taches crées ce mois",
				fontSize: 15,
				fontFamily: 'Calibri',
			}],
			data: [{				
					 
					  type: "doughnut",
					  
					  dataPoints: [
						  { label: "En cours",  y: this.state.tasks.ongoing },
						  { label: "Terminé (Doc Recu)", y: this.state.tasks.completed  },
					  ]
			 }]
			 
		 }
		 const agent = {
			colorSet: "statuAgent",
			backgroundColor:'#ffffff',
			
			height:150,
			subtitles: [{
				text: this.state.agents.total +" Agents au total",
				verticalAlign: "center",
				fontSize: 15,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			data: [{				
					 
					  type: "column",
					  
					  dataPoints: [
						  { label: "En ligne",  y: this.state.agents.online },
						  { label: "Hors ligne", y: this.state.agents.onduty },
					  ]
			 }]
		 }
		 
		 const usage = {
			colorSet: "blueShades",
			backgroundColor:'#ffffff',
			
			height:150,
			data: [{
					click:  (e) =>{
						this.PopUp()
					},	
					type: "pie",
					dataPoints: [
						{ label: "Usage Mobile",  y: Object.keys(this.state.agentLog).length},
						{ label: "Usage Web", y: this.state.webUser },
						{ label: "Autres", y:  Object.keys(this.state.agentNotInApp).length },
					]
					  
			 }]
		 }
		const clientmap = Object.entries(this.state.topClient).map(([key,val]) =>({
			label: val.name,
			 y: val.value
		 }))
		const clientlastmap = Object.entries(this.state.topClientMonthBefor).map(([key,val]) =>({
			label: val.name,
			y: val.value
		}))

		var curDateMonth = new Date();
        var prvDateMonth = new Date()
		var prvDateMonthBefore = new Date()
        prvDateMonth.setMonth(prvDateMonth.getMonth() - 1)
		prvDateMonthBefore.setMonth(prvDateMonthBefore.getMonth() - 2)
		
		const client = {
			colorSet: "fireShades",
			
			
			height:150,
			axisX:{
				labelFontSize: 0,
			  },
			data: [{
						showInLegend: true,
						legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
						type: "column",
						dataPoints: clientmap,
					},

					{
						showInLegend: true,
						legendText: "du "+dateFormat(prvDateMonthBefore.toString(), "dd mmmm") +" au "+dateFormat(prvDateMonth.toString(), "dd mmmm"),
						type: "column",
						dataPoints: clientlastmap,
					}
					
					  
			 ]
		 }
		client.data[0].dataPoints.sort(compareDataPointYDescend);
		client.data[1].dataPoints.sort(compareDataPointYDescend);

		

		
        return <div>
            <div className={"container-header nmb"}>
				<h3>Dashboard</h3>
			</div>

			<div className={"ui-rect np"}>

			
			<div className={"row pb-5"}>

                

				<div className={"col-md-5"}>

					<span className={"dashboard-title"}><FontAwesomeIcon icon={faUser} className={"dashboard-icon"} /> <Trans i18nKey="label_agents" /> </span>
					<div className={"row"}>
						
						<CanvasJSChart options = {agent} />
					</div>
				</div>


				<div className={"col-md-5 offset-2"}>
					<span className={"dashboard-title"}><FontAwesomeIcon icon={faTag} className={"dashboard-icon"} /><Trans i18nKey="label_tasks" /></span>

					<div className={"row"} >
						<CanvasJSChart options = {task} />
					</div>

				</div>
				
				

			</div>
			

			

			</div>
			<div className="ui-rect np  my-3">
							<div className="row pb-5">
								<div className="col-md-5">
									<span className="dashboard-title"><FontAwesomeIcon icon={faChartLine} className={"dashboard-icon"} />Usage</span>
									<CanvasJSChart options={usage} />
									
								</div>

								<div className="col-md-5 offset-2">
									<span className="dashboard-title"><FontAwesomeIcon icon={faUserTie} className={"dashboard-icon"} />Client</span>
									<div className="row">
											<CanvasJSChart options={client} />
										
									</div>
									
								</div>
							</div>
			</div>

{/* 
			{this.state.showDiv &&<div className="ui-rect np  my-3" >
				<div className="col-md-8">
					<div className= {"container-header"}>
							<h3>Mobile Usage</h3>
							
							<button className={"btn btn-md btn-success"}>
									<Trans i18nKey="button_export"/>
							</button>
					</div>
				</div>
				
					<thead className="row h5">
							<div className="col-md-4">
								Mobile Usage (Agent)
							</div>
							<div className="col-md-4">
								Mobile Usage (Agent) <b>du mois dernier </b>
							</div>
							<div className="col-md-4">
								Agents jamais utilise mobile
							</div>
					</thead>
					
			</div>} */}
			<Modal show={this.state.showPop} centered>
				<Modal.Header >
					<Modal.Title>Mobile Usage</Modal.Title>
				</Modal.Header>
				<Modal.Body className="show-grid">
					<Container style={{height:"250px", overflowY:"auto"}}>
						<div className="row">
							<div className="col-md-4">
								Mobile Usage (Agent)
							</div>
							<div className="col-md-4">
								Mobile Usage (Agent) <b>du mois dernier </b>
							</div>
							<div className="col-md-4">
								Agents jamais utilise mobile
							</div>		
						</div>

						<div className="row">
						<div className="col-md-8">
							{Object.entries(this.state.agentLog).map(([key,val]) =>(
								<div className = "row">
									<div className="col">
										<Link to={{
												pathname:'/manager/agents/'+key+'/activity'
											}}>
											{val.agent} 
										</Link>
									</div>
									<div className="col">
										<Link to={{
												pathname:'/manager/agents/'+key+'/activity'
											}}>
											{val.agentMonth} 
										</Link>
									</div>
								</div>
							))}
						</div>
						<div className="col-md-4">
							{Object.entries(this.state.agentNotInApp).map(([key,val]) =>( 
								<div className="row">
									<div className="col">
										<Link to={{
												pathname:'/manager/agents/'+key+'/activity'
											}}>
											{val} 
										</Link>
									</div>
								</div>
							))}
						</div>
					</div>

					</Container>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={this.PopUp}>
					Fermer
				</Button>
					<ExportCSV csvData={this.state.agentExport} fileName={'Agents_Not_Using_AppMobile'}/>
				</Modal.Footer>
     		 </Modal>

 
			


			{/* <div className="ui-rect np my-3">
				<form >
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-3 ">
								<Select
									placeholder="Recherche un agent..."
									options={this.state.agent_list}
									onChange={e => this.updateFilter(e,"agent")}
								/>
							</div>
							
						</div>
					</div>
					
					
				</form>
				
			</div> */}

			{/* <div className={"ui-rect np my-3" }>
					<div className={"col-md-12"}>
						<div className="col-md-12 ctable">

							<div className={"row ctable-row "}>
								<div className={"col-md-12"}>
								<h5><strong><Trans i18nKey="recent_activity" /></strong></h5>
								</div>
							</div> */}

							{/* {this.state.agents.activity && this.state.agents.activity.map(act => (
								<div className={"row ctable-row"}>
									<div className={"col-md-4 stat-activity-wrap"}>
										<span className={"stat-activity-label"}>{act.content} </span>
									</div>
									<div className={"col-md-2"}>
										{act.datetime} 
										
									</div>
								</div>
							))} */}

							{/* {this.state.filters['agent'] ? (
								this.state.agents.activity && this.state.agents.activity.map(act => (
									act.content.includes(this.state.filters['agent']) ? 
										
										<div className={"row ctable-row"}>
											{this.setTrue(count)}
											<div className={"col-md-4 stat-activity-wrap"}>
												<span className={"stat-activity-label"}>{act.content} </span>
											</div>
											<div className={"col-md-2"}>
												{act.datetime} 
												
											</div>
											<div className={"col-md-2"}>
												{act.task_name} 
											
											</div>
										</div>
									:
									""
								))
								
							):(
								this.state.agents.activity && this.state.agents.activity.map(act => (
									<div className={"row ctable-row"}>
										<div className={"col-md-4 stat-activity-wrap"}>
											<span className={"stat-activity-label"}>{act.content} </span>
										</div>
										<div className={"col-md-2"}>
											{act.datetime} 
											
										</div>
										<div className={"col-md-2"}>
											{act.task_name} 
											
										</div>
									</div>
								))
							)} */}
								{/* <div className={"row ctable-row"}>
									<div className={"col-md-4 stat-activity-wrap"}>
										<span className={"stat-activity-label"}> LAHGUI 1.5 T updated a task : DONE</span>
									</div>
									<div className={"col-md-2"}>
									2021-02-02 14:30
									</div>
								</div> */}

						{/* </div>
					</div>
			</div> */}

        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard)