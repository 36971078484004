import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import userReducer from './../../Redux/reducers/index';
import {setAuthAction} from './../../Redux/actions/index';


function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user))
  };
}



class AccountSideMenu extends Component {

	render() {
		return (
			<div>

			<ul>
				<li><a href="#">Link 1</a></li>
				<li><a href="#">Link 2</a></li>
				<li><a href="#">Link 3</a></li>
				<li><a href="#">Link 4</a></li>

			</ul>


			</div>
		)


	}
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountSideMenu)


/*
			<div className="list-group">
			  <Link className="list-group-item list-group-item-action" to={"/account/personal"}>Personal information</Link>
			  <Link className="list-group-item list-group-item-action" to={"/account/roles"}>My authorizations</Link>
			  <Link className="list-group-item list-group-item-action" to={"/account/history"}>History</Link>
			  <Link className="list-group-item list-group-item-action" to={"/account/items"}>Items</Link>
			  <Link className="list-group-item list-group-item-action" to={"/account/security"}>Security</Link>
			  <Link className="list-group-item list-group-item-action" to={"/account/logout"}>Logout</Link>
			</div>
				
				*/