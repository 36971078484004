import React, { Component } from "react";

import "../../../react-datetime.css";
import { Trans } from "react-i18next";

import api from "../../Utils/Api";
import TaskForm from "./subcomponents/TaskForm";
import SubTaskForm from "./subcomponents/SubTaskForm";
import { Link } from "react-router-dom";

class TaskNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: null,
      pinOnMap: false,
      pinForTask: "single",
      geo_lat: null,
      geo_lng: null,
      app_id: "sodMSfpEnNEZfHC7oV0M",
      app_code: "noVfKVAMsfkjYV3Cv6jmnA",
      useHTTPS: true,
      center: {
        lat: 36.75,
        lng: 3.04
      },
      zoom: 5,
      address: "",
      last_use: "component",
      pickup_lat: "",
      pickup_lng: "",
      delivery_lat: "",
      delivery_lng: "",
      subTasks: [],
      startTask: {
        address: {}
      },
      endTask: {
        address: {}
      },
      client: {},
      task: {}
    };

    this.taskForm = React.createRef();
    this.submitTask = this.submitTask.bind(this);
    // this.selectAddress = this.selectAddress.bind(this);
    // this.pinToggle = this.pinToggle.bind(this)

    this.platform = null;
    this.map = null;
    this.marker = null;
    this.fromMarker = null;
    this.toMarker = null;
    this.routeObject = null;

    this.onPinSelect = () => true;

    this.midListener = null;
  }

  selectClient = client => {
    

    this.setState(
      {
        client: client
      },
      () => {
        this.updateMarkers();
        this.traceRoutes();
      }
    );
  };

  onRouteResult = result => {
    var route, routeShape, linestring;

    if (result.response && result.response.route) {
      if (this.routeObject) this.map.removeObject(this.routeObject);
      // Pick the first route from the response:
      route = result.response.route[0];

      // Pick the route's shape:
      routeShape = route.shape;

      // Create a linestring to use as a point source for the route line
      linestring = new window.H.geo.LineString();

      // Push all the points in the shape into the linestring:
      routeShape.forEach(function(point) {
        var parts = point.split(",");
        linestring.pushLatLngAlt(parts[0], parts[1]);
      });

      // Create a polyline to display the route:
      this.routeObject = new window.H.map.Polyline(linestring, {
        style: { strokeColor: "#31b6fc", lineWidth: 5 }
      });

      this.map.addObject(this.routeObject);

      this.map.setViewBounds(this.routeObject.getBounds());
    }
  };

  /**
   * Function to draw routes based on locations found in subtasks
   */
  traceRoutes = () => {
    var router = this.platform.getRoutingService();

    var routingParameters = {
      mode: "fastest;car",
      representation: "display",
      legAttributes: "length"
    };

    const buildWaypoints = () => {
      const result = [];

      // console.log("start task location: ", this.state.startTask)
      if (this.state.startTask.location) {
        const { lat, lng } = this.state.startTask.location;
        result.push(lat + "," + lng);
      }

      this.state.subTasks.map(subtask => {
        if (subtask.location) {
          const { lat, lng } = subtask.location;
          result.push(lat + "," + lng);
        }
      });

      if (this.state.endTask.location) {
        const { lat, lng } = this.state.endTask.location;
        result.push(lat + "," + lng);
      }

      return result;
    };

    buildWaypoints().map((waypoint, idx) => {
      routingParameters["waypoint" + idx] = waypoint;
    });

    console.log(routingParameters);

    router.calculateRoute(routingParameters, this.onRouteResult, function(
      error
    ) {
      alert(error.message);
    });
  };

  /**
   * Sets the marker object on map and stores it in state
   * Creates new one if does not exist
   */
  setMarker = (coord, taskIndex) => {
    console.log("setting marker for: " + taskIndex);

    let taskObj;

    const { subTasks } = this.state;

    if (isNaN(taskIndex)) {
      taskObj = this.state[taskIndex];
    } else {
      taskObj = subTasks[taskIndex];
    }

    if (taskObj.marker) {
      taskObj.marker.setPosition(coord);
    } else {
      console.log("marker not found : ", taskObj);

      const marker = new window.H.map.Marker(coord);
      this.map.addObject(marker);
      taskObj.marker = marker;

      if (isNaN(taskIndex)) {
        this.setState({ [taskIndex]: taskObj });
        taskObj = this.state[taskIndex];
      } else {
        subTasks[taskIndex] = taskObj;
        this.setState({ subTasks });
      }
    }
  };

  /**
   * Updates markers on map based on location found in state
   */
  updateMarkers = () => {
    if (this.state.startTask.location) {
      this.setMarker(this.state.startTask.location, "startTask");
    }
    if (this.state.endTask.location) {
      this.setMarker(this.state.endTask.location, "startTask");
    }

    this.state.subTasks
      .filter(item => item.location)
      .forEach((item, idx) => this.setMarker(item.location, idx));
  };

  hookOnTap = (cback, taskIndex) => {
    this.midListener = evt => {
      const coord = this.map.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY
      );

      this.setMarker(coord, taskIndex);

      if (typeof cback === "function") cback(coord);

      this.getReadableAddress(coord.lat, coord.lng, res => {
        this.updateSubTaskAddress(taskIndex, res, coord);
        this.traceRoutes();
      });

      this.map.removeEventListener("tap", this.midListener);
    };

    this.map.addEventListener("tap", this.midListener);
  };

  updateSubTaskAddress = (idx, address, location) => {
    if (idx !== "endTask" && idx !== "startTask") {
      const { subTasks } = this.state;
      subTasks[idx].address = address;

      if (location) subTasks[idx].location = location;

      this.setState({ subTasks });
    } else {
      const taskObj = this.state[idx];
      taskObj.address = address;

      if (location) taskObj.location = location;

      this.setState({ [idx]: taskObj });
    }
  };

  getReadableAddress = async (lat, lng, cback) => {
    const call = await api.get(
      "https://nominatim.openstreetmap.org/reverse?lat=" +
        lat +
        "&lon=" +
        lng +
        "&format=json"
    );

    if (!call.data.error) {
      if (typeof cback === "function") {
        cback(call.data.display_name);
      } else {
        return call.data.display_name;
      }
    }
  };

  initMapService = () => {
    this.platform = new window.H.service.Platform(this.state);
    var layer = this.platform.createDefaultLayers();
    var container = document.querySelector("#here-maps");

    this.map = new window.H.Map(container, layer.normal.map, {
      center: this.state.center,
      zoom: this.state.zoom
    });

    var events = new window.H.mapevents.MapEvents(this.map);
    new window.H.mapevents.Behavior(events);
    new window.H.ui.UI.createDefault(this.map, layer);
  };



  loadTask = taskid => {
    let that = this;

    api.get("/api/v2/task/fetch?id=" + taskid).then(response => {
      if (response.data.success) {
        let startTask, endTask;
        let subTasks = [];

        const task = response.data.tasks[0];
        Object.values(task.subtasks).forEach(item => {
          // return;
          if (item.subtask_index === "999") endTask = item;
          else if (item.subtask_index === "-1") startTask = item;
          else subTasks.push(item);
        });

        
        this.setState({
          task: task,
          startTask: startTask,
          endTask: endTask,
          subTasks: subTasks,
          client: {
            client_id: task.task_client_id,
            client_name: task.task_client_name
          },
          ready: true
        });
      } 

    });
  };


  componentDidMount() {
    this.initMapService();

    if(this.props.isDuplicate && this.props.taskId) {
      this.loadTask(this.props.taskId)
    }
  }

  handleAddressSelect = (address, idx) => {
    this.updateSubTaskAddress(idx, address);
    return;
  }

  selectAddress = (addr, e) => {
    const obj = {
      lat: addr.lat,
      lng: addr.lon
    };

    if (obj.lat) {
      if (e && e === "pickup") {
        this.map.setCenter(obj);

        if (!this.fromMarker) {
          this.fromMarker = new window.H.map.Marker(obj);
          this.map.addObject(this.fromMarker);
        } else {
          this.fromMarker.setPosition(obj);
        }

        this.setState({
          pickup_lat: addr.lat,
          pickup_lng: addr.lon
        });
      } else if (e && e === "delivery") {
        this.map.setCenter(obj);

        if (!this.toMarker) {
          this.toMarker = new window.H.map.Marker(obj);
          this.map.addObject(this.toMarker);
        } else {
          this.toMarker.setPosition(obj);
        }

        this.setState({
          delivery_lat: addr.lat,
          delivery_lng: addr.lon
        });
      } else {
        this.map.setCenter(obj);

        if (!this.marker) {
          this.marker = new window.H.map.Marker(obj);
          this.map.addObject(this.marker);
        } else {
          this.marker.setPosition(obj);
        }

        this.setState({
          geo_lat: addr.lat,
          geo_lng: addr.lon
        });
      }

      this.map.setZoom(12);
    }
  };

  submitTask(e) {
    e.preventDefault();

    console.log(this.state);

    this.props.handlers.submitTask(e, this.taskForm.current);
  }

  pinToggle = (hook, taskIdx) => {
    this.hookOnTap(hook, taskIdx);

    this.setState({ pinOnMap: true });
  };

  

  addSubtask = () => {
    const { subTasks } = this.state;
    subTasks.push({
      type: "pickup",
      address: {},
      label: "",
      address_lat: "",
      address_lng: "",
      hour: "",
      contact: ""
    });

    this.setState({ subTasks });
  };

  removeSubtask = taskIndex => {
    if (isNaN(taskIndex)) return false;

    const { subTasks } = this.state;

    const marker = subTasks[taskIndex].marker;
    if (marker) this.map.removeObject(marker);

    subTasks.splice(taskIndex, 1);

    this.setState(
      {
        subTasks
      },
      () => {
        this.traceRoutes();
      }
    );

    return true;
  };

  render() {
    const refs = this.props.refs;
    const data = this.props.data;
    const handlers = {
      selectAddress: this.selectAddress,
      selectFromBook: this.selectFromBook,
      pinToggle: this.pinToggle,
      selectClient: this.selectClient
    };

    
    return (
      <React.Fragment>
        <div className={"container-header"}>
          <Trans i18nKey="button_new_bordereau" />
          {/* <div>
            <button className={"cbtn success"}>Sauvegarder</button>
          </div> */}
        </div>

        <div>
          <TaskForm
            isDuplicate={this.props.isDuplicate}
            bordereau = {this.props.bordereau}
            inline={this.props.inline}
            afterPost={this.props.afterPost}
            data={this.state.task}
            client={this.state.client}
            refs={refs}
            handlers={handlers}
            pinOnMap={this.state.pinOnMap}
          >
            {/* <SubTaskForm
              handleAddressSelect={this.handleAddressSelect}
              selectedClient={this.state.client}
              pinToggle={this.pinToggle}
              formType={"start"}
              taskType={"pickup"}
              expand={true}
              data={this.state.startTask}
              idx={"startTask"}
            /> */}

            {/* {this.state.subTasks.map((subtask, key) => (
              <SubTaskForm
                handleAddressSelect={this.handleAddressSelect}
                selectedClient={this.state.client}
                removeItem={this.removeSubtask}
                pinToggle={this.pinToggle}
                formType={"point"}
                taskType={subtask.type}
                data={subtask}
                key={key + 1}
                idx={key}
              />
            ))} */}

            {/* <div className={"row my-3"}>
              <div className={"col-md-12"}>
                <button
                  type="button"
                  className={"cbtn float-right"}
                  onClick={this.addSubtask}
                >
                  Ajouter un point
                </button>
              </div>
            </div> */}

            {/* <SubTaskForm
              handleAddressSelect={this.handleAddressSelect}
              selectedClient={this.state.client}
              pinToggle={this.pinToggle}
              formType={"end"}
              taskType={"pickup"}
              idx={"endTask"}
              expand={true}
              data={this.state.endTask}
            /> */}
          </TaskForm>
        </div>
      </React.Fragment>
    );
  }
}

export default TaskNew;
