import React from "react";
import api from "../../Utils/Api";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import { Trans } from "react-i18next";

import { REMOTE_API } from "../../Utils/Constants";
const moment = require("moment");

//const mapPin = require("./../../../map-pin-solid.svg");

export default class AgentGeo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geohistory: [],
      agent: {},
      coords: {},
      appId: "sodMSfpEnNEZfHC7oV0M",
      appCode: "noVfKVAMsfkjYV3Cv6jmnA",
      useHTTPS: true,
      sliderStatus: {},
      minSlider: null,
      maxSlider: null,
      showing: "live",
      app_id: "sodMSfpEnNEZfHC7oV0M",
      app_code: "noVfKVAMsfkjYV3Cv6jmnA",
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 15
    };

    this.platform = null;
    this.map = null;
    this.marker = null;
    this.ui = null;

    this.addRoutes = this.addRoutes.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.selectedHour = this.selectedHour.bind(this);
  }

  componentDidMount() {
    var obj = {};

    // console.log(mapPin)
    // const mp = <FontAwesomeIcon icon={faMapPin} />;

    // var icon = new window.H.map.DomIcon('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-pin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" class="svg-inline--fa fa-map-pin fa-w-9 fa-2x mapPinSvg "><path fill="currentColor" d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" class=""></path></svg>');

    const that = this;
    let i = 0;

    let source = new EventSource(
      REMOTE_API + "api/v2/user/" + this.props.match.params.userid + "/geo"
    );
    source.onmessage = function(evt) {
      const location = JSON.parse(evt.data);

      console.log(location);

      obj = {
        lat: location.geo.coords.coords.latitude,
        lng: location.geo.coords.coords.longitude
      };

      that.setState({ coords: location.geo.coords.coords, center: obj });

      if (that.map) {
        if (i === 0) {
          that.map.setCenter(obj);
          that.addRoutes();
          i++;
        }

        that.setMarker(obj);
      }
    };

    this.platform = new window.H.service.Platform(this.state);

    var layer = this.platform.createDefaultLayers();
    var container = document.querySelector("#here-map");

    this.map = new window.H.Map(container, layer.normal.map, {
      center: this.state.center,
      zoom: this.state.zoom
    });

    var events = new window.H.mapevents.MapEvents(this.map);

    new window.H.mapevents.Behavior(events);

    this.ui = new window.H.ui.UI.createDefault(this.map, layer);
  }

  setMarker = (coo, info) => {
    console.log(coo);

    if (this.marker) {
      this.marker.setPosition(coo);
    } else {
      this.marker = new window.H.map.Marker(coo);
      this.map.addObject(this.marker);
    }

    if (info) {
      this.ui.addBubble(
        new window.H.ui.InfoBubble(this.marker.getPosition(), {
          content: "<div class='bubble_info'>" + info + "</div>"
        })
      );
    }
  };

  addRoutes() {
    const that = this;
    var icon = new window.H.map.DomIcon(
      '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-pin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" class="mapPinSvg svg-inline--fa fa-map-pin fa-w-9 fa-2x"><path fill="currentColor" d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z" class=""></path></svg>'
    );

    api
      .get("/api/user/" + this.props.match.params.userid + "/status")
      .then(function(response) {
        let slider = {};
        // let ke;
        if(response.data.history.length > 0) {
          const min =
            response.data.history[response.data.history.length - 1].timestamp;
          const max = response.data.history[0].timestamp - min;
  
          response.data.history.forEach(function(hist) {
            const ke = ((hist.timestamp - min) / max) * 100;
            slider[ke] = hist.datetime.date;
          });
        }

        that.setState({
          agent: response.data.agent,
          geohistory: response.data.history,
          sliderStatus: slider,
          maxSlider: 100,
          minSlider: 0
        });

        if (
          that.state.coords.latitude &&
          response.data.success &&
          response.data.agent.task &&
          response.data.agent.task.task_start_geo.latitude
        ) {
          var routingParameters = {
            // The routing mode:
            mode: "fastest;car",
            waypoint0:
              "geo!" +
              response.data.agent.task.task_start_geo.latitude +
              "," +
              response.data.agent.task.task_start_geo.longitude,
            waypoint1:
              "geo!" +
              response.data.agent.task.task_end_geo.lat +
              "," +
              response.data.agent.task.task_end_geo.lng,
            // To retrieve the shape of the route we choose the route
            // representation mode 'display'
            representation: "display",
            legAttributes: "length"
          };

          var onResult = function(result) {
            var route, routeShape, startPoint, endPoint, linestring;

            if (result.response && result.response.route) {
              // Pick the first route from the response:
              route = result.response.route[0];

              // Pick the route's shape:
              routeShape = route.shape;

              // Create a linestring to use as a point source for the route line
              linestring = new window.H.geo.LineString();

              // Push all the points in the shape into the linestring:
              routeShape.forEach(function(point) {
                var parts = point.split(",");
                linestring.pushLatLngAlt(parts[0], parts[1]);
              });

              // Retrieve the mapped positions of the requested waypoints:
              startPoint = route.waypoint[0].mappedPosition;
              endPoint = route.waypoint[1].mappedPosition;

              // Create a polyline to display the route:
              var routeLine = new window.H.map.Polyline(linestring, {
                style: { strokeColor: "#31b6fc", lineWidth: 10 }
              });

              // Create a marker for the start point:
              var startMarker = new window.H.map.DomMarker(
                {
                  lat: startPoint.latitude,
                  lng: startPoint.longitude
                },
                { icon: icon }
              );

              // Create a marker for the end point:
              var endMarker = new window.H.map.DomMarker(
                {
                  lat: endPoint.latitude,
                  lng: endPoint.longitude
                },
                { icon: icon }
              );

              // Add the route polyline and the two markers to the map:
              that.map.addObjects([routeLine, startMarker, endMarker]);

              that.ui.addBubble(
                new window.H.ui.InfoBubble(startMarker.getPosition(), {
                  content: "<div class='bubble_info'>Start</div>"
                })
              );

              that.ui.addBubble(
                new window.H.ui.InfoBubble(endMarker.getPosition(), {
                  content: "<div class='bubble_info'>End</div>"
                })
              );

              // Set the map's viewport to make the whole route visible:
              that.map.setViewBounds(routeLine.getBounds());
            }
          };

          var router = that.platform.getRoutingService();

          // Call calculateRoute() with the routing parameters,
          // the callback and an error callback function (called if a
          // communication error occurs):
          router.calculateRoute(routingParameters, onResult, function(error) {
            alert(error.message);
          });
        }
      });
  }

  selectedHour(e) {
    //	console.log()

    if (e.target && e.target.value) {
      const obj = {
        lat: this.state.geohistory[e.target.value].content.geo.coords.coords
          .latitude,
        lng: this.state.geohistory[e.target.value].content.geo.coords.coords
          .longitude
      };

      this.setMarker(obj);
    } else {
      const value = Object.keys(this.state.sliderStatus).filter(function(x) {
        return x === String(e);
      })[0];

      const geo = this.state.geohistory.filter(
        x => x.datetime.date === this.state.sliderStatus[value]
      )[0];

      const obj = {
        lat: geo.content.geo.coords.coords.latitude,
        lng: geo.content.geo.coords.coords.longitude
      };

      this.setMarker(obj);

      this.setState({
        showing: geo.datetime.date
      });
    }
  }

  render() {
    return (
      <div>
        <div className={"col-md-12"}>
          <h4>Geo position</h4>

          <h5>
            <Trans i18nKey="agent_status" /> :
          </h5>
          {this.state.agent.status ? (
            this.state.agent.status === "onduty" ? (
              <React.Fragment>
                <h5>
                  <Trans i18nKey="agent_onduty" />
                </h5>
                <label>
                  <Trans i18nKey="label_task" /> #
                  {this.state.agent.task.task_id}
                </label>
                <br />
                <label>
                  <Trans i18nKey="status_started" /> :{" "}
                  {this.state.agent.task.task_start_time}
                </label>
                <br />
                <label>
                  <Trans i18nKey="task_end_date" /> :{" "}
                  {this.state.agent.task.task_end_time}
                </label>
                <br />
              </React.Fragment>
            ) : (
              " Last activity : " +
              moment
                .unix(this.state.agent.status)
                .format("YYYY-MM-DD HH:mm")
                .toString()
            )
          ) : (
            ""
          )}

          <p>
            Showing position at :{" "}
            {this.state.showing === "live" ? (
              <span className={"task-status status-id-5"}>LIVE</span>
            ) : (
              this.state.showing
            )}
          </p>

          <Slider
            min={this.state.minSlide}
            max={this.state.maxSlide}
            marks={this.state.sliderStatus}
            onChange={this.selectedHour}
            step={null}
          />

          <select
            style={{ display: "none" }}
            onChange={e => this.selectedHour(e)}
          >
            {this.state.geohistory.length > 0 &&
              this.state.geohistory.map((hist, i) => (
                <option value={i}>{hist.datetime.date} </option>
              ))}
            <option></option>
          </select>

          <div
            id="here-map"
            style={{
              borderWidth: 1,
              borderColor: "#ccc",
              width: "100%",
              height: "500px",
              background: "grey"
            }}
          ></div>
        </div>
      </div>
    );
  }
}
