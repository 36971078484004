import React, {Component} from 'react';
import { Trans } from 'react-i18next';
import { doneLoading, startLoading } from '../../Redux/actions';
import { connect } from "react-redux";
import WTNotifications, { withNotifications } from "react-socket-notification";
import api from '../../Utils/Api';
import  * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


import RapportRow from './RapportRow';
import { ExportCSV } from './ExportCSV';
import Select from 'react-select';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import ReactDatePicker from 'react-datepicker';
import { buildUriParams } from '../../Utils/Helpers';
import moment from 'moment';

import communes from "../Rapport/communes.json";


import ClientInput from "../Task/subcomponents/ClientInput";


const mapStateToProps = state =>{
    return {
        auth: state.auth,
        user: state.user
      };
}

function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading())
    };
}


class rapportComponent extends Component {
    constructor(props) {
        super(props);

        this.props.isLoading({
            message: "Loading Rapport",
            animate: true
        });

        this.state = { 
            manager: [],
            rapport_list: [],
            emptyArry: true,
            fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            fileExtension: '.xlsx',
            agents: [],
            codes: [],
            vehicules: [],
            clients:[],
            status: [],
            types: [],
            current: 1,
            limit: 60,
            filters: {},
            width: 0,
            height: 0,
            reduce:true
        }

        
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        
        
        this.loadRapport(1);
        
    }

    loadRapport = (p= this.state.current, e) =>{
        if(e)
            e.preventDefault()
        
        
        this.props.isLoading();
        const params = buildUriParams(this.state.filters);
        const crits = "limit=" + this.state.limit + params;
        console.log(params);

        api.get("/api/rapport/fetch?page=" + p +"&"+ crits).then((response) => {
            
            if(response.data){
                this.setState({
                    rapport_list: response.data,
                    emptyArry: false
                })
            }
            this.props.doneLoading();
            
            this.state.rapport_list = this.state.rapport_list;
            
        });
        
    }

    updateFilter = (event, name, isDate = false) =>{
        let value = "";

        if(isDate) {
            value = event;
        } else{
            value = event.target ? event.target.value : event.value;
        }

        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            }
        );
    };

    restFilters = () =>{
        this.setState(state => ({...state, filters: {} }), this.loadRapport());
    }

    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        let that = this
        api.get("/api/rapport/form").then(function(response){
            if(response){
                that.setState({
                    agents: [...response.data.agents],
                    codes: [...response.data.taskCode],
                    vehicules: [...response.data.vehicule],
                    clients: [...response.data.client]
                })
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
    updateWindowDimensions() {
        let width = document.getElementById('container').clientWidth
        console.log("width container" + width)
        console.log("window"+window.innerWidth)
        this.setState({ width: window.innerWidth-100 , height: window.innerHeight });
      }

    formatDate = date => (date ? new Date(date) : undefined);

    exportToCSV = () =>{
        console.log(this.state.rapport_list);
        const ws = XLSX.utils.json_to_sheet(this.state.rapport_list);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: this.state.fileType});
        var today = new Date();
        const fileName = 'Rapport_' + today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
        FileSaver.saveAs(data, fileName + this.state.fileExtension);
    }
    
    isNull = (item)=>{
        if(item){
            return item;
        }else{
            return 'Null';
        }
    } 
 
    
    render() { 

        const tabStyle = {
            
        };

        const divStyle = {
            width: this.state.width,
            height:"500px", 
            overflowX:"auto", 
            display:"inline-block",
            scrollCollapse: true,
        };
        const {filters} = this.state;
        
        const locations = communes.communes.map(item => ({
            label: item.nom + " (" + item.code_postal + ")",
            value: item.nom
        }));

        var curDateMonth = new Date();
        //var prvDateMonth = new Date(curDateMonth.getFullYear(),curDateMonth.getMonth()-1,curDateMonth.getMonth());
        var prvDateMonth = new Date()
        prvDateMonth.setMonth(prvDateMonth.getMonth() - 1)
        
       
        return (
            
            <React.Fragment>
                <div  className="col" id="container"></div>
                <div className="col-sm-12" >
                <div className= {"container-header"} >
                    <Trans i18nKey="label_report"/>
                    <React.Fragment>
                        <button className={"btn btn-md btn-success px-4"} onClick={this.exportToCSV}>
                            <Trans i18nKey="button_export"/>
                        </button>
                        {/* <ExportCSV csvData={this.state.rapport_list} fileName={'hello_world'}/> */}
                    </React.Fragment>
                </div>

                <React.Fragment>
                    <div className="form ui-rect np my-4">
                        <form onSubmit={e => this.loadRapport(1, e)}>
                            <div className="col-md-12">
                                {/* -------first row----------- */}
                                <div className="row">
                                    
                                        <Select
                                            //value={row.filter(item => item.value === filters.code)}
                                            classNamePrefix={"custom-select"}
                                            className="col-md-3 custom-select-c"
                                            options={this.state.codes}
                                            placeholder="Code"
                                            onChange={e => this.updateFilter(e, "code")}
                                            cacheOptions={true}
                                        />
                                   
                                    <div className="col-md-3 form-group">
                                        <div className="dates-wrap">
                                            <ReactDatePicker
                                                //selected={date}
                                                //showMonthYearPicker
                                                dateFormat={"dd, MMMM yyyy"}
                                                placeholderText="Mois Debut"
                                                timeFormat={"HH:mm"}
                                                showTimeSelect={false}
                                                className={" form-control"}
                                                wrapperClassName={"col-md-12"}
                                                selected={this.formatDate(filters.date_debut) ? this.formatDate(filters.date_debut) :prvDateMonth}
                                                onChange={e =>
                                                    this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"),"date_debut", true)
                                                }
                                                selectsStart
                                                startDate={this.formatDate(filters.date_debut)}
                                                endDate={this.formatDate(filters.date_fin)}
                                            />
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-3 form-group">
                                         <ReactDatePicker
                                            //showMonthYearPicker
                                            dateFormat={"dd, MMMM yyyy"}
                                            timeFormat={"HH:mm"}
                                            showTimeSelect={false}
                                            className={" form-control"}
                                            placeholderText="Mois fin"
                                            wrapperClassName={"col-md-12"}
                                            selected={this.formatDate(filters.date_fin) ? this.formatDate(filters.date_fin) :curDateMonth}
                                            onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                                            selectsEnd
                                            startDate={this.formatDate(filters.date_debut)}
                                            endDate={this.formatDate(filters.date_fin)}
                                            minDate={this.formatDate(filters.date_debut)}
                                        />
                                        
                                    </div>
                                    <div className={"col-md-3"}>
                                        <Select
                                            //value={list_client.filter(item => item.value === filters.client)}
                                            classNamePrefix={"custom-select"}
                                            className="  custom-select-c"
                                            placeholder="Client"
                                            options={this.state.clients}
                                            wrapperClassName={"col-md-12"}
                                            onChange={e => this.updateFilter(e, "client")}
                                        />
                                    </div>
                                        {/* <ClientInput
                                            placeholder={"Client"}
                                            required={false}
                                            defaultValue={filters.client || ""}
                                            //selectClient={sug => this.updateFilter({value: sug.client_name}, "client")}
                                            type="text"
                                            name="task_client"
                                            className={"form-control"}
                                        /> */}
                                        
                                   
                                    
                                </div>
                                  {/* -------Second row----------- */}
                                <div className="row">
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col-md-3  custom-select-c"
                                        placeholder="Depart"
                                        options={locations}
                                        onChange={e => this.updateFilter(e, "depart")}
                                        cacheOptions={true}

                                    />
                                     
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col-md-3  custom-select-c"
                                        placeholder="Point"
                                        options={locations}
                                        onChange={e => this.updateFilter(e, "point")}
                                        cacheOptions={true}
                                    />
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col-md-3  custom-select-c"
                                        placeholder="Arrive"
                                        options={locations}
                                        onChange={e => this.updateFilter(e, "arrive")}
                                        cacheOptions={true}
                                    />
                                    <Select 
                                        classNamePrefix={"custom-select"}
                                        className="col-md-3  custom-select-c"
                                        //type="text" 
                                        placeholder="Label vehicule"
                                        options={this.state.vehicules}
                                        //wrapperClassName={"col-md-12"}
                                        name="label_vehicule"
                                        onChange={e => this.updateFilter(e, "label_vehicule")}
                                    />
                                </div>
                                  {/* -------third row----------- */}
                                <div className="row mt-3 px-2">
                                    
                                    
                                    <Select 
                                        classNamePrefix={"custom-select"}
                                        className="col-md-2 custom-select-c"
                                        placeholder="Sous traitant"
                                        options={this.state.agents}
                                        placeholder="agent"
                                        onChange={e => this.updateFilter(e, "agent")}
                                    />
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col-md-2 custom-select-c" 
                                        options={[
                                            { label: "Tout", value: "" },
                                            { label: "Non assigné", value: "Non assigné"},
                                            { label: "Assigné provisoire", value: "Assigné provisoire" },
                                            { label: "Assigné ", value: "Assigné" },
                                            { label: "Annulé", value: "Annulé" },
                                        ]}
                                        placeholder="Status"
                                        onChange={e => this.updateFilter(e, "status")}
                                    />
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col-md-2 custom-select-c" 
                                        options={[
                                            { label: "Tout", value: "" },
                                            { label: "Dérangement", value: "Dérangement" },
                                            { label: "Annulé", value: "Annulé" },
                                            { label: "Reportée ", value: "Reportée" },
                                            { label: "Non disponible", value: "Non disponible" },
                                        ]}
                                        placeholder="Status tache"
                                        onChange={e => this.updateFilter(e, "task_status")}
                                    />

                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col custom-select-c"
                                        options={[
                                            { label: "Tout", value: "" },
                                            { label: "NULL", value: "false" },
                                            { label: "TRUE", value: "true" }
                                          ]}
                                        placeholder="Reception"
                                        onChange={e => this.updateFilter(e, "docs")}
                                    />
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className="col custom-select-c"
                                        options={[
                                            { label: "Tout", value: "" },
                                            { label: "léger", value: "P" },
                                            { label: "Lourd", value: "L" }
                                          ]}
                                        placeholder="Catégorie vehicule"
                                        onChange={e => this.updateFilter(e, "type_vehicule")}
                                    />
                                    <div className="col text-right"> 
                                        <button onClick={this.restFilters} className={"btn btn-md btn-outline-primary"}>
                                            Reinitialiser les filtres
                                        </button>
                                        <button  className={"btn btn-md btn-primary ml-2"} onClick={() => this.loadRapport(1)}>
                                            Filtrer
                                        </button>
                                        
                                    </div>
                                </div>

                                <div className="row my-3 ">
                                    
                                </div>
                            </div>

                        </form>
                    </div>
                </React.Fragment>

                <React.Fragment> <div className="ui-rect np my-4"> <strong>{this.state.rapport_list.length} lignes trouvés</strong></div></React.Fragment>
               
                <React.Fragment>
                <div className="ui-rect np">
                {/* <div className="row"  style={{width:"1465px", height:"500px", overflow: "auto", display:"inline-block"}}> */}
                <div >
                
                <div className="row"  style={divStyle}>

                    <table class="table table-striped m-1"  >
                        <thead>
                            <tr className=" head-titles table-row">
                                <th scope="col">Code</th>
                                <th scope="col">Date & Heure chargement</th>
                                <th scope="col">Client</th>
                                <th scope="col">Depart</th>
                                <th scope="col">Point</th>
                                <th scope="col">Arrivee</th>                                
                                {/* <th scope="col">Heure chargement</th> */}
                                <th scope="col">Catégorie vehicule</th>
                                <th scope="col">Type vehicule</th>
                                <th scope="col">Sous traitant</th>
                                <th scope="col">Status</th>
                                <th scope="col">Status tache</th>
                                <th scope="col">Vente</th>
                                <th scope="col">Reception</th>
                                <th scope="col">Achat</th>
                                <th scope="col">Observation</th>
                                <th scope="col">Retour</th>
                                <th scope="col">Mise dispo</th>
                                <th scope="col">bl Client</th>
                                <th scope="col">info facture</th>
                            </tr>
                        </thead>
                        <tbody>
                        
                            {this.state.rapport_list.length >0 ? this.state.rapport_list.map((item) => (
                                <tr key={item.code}> 
                                    <td>{item.code}</td>
                                    <td>{item.Date}</td>
                                    <td>{item.Client}</td>
                                    <td>{item.Depart}</td>
                                    <td>{item.Point}</td>
                                    <td>{item.Arrivee}</td>
                                    <td>{item.TypeVehicule}</td>
                                    <td>{item.LabelVehicule}</td>
                                    <td>{item.agent}</td>
                                    <td>{item.status}</td>
                                    <td>{item.task_status}</td>
                                    <td>{item.tarif_vente}</td>
                                    <td>{this.isNull(item.docs_recu)}</td>
                                    <td>{item.tarif_achat}</td>
                                    <td>{item.obs_vente}</td>
                                    <td>{item.retour}</td>
                                    <td>{item.mise_dispo}</td>
                                    <td>{item.bl_client}</td>
                                    <td>{this.isNull(item.info_facture)}</td>
                                </tr>
                            )) :<tr><td colspan="19"><p class="text-center">Aucun élément a afficher</p></td></tr> }
                            
                                
                            
                            
                        </tbody>
                    </table>
                </div>
                </div>
                </div>
                    
                </React.Fragment>
                </div>
                
            </React.Fragment>
        );
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(rapportComponent);
export function getReduce(reduce){
    console.log("reduce" + reduce)
    this.setState({
        reduce:reduce
    })
}