import React, {useState, Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { faAngleDown,faAngleUp, faCheckCircle, faBox, faTrash, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskList from "../Components/Bordereau/TaskList"
import * as CONSTANTS from "../Utils/Constants";
import Modal from 'react-bootstrap/Modal';
import { connect } from "react-redux";
import WTNotifications, { withNotifications } from "react-socket-notification";
import * as actions from '../Redux/actions/index';
import BordereauNewContainer from "./BordereauNewContainer";

import api from "../Utils/Api";


const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList,
    filters: state.filters,
    taskStatus: state.taskStatus,
    task_status: state.task_status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // isLoading: e => dispatch(startLoading(e)),
    // doneLoading: e => dispatch(doneLoading()),
    // setAuth: e => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(actions.setFlash(f)),
    closeFlash: (f) => dispatch(actions.closeFlash(f))
  };
}


class BordereauRow extends Component {
  
    constructor(props) {
      super(props);
            
        this.state = {
            isDetailsShown: false,
            isModal: false,
            isdeleteModal: false,
            showHistory:false,
            echecBtnDisabled:false,
            showNewTask: false,
            data:{
              montant: '',
              collect : '',
              motif: '',
              surpoids: ''
            },
           
        };
        
    }

  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showNewTask: false });
    }
  };

  duplicateTask = id => {
    console.log("bordereau");
    console.log(this.props.bordereau);
    this.setState({
      showNewTask: true,
      selected_task: id,
      is_duplicate: true
    })
  }



    showDetails = () => {
        let bool = this.state.isDetailsShown 
        this.setState({
        isDetailsShown: bool? false :true
        })
        
    }
    change
    
    // componentWillReceiveProps(nextProps) {
    //   if(this.props.from){
    //     this.setState({ 
    //       data :{
    //       montant : nextProps.bordereau.tarif,
    //       collect : nextProps.bordereau.collect,
    //       motif: ''
    //     } });  
    //     setTimeout(() => {
    //       this.loadBordereau()
    //     }, 1);
    //   }
       
    // }
    ColorSubTask = (item) =>{
      if(item == 'done')
        return 'text-success'
      if(item == 'started')
        return 'text-warning'
      return 'text-muted'
    }

    getDocument = (action) => {
      let url = CONSTANTS.REMOTE_API + 'File/';
      
      if(action == 'bordereau' && this.props.bordereau.file){
        url += 'Bordereau/' + this.props.bordereau.file;
        window.open(url, '_blank').focus();
      }
      if(action == 'bordereauA4' && this.props.bordereau.fileA4){
        url += 'Bordereau/' + this.props.bordereau.fileA4;
        window.open(url, '_blank').focus();
      }

    
   }

  getMouseHover = (key, zone, status, date) => {
    let type = key ==='-1' ? 'Enlevement ' : 'Arrivée '
    let text = type + ' ' + zone + ' '
    text += status === null ? 'waiting ' : (status === 'started' ? 'START ' : 'DONE ')
    text += date

    return text;
  }
  
  numberDisplay = (nbr) => {
    let number = parseInt(nbr);
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(number)
  }

  echec = () => {
    if(this.state.echecBtnDisabled == false){//you dont have to check since this will not run if btn is dissabled
      this.setState({
        echecBtnDisabled: true
      })

      api.post('/api/bordereau/echec/'+ this.props.bordereau.key_bordereau).then(result =>{

      
		  
		  if(result.data.success){
			
			 window.location.reload(false);
			
        }
      this.setState({
        echecBtnDisabled: false
        })

		})


    }
  }



   // afterPost = () => {
   //  this.props.isLoading();
   //  this.loadTasks();
   //  this.setState({
   //    showNewTask: false,
   //    is_duplicate: false
   //  });
  // };


  updateData = (event,name) =>{
    console.log(event)
    this.setState({
      data:{
      ...this.state.data,
      [name]: event.target ? event.target.value : event.value
      }
    });
  }

  
  delete  = () => {
    api.post('/api/bordereau/delete/'+ this.props.bordereau.key_bordereau).then(result =>{
		  
		  if(result.data.success){
			
			 window.location.reload(false);
			
		  }
		})
  }

   editSurpoids = () => {
    let data = {
      surpoids: this.state.data.surpoids,
    }
    api.post('/api/bordereau/edit/surpoids/'+ this.props.bordereau.key_bordereau,data).then(result =>{
		  
		  if(result.data.success){
        this.props.handlers.loadTasks()
        this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
        this.setState({
          isSurpoidsModal: false,
          data:{
            montant: '',
            collect:'',
            motif: '',
            surpoids: '',
          },
        })

        
		  }
		})
  }


  editMontant = () => {
    let data = {
      montant: this.state.data.montant,
      motif: this.state.data.motif,
      type : 'tarif'
    }
    api.post('/api/bordereau/edit/montant/'+ this.props.bordereau.key_bordereau,data).then(result =>{
		  
		  if(result.data.success){
        this.props.handlers.loadTasks()
        this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
        this.setState({
          isEditModal: false,
          data:{
                  montant: '',
                  collect:'',
                  motif: ''
                },
        })

        
		  }
		})
  }

  editMontantCollect = () => {
    let data = {
      collect: this.state.data.collect,
      motif: this.state.data.motif,
      type : 'collect'
    }
    api.post('/api/bordereau/edit/montant/'+ this.props.bordereau.key_bordereau,data).then(result =>{
		  
		  if(result.data.success){
        this.props.handlers.loadTasks()
        this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
        this.setState({
          isEditCModal: false,
          data:{
            montant: '',
            collect:'',
            motif: ''
          },
        })
        
		  }
		})
  }

  render(){
        return(
                
                <div data-row-index={this.props.idx} className="row task-row no-gutters py-1">
                  <div className="col-md-12">
                    <div className="row table-row">
                      <div className="col-md-2 truncateLongTexts" title={this.props.bordereau.bordereau_code}>
                        {this.props.bordereau.bordereau_code}
                        {this.props.bordereau.isDone && this.props.bordereau.status_bordereau !== 'echèc' && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Done </div>}
                        {!this.props.bordereau.isDone && !this.props.bordereau.status_bordereau && <div style={{ border: 'solid 1px #f0ad4e' , borderRadius : '3px', textAlign: 'center' , color: '#f0ad4e' , width: '60px'}}> Waiting... </div>}
                        {this.props.bordereau.status_bordereau === 'echèc' && <div style={{ border: 'solid 1px #bb2124' , borderRadius : '3px', textAlign: 'center' , color: '#bb2124' , width: '60px'}}> Echèc </div>}                      
                        {this.props.bordereau.isStopdesk  && <div style={{marginTop:5, border: 'solid 1px #28397B' , borderRadius : '3px', textAlign: 'center' , color: '#28397B' , width: '60px'}}> stop-desk</div>}
                      </div>

                      <div className="col-md-1">
                        {this.props.bordereau.client}
                      </div>

                      <div className="col-md-3">
                        {this.props.bordereau.status_bordereau === 'echèc' ? <ul class="progress-tracker progress-tracker--text">
                          {Object.keys(this.props.bordereau.tasks).map((idx, i) => 
                                
                                Object.keys(this.props.bordereau.tasks[idx].subtasks)
                                .reverse()
                                .map((subtaskKey, i)=> 
                                    
                                        <li class={"progress-step is-stop" }>
                                            <span class="progress-marker"></span>
                                            <span class="progress-text" title={this.getMouseHover(subtaskKey , this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_address_commune, this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status , this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_end_datetime)}>
                                                 
                                                 {this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_address_commune}
                                            </span>
                                        </li>
                                )
                            )}
                          </ul>
                           :                        
                          <ul class="progress-tracker progress-tracker--text">
                                {Object.keys(this.props.bordereau.tasks).map((idx, i) => 
                                      
                                      Object.keys(this.props.bordereau.tasks[idx].subtasks)
                                      .reverse()
                                      .map((subtaskKey, i)=> 
                                          
                                              <li class={""+ this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status === 'started' ? "progress-step is-active" : (this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status === 'done' ? "progress-step is-complete"  : "progress-step")}>
                                                  <span class="progress-marker"></span>
                                                  <span class="progress-text" title={this.getMouseHover(subtaskKey , this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_address_commune, this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status , this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_end_datetime)}>
                                                       {/* <h6 class="progress-title">{subtaskKey == 'a_-1'? 'Enlevement': subtaskKey == 'a_999'? 'Arrivée' : ''}</h6> */}
                                                       {this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_address_commune}
                                                       {/*{this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status == null ? 'waiting' : this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_status == 'started' ? 'START' : 'DONE'}<br/>
                                                      {this.props.bordereau.tasks[idx].subtasks[subtaskKey].subtask_end_datetime} */}
                                                  </span>
                                              </li>
                                      )
                                  )}
                        </ul>}
                        {/* {this.props.bordereau.depart} */}
                        {/* {this.props.bordereau.tasks["a_-1"] &&
                        <span class={this.changeColorSubTask(this.props.bordereau.tasks["a_-1"].subtasks["a_-1"].subtask_status)}>
                        <b>{this.props.bordereau.tasks["a_-1"].subtasks["a_-1"].subtask_address_commune} </b>
                        </span>} */}
                      </div>

                      {/* <div className="col-md-2">
                        {this.props.bordereau.tasks["a_999"] &&
                        <span class={this.changeColorSubTask(this.props.bordereau.tasks["a_999"].subtasks["a_999"].subtask_status)}>
                        <b>{this.props.bordereau.tasks["a_999"].subtasks["a_999"].subtask_address_commune} </b>
                        </span>}
                      </div> */}

                      <div className="col-md-1">
                        {this.props.bordereau.type}
                      </div>

                      <div className="col-md-1">
                        {this.props.bordereau.author}
                      </div>

                      <div className="col-md-1">
                        {this.props.bordereau.paiment === "prepaid" ?  <div>
                          <p style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33'}}>Prepaid</p>
                          {/* {this.props.bordereau.collect === 0 && <p class="text-success">Frais de la livraison : {this.numberDisplay(this.props.bordereau.tarif)} DZD</p>}
                          {this.props.bordereau.collect !== 0 && <p class="text-danger">Coût colis : {this.numberDisplay(this.props.bordereau.collect)} DZD</p>} */}
                          <span class="text-success truncateLongTexts" title={this.props.bordereau.tarif + ' DZD'}> F : {this.numberDisplay(this.props.bordereau.tarif)} DZD </span><br/>
                          {this.props.bordereau.collect !== 0 && <span class="text-danger truncateLongTexts" title={this.props.bordereau.collect + ' DZD'}>C : {this.numberDisplay(this.props.bordereau.collect)} DZD</span>}<br/>
                          {this.props.bordereau.surpoids !== "0" && <span class="text-danger truncateLongTexts" title={this.props.bordereau.surpoids + ' DZD'}> surpoids : {this.numberDisplay(this.props.bordereau.surpoids)} DZD</span>}
                          
                          
                        </div>
                        : 
                        <div>
                          <p style={{ border: 'solid 1px #bb2124' , borderRadius : '3px', textAlign: 'center' , color: '#bb2124'}}>Postpaid</p>
                          {this.props.bordereau.collect !== 0 && this.props.bordereau.surpoids !== "0" && <p class="text-danger truncateLongTexts" title={this.props.bordereau.collect + ' + ' + this.props.bordereau.tarif + '+' + this.props.bordereau.surpoids  + ' DZD'}>C + F + S: {this.numberDisplay(this.props.bordereau.collect)}  + {this.numberDisplay(this.props.bordereau.tarif)} + {this.numberDisplay(this.props.bordereau.surpoids)}DZD</p>}
                          {this.props.bordereau.collect !== 0 && this.props.bordereau.surpoids === "0" && <p class="text-danger truncateLongTexts" title={this.props.bordereau.collect + ' + ' + this.props.bordereau.tarif  + ' DZD'}>C + F: {this.numberDisplay(this.props.bordereau.collect)}  + {this.numberDisplay(this.props.bordereau.tarif)} DZD</p>}

                          {this.props.bordereau.collect === 0 && this.props.bordereau.surpoids !== "0" && <p class="text-danger truncateLongTexts" title={this.props.bordereau.tarif + '+' + this.props.bordereau.surpoids + ' DZD'}>F + S: {this.numberDisplay(this.props.bordereau.tarif)}+ {this.numberDisplay(this.props.bordereau.surpoids)} DZD</p>}
                          {this.props.bordereau.collect === 0 && this.props.bordereau.surpoids === "0" && <p class="text-danger truncateLongTexts" title={this.props.bordereau.tarif + ' DZD'}>F: {this.numberDisplay(this.props.bordereau.tarif)} DZD</p>}
                        </div>
                        
                        }
                        
                      </div>

                      {/* <div className="col-md-2 action-col text-right">
                          <div class="btn-group dropleft">
                              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Document
                              </button>
                              <div class="dropdown-menu">
                                <button type="button" class="btn" onClick={e => this.getDocument('bordereau')}>
                                    Format A6
                                </button>
                                <br/>
                                <button type="button" class="btn" onClick={e => this.getDocument('bordereauA4')}>
                                    Format A4
                                </button>
                              </div>
                            </div>
                            {this.props.bordereau.isRetour &&
                             this.props.bordereau.status_bordereau !== 'echèc'   &&  
                             !this.props.bordereau.isDone    && 
                                <button type="button" class="btn btn-primary ml-1" onClick={e => this.setState({isModal: true})}>
                                  Echec
                                </button>}
                            {this.props.user.role === 'manager' && <button type="button" class="btn btn-primary ml-1" onClick={e => this.setState({isdeleteModal: true})}>
                              <FontAwesomeIcon className={"ml"} icon={faTrash} />
                            </button>}
                            {!this.state.isDetailsShown?
                                <button type="button" class="btn btn-link" onClick={e => this.showDetails()}><FontAwesomeIcon  className={"ml-2"} icon={faAngleDown} /> Détails</button>  
                                :
                                <button type="button" class="btn btn-link" onClick={e=> this.showDetails()}><FontAwesomeIcon className={"ml-2"} icon={faAngleUp} />Détails</button>
                            }
                        </div> */}

                        <div className="col-md-2 action-col text-right">
                          <div class="btn-group dropleft">
                              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Actions
                              </button>
                              <div class="dropdown-menu">
                                <button type="button" class="btn a6" onClick={e => this.getDocument('bordereau')}>
                                   Impression Format A6
                                </button>
                                <br/>
                                <button type="button" class="btn a4" onClick={e => this.getDocument('bordereauA4')}>
                                  Impression Format A4
                                </button>
                                <br/>
                                {
                                  (this.props.user.role === "manager" ||
                                    this.props.user.role === "commercial" ||
                                    this.props.user.role === "client" ||
                                    this.props.user.role === "sup ops" ||
                                    this.props.user.role === "admin" || 
                                    this.props.user.role === "superviseur")?
                                    <button type="button" class="btn a4" onClick={e => this.setState({showNewTask : true})}>
                                  Dupliquer 
                                </button>
                                    : ('')
                                }
                                
                                <br/>
                                {this.props.bordereau.isRetour &&
                                  this.props.bordereau.status_bordereau !== 'echèc'   &&  
                                  !this.props.bordereau.isDone    && this.props.user.role !== 'finance' && this.props.user.role !== "gestion" &&
                                    <button type="button" class="btn echec" onClick={e => this.setState({isModal: true})}>
                                      Echec
                                    </button>}
                                {this.props.user.role === 'manager' && <button type="button" class="btn" onClick={e => this.setState({isdeleteModal: true})}>
                                  {/* <FontAwesomeIcon className={"ml"} icon={faTrash} /> */}
                                  Supprimer
                                </button>}
                                {(this.props.user.role === 'manager' || this.props.user.role === 'superviseur') && this.props.bordereau.isRetour &&
                                  this.props.bordereau.status_bordereau !== 'echèc'   &&  
                                  !this.props.bordereau.isDone  && <button type="button" class="btn" onClick={e => this.setState({isEditModal: true})}>
                                  Modifier Tarif
                              </button>}
                              {(this.props.user.role === 'manager' || this.props.user.role === 'superviseur') && this.props.bordereau.isRetour &&
                                  this.props.bordereau.status_bordereau !== 'echèc'   &&  
                                  !this.props.bordereau.isDone  && <button type="button" class="btn" onClick={e => this.setState({isSurpoidsModal: true})}>
                                  Surpoids
                                </button>}

                                {(this.props.user.role === 'manager' || this.props.user.role === 'superviseur') && this.props.bordereau.isRetour &&
                                  this.props.bordereau.status_bordereau !== 'echèc'   &&  
                                  !this.props.bordereau.isDone  && <button type="button" class="btn" onClick={e => this.setState({isEditCModal: true})}>
                                  Modifier Collecte
                                </button>}
                                <button type="button" class="btn a4" onClick={e => this.setState({showHistory:true})}>
                                  Historique
                                </button>
                              </div>
                            </div>


                            {this.state.showNewTask && (
                              <div className={`ui-overlay open`}>
                                <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
                                  <button
                                    onClick={() => this.setState({ showNewTask: false })}
                                    className={"ui-overlay-close btn btn-danger btn-sm"}
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>

                                  <BordereauNewContainer taskId={this.state.selected_task} bordereau={this.props.bordereau}  isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} />
                                </div>
                              </div>
                            )}

                            
                            {!this.state.isDetailsShown?
                                <button type="button" class="btn btn-link" onClick={e => this.showDetails()}><FontAwesomeIcon  className={"ml-2"} icon={faAngleDown} /> Détails</button>  
                                :
                                <button type="button" class="btn btn-link" onClick={e=> this.showDetails()}><FontAwesomeIcon className={"ml-2"} icon={faAngleUp} />Détails</button>
                            }
                        </div>
                        <div className="col-md-1 mt-2 text-center">
                            {this.props.data.bordereauxOnAction.includes(this.props.bordereau.key_bordereau) ? <input
                              name={"apply"}
                              type="checkbox" 
                              className="text-right"
                              checked
                              onChange={e => this.props.handlers.fillBOnAction(e, this.props.bordereau.key_bordereau)}
                            /> :

                            <input
                              name={"apply"}
                              type="checkbox" 
                              className="text-right"
                              onChange={e => this.props.handlers.fillBOnAction(e, this.props.bordereau.key_bordereau)}
                            />
                            }
                        </div>
                      </div>

                      {this.state.isDetailsShown? 
                        <div className={""}>
                            <TaskList duplicateTask={this.props.duplicateTask} data={this.props.data} rows={this.props.data.rows} handlers={this.props.handlers} task= {this.props.bordereau.tasks}/>
                        </div>
                        :
                        ""
                      }
                    </div>

                    <Modal show={this.state.isModal} centered>
                      <Modal.Header >
                        <Modal.Title>Echec Bordereau {this.props.bordereau.bordereau_code}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Cliquer sur confirmer pour confirmer l'échec
                      </Modal.Body>
                      <Modal.Footer>
                        <button className={"btn btn-danger"}  onClick={e => this.setState({isModal: false})}>
                          Annuler
                        </button>
                        <button type='submit' className={"btn btn-success"} disabled={this.state.echecBtnDisabled} onClick={e => this.echec()} >
                        confirmer 
                        </button>
                      </Modal.Footer>
                    </Modal>	

                    <Modal show={this.state.isdeleteModal} centered>
                        <Modal.Header >
                          <Modal.Title>Suppression du  Bordereau </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Voulez vous vraiment supprimer le bordereau <b>{this.props.bordereau.bordereau_code}</b>
                        </Modal.Body>
                        <Modal.Footer>
                          <button className={"btn "}  onClick={e => this.setState({isdeleteModal: false})}>
                            Annuler
                          </button>
                          <button type='submit' className={"btn btn-danger"} onClick={e => this.delete()} >
                            Supprimer  
                          </button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={this.state.isEditModal} centered>
                        <Modal.Header >
                          <Modal.Title>Modifier le montant de <b> {this.props.bordereau.bordereau_code}</b></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p class="text-center"><b>Entrer le nouveau montant </b></p>  
                          <input 
                            type="number"
                            name="montant"
                            placeholder="Tapez le montant a changer ..."
                            value={this.state.data.montant}
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'montant')} 
                            min="0"
                            
                           
                          />
                         
                          <p class="text-center"><b>Entrer Un commentaire </b></p>  
                          <input 
                            type="text"
                            name="motif"
                            value={this.state.data.motif}
                            placeholder="Tapez le motif ..."
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'motif')}
                            required
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <button className={"btn "}  onClick={e => this.setState({isEditModal: false})}>
                            Annuler
                          </button>
                          <button type='submit' className={"btn btn-primary"} onClick={e => this.editMontant()} >
                            Modifier   
                          </button>
                        </Modal.Footer>
                      </Modal>

                  <Modal show={this.state.isSurpoidsModal} centered>
                    <Modal.Header >
                      <Modal.Title>Ajouter un cout pour le surpoids pour <b> {this.props.bordereau.bordereau_code}</b></Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      <form >
                        
                          <p class="text-center"><b>Entrer le cout du surpoids</b></p>
                          <input 
                            type="text"
                            name="collect"
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'surpoids')} 

                          />
                      
                      </form>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e=> (this.setState({isSurpoidsModal: false}))}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.editSurpoids()}>
                        Ajouter
                      </button>
                      
                    </Modal.Footer>
              </Modal>


                    <Modal show={this.state.isEditCModal} centered>
                        <Modal.Header >
                          <Modal.Title>Modifier la collecte de <b> {this.props.bordereau.bordereau_code}</b></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          
                          <p class="text-center"><b>Entrer le nouveau montant de la collecte </b></p>  
                          <input 
                            type="number"
                            name="collect"
                            placeholder="Tapez le montant a changer ..."
                            value={this.state.data.collect}
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'collect')} 
                            min="0"
                           required
                          />
                          <p class="text-center"><b>Entrer Un commentaire </b></p>  
                          <input 
                            type="text"
                            name="motif"
                            value={this.state.data.motif}
                            placeholder="Tapez le motif ..."
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'motif')}
                            required
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <button className={"btn "}  onClick={e => this.setState({isEditCModal: false})}>
                            Annuler
                          </button>
                          <button type='submit' className={"btn btn-primary"} onClick={e => this.editMontantCollect()} >
                            Modifier   
                          </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showHistory} centered onHide={e => this.setState({showHistory:false})}>
                        <Modal.Header closeButton >
                          <Modal.Title>Historique</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {this.props.bordereau.tarifHist.map((item, i) => (
                         
                            <span className={"detail-label"}>
                              {/* <b>Tentative : {tentative.number} </b> */}
                              <br/><b>Date:</b>  {item.history_date}  
                              <br/><b>Motif:</b>  {item.history_action}
                              <hr/>
                              {/* <br/>Modifier par {item.history_by} */}
                            </span>
                            
                        ))}
                        </Modal.Body>
                      
                    </Modal>


                   
                </div>

                
        )
    }


}export default withNotifications(connect(
  mapStateToProps,  mapDispatchToProps
) (BordereauRow))
