import React, { Component } from "react";
import _ from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import {
  setAuthAction,
  startLoading,
  doneLoading
} from "../../Redux/actions/index";
import { Trans } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import TaskRow from "./subcomponents/TaskRow";
import ReactDatePicker from "react-datepicker";
import TaskDetailModal from "./subcomponents/TaskDetailModal";

const moment = require("moment");
moment().utcOffset(0);
const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: e => dispatch(startLoading(e)),
  doneLoading: () => dispatch(doneLoading()),
  setAuth: e => dispatch(setAuthAction(e))
});

class TaskList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      showOverlay: false,
      taskid: "",
      agentid: "",
      temp: false,
      showDocOverlay: false,
      docs_recu: false,
      docs_comment: "",
      selected_task: {},
      showTaskDetail: false
    };

    // this.formSubmit = this.formSubmit.bind(this);
  }


  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showDocOverlay: false, showTaskDetail: false, showOverlay: false });
    }
  };


  componentDidMount() {
    document.addEventListener("keyup", this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape);
  }


  toggleOverlay = e => {
    this.setState({
      taskid: e,
      showOverlay: !this.state.showOverlay
    });
  };

  toggleDocOverlay = e =>
    this.setState({ taskid: e, showDocOverlay: !this.state.showDocOverlay });


  formDocSubmit = e => {

    e.preventDefault();


    const data = {
      docs_recu: this.state.docs_recu,
      docs_comment: this.state.docs_comment,
      taskid: this.state.taskid
    };

    this.props.isLoading();

    api.post("/api/v2/task/docs", data).then(() => {
      this.toggleDocOverlay();
      this.props.doneLoading();
      this.props.handlers.loadTasks();
    });

  }


  formAgentSubmit = e => {
    e.preventDefault();


    const data = {
      user: this.props.user,
      agent: this.state.agentid,
      task: this.state.taskid,
      temp: this.state.temp,
      heure_dispo: this.state.heure_dispo
    };

    this.props.isLoading();

    api.post("/api/v2/task/assign", data).then(() => {
      this.toggleOverlay();
      this.props.doneLoading();
      this.props.handlers.loadTasks();
    });
  };

  selectTask = task => this.setState({selected_task: task, showTaskDetail: true});

  formatDate = date => (date ? new Date(date) : undefined);

  render() {
    //const { t, i18n } = useTranslation();

    const data = this.props.data;

    const { showOverlay, showDocOverlay } = this.state;

    return (
      <React.Fragment>
        <div className="table  m-1">
      
          {Object.keys(this.props.task).map((idx, task) => (
            <div><hr/>
                <TaskRow
                  //key={`task_row_${task.task_id}`}
                  showTaskDetail={() => this.selectTask(this.props.task)}
                  task={this.props.task[idx]}
                  //idx={i}
                  show={false}
                  toggleDocOverlay={this.toggleDocOverlay}
                  toggleOverlay={this.toggleOverlay}
                  user={this.props.user}
                  duplicateTask={this.props.duplicateTask}
              />
              
            </div>
          ))}

          {this.props.task.length === 0 && (
            <div className={"col-md-12 text-center my-3"}>
              <Trans i18nKey={"empty_tasks"} />
            </div>
          )}
        </div>

        {/**
         * Assigner à un agent modal
         */}
        <div className={showOverlay ? "ui-overlay open" : "ui-overlay"}>
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id="0"
              onClick={e => {
                this.toggleOverlay(e);
              }}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Assignation la tache a un coursier </h5>
            <form onSubmit={this.formAgentSubmit} >
              {/* <div className="form-group mt-3">
                <div className="custom-control custom-checkbox">
                  <input
                    checked={this.state.temp}
                    onChange={e => this.setState({ temp: e.target.checked })}
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck2"
                    name="example1"
                  />
                  <label className="custom-control-label" htmlFor="customCheck2">
                    Assigné provisoire
                  </label>
                </div>


              </div>

              <div className="form-group mt-3">
                <label className={"font-exp"}>Heure disponibilité</label>

                <ReactDatePicker
                showTimeSelect
                showTimeSelect
                timeFormat={"HH:mm"}
                dateFormat={"yyyy-MM-dd HH:mm"}
                  selected={this.formatDate(this.state.heure_dispo)}
                  className={"form-control ml-2"}
                  name={"heure_dispo"}
                  onChange={e => {
                    this.setState({
                      heure_dispo: moment(e).format("YYYY-MM-DD HH:mm")
                    })
                  }
                  }
                />
              </div> */}
              <br/>

              <div className="form-group mt-3">
                <label className={"font-exp"}>Selectionnez un coursier</label>

                <Select
                  options={data.agents}
                  cacheOptions
                  defaultOptions
                  onChange={e => this.setState({ agentid: e.value })}
                  placeholder="Selectionnez ..."
                  className={"d-block mt-1"}
                />

                <input
                  type="submit"
                  value="Assigner"
                  className={"btn btn-primary btn-sm mt-2"}
                />
              </div>
            </form>
            <div>
              <span className={"font-exp"}>
                Le coursier recevra une notification.
              </span>
            </div>
          </div>
        </div>

        {/**
         * Document reçu modal
         */}
        <div className={showDocOverlay ? "ui-overlay open" : "ui-overlay"}>
          <div
            className={
              "ui-overlay-content status-overlay-text col-md-4 offset-md-4"
            }
          >
            <button
              data-package-id="0"
              onClick={e => {
                this.toggleDocOverlay(e);
              }}
              className={"ui-overlay-close btn btn-danger btn-sm"}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h5>Docuement Reçu</h5>
            <form onSubmit={this.formDocSubmit}>
              <div className="form-group mt-3">
                <div className="custom-control custom-checkbox">
                  <input
                    checked={this.state.docs_recu}
                    onChange={e => this.setState({ docs_recu: e.target.checked })}
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck"
                    name="example1"
                  />
                  <label className="custom-control-label" htmlFor="customCheck">
                    Documents reçu
                  </label>
                </div>

                {/* <label className={"font-exp"}>Assigné provisoire </label>

                <input
                  checked={this.state.temp}
                  onChange={e => this.setState({ temp: e.target.checked })}
                  type="checkbox"
                  className={"btn btn-primary btn-sm ml-3"}
                /> */}
              </div>

              <div className="form-group mt-3">
                <label className={"font-exp"}>Observation</label>

                <textarea onChange={e => this.setState({ docs_comment: e.target.value })} className={"form-control"} />

                <input
                  type="submit"
                  value="Mettre à jour"
                  className={"btn btn-primary btn-sm mt-2"}
                />
              </div>
            </form>
          </div>
        </div>

        {this.state.showTaskDetail && <TaskDetailModal task={this.state.selected_task} close={() => this.setState({showTaskDetail: false})} />}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
