import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";


const axios = require("axios");



function mapStateToProps(state) {
  return { auth: state.auth };
};


class UserDetailContainer extends Component {

	constructor(props) {

		super(props);

		this.formRef = React.createRef();

		this.formSubmit = this.formSubmit.bind(this);

		this.state = {
			user: {}
		}

	}

	formSubmit(e) {
		e.preventDefault();

		var formdata = new FormData(e.target);
		var data = {};
		for( var [key, value] of formdata.entries()) {
			data[key] = value;
		}
		console.log(data);
		axios.post('/api/user/edit/'+this.props.match.params.userid, {...data})
		.then(res => res.json())
		.then(res => console.log(res));

		
		
		console.log(this.formRef.current);

		return false;
	}

	componentWillMount(){

		
		console.log(this.props.auth);

		let that = this;
		axios.get('/api/user/'+this.props.match.params.userid)
		.then(function(response) {
			that.setState({user: response.data});

		});

		console.log(process.env)

	}

	render() {
		return(

			<div>
				
				<form onSubmit={this.formSubmit} ref={this.formRef} name="userForm" method="post">
				<input type="hidden" name="userid" defaultValue={this.state.user.userid} />
					<div className={"form-group col-md-6"}>


						<label>Username </label>
						<input className="form-control" type="text" name="username" defaultValue={this.state.user.username} />
					</div>

					<div className={"form-group col-md-6"}>
						<label>First Name </label>
						<input className="form-control" type="text" name="firstName" defaultValue={this.state.user.firstName} />
					</div>

					<div className={"form-group col-md-6"}>
						<label>Last Name </label>
						<input className="form-control" type="text" name="lastName" defaultValue={this.state.user.lastName} />
					</div>

					<div className={"form-group col-md-6"}>
						<label>Email </label>
						<input className="form-control" type="text" name="email" defaultValue={this.state.user.email} />
					</div>

					<div className={"form-group col-md-6"}>
						<label>Role </label><Link to={'/users/'+this.props.match.params.userid+'/roles'} ><span style={{ float: "right" }} className={"xm-sm btn btn-sm btn-link"}>Manage roles</span></Link>
						<input className="form-control" type="text" name="role" defaultValue={this.state.user.role}  disabled={true} />
					</div>

					<div className={"form-group col-md-6"}>
						
						<input className={"cp form-control btn btn-primary "} type="submit" value="Save" />
					</div>
				</form>
			</div>

			
		)
	}
}

export default connect(mapStateToProps)(UserDetailContainer)
