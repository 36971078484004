import React, {useState, Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { faAngleDown,faAngleUp, faHandHoldingUsd, faFileInvoice, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FacturesDetail from './FacturesDetail';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import api from "..//../Utils/Api";
import * as CONSTANTS from "../../Utils/Constants";
import { connect } from "react-redux";
import WTNotifications, { withNotifications } from "react-socket-notification";



const status = [
  {label: "payé", value: 1 },
  {label: "partiellement payé", value: 3 }
]

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
  };
};


class FacturesRow extends Component {
    constructor(props) {
        super(props);

        
    
        this.state = {
            isDetailsShown: false,
            isStatusShown: false,
            statusSelected: '',
            data:{
              status: '',
              reference: '',
              montant_part: '',
              method:''
              
            },
            error: '',
            statusClass: this.props.facture.status === 'payé' ? 'factStatus first' :( this.props.facture.status === 'non payé'? 'factStatus second': 'factStatus third'),
            isCancel: false
        };
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ statusClass: nextProps.facture.status === 'payé' ? 'factStatus first' :( nextProps.facture.status === 'non payé'? 'factStatus second': 'factStatus third') });  
    }

    showDetails = () => {
        let bool = this.state.isDetailsShown 
        this.setState({
        isDetailsShown: bool? false :true
        })
        
    }
    changeColorSubTask = (item) =>{
      if(item == 'done')
        return 'text-success'
      if(item == 'started')
        return 'text-warning'
      return 'text-muted'
    }
    
    showSetStatus = (b) =>{
      if(this.props.facture.status ==='payé'){
        return
      }
      this.setState({
        isStatusShown: b
      })
    }

    changeStatus = () => {
      
      // const formData = new FormData(this.clientForm);
      // let data = {};
      // for (var [key, value] of formData.entries()) data[key] = value;
      
      api.post('/api/facture/setStatus/'+this.props.facture.id,this.state.data).then(result =>{
        
        if(result.data.success){
          this.setState({
            isStatusShown:false,
            error:''
          })
          window.location.reload(false);
          
        }else{
          this.setState({error: result.data.error})
          console.log('error'+ result.data.error)
        }
      })
    }
    updateData = (event,name) =>{
      console.log(event)
      this.setState({
        data:{
          ...this.state.data,
          [name]: event.target ? event.target.value : event.value
        }
      });
    }

    getDocument = (action) => {
      let url = CONSTANTS.REMOTE_API + 'File/';
      if(action == 'facture' && this.props.facture.file){
          url += 'Facture/' + this.props.facture.file;
          window.open(url, '_blank').focus();
      }
    }

    numberDisplay = (nbr) => {
      let number = parseInt(nbr);
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(number)
    }

    cancelBill = () =>{

      api.post('/api/facture/cancel/'+this.props.facture.id).then(result =>{
        
        if(result.data.success){
          this.setState({
            isCancel:false,
            error:''
          })

          let url = CONSTANTS.REMOTE_API + 'File/';
          url += 'Facture/' + result.data.file;
          window.location.reload(false);
          window.open(url, '_blank').focus();
          
        }else{
          this.setState({error: result.data.error})
          console.log('error'+ result.data.error)
        }
      })
    }

    render(){
      console.log('partially'+this.props.facture.if_partially)
        return(
                
                <div data-row-index={this.props.idx} className="row task-row no-gutters py-3">
                  <div className="col-md-12">
                    <div className="row table-row">
                      <div className="col-md-2">
                        {this.props.facture.code} <br/>
                        {this.props.facture.date_facturation &&  this.props.facture.date_facturation }
                      </div>

                      <div className="col-md-2">
                        {this.props.facture.client_name} <br/>
                        {this.props.facture.client_username && this.props.facture.client_username}
                      </div>

                      <div className="col-md-2">
                        {this.props.facture.type}
                        
                      </div>

                      <div className="col-md-2">
                        <div className={this.state.statusClass}>
                          {this.props.facture.status}
                        </div>
                        <div style={{textAlign: 'center'}}>
                          {this.props.facture.if_partially && this.props.facture.status !='payé' && '('+this.props.facture.if_partially+' DZD)'}
                        </div>
                        <div style={{textAlign: 'center'}}>
                          {this.props.facture.date_payment && this.props.facture.date_payment}
                        </div>
                        
                        
                          
                        
                      </div>

                      <div className="col-md-1">
                        {this.numberDisplay(this.props.facture.montant)} DZD
                      </div>

                      
                      <div className="col-md-3 text-right">
                        <div class="row" >
                          {/* <div class="col-md-2"></div> */}
                          <div class="col">

                            <div class="btn-group dropleft">
                                  <button type="button" class="btn btn-outline dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      Plus
                                  </button>
                                  <div class="dropdown-menu">
                                    {(this.props.user.role === "manager" || this.props.user.role === "sup ops"|| this.props.user.role === "superviseur") && 
                                      <button type="button" class="btn " onClick={e=> this.showSetStatus(true)}><FontAwesomeIcon icon={faHandHoldingUsd} />Modifier Status</button>
                                    }
                                    {(this.props.user.role === "manager" || this.props.user.role === "sup ops"|| this.props.user.role === "superviseur") && this.props.facture.type.includes("Avoir") == false && <button type="button" class="btn" onClick={e => this.setState({isCancel: true})}>
                                        <FontAwesomeIcon   icon={faTimes}  size="1x"/>Facture d'avoir
                                    </button>}
                                    <button type="button" class="btn" onClick={e => this.getDocument('facture')}>
                                        <FontAwesomeIcon   icon={faFileInvoice}  size="1x"/> Facture.pdf
                                    </button>

                                    
                                  </div>
                            </div>
                            {!this.state.isDetailsShown?
                                <button type="button" class="btn " onClick={e => this.showDetails()}><FontAwesomeIcon  className={"ml-2"} icon={faAngleDown} /> Détails</button>  
                                :
                                <button type="button" class="btn " onClick={e=> this.showDetails()}><FontAwesomeIcon className={"ml-2"} icon={faAngleUp} />Moins de details</button>
                            }
                          </div>
                        </div>


                       

                        {/* <div class="row">
                          <div class="col-md-2"></div>
                          <div class="col-md-4">
                            Détails
                          </div>
                          <div class="col-md-4">
                            Modifier Status
                          </div>
                          <div class="col-md-2"></div>
                        </div> */}
                       
                      </div>
                    </div>
                    
                   {this.state.isDetailsShown? 
                    <div className={""}>
                      <FacturesDetail bordereaux={this.props.facture.bordereaux} payment={this.props.facture.payment}/>
                    </div>
                    :
                    ""
                    }
                  </div>


                  <Modal show={this.state.isStatusShown} centered>
                    <Modal.Header >
                      <Modal.Title>Modifier le Status de la facture {this.props.facture.code}</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      <form >
                        <p class="text-center"><b>Selectionner le status</b></p>
                        <Select
                          classNamePrefix={"custom-select"}
                          name="status"
                          options={status}
                          cacheOptions={true}
                          onChange={e => this.updateData(e, 'status')}
                        />
                        
                        
                        {this.state.data.status == 3 && <div>
                          <p class="text-center"><b>Entrer le montant payé</b></p>
                          <input 
                            type="text"  
                            value={this.state.data.montant_part}  
                            name="montant_part"
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'montant_part')}
                            placeholder='Montant ...'
                          />
                        </div>}
                        <p class="text-center"><b>Selectionner la methode du paiement</b></p>
                        <Select
                          classNamePrefix={"custom-select"}
                          name="method"
                          options={this.props.data.methods}
                          cacheOptions={true}
                          onChange={e => this.updateData(e, 'method')}
                        />
                        <div>
                          <p class="text-center"><b>Entrer la référence du paiement </b></p>
                            <input 
                              type="text"
                              placeholder='Référence ...'  
                              value={this.state.data.reference}  
                              name='reference'
                              className={"form-control"}   
                              onChange={e => this.updateData(e, 'reference')}
                              
                            />
                        </div>
                      
                      </form>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e => this.showSetStatus(false)}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.changeStatus(e)}>
                        Confirmer
                      </button>
                      
                    </Modal.Footer>
                  </Modal>

                  <Modal show={this.state.isCancel} onHide={e => this.setState({isCancel: false})} centered>
                    <Modal.Header closeButton>
                      Generation Facture D'avoir &nbsp; <b> { this.props.facture.code} </b>
                    </Modal.Header>
                    <Modal.Body>
                      {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      Êtes-vous sur de vouloir annuler cette facture?
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e => this.setState({isCancel: false})}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.cancelBill()}>
                        Oui
                      </button>
                      
                    </Modal.Footer>
                  </Modal>
                </div>

                
        )
    }


}export default withNotifications(connect(
  mapStateToProps
) (FacturesRow) )
