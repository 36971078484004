import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import Select from "react-select";




const mapStateToProps = state => {
	return {
        user: state.user,
        auth: state.auth
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
        doneLoading: () => dispatch(doneLoading()),
        setAuth: e => dispatch(setAuthAction(e))
	}
}


class StatusSettings extends Component {


    constructor(props) {
        super(props);

        this.state = {
            status: [],
            rules: [],
            roles: [],
            autostart: false
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.changeFrom = this.changeFrom.bind(this);
        this.changeRoles = this.changeRoles.bind(this);
        this.toggleStart = this.toggleStart.bind(this);
    }

    componentDidMount() {

        const that = this;

        api.get('/api/settings/status?token='+this.props.user.token)
        .then(function(response) {
            if(response.data.success) {
                
                that.setState({
                    autostart: response.data.autostart,
                    status: response.data.status,
                    roles: response.data.roles
                })

            }

        })
    }

    toggleStart(e) {
        this.setState({
            autostart: e.target.checked
        })
    }

    saveChanges(e) {
        this.props.isLoading();

        const that = this;
        const data = {
            token: this.props.user.token,
            status: this.state.status,
            autostart: this.state.autostart
        }

        api.post('/api/settings/status/update', data)
        .then(function(response) {
            that.props.doneLoading();
            console.log(response.data)
            
        })

        console.log(this.state.status);

    }

    changeFrom(i, stat, e) {

        
        let status = Array.from( this.state.status);
        if(status[i].rules && status[i].rules.from) {
            
            status[i].rules.from = e.map(c => c.value);
        } else {
            status[i].rules = {
                ...status[i].rules,
                from: e.map(c => c.value)
            };
        }

        this.setState({
            status: status
        })
       
    }

    changeRoles(i, stat, e) {
        
        let status = Array.from( this.state.status);
        if(status[i].rules && status[i].rules.roles) {
            
            status[i].rules.roles = e.map(c => c.value);
        } else {
            status[i].rules = {
                ...status[i].rules,
                roles: e.map(c => c.value)
            };
        }

        this.setState({status: status})
    }

    render() {
        return (<React.Fragment>

            <div className={"container-header nmb"}>Status Settings</div>

            <div className={"row mt-3"}>


                <div className={"col-md-6"}>
                <div class="custom-control custom-checkbox clearfix">
                    <input onChange={this.toggleStart} type="checkbox" class="custom-control-input" id="autostart" checked={this.state.autostart} />
                    <label class="custom-control-label" for="autostart">Start task on accept</label>
                </div>

                <br />
                    {this.state.status.map((status, i) => (

                    <div className={"card mb-3"}>
                        <div className={"card-header"}>
                        Status : {status.label}

                        <button 
                        className={"btn btn-primary btn-sm float-right ml-1"} 
                        data-toggle="collapse" 
                        data-target={"#card_id_"+i}>
                        Toggle
                        </button>

                        </div>

                        <div className={"card-body collapse"} id={"card_id_"+i}>

                            <div className={"form-group"}>
                                <label>From status</label>
                                <Select 
                                value={status.rules && status.rules.from ? status.rules.from.map(x => ({
                                    label: this.state.status.filter(m => m.value === x)[0].label,
                                    value: x
                                })  ) : []}
                                onChange={e => this.changeFrom(i, status.value, e)}
                                options={this.state.status.filter(x => x.value !== status.value)}
                                isMulti 
                                />
                            </div>

                            <div className={"form-group"}>
                                <label>Roles</label>
                                <Select 
                                value={status.rules && status.rules.roles ? status.rules.roles.map(x => ({
                                    label: this.state.roles.filter(m => m.value === x)[0].label,
                                    value: x
                                })  ) : []}
                                onChange={e => this.changeRoles(i, status.value, e)}
                                options={this.state.roles}
                                isMulti 
                                />
                            </div>

                        </div>
                    </div>

                    ))}

                <input type="submit" value="Save" onClick={this.saveChanges} />
                                
                </div>
            </div>
        </React.Fragment>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusSettings)