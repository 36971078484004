import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import './ClientInterfaceStyle.css';
import { faCube,faBox, faFile, faFileInvoice} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
import * as CONSTANTS from "../../Utils/Constants";


const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  }


class FacturesInterfaceRow extends Component {

    constructor(props) {
        super(props);

        // this.props.isLoading({
        //   message: "Loading tasks",
        //   animate: true
        // });
    
        this.state = {
            factureIconColor:this.props.data.file? "#585858": "#D3D3D3",
        };
    }

    getDocument = (action) => {
        let url = CONSTANTS.REMOTE_API + 'File/';
        if(action == 'facture' && this.props.data.file){
            url += 'Facture/' + this.props.data.file;
            window.open(url, '_blank').focus();
        }
    }

    render(){
        return(
            
                            <div class="table-row-accueil">
                                <div class="col col-2">{this.props.data.code}</div>
                                {this.props.user.role === "commercial" && <div class="col col-1">{this.props.data.client_name}</div>}
                                <div class="col col-2" >{this.props.data.type}</div>
                                <div class="col col-2">{this.props.data.status}</div>
                                <div class="col col-2">{this.props.data.date_payment}</div>
                                <div class={this.props.user.role === "commercial" ?  "col col-1": "col col-2"}>{this.props.data.montant} DZD</div>
                                <div class="col col-2 text-center">
                                    <div >
                                        <button type="button" class="btn" onClick={e => this.getDocument('facture')}>
                                            <FontAwesomeIcon  className={""}   icon={faFileInvoice}  color={this.state.factureIconColor} size="2x"/>
                                        </button>
                                    </div>
                                    <div className="">
                                            Facture .pdf
                                        </div>
                                    {/* <div class="row">
                                        <div class="col-md-6">
                                            Bordereau
                                            
                                        </div>
                                        
                                    </div> */}
                                     
                                </div>
                            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(FacturesInterfaceRow));
  
