import React from 'react';
import {connect} from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import * as actions from "../../Redux/actions/index";
import api from '../../Utils/Api'
import { useTranslation, Trans } from "react-i18next";


const moment = require('moment');

const mapStateToProps = state => {
    return {
        auth: state.auth,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAuth: e => dispatch(actions.setAuthAction(e)),
        isLoading: e => dispatch(actions.startLoading(e)),
        doneLoading: e => dispatch(actions.doneLoading(e))
    }
}

class TaskHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            task: {}
        }

    }

    componentDidMount() {
        const that = this;
        api.get('/api/task/'+that.props.match.params.taskid+'?token='+that.props.user.token)
        .then(function(response) {
            that.props.doneLoading();
            that.setState({task: response.data.task, ready: true})
        });
    }

    render() {
        const task = this.state.task;
        if(!this.state.ready)
            return <React.Fragment>Loading</React.Fragment>


        return (<React.Fragment> 
            {!this.props.auth || !this.props.user || this.props.user.role === 'agent'  ? <Redirect to="/login" /> :  ""}
            <div className={"container-header"}>
				Task history
			</div>

            <div className={"animateRow row"}>
				
                <div className={"col-md-8"}>
                <ul className={"timeline"}>
                {task.task_history.map((x, i) => (
                    <li key={i}>

                        <span className={"task-status status-id-"+x.history_status_id} ><Trans i18nKey={"status_"+x.history_status.toLowerCase()} /></span>
                        
                        <div className={"history-info"}>
                            <span style={{marginRight:'5px'}} title={x.history_date}>{moment(x.history_date).fromNow().toString()}</span>
                            <b>•</b>
                            <span style={{marginLeft:'5px'}}>{x.history_by && (x.history_by)} {x.history_image ? (<React.Fragment><b> • </b><a href={"https://salim.tictechs.tech/"+x.history_image} target="_blank"><span className={"history-attachment"}>View attachment</span> <img className={"history-thumbnail"} src={"https://salim.tictechs.tech/"+x.history_image} /></a></React.Fragment>) : ""}</span>
                            <br />
                            {x.history_note ? (<span className={"history-note"}>Note : {x.history_note}</span>) : ""}
                        </div>

                    </li>
                    ))}
                    
                </ul>
                </div>
            </div>


            

        </React.Fragment>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskHistory);