import React from "react"


import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faBoxes, faFileInvoiceDollar, faDonate, faHandPointLeft ,faCaretUp, faCaretDown, faPercent, faMoneyBill, faHandHoldingUsd} from '@fortawesome/free-solid-svg-icons'
import api from '../../Utils/Api';
import { Trans } from "react-i18next";


import {  startLoading, doneLoading } from '../../Redux/actions/index';
import Select from "react-select";
import { Item } from "../../Utils/MoreInfoDialog";
import { Link } from "react-router-dom";
import CanvasJSReact from "../../Canvas/canvasjs.react";
import Modal from 'react-bootstrap/Modal';
import { Button, Container} from 'react-bootstrap';
import {ExportCSV}  from '../Rapport/ExportCSV';
import dateFormat from 'dateformat';
import DatePicker from "react-datepicker";

const moment = require("moment");

const mapStateToProps = state => {
	return {
		user: state.user
	}
} 

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading(e))
	}
}

class ManagerDashboardV2 extends React.Component {


	constructor(props) {
    super(props);
    // const currentMonth = new Date().getMonth() + 1; // Get the current month (1-12)
    // this.state = {
    //   selectedMonth: currentMonth.toString().padStart(2, '0'), // Initialize with the current month in MM format
    // };

		this.state = {
			delaisPick: {},
			delaisDeliv: {},
			delaisTotal: {},
			bordereau: {},
			factures: {},
      collect: {},
      collects: {},
			bordereauLast2month: {},
			facturesLast2month: {},
			collectLast2month: {},
			remis: {},
      isReady: false,
      total_commission : 0,
      prev_total_commission : 0,
      facture_divers_total : 0,
      prev_facture_divers_total : 0,
      facture_divers: {},
      prev_facture_divers : {},
      total_collect : 0,
      prev_total_collect : 0,
      commission : {},
      prev_commission : {},
      collects_trace : {},
      total_remise : 0,
      prev_total_remise : 0,
      remise : {},
      cache : {},
      selectedDate: new Date(),
		}

		
		props.isLoading();
	}

	componentDidMount() {
		
    const that = this;

    // Get the current month and set it as the selectedMonth state
    var todaydate = new Date();
    var currentMonth = todaydate.getMonth() + 1; // Get the current month (1-12)
    currentMonth = currentMonth.toString().padStart(2, '0');
    var currentYear = todaydate.getFullYear().toString();
    console.log(currentYear);
    console.log(currentMonth);
    this.setState({ selectedMonth: currentMonth });
    this.setState({ selectedDate: todaydate });
    this.setState({ selectedYear: todaydate.getFullYear() });

    api.get('/api/V2/user/'+this.props.user.userid+'/month/'+currentMonth+'/year/'+currentYear+'/dashboard?token='+this.props.user.token)
		.then(function(response) {
			if(response.data.success){
				that.setState({
					delaisPick: response.data.delaisPick,
					delaisDeliv: response.data.delaisDeliv,
					delaisTotal: response.data.delaisTotal,
					bordereau: response.data.bordereau,
					factures: response.data.factures,
          collect: response.data.collect,
					collects: response.data.collects,
					bordereauLast2month: response.data.bordereauLast2month,
					facturesLast2month: response.data.facturesLast2month,
					collectLast2month: response.data.collectLast2month,
					remis : response.data.remis,
          isReady: true,
          total_commission : response.data.total_commission,
          facture_divers_total : response.data.facture_divers_total,
          facture_divers : response.data.facture_divers,
          total_collect : response.data.total_collect,
          commission : response.data.commission,
          collects_trace: response.data.collects_trace,
          prev_commission : response.data.prev_commission,
          prev_total_collect : response.data.prev_total_collect,
          prev_facture_divers : response.data.prev_facture_divers,
          prev_facture_divers_total : response.data.prev_facture_divers_total,
          prev_total_commission : response.data.total_prev_commission,
          total_remise : response.data.total_remise,
          prev_total_remise : response.data.prev_total_remise,
          remise : response.data.remise,
          cache : response.data.cache
				})
				
			}
			that.props.doneLoading()		
				
		})
    }

  handleDateChange = (e) => {

		const that = this;
    that.props.isLoading();
    this.setState({selectedDate : e});
    //const selectedMonth = e.target.value;
    const selectedYear = e.getFullYear();
    const selectedMonth = e.getMonth() + 1;
    this.setState({ selectedMonth });

    api.get('/api/V2/user/'+this.props.user.userid+'/month/'+selectedMonth+'/year/'+selectedYear+'/dashboard?token='+this.props.user.token)
		.then(function(response) {
			if(response.data.success){
				that.setState({
					delaisPick: response.data.delaisPick,
					delaisDeliv: response.data.delaisDeliv,
					delaisTotal: response.data.delaisTotal,
					bordereau: response.data.bordereau,
					factures: response.data.factures,
          collect: response.data.collect,
					collects: response.data.collects,
					bordereauLast2month: response.data.bordereauLast2month,
					facturesLast2month: response.data.facturesLast2month,
					collectLast2month: response.data.collectLast2month,
					remis : response.data.remis,
          isReady: true,
          total_commission : response.data.total_commission,
          prev_total_commission : response.data.total_prev_commission,
          facture_divers_total : response.data.facture_divers_total,
          prev_facture_divers_total : response.data.prev_facture_divers_total,
          facture_divers : response.data.facture_divers,
          prev_facture_divers : response.data.prev_facture_divers,
          total_collect : response.data.total_collect,
          prev_total_collect : response.data.prev_total_collect,
          commission : response.data.commission,
          prev_commission : response.data.prev_commission,
          collects_trace: response.data.collects_trace,
          total_remise : response.data.total_remise,
          prev_total_remise : response.data.prev_total_remise,
          remise : response.data.remise,
          cache : response.data.cache


				})
				
			}
			that.props.doneLoading()		
				
		})

    }



  render() {

		var today = new Date(),
		now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
		var curDateMonth = new Date();
        var prvDateMonth = new Date()
        prvDateMonth.setMonth(prvDateMonth.getMonth() - 1)

		var CanvasJS = CanvasJSReact.CanvasJS;
		var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		const mapPick = Object.entries(this.state.delaisPick).map(([key,val]) =>({
			y: val,
			//label: 'Zone '+key
			label: key
		}))
		const mapDeliv = Object.entries(this.state.delaisDeliv).map(([key,val]) =>({
			y: val,
			//label: 'Zone '+key
			label: key
		}))
		const mapTotal = Object.entries(this.state.delaisTotal).map(([key,val]) =>({
			y: val,
			//label: 'Zone '+key
			label: key
		}))


		
		CanvasJS.addColorSet("fireShades",
				[
					"#ffb703",
          "#023047",
          "#FF1CAE"
				]);

		const delaisPick = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais d’enlèvement/ jours"
			},
			legend: {
				fontSize: 15
			},
			data: [{
				showInLegend: true,
				legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				type: "column",
				dataPoints: mapPick,
			}]
		 }

		const delaisDeliv = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais de livraison/ jours"
			},
			legend: {
				fontSize: 15
			},
			data: [{
				showInLegend: true,
				legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				type: "column",
				dataPoints: mapDeliv,
			}]
		}
		
		const delaisTotal = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais de livraison/ jours",
				
			},
			legend: {
				fontSize: 15
			},
			data: [{
				showInLegend: true,
				fontSize: 15,
				legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				type: "column",
				dataPoints: mapTotal,
			}]
		 }

		 const bordereau = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais de livraison/ jours"
			},

			subtitles: [{
				text: this.state.bordereau.total +" Bordereau au total",
				fontSize: 15,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			legend: {
				fontSize: 15
			},
			data: [{
				showInLegend: true,
				//legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				type: "pie",
				legendText: "{label}",
				dataPoints: [
					{ label: "Pending",  y:  this.state.bordereau.total -  this.state.bordereau.fait },
					{ label: "Fait", y: this.state.bordereau.fait },
					{ label: "Retour", y: this.state.bordereau.retour },
				],
			}]
		 }
		 const factures = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais de livraison/ jours"
			},

			subtitles: [{
				text: this.state.factures.total +" Factures au total",
				fontSize: 15,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			legend: {
				fontSize: 15
			},
			
			data: [{
				showInLegend: true,
				//legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				legendText: "{label}",
				type: "pie",
				dataPoints: [
					{ label: "Payé",  y:  this.state.factures.payed },
					{ label: "Non payé", y:this.state.factures.total -  this.state.factures.payed },
				]
				
			}]
		 }
		 const collect = {
			colorSet: "fireShades",
			backgroundColor:'transparent',
			
			height:250,
			axisY: {
				title: "Délais de livraison/ jours"
			},
			
			subtitles: [{
				text: this.state.collect.total +" Collect au total",
				fontSize: 15,
				fontFamily: 'Calibri',
				dockInsidePlotArea: true
			}],
			legend: {
				fontSize: 15
			},
			data: [{
				showInLegend: true,
				//legendText: "du "+dateFormat(prvDateMonth.toString(), "dd mmmm") +" au "+dateFormat(curDateMonth.toString(), "dd mmmm"),
				legendText: "{label}",
				fontSize: 15,
				type: "pie",
				dataPoints: [
					{ label: "Collecté",  y:  this.state.collect.total -  this.state.collect.due },
					{ label: "Due", y: this.state.collect.due },
				],
			}]
     }

    const datePickerStyle = {
      width: '100px', // Set your desired width for the date picker container
    };

        return <div>
            <div className={"container-header nmb"}>
              <h3>Dashboard</h3>
              {/* <div className="month-dropdown"> */}
              {/* <label className="small-label">Select a month:</label> */}
              {/* <select className="small-dropdown" onChange={this.handleMonthSelect} value={this.state.selectedMonth}> */}
              {/* <option value="">Select a month</option> */}
              {/* <option value="01">Jan</option> */}
              {/* <option value="02">Feb</option> */}
              {/* <option value="03">Mar</option> */}
              {/* <option value="04">Apr</option> */}
              {/* <option value="05">May</option> */}
              {/* <option value="06">June</option> */}
              {/* <option value="07">July</option> */}
              {/* <option value="08">Aug</option> */}
              {/* <option value="09">Sep</option> */}
              {/* <option value="10">Oct</option> */}
              {/* <option value="11">Nov</option> */}
              {/* <option value="12">Dec</option> */}
            {/* </select> */}
            {/* </div> */}

          </div>

          <DatePicker
            className="custom-date-picker"
            selected={this.state.selectedDate}
            //onChange={(date) => this.setState({ selectedDate: date })}
            onChange={this.handleDateChange}
            style={datePickerStyle} // Apply the style here
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            showFourColumnMonthYearPicker
          />



		 	<div className = "row">
				<div className='col-md-12'>
					<div className='row'>
						<div className = "col-md-4 p-4">
						<div className="ui-rect np">
              {/* First Row */}
              <div className="row align-items-center">
                <div className="col-md-2 text-center">
                  {/* Icon at a smaller size */}
                  <div className="dot" style={{ backgroundColor: '#DD3B62' }}>
                    <FontAwesomeIcon icon={faBoxes} color="white" size="lg" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Heading on the left */}
                  <h4>Bordereaux</h4>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {/* Export button on the right */}
                  {/* <button className="btn btn-primary">Export</button> */}
                  {/* <ExportCSV csvData={this.state.bordereau.total} fileName={'bordereaux' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} /> */}
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                <div className="col-md-12">
                  {/* Text lines */}
                  <b style={{ fontSize: '15px' }}>{this.state.bordereau.total} Bordereaux ce mois</b><br />
                  {this.state.bordereauLast2month.total - this.state.bordereau.total >= 0 && (
                    <div className="text-danger">
                      <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                      {this.state.bordereauLast2month.total - this.state.bordereau.total} Moins que le mois passé
                    </div>
                  )}
                  {this.state.bordereauLast2month.total - this.state.bordereau.total < 0 && (
                    <div className="text-success">
                      <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                      {-(this.state.bordereauLast2month.total - this.state.bordereau.total)} Plus que le mois passé
                    </div>
                  )}
                </div>
              </div>
            </div>

						</div>
						<div className = "col-md-4 p-4">
						<div className="ui-rect np">
              {/* First Row */}
              <div className="row align-items-center">
                <div className="col-md-2 text-center">
                  {/* Icon at a smaller size */}
                  <div className="dot" style={{ backgroundColor: '#F7B924' }}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} color="white" size="lg" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Heading on the left */}
                  <h4>Factures</h4>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {/* Export button on the right */}
                  {/* <button className="btn btn-primary">Export</button> */}
                  {/* <ExportCSV csvData={this.state.factures.total} fileName={'factures' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} /> */}
                  <ExportCSV csvData={this.state.cache} fileName={'cache' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} buttonLabel="cache" />

                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                <div className="col-md-12">
                  {/* Text lines */}
                  <b style={{ fontSize: '15px' }}>{this.state.factures.total} Factures ce mois</b><br />
                  {this.state.facturesLast2month.total - this.state.factures.total  >= 0 && (
                    <div className="text-danger">
                      <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                      {this.state.facturesLast2month.total - this.state.factures.total } Moins que le mois passé
                    </div>
                  )}
                  {this.state.facturesLast2month.total - this.state.factures.total  < 0 && (
                    <div className="text-success">
                      <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                      {-(this.state.facturesLast2month.total - this.state.factures.total )} Plus que le mois passé
                    </div>
                  )}
                </div>
              </div>
            </div>

						</div>
						<div className = "col-md-4 p-4">
						<div className="ui-rect np">
              {/* First Row */}
              <div className="row align-items-center">
                <div className="col-md-2 text-center">
                  {/* Icon at a smaller size */}
                  <div className="dot" style={{ backgroundColor: '#4ECA8A' }}>
                    <FontAwesomeIcon icon={faDonate} color="white" size="lg" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Heading on the left */}
                  <h4>Collecte</h4>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {/* Export button on the right */}
                  <ExportCSV csvData={this.state.collects} fileName={'collect' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} />
                  &nbsp;
                  <ExportCSV csvData={this.state.collects_trace} fileName={'collect_trace' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} buttonLabel="Trace" />
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                <div className="col-md-12">
                  {/* Text lines */}
                  <b style={{ fontSize: '15px' }}>{this.state.total_collect} DZD</b><br />
                  {this.state.prev_total_collect - this.state.total_collect >= 0 && (
                    <div className="text-danger">
                      <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                      {this.state.prev_total_collect - this.state.total_collect } Moins que le mois passé
                    </div>
                  )}
                  {this.state.prev_total_collect - this.state.total_collect < 0 && (
                    <div className="text-success">
                      <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                      {-(this.state.prev_total_collect - this.state.total_collect )} Plus que le mois passé
                    </div>
                  )}
                </div>
              </div>
            </div>

						</div>
			    </div> 

				</div>

      </div>

      <div className = "row">
				<div className='col-md-12'>
					<div className='row'>
						<div className = "col-md-4 p-4">
						<div className="ui-rect np">
                {/* First Row */}
                <div className="row  align-items-center">
                  <div className="col-md-2 text-center">
                    {/* Icon at a smaller size */}
                    <div className="dot" style={{ backgroundColor: '#DD3B62' }}>
                      <FontAwesomeIcon icon={faPercent} color="white" size="lg" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Heading on the left */}
                    <h4>Commission</h4>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end">
                    {/* Export button on the right */}
                    {/* <button className="btn btn-primary">Export</button> */}
					          <ExportCSV csvData={this.state.commission} fileName={'commission' + now} classBtn = {'btn btn-md mt-4 btn-outline-success '}/>
                  </div>
                </div>

                {/* Second Row */}
                <div className="row">
                  <div className="col-md-12">
                    {/* Text lines */}
                    <b style={{ fontSize: '15px' }}>{this.state.total_commission}</b><br />
                    {this.state.prev_total_commission - this.state.total_commission >= 0 && (
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                        {this.state.prev_total_commission - this.state.total_commission } Moins que le mois passé
                      </div>
                    )}
                    {this.state.prev_total_commission - this.state.total_commission  < 0 && (
                      <div className="text-success">
                        <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                        {-(this.state.prev_total_commission - this.state.total_commission)} Plus que le mois passé
                      </div>
                    )}
                  </div>
                </div>
              </div>

						</div>
						<div className = "col-md-4 p-4">
						<div className="ui-rect np">
              {/* First Row */}
              <div className="row align-items-center">
                <div className="col-md-2 text-center">
                  {/* Icon at a smaller size */}
                  <div className="dot" style={{ backgroundColor: '#F7B924' }}>
                    <FontAwesomeIcon icon={faMoneyBill} color="white" size="lg" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Heading on the left */}
                  <h4>Factures divers</h4>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {/* Export button on the right */}
                  {/* <button className="btn btn-primary">Export</button> */}
                  <ExportCSV csvData={this.state.facture_divers} fileName={'facture_divers' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} />
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                <div className="col-md-12">
                  {/* Text lines */}
                  <b style={{ fontSize: '15px' }}>{this.state.facture_divers_total}</b><br />
                  {this.state.prev_facture_divers_total - this.state.facture_divers_total >= 0 && (
                    <div className="text-danger">
                      <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                      {this.state.prev_facture_divers_total - this.state.facture_divers_total } Moins que le mois passé
                    </div>
                  )}
                  {this.state.prev_facture_divers_total - this.state.facture_divers_total < 0 && (
                    <div className="text-success">
                      <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                      {-(this.state.prev_facture_divers_total - this.state.facture_divers_total )} Plus que le mois passé
                    </div>
                  )}
                </div>
              </div>
            </div>


						</div>
						<div className = "col-md-4 p-4">
							<div className="ui-rect np">
              {/* First Row */}
              <div className="row align-items-center">
                <div className="col-md-2 text-center">
                  {/* Icon at a smaller size */}
                  <div className="dot" style={{ backgroundColor: '#4ECA8A' }}>
                    <FontAwesomeIcon icon={faHandHoldingUsd} color="white" size="lg" />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Heading on the left */}
                  <h4>Remise</h4>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                  {/* Export button on the right */}
                  {/* <button className="btn btn-primary">Export</button> */}
                  {/* <ExportCSV csvData={this.state.collect.total} fileName={'remise' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} /> */}
                  <ExportCSV csvData={this.state.remise} fileName={'remise' + now} classBtn={'btn btn-md mt-4 btn-outline-success'} />
                  &nbsp;
                </div>
              </div>

              {/* Second Row */}
              <div className="row">
                <div className="col-md-12">
                  
                  {/* {/2* Total *2/} *1/} */}
                  <b style={{ fontSize: '15px' }}>{this.state.total_remise} DZD</b> <br />
                  {/* Conditional Text */}
                  {(this.state.prev_total_remise - this.state.total_remise) >= 0 && (
                    <div className="text-danger">
                      <FontAwesomeIcon icon={faCaretDown} size="1x" />{' '}
                      {(this.state.prev_total_remise - this.state.total_remise)} Moins que le mois passé
                    </div>
                  )}
                  {(this.state.prev_total_remise - this.state.total_remise) < 0 && (
                    <div className="text-success">
                      <FontAwesomeIcon icon={faCaretUp} size="1x" />{' '}
                      {-(this.state.prev_total_remise - this.state.total_remise)} Plus que le mois passé
                    </div>
                  )}

                </div>
              </div>
            </div>


						</div>
	        </div> 

				</div>

			</div>


			<div className={"ui-rect np"}>

			
			<div className={"row pb-5"}>

                

				<div className={"col-md-4"}>

					<span className={"dashboard-title"}>Délais d’enlèvement </span>
					<div className={"row"}>
						
						<CanvasJSChart options = {delaisPick} />
					</div>
				</div>


				<div className={"col-md-4 "}>
					<span className={"dashboard-title"}>Délais de livraison</span>

					<div className={"row"} >
						<CanvasJSChart options = {delaisDeliv} />
					</div>

				</div>

				<div className={"col-md-4 "}>
					<span className={"dashboard-title"}>Délais total</span>

					<div className={"row"} >
						<CanvasJSChart options = {delaisTotal} />
					</div>

				</div>
				
				

			</div>
			

			

			</div>
			<div className="ui-rect np  my-3">
							<div className="row pb-5">
								<div className="col-md-4">
									<span className="dashboard-title">Bordereau</span>
										<CanvasJSChart options={bordereau} />
									
								</div>

								<div className="col-md-4 ">
									<span className="dashboard-title">Factures</span>
									<div className="row">
											<CanvasJSChart options={factures} />
										
									</div>
									
								</div>
								<div className="col-md-4 ">
									<span className="dashboard-title">Collect</span>
									<div className="row">
											<CanvasJSChart options={collect} />
										
									</div>
									
								</div>
							</div>
			</div>



        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboardV2)
