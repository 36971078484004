import React, { Component } from 'react';
import { connect, connectAdvanced } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { useTranslation, Trans } from "react-i18next";
import { faAngleDown,faAngleUp, faCoins} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';

class AgentCollectRow extends Component {

	constructor(props){
        super(props)

		

        this.state = {
			isStatusShown: false,
			data:{
				collect: '',
			},
		}
        
    }

	showSetStatus = (b) =>{
		this.setState({
		  isStatusShown: b
		})
	  }
  
	  changeStatus = () => {
		
		// const formData = new FormData(this.clientForm);
		// let data = {};
		// for (var [key, value] of formData.entries()) data[key] = value;
		
		api.post('/api/bordereau/'+ this.props.info.bordereau_id + '/collect',this.state.data).then(result =>{
		  
		  if(result.data.success){
			this.setState({
			  isStatusShown:false
			})
			window.location.reload(false);
			
		  }else{
			this.setState({error: result.data.error})
			console.log('error'+ result.data.error)
		  }
		})
	  }

	updateData = (event,name) =>{
	console.log(event)
	this.setState({
		data:{
		...this.state.data,
		[name]: event.target ? event.target.value : event.value
		}
	});
	}

	
    render() {
        return <div>
			<div className={"row act-row"}>

				<div className={"col-md-4"}>
					{this.props.info.code}
				</div>

				<div className={"col-md-4"}>
					{this.props.info.collect} DZD
				</div>

				<div className={"col-md-2"}>
					{this.props.info.due ?this.props.info.due : this.props.info.collect } DZD
				</div>

				<div className={"col-md-2"}>
					<button type="button" class="btn " onClick={e=> this.showSetStatus(true)}><FontAwesomeIcon className={"ml-2"} icon={faCoins} color="#E5C100 "/>Collecter</button>
				</div>

			</div>

			<Modal show={this.state.isStatusShown} centered>
                    <Modal.Header >
                      <Modal.Title>Modifier la collect de  {this.props.info.code}</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      <form >
                        
                          <p class="text-center"><b>Entrer le montant collecté par l'agent</b></p>
                          <input 
                            type="text"
                            name="collect"
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'collect')}
                          />
                      
                      </form>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e => this.showSetStatus(false)}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.changeStatus(e)}>
                        Collecter
                      </button>
                      
                    </Modal.Footer>
                  </Modal>

        </div>
    }
}

export default AgentCollectRow