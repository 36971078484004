import React from "react";
import { Link } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import * as CONSTANTS from "./../../../Utils/Constants";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import { connect } from "react-redux";
import TaskDetailInline from "./TaskDetailInline";
import TaskEditInline from "./TaskEditInline";
import Modal from 'react-bootstrap/Modal';
import api from "../../../Utils/Api";
import Alert from 'react-bootstrap/Alert'


class TaskRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: {},
      showDetails: !!this.props.show,
      showPhotos: false,
      selectedIdx: false,
      edit: false,
      showModel: false,
      showModelUndo:false,
      variant: 'success',
      message: '',
      isAlert:false,
      data :{
        comment : '',
      },
    };
  }

  toggleTask = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  showPhotos = idx => this.setState({ showPhotos: true, selectedIdx: idx });


  hidePhotos = () => this.setState({ showPhotos: false, selectedIdx: false });


  changeColorBadge = (item) =>{
    if(item == 1){
      return "badge badge-success badge-pill";
    }else if(item == 0){
      return "badge badge-danger badge-pill";
    }
    return  "badge badge-light badge-pill";
  }
  changeColorBadgeDoc = (item) =>{
    if(item == 'true'){
      return "badge badge-success badge-pill";
    }
      return "badge badge-danger badge-pill";
    
  }
  changeColorSubTask = (item) =>{
    if(item == 'done')
      return 'text-success'
    if(item == 'started')
      return 'text-warning'
    return 'text-muted'
  }

  DoneTask = (taskid) => {
    api.post('/api/task/done/'+taskid).then(result => {
      if(result.data.success){
        
        this.setState({
          isAlert:true,
          message: 'Action Done Successfully',
        })
        setTimeout(() => {
          this.setState({
            showModel: false
          })
        }, 3000);
        
        window.location.reload(false);
      }else{
        this.setState({
          isAlert:true,
          message: result.data.error,
          variant:'danger'
        })

      }
    })
  }

  updateData = (event,name) =>{
    console.log(event)
    this.setState({
      data:{
        ...this.state.data,
        [name]: event.target ? event.target.value : event.value
      }
    });
  }


  addComment = (taskid) => {
     let data = {
      comment: this.state.data.comment,
    }

    api.post('/api/task/addComment/'+taskid,data).then(result => {
      if(result.data.success){
        
        this.setState({
          isAlert:true,
          message: 'Action Done Successfully',
        })
        setTimeout(() => {
          this.setState({
            showModel: false
          })
        }, 3000);
        
        window.location.reload(false);
      }else{
        console.log(result.data.message);
        this.setState({
          isAlert:true,
          message: result.data.error,
          variant:'danger'
        })

      }
    })
  }



  UndoTask = (taskid) => {
    api.post('/api/task/undo/'+taskid).then(result => {
      if(result.data.success){
        
        this.setState({
          isAlert:true,
          message: 'Action Done Successfully',
        })
        setTimeout(() => {
          this.setState({
            showModel: false
          })
        }, 3000);
        
        window.location.reload(false);
      }else{
        this.setState({
          isAlert:true,
          message: result.data.error,
          variant:'danger'
        })

      }
    })
  }

  render() {
    const { task, idx, toggleOverlay } = this.props;

    const points = Object.keys(task.subtasks).filter(item => item !== "a_-1" && item !== "a_999");

    const isFuture =
      Boolean(task.subtasks["a_-1"]) &&
      parseInt(moment(task.subtasks["a_-1"].subtask_start_datetime).format("D")) > parseInt(moment().format("D"));
    const isPast =
      Boolean(task.subtasks["a_-1"]) &&
      parseInt(moment(task.subtasks["a_-1"].subtask_start_datetime).format("D")) < parseInt(moment().format("D"));

    
    const isDone = (task.subtasks["a_999"].subtask_status === 'done' && task.subtasks["a_-1"].subtask_status === 'done') ? true : false

    // const isFailed = (task.subtasks["a_999"].failed_flag === true || task.subtasks["a_-1"].failed_flag === true) ? true : false
    const showSubtaskType = (type) => {


      if(type)
        return String(type).substr(0, 1);


      return "";

    }  
    

    return (
      <>
        {this.state.showPhotos && (
          <>
            <div className={this.state.showPhotos ? "ui-overlay open" : "ui-overlay"}>
              <div className={"ui-overlay-content photos-overlay col-md-5"}>
                <button
                  data-package-id="0"
                  onClick={() => this.hidePhotos()}
                  className={"ui-overlay-close btn btn-danger btn-sm"}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>

                <div className={"task-photos-wrap"}>
                  {task.subtasks[this.state.selectedIdx].subtask_photos &&
                    task.subtasks[this.state.selectedIdx].subtask_photos.map(photo => (
                      <div className={"task-photos-element"}>
                        <a href={CONSTANTS.REMOTE_API + "/" + photo} target={"_blank"}>
                          <img src={CONSTANTS.REMOTE_API + "/" + photo} />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        <React.Fragment>
          <div
            // onClick={this.toggleTask}
            //data-row-index={idx}
            // className={
            //   "row task-row no-gutters " +
            //   (!!this.state.showDetails && " active-row ") +
            //   (isFuture && " future-task ") +
            //   (isPast && " past-task ")
            // }
          >
              <div className={"col-md-12"}>
                  <div className={"row table-row mt-2"}>
                    {/* ----------------to display Date --------------- */}
                    <div className={"col-md-1"}>
                      {!!task.task_created_at && moment(task.task_created_at).format("DD/MM/YY")}<br/>
                      {task.task_name}
                    </div>

                    {/* -----------------to display Code ------------ */}
                    {/* <div className={"col-md-2"}> {task.task_name} </div> */}

                    {/*------------- to display Client  ------------*/}
                    <div className={"col-md-2"}>
                      {/* <b>{task.task_client_name} </b>  */}
                    </div>

                    {/*---------- to display Trajet  ------------*/}
                    
                    <div className="col-md-1">
                      <span class={this.changeColorSubTask(task.subtasks["a_-1"].subtask_status)}>{!!task.subtasks["a_-1"] && <b>{task.subtasks["a_-1"].subtask_address_commune} </b>}</span>
                    </div>
                    <div className="col-md-1">
                                {points.map((point, k) => (
                                  <li key={k} class={this.changeColorSubTask(task.subtasks[point].subtask_status)}>
                                    {showSubtaskType(task.subtasks[point].subtask_type)} <b>{task.subtasks[point].subtask_address_commune}</b>
                                  </li>
                                ))}
                    </div>
                    <div className="col-md-1">
                      <span class={this.changeColorSubTask(task.subtasks["a_999"].subtask_status)}>{!!task.subtasks["a_999"] && <b>{task.subtasks["a_999"].subtask_address_commune}</b>}</span>
                    </div>

                    {/*------------- to display Type of Vehicule-------------  */}
                    {/* <div className={"col-md-1"}>{task.task_vehicule_type}</div> */}

                    {/* --------------to display Task hours --------------*/}
                    <div className={"col-md-2 agent-col"}>
                      {/* <span className={"task-hours"}>
                        Chr
                        <b>{!!task.task_heure_charge && moment(task.task_heure_charge).format("HH:mm")}</b>
                      </span>
                      <span className={"task-hours"}>
                        Dsp
                        <b>{!!task.task_heure_dispo && moment(task.task_heure_dispo).format("HH:mm")}</b>
                      </span> */}
                    </div>
                      
                      {/* --------------to display Ajouer par  ------------*/}
                    <div className={"col-md-1 "}>
                      {/* <span>{task.task_created_by}</span> */}
                    </div>
                      {/*------------ to display agent name and statut of task (assigned or not) ---------- */}
                    <div className={"col-md-1"}>
                      <span>
                        {task.task_agent && (
                          <b>
                            <Link to={"/users/agents/"+task.task_agent_id}>
                              {task.task_agent}
                            </Link>
                            <br />
                          </b>
                        )}
                      </span>
                      <span className={"task-status d-inline status-id-" + task.task_status_id}>
                        {String(task.task_status).toLowerCase()}
                      </span>

                      {task.status && (<span className={"task-status d-block pr-1"}>{task.status}</span>)}
                    </div>



                      {/*---------- to display arry of button DMDAD -------------- */}
                      <div className ={"col-md-1 text-right"} >
                        
                            
                              
                                <button className="btn btn-primary btn-block btn-sm" onClick={this.toggleTask} title="Détails">Détails</button>
                             
                              
                              
                                {((this.props.user.role === "manager" || this.props.user.role === "sup ops" || this.props.user.role === "superviseur" ||  this.props.user.role === "commercial")  && (!isDone)) && (
                                  <button className="btn btn-primary btn-block btn-sm mt-2 " onClick={() => toggleOverlay(task.task_id)} title="Assigner">
                                    {task.task_agent &&  "Réassigner"}
                                    {!task.task_agent &&  "Assigner"}
                                  </button>
                                )}

                                {((this.props.user.role === "manager" || this.props.user.role === "sup ops" || this.props.user.role === "superviseur" ||  this.props.user.role === "commercial")) && (
                                  <button className="btn btn-primary btn-block btn-sm mt-2 " onClick={e => this.setState({showModelComment: true})} title="Comment">
                                    Commenter
                                  </button>
                                )}

                              
                              
                                {!isDone && (this.props.user.role === "manager"  || this.props.user.role === "superviseur") && <button className="btn btn-primary btn-block btn-sm mt-2 "  onClick={e => this.setState({showModel: true})} title="Détails">Done</button>}
                                {isDone && (this.props.user.role === "manager"  || this.props.user.role === "superviseur") && <button className="btn btn-primary btn-block btn-sm mt-2 "  onClick={e => this.setState({showModelUndo: true})} title="Détails">Undo</button>}

                              
                      </div>
                      {/* -----------Retour----------- */}
                     
                      
                  </div>

                  <TaskDetailInline showPhotos={this.showPhotos} task={task} active={this.state.showDetails} />
                  {this.state.edit && (
                    <TaskEditInline close={() => this.setState({ edit: false })} task={task} active={this.state.edit} />
                  )}
              </div>


              <Modal show={this.state.showModel} centered>
                <Modal.Header >
                  <Modal.Title>Terminer la tache {task.task_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Voulez vous vraiment changer le status de la tache
                  {this.state.isAlert && <Alert variant={this.state.variant}>
                    {this.state.message}
                  </Alert>}
                 
                </Modal.Body>
                <Modal.Footer>
                  <button className={"btn btn-danger"}  onClick={e => this.setState({showModel: false})}>
                    Annuler
                  </button>
                  <button type='submit' className={"btn btn-success"}  onClick={e => this.DoneTask(task.key)}>
                    Confirmer 
                  </button>
                </Modal.Footer>
              </Modal>

              <Modal show={this.state.showModelComment} centered>
                <Modal.Header >
                  <Modal.Title>Ajouter un commentaire pour la tache {task.task_name}</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body> */}
                {/*   Voulez vous vraiment changer le status de la tache */}
                {/*   {this.state.isAlert && <Alert variant={this.state.variant}> */}
                {/*     {this.state.message} */}
                {/*   </Alert>} */}
                 
              {/* </Modal.Body> */}

               <Modal.Body>
                    {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      <form >
                          <p class="text-center"><b>Entrer votre commentaire</b></p>
                          <input 
                            type="text"
                            name="commentaire"
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'comment')} 
                          />
                      </form>
                    </Modal.Body>
                <Modal.Footer>
                  <button className={"btn btn-danger"}  onClick={e => this.setState({showModelComment: false})}>
                    Annuler
                  </button>
                  <button type='submit' className={"btn btn-success"}  onClick={e => this.addComment(task.key)}>
                    Confirmer 
                  </button>
                </Modal.Footer>
              </Modal>



              <Modal show={this.state.showModelUndo} centered>
                <Modal.Header >
                  <Modal.Title>Annuler la tache {task.task_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Voulez vous vraiment changer le status de la tache
                  {this.state.isAlert && <Alert variant={this.state.variant}>
                    {this.state.message}
                  </Alert>}
                 
                </Modal.Body>
                <Modal.Footer>
                  <button className={"btn btn-danger"}  onClick={e => this.setState({showModelUndo: false})}>
                    Annuler
                  </button>
                  <button type='submit' className={"btn btn-success"}  onClick={e => this.UndoTask(task.key)}>
                    Confirmer 
                  </button>
                </Modal.Footer>
              </Modal>

          </div>
        </React.Fragment>
      </>
    );
  }
}

const mapState = state => ({ user: state.user });

export default connect(mapState)(TaskRow);
