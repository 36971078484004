import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faSync, faUser } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import api from "../../../Utils/Api";
import ClientInput from "./ClientInput";
import { connect } from "react-redux";
import { startLoading, doneLoading, setOverlay, setFlash } from "../../../Redux/actions";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { loadCommonData } from "../../../Utils/Helpers";
import communes from "../../../../assets/communes.json";
import wilayas from "../../../../assets/wilayas.json"
import communes58 from "../../../../assets/communes58.json";
import Modal from 'react-bootstrap/Modal';
import * as CONSTANTS from "../../../Utils/Constants";




const vehiculeOptions = [
  { label: "20T", value: "20T" },
  { label: "20T MRCH", value: "20T MRCH" },
  { label: "20T TC", value: "20T TC" },
  { label: "40T", value: "40T" },
  { label: "10T", value: "10T" },
  { label: "10T MRCH", value: "10T MRCH" },
  { label: "CAMION 2T5", value: "CAMION 2T5" },
  { label: "CAMION 3T5", value: "CAMION 3T5" },
  { label: "CAMION 4T", value: "CAMION 4T" },
  { label: "Camion 4T5", value: "Camion 4T5" },
  { label: "CAMION 5T", value: "CAMION 5T" },
  { label: "CAMION FRIGO", value: "CAMION FRIGO" },
  { label: "CHARIOT ELEVATEUR", value: "CHARIOT ELEVATEUR" },
  { label: "DEPANNAGE", value: "DEPANNAGE" },
  { label: "FOURGON 1T5 CHL", value: "FOURGON 1T5 CHL" },
  { label: "FOURGON 1T5", value: "FOURGON 1T5" },
  { label: "FOURGON 2T5", value: "FOURGON 2T5" },
  { label: "FOURGON 3T5", value: "FOURGON 3T5" },
  { label: "FOURGON 3T5 HAYON", value: "FOURGON 3T5 HAYON" },
  { label: "FOURGON CONTENEUR", value: "FOURGON CONTENEUR" },
  { label: "FOURGONNETTE", value: "FOURGONNETTE" },
  { label: "GROUPAGE", value: "GROUPAGE" },
  { label: "GRUE", value: "GRUE" },
  { label: "PORTE CHAR", value: "PORTE CHAR" },
  { label: "TRAFIC", value: "TRAFIC" },
];

//const listCommunes = communes.communes.map(item => ({ label: item.nom+" ("+item.code_postal+")", value: item.nom, code: item.code_postal }))
const listCommunes = communes.communes.map(item => ({ label: item.nom, value: item.nom, wilayaToId : item.wilaya_id, zone : wilayas.wilayas.find(wilaya =>wilaya.id === item.wilaya_id ).nom}))
//const listCommunes = communes58.map(item => ({ label: item.commune_name_ascii + '(' + item.commune_name + ')', value: item.commune_name_ascii , zone : item.wilaya_name_ascii  }))
const listWilaya =  wilayas.wilayas.map(item => ({ label: item.nom, value: item.nom , id : item.id}))
var locName = ''

function getApi(bdcApi) {
 
    const Http = new XMLHttpRequest();
  Http.open("GET", bdcApi);
  Http.send();
  Http.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
          var result = JSON.parse(this.responseText)
          locName = result.locality +', '+  result.principalSubdivision
          
      }
  };
}



class TaskForm extends React.Component {
  constructor(props) {
    super(props);
    if(this.props.bordereau != null){
      var communeFromObject = communes.communes.find(commune => commune.nom == this.props.bordereau.depart);
      var wilayaFromObject = wilayas.wilayas.find(wilaya => wilaya.id == communeFromObject.wilaya_id);
      var communeToObejct = communes.communes.find(commune => commune.nom == this.props.bordereau.arrive)
      var wilayaToObject = wilayas.wilayas.find(wilaya => wilaya.id == communeToObejct.wilaya_id);
    }else{
      var communeFromObject = null;
      var wilayaFromObject = null;
      var communeToObejct = null;
      var wilayaToObject = null;
    }


    this.state = {
      data: {},
      error: "",
      clientsList: [],
      client: {
        client_name: this.props.bordereau != null ? this.props.bordereau.client : "",
        client_id: "",
        client_address: "",
      },
      wilayaFrom: wilayaFromObject != null ?  wilayaFromObject.nom  : "",
      //wilayaIdFrom : this.props.bordereau != null ? wilayas.wilayas.find(wilaya => wilaya.id == com).id : '',
      wilayaTo: wilayaToObject != null ? wilayaToObject.nom : "" ,
      communesDepart: [],
      communesArrive: [],
      devis2: 0,
      //SAM
      from : communeFromObject != null ? communeFromObject.id  : "",
      fromNom : communeFromObject != null ? communeFromObject.nom : "",
      //from: this.props.bordereau != null ? communes.communes.find(commune => commune.nom == this.props.bordereau.depart).id : "",
      to: communeToObejct != null ? communeToObejct.id : "",
      toNom : communeToObejct != null ? communeToObejct.nom : "",
      //communeFromObject : communes.communes.find(commune => commune.nom == this.props.bordereau.depart),
      wilayaIdTo: '',
      type: this.props.bordereau != null ? this.props.bordereau.type : "", 
      paiment: this.props.bordereau != null ? this.props.bordereau.paiment : "",
      showDevis:false,
      confirmCommand: false,
      devis: 0,
      phone: this.props.bordereau != null ? this.props.bordereau.contact_phone : '',
      client_type: '',
      geo: {
        locName: '',
        lat: '',
        lon: '',
      },
      tarifs: {},
      zones: [],
      station: '',
      typeClient: '',
      isCollect: this.props.bordereau != null  ? (this.props.bordereau.collect != 0 ? true : false) : false,
      reverse:false,
      isStopDesk: this.props.bordereau != null  ? this.props.bordereau.isStopdesk : false,
      error: ''
      
    };


    this.clientInputRef = null;

    this.taskForm = null;
  }

 

  
  submitTask = (e) => {
    
    e.preventDefault();
    this.closeShowDevis()
 

    
    this.setState({error: ""});
    const formData = new FormData(this.taskForm);

    let data = {};

    
    for (var [key, value] of formData.entries()) data[key] = value;

    console.log('costcolis => '+ this.state.isCollect + ' collect => ' + !data['collect'])

    if(this.state.type === 'documents'){
      data['poids'] = 1
      data['longeur'] = 1
      data['largeur'] = 1
      data['hauteur'] = 1
    }

    data['reverse'] = this.state.reverse
    data['stopdesk'] = this.state.isStopDesk

    if(!data['contact_phone'] || !data['quantite'] || !data['poids']  || !data['to'] || !data['type'] || !data['longeur'] || !data['largeur'] || !data['hauteur'] || !data['sendType']) {
      if(this.props.user.role !== 'commercial' && !data['from']){
        this.setState({error: "Veuillez remplir les champs obligatoire"})
        return false;
      }else{
        this.setState({error: "Veuillez remplir les champs obligatoire"})
        return false;
      }
      
    }
    if( this.state.isCollect && !data['collect']  ){
      this.setState({error: "Veuillez remplir les champs obligatoire"})
      return false;
    }

    data['zoneFrom'] = this.state.wilayaFrom
    data['zoneTo'] = this.state.wilayaTo
    data['wilayaToId'] = this.state.wilayaIdTo
    data['isCollect'] =  this.state.isCollect

    let poidsVolume = ((data['longeur'] * data['largeur'] * data['hauteur']) / 5000).toFixed()
    if(poidsVolume === 0)
        poidsVolume = 0.5

   // console.log('poids => ' +  data['poids'] + ' vol => ' + poidsVolume)
    if(parseInt(poidsVolume) > parseInt(data['poids'])){
     // console.log('here')
      data['poids'] = poidsVolume
    }
    //console.log('after poids => ' +  data['poids'])
    

    //calculate devis through back end 
    this.props.isLoading({
      message: "Calcule devis",
      animate: true,
    });
    let isClient = (this.props.user.role === 'client') ? true: false;
    let dataToDevis  = {client: data['task_client'], username: this.props.user.username , poids: data['poids'], arrive: this.state.wilayaTo, depart: this.state.wilayaFrom , quantite: data['quantite'] , type: this.state.client_type , isClient: isClient, isReverse: this.state.reverse ,  isStopDesk: this.state.isStopDesk}
    api.post('/api/bordereau/CalculeDevis', dataToDevis).then(result => {
      if (result.data.success){
        console.log('calcul decv' + result.data.devis)
        data['devis'] = result.data.devis
        this.setState({
          devis: data['devis']
        })
        data['paiment'] = this.state.paiment
        this.setState({
          showDevis:true,
          data: data,
        })
        this.props.doneLoading();
      }else{
        this.setState({error: result.data.error})
        this.props.doneLoading();
      }
    })
    //end calculating

    //let devis = this.state.tarifs[data['poids'].toString()][''+this.state.zones[data['to']].zone]
    
    //let devis = this.state.tarifs[data['poids'].toString()][''+this.state.zones[this.state.wilayaTo].zone]
    //console.log('tarif' + this.state.tarifs[''+ data['poids']]['zone_2'])
    //data['devis'] = data['quantite'] * devis
    //data['paiment'] = this.state.paiment

    // data['from'] = this.state.from;
    // data['to'] = this.state.to;
    // this.setState({
    //   //devis: data['quantite'] * data['poids'],
    //   //devis: data['devis'],
    //   showDevis:true,
    //   data: data,
    // })
    //while(this.state.waiting === false){}
    
    //console.log("this is data"+ data.quantite)
    
  };


  updateCommune = (commune_id, status, ) => {
    
    status == 'from' ? this.setState({from: commune_id}) : this.setState({to: commune_id });
   // status == 'from' ? this.setState({wilayaFrom: zone}) : this.setState({wilayaTo: zone});
  }
  updateWilaya = ( status, zone = '' , id = '') => {
    console.log('id => '+ id)
    //status == 'from' ? this.setState({from: commune_id}) : this.setState({to: commune_id});
    status == 'from' ? this.setState({wilayaFrom: zone}) : this.setState({wilayaTo: zone, wilayaIdTo : id});
    let  array = []
      
    array =  communes.communes.map(item => 
                wilayas.wilayas.find(wilaya =>wilaya.id === item.wilaya_id ).nom === zone && ({ label: item.nom, value: item.nom,  zone : wilayas.wilayas.find(wilaya =>wilaya.id === item.wilaya_id ).nom}) 
             )
    let commune = array.filter(value => Object.keys(value).length !== 0);
    status == 'from' ?
    this.setState({
      communesDepart:commune
    })
    : 
    this.setState({
      communesArrive: commune
    })
  }

  updateType = (e) => {
    // console.log(e.target.value)
    this.setState({type: e.target.value});
    console.log(this.state.type === 'documents')
  }

  updatePaiment = (e) => {
    this.setState({paiment: e.target.value});  }

  componentDidMount() {
    //getting the location 
    let that = this;
    api.get('/api/form/zones').then(result => {
      if(result.data.success){
        that.setState({
          tarifs: result.data.tarifs,
          zones: result.data.zones
        })
      }
    })
   
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   that.setState({
    //     geo:{
    //       lat: position.coords.latitude,
    //       lon: position.coords.longitude
    //     }
    //   })
    //fetiching api bigdatacloud to get the name of the location
    // let apiLoc = "https://api.bigdatacloud.net/data/reverse-geocode-client?latitude="+ position.coords.latitude + "&longitude=" + position.coords.longitude+ "&localityLanguage=fr";

    // getApi(apiLoc)
    
    // });
    
    loadCommonData();
    if(this.props.user.role === 'client' || this.props.user.role === 'commercial'){
      //this.props.isLoading();
 		  let that = this;

      api.get('/api/user/'+this.props.user.userid+'?tab=personal&token='+this.props.user.token)
      .then(function(response) {
        that.setState({
          phone: response.data.phone,
          client_type: response.data.client_type,
          station: response.data.station,
        });
        // if(this.props.user.role === "commercial" ){
        //   that.setState({
        //     phone: response.data.station,
        //   });
        // }
        
      }).catch(err => that.props.doneLoading())
    }
    
  }

  DevisCalcule = (event) => {
    this.setState({
      showDevis: true
    })
    const formData = new FormData(this.taskForm);
    let data = {};

    
    for (var [key, value] of formData.entries()) data[key] = value;
    
  }
  closeShowDevis = () => {
    this.setState({
      showDevis: false
    })
  }
  confirmCommand = () =>{
    this.setState({
      confirmCommand: true,
      showDevis: false,
    })
    const formData = new FormData(this.taskForm);

    this.props.isLoading({
      message: "Ajout en cours",
      animate: true,
    });

    
    api.post("/api/bordereau/new", this.state.data).then(result =>{
      if (result.data.success){

        // this.exportPDF.action = CONSTANTS.REMOTE_API + result.data.file;
    
        // this.exportPDF.submit();
        if (this.props.inline && this.props.afterPost) {
          if (this.props.bordereau != null){
            this.props.afterPost();
          }
        }else{
          this.props.history.push("/");
        }

        if (this.props.afterPost){
          this.props.afterPost();
        }
        
      }else{
        this.setState({
          error: result.data.message
        })
        this.props.doneLoading();
      }
    })
    //this.props.doneLoading();

   
    return false;
  }

  updateDevi = (e) => {
    // console.log('this devis'+ e.target.value)
    // this.setState({
    //   devis2: e.target.value
    // })
    this.setState(
      {
        data: {
          ...this.state.data,
          devis: e.target.value
        }
      }
    );
  }

  render() {
    const selectedVehicule = {
      label: this.props.data.task_vehicule_type,
      value: this.props.data.task_vehicule_type_id,
    };

    console.log(this.props.client);
    
    let that = this
    const zones = Object.entries(that.state.zones).map(([key, i]) => ({
      label: key,
      value: key
    }))

    const poids = Object.entries(that.state.tarifs).map(([key, i]) => ({
      label: key,
      value: key
    }))
    // that.state.zones.map(item => ({
    //   label: item.wilaya,
    //   value: item.wilaya,
    // }))
    
    return (
      <>
        <form method="post" ref={ref => this.exportPDF = ref}>
            {/* <button onClick={e => this.export()}>get pdf</button> */}
            {/* <input type="hidden" name={"x-sts-token"} ref={ref => this.userInfo = ref} value={this.props.user.token} /> */}
        </form> 
        {Boolean(this.state.error) && <span className={"alert alert-warning d-block"}>{this.state.error}</span>}
        <form ref={(ref) => (this.taskForm = ref)} onSubmit={this.submitTask}>
          {!this.props.isDuplicate && <input type="hidden" name="task_id" value={this.props.data.task_id} />}

          <div className={"row"}>
            <div className={"col-md-12 taskForm"}>
              <div className={"ui-rect np mb-3"}>

                <div className="row">
                  <div className={"col-md-12"}>
                    <h5>Informations du client</h5>
                  </div>
                </div>

                <div className="row my-2">
                  {this.props.user.role !== "client"  && <div className={"col-md-4"}>
                    <label>
                      <b>Nom client *</b>
                    </label>
                    
                    
                    <div>
                      <ClientInput
                        defaultValue={this.state.client.client_name}//{this.props.data.task_client_name}
                        selectClient={this.props.handlers.selectClient}
                        type="text"
                        name="task_client"
                        className={"form-control"}
                      />
                      <input
                        type="hidden"
                        name="task_client_id"
                        value={this.props.client.client_id || ""}
                      />
                    </div>

                   
                  </div> }
                                     


                  {this.props.user.role !== "client"  && <div className={"col-md-4"}>
                    <label>
                      <b>Nom Client destinataire *</b>
                    </label>
                    <input
                      type="text"
                      name={'client_contact_name'}
                      className={"form-control"}
                    />
                  </div>}
                  
                  {this.props.user.role === "client"  && !this.state.reverse && <div className={"col-md-8"}> 
                    <div class="row">
                      <div class='col-md-5'>
                        <label>
                          <b>Nom client *</b>
                        </label>
                        <input
                          type="text"
                          name={`task_client`}
                          className={"form-control"}
                          value= {this.props.user.username}
                          disabled
                        />
                      </div>
                      <button className="btn btn-light  my-4" onClick={() => {this.setState({reverse: true} )}}>
                        <FontAwesomeIcon   icon={faSync} />
                      </button>
                      <div class='col-md-5'>
                        <label>
                          <b>Nom Client destinataire *</b>
                        </label>
                        <input
                          type="text"
                          name={'client_contact_name'}
                          className={"form-control"}
                        />
                      </div>
                      
                    </div>
                     
                  </div>}

                  {this.props.user.role === "client"  && this.state.reverse && <div className={"col-md-8"}> 
                    <div class="row">
                      <div class='col-md-5'>
                          <label>
                            <b>Nom Client Expiditeur  *</b>
                          </label>
                          <input
                            type="text"
                            name={'client_contact_name'}
                            className={"form-control"}
                          />
                        </div>
                      
                      <button className="btn btn-light  my-4" onClick={() => {this.setState({reverse: false} )}} >
                        <FontAwesomeIcon   icon={faSync} />
                      </button>
                      <div class='col-md-5'>
                        <label>
                          <b>Nom client *</b>
                        </label>
                        <input
                          type="text"
                          name={`task_client`}
                          className={"form-control"}
                          value={this.props.user.username}
                          disabled
                        />
                      </div>
                      
                    </div>
                     
                  </div>}

                  

                  <div className={"col-md-4"}>
                    <label>
                      <b>
                        Numéro de téléphone du destinataire *
                      </b>
                      
                    </label>
                    <input
                      defaultValue={this.props.user.role != "client" ? this.state.phone : 0}
                      type="text"
                      name={'contact_phone'}
                      //inputmode="numeric"
                      //pattern="\d+(\.\d*)?"
                      className={"form-control"}
                    />
                  </div>
                </div>
                
                
                <div className={"row"}>
                  <div className={"col-md-4"}>
                    <h5>Informations sur départ</h5>
                  </div>
                  <div className={"col-md-8"}>
                    <h5>Informations sur arrivée</h5>
                  </div>
                </div>
                
                <div className={"row"}>
                <div className={"form-group col-md-2"}>
                    <label><b>Wilaya départ *</b></label>
                    {/* SAM */}
                    <Select
                      placeholder={"Wilaya Départ"}
                      classNamePrefix={"custom-select"}
                      name={`fromWilaya`}
                      onChange={e => this.updateWilaya('from' , e.value)}
                      defaultValue={listWilaya.filter(item => item.value === this.state.wilayaFrom)}
                      options={listWilaya}
                    />
                </div>
                <div className={"form-group col-md-2"}>
                    <label><b>Commune départ *</b></label>
                    {/* SAM */}
                    <Select
                      placeholder={"Ville Départ"}
                      classNamePrefix={"custom-select"}
                      name={`from`}
                      onChange={e => this.updateCommune(e.code, 'from' )}
                      //defaultValue = {this.state.communeFromObject}
                      defaultValue={listCommunes.filter(item => item.value === this.state.fromNom)}
                      //default={this.props.bordereau != null ? this.props.bordereau.depart : ""}
                      options={this.state.wilayaFrom ? this.state.communesDepart :listCommunes}
                    />
                  </div>
{/* 
                  {this.props.user.role === "commercial" && <div className={"form-group col-md-2"}>
                    <label><b>Commune départ *</b></label>
                    <input 
                      className={"form-control"}
                      defaultValue={this.state.station}
                      type='text'
                      onChange={e => this.updateCommune(e.code, 'from')}
                      name="from"
                      disabled
                    />
                  </div>} */}
                  <div className={"form-group col-md-2"}>
                    <label><b>Wilaya arrivée *</b></label>
                    <Select
                      placeholder={"Wilaya Arrivé"}
                      classNamePrefix={"custom-select"}
                      name={`toWilaya`}
                      onChange={e => this.updateWilaya( 'to' , e.value, e.id)}
                      defaultValue={listWilaya.filter(item => item.value === this.state.wilayaTo)}
                      options={listWilaya}
                    />
                  </div>
                  <div className={"form-group col-md-2"}>
                    <label><b>Commune arrivée * </b></label>
                    <Select
                      placeholder={"Ville Arrivé"}
                      classNamePrefix={"custom-select"}
                      name={`to`}
                      onChange={e => this.updateCommune(e.code, 'to' )}
                      defaultValue={listCommunes.filter(item => item.value === this.state.toNom)}
                      options={this.state.wilayaTo ? this.state.communesArrive :listCommunes}
                    />
                  </div>

                  <div className={"form-group col-md-4"}>
                    <label>Adresse Arrivée complete</label>
                    <input
                      placeholder={"Adresse complete"}
                      name={`adresse`}
                      className={"form-control"}
                      type='text'
                      
                    />
                  </div>

                  {/* <div className={"form-group col-md-1"}></div> */}
                  
                </div> 

                <div className={"row"}>
                 <div className={"col-md-4"}>
                    <h5>Nature d'envoi</h5>
                  </div>
                  <div className={"col-md-8"}>
                    <h5>Type de produit</h5>
                  </div>
                </div>
                <div className="row">
                  {/* <div className={"form-group col-md-1"}></div> */}
                  <div className={"form-group col-md-4"}>
                    <label><b>Nature d'envoi *</b></label>
                    <input
                      placeholder={"Nature d'envoi"}
                      name={`sendType`}
                      className={"form-control"}
                      type='text'
                      maxlength="20"
                      
                    />
                  </div>
                  <div className={"form-group col-md-2"}></div>
                  <div className={"form-group col-md-2"}>
                      <div className={"form-check"}>
                        <label style={{marginTop:'25px'}}>
                          <input 
                            defaultValue='colis'
                            value="colis"
                            checked={this.state.type === 'colis'}
                            onChange={this.updateType}
                            type="radio"
                            name="type"
                            className="form-check-input"
                          />
                          Colis
                        </label>
                      </div>
                    </div>
                    <div className={"form-group col-md-2"}>
                      <div className={"form-check"}>
                        <label style={{marginTop:'25px'}}>
                          <input 
                            defaultValue='documents'
                            value="documents"
                            checked={this.state.type === 'documents'}
                            onChange={this.updateType}
                            type="radio"
                            name="type"
                            className="form-check-input" 
                          />
                          Documents
                        </label>
                      </div>
                    </div>
                  <div className={"form-group col-md-4"}></div>

                </div>
               

                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <h5>Entrez le poids et les dimensions de vos colis</h5>
                  </div>
                </div>

                <div className={"row"}>
                  <div className={"form-group col-md-3"}>
                    <label><b>Nombre de colis *</b></label>
                    <input
                      defaultValue={this.props.bordereau != null ? this.props.bordereau.quantity : ""}
                      type="number"
                      name={`quantite`}
                      className={"form-control"}
                    />
                  </div>

                  <div className={"form-group col-md-3"}>
                    <label>
                      <b>Poids * (Kg)</b>
                    </label>
                    {/* <input
                      defaultValue={this.props.data.task_bl_client}
                      type="text"
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`poids`}
                      className={"form-control"}
                    /> */}
                    {this.state.type === 'documents' ?  <input 
                      disabled
                      type="number" 
                      min="1" 
                      max="50"
                      pattern="\d+(\.\d*)?"
                      name={`poids`}
                      className={"form-control"}
                    />
                    :
                    <input
                      defaultValue={this.props.bordereau != null ? this.props.bordereau.poids : ""}
                      type="number" 
                      min="1" 
                      max="50"
                      pattern="\d+(\.\d*)?"
                      name={`poids`}
                      className={"form-control"}
                    />
                  
                  }
                    

                    {/* <Select
                      placeholder={"Poids"}
                      classNamePrefix={"custom-select"}
                      name={`poids`}
                      //onChange={e => this.updateCommune(e.code, 'poids' )}
                      options={poids}
                    />   */}

                  </div>

                  <div className={"form-group col-md-2"}>
                    <label><b>Longeur (cm)*</b></label>
                    {this.state.type === 'documents' ? <input
                      disabled
                      type="text"
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`longeur`}
                      className={"form-control"}
                    />
                    :
                    <input
                      type="text"
                      defaultValue={this.props.bordereau != null ? this.props.bordereau.longueur : ""}
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`longeur`}
                      className={"form-control"}
                    />
                  
                  }
                   
                  </div>

                  <div className={"form-group col-md-2"}>
                    <label><b>Largeur (cm)*</b></label>
                    {this.state.type === 'documents' ? <input
                      disabled
                      type="text"
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`largeur`}
                      className={"form-control"}
                    />
                   :
                   <input
                     type="text"
                     defaultValue={this.props.bordereau != null ? this.props.bordereau.largeur : ""}
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`largeur`}
                      className={"form-control"}
                    />
                  }
                    
                  </div>
                  <div className={"form-group col-md-2"}>
                    <label><b>Hauteur (cm)*</b></label>
                    {this.state.type === 'documents' ? <input
                      disabled
                      type="text"
                      inputmode="numeric"
                      pattern="\d+(\.\d*)?"
                      name={`hauteur`}
                      className={"form-control"}
                    />
                    :
                    <input
                      type="text"
                      inputmode="numeric"
                      defaultValue={this.props.bordereau != null ? this.props.bordereau.hauteur : ""}
                      pattern="\d+(\.\d*)?"
                      name={`hauteur`}
                      className={"form-control"}
                    />
                    }
                   
                  </div>
                </div>

                <div className={"row"}>
                  
                </div>

                <div className={"row"}>
                  
                </div>
               
              </div>

              

              <div className={"row"}>
                  {/* <div className={"form-group col-md-4"}>
                    <label>Code Promotionnel</label>
                    <input
                      type="text"
                      name={`promo`}
                      className={"form-control"}
                    />
                  </div> */}
                  
                  <div class="col-md-6">
                    <div className="row">
                        <div className={"form-group col-md-3"}>
                        <div className={"form-check"}>
                          <label style={{marginTop:'25px'}}>
                            <input 
                              defaultValue='prepaid'
                              value="prepaid"
                              checked={this.state.paiment === 'prepaid'}
                              onChange={this.updatePaiment}
                              type="radio"
                              name="paiment"
                              className="form-check-input"
                            />
                            
                            {this.props.user.role === "client" ? <b>à ma charge *</b> : <b>Prepaid*</b>}
                          </label>
                        </div>
                      </div>

                      <div className={"form-group col-md-3"}>
                        <div className={"form-check"}>
                          <label style={{marginTop:'25px'}}>
                            <input 
                              defaultValue='postpaid'
                              value="postpaid"
                              checked={this.state.paiment === 'postpaid'}
                              onChange={this.updatePaiment}
                              type="radio"
                              name="paiment"
                              className="form-check-input" 
                            />
                            
                            {this.props.user.role === "client" ? <b>à la charge du destinataire *</b>  : <b>Postpaid*</b>}
                          </label>
                        </div>
                      </div>
                      {this.state.isCollect ? <div className={"col-md-3"}>
                        <label>
                          <b>Veuillez entrez le montant a collecté *</b>
                        </label>
                        <input
                          defaultValue={ this.state.isCollect == true ? (this.props.bordereau != null ? this.props.bordereau.collect : 0) : 0}
                          type="text"
                          name={'collect'}
                          inputmode="numeric"
                          pattern="\d+(\.\d*)?"
                          className={"form-control"}
                        />
                      </div> 
                      :
                      <div className={"form-group col-md-3"}>
                        <div className={"form-check"}>
                          <label style={{marginTop:'25px'}}>
                            <input 
                              defaultValue='costcolis'
                              value="costcolis"
                             //checked={this.state.paiment === 'collect'}
                              onChange={e => this.setState({isCollect: true})}
                              type="radio"
                              name="costcolis"
                              className="form-check-input" 
                            />
                            <b>Coût colis *</b> 
                          </label>
                        </div>
                      </div>
                      }

                    <div className={"form-group col-md-3"}>
                        <div className={"form-check"}>
                          <label style={{marginTop:'25px'}}>
                            <input 
                              defaultValue='stopdesk'
                              value="stopdesk"
                              checked={this.state.isStopDesk == true}
                              onChange={e => this.setState({isStopDesk: !this.state.isStopDesk})}
                              type="checkbox"
                              name="stopdesk"
                              className="form-check-input" 
                            />
                            <b>Stop Desk</b> 
                          </label>
                        </div>
                      </div>
                      
                    </div>

                   
                    
                  </div>
                  
                  <div className={"form-group col-md-6"}>
                    <button type="submit" className={"cbtn large block success"}>
                      OBTENIR UN DEVIS
                    </button>
                  </div>
              </div>
              
              
              
            </div>

            <div className={"col-md-6 taskForm"}>
              {this.props.children}

              <div
                className={
                  "d-none ui-rect map-container " +
                  (this.props.pinOnMap && "show")
                }
                style={{
                  height: "400px",
                  borderColor: "#ccc",
                  borderWidth: "1px",
                  borderRadius: "2px",
                  borderStyle: "solid",
                }}
                id="here-maps"
              />
            </div>
          </div>
        </form>

              <Modal show={this.state.showDevis} centered>
                <Modal.Header >
                  <Modal.Title>Votre devis</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.state.devis === 0  && this.props.user.role === "client"? <div> Pour le moment il n'y a  pas de tarification specifique pour ce trajet <br/> Veuillez contacter le service client</div> 
                  :  
                  <div>Le devis est de: <b><p class="text-success">{this.state.devis} DZD </p></b></div>
                 }
                  
                 {this.props.user.role !== "client" && 
                  <span>
                    Si vous voulez le modifier  veuillez entrer le montant voulus <br/>
                    <input 
                      onChange={this.updateDevi}
                      name="devis2"
                      cclassName={"form-control"} 
                    /> DZD
                    
                  </span>
                 }
                  
                  <p class="text-secondary font-italic"><b>NB: pour finaliser votre commande appuyez sur Commander !!</b></p>
                </Modal.Body>
                <Modal.Footer>
                  <button className={"btn btn-secondary"}  onClick={e => this.closeShowDevis()}>
                    Annuler
                  </button>
                  {this.state.devis === 0  && this.props.user.role === "client" ? '' : <button type='submit' className={"btn btn-success"}  onClick={e => this.confirmCommand()}>
                    Commander
                  </button>}
                </Modal.Footer>
              </Modal>
          
      </>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    isLoading: (conf) => dispatch(startLoading(conf)),
    doneLoading: () => dispatch(doneLoading()),
    setOverlay: e => dispatch(setOverlay(e)),
    setFlash: f => dispatch(setFlash(f))
  };
};

const mapState = (state) => {
  return {
    vehicules: state.vehicules,
    miseDispo: state.miseDispo,
    typeCourse: state.typeCourse,
    typeReglement: state.typeReglement,
    task_status: state.task_status,
    auth: state.auth,
		user: state.user
  };
};


export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(TaskForm)
);
