import React, {useState, Component } from "react";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { connect } from 'react-redux';
import WTNotifications, { withNotifications } from "react-socket-notification";
import './ClientInterfaceStyle.css';
import { faCube,faBox, faFile, faFileInvoice, faTimes, faImage, faMobileAlt, faHistory, faQuestion, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  BordereauNewContainer  from "../../Containers/BordereauNewContainer";
import * as CONSTANTS from "./../../Utils/Constants";


const mapStateToProps = state => {
    return {
      auth: state.auth,
      user: state.user,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      isLoading: e => dispatch(startLoading(e)),
      doneLoading: e => dispatch(doneLoading()),
      setAuth: e => dispatch(setAuthAction(e))
    };
  }


class CommandeRow extends Component {

    constructor(props) {
        super(props);

        // this.props.isLoading({
        //   message: "Loading tasks",
        //   animate: true
        // });
    
        this.state = {
            isDetailsShown: false,
            showPhotos: false,
            showTentative: false,
            faHistory:false,
            factureIconColor:this.props.data.facture? "#585858": "#D3D3D3",
            bordereauIconColor: this.props.data.file? "#585858": "#D3D3D3",
            photoIconColor: this.props.data.tasks['a_999'] && this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos? "#585858": "#D3D3D3",
        };
    }

    showDetails = () => {
        let bool = this.state.isDetailsShown 
        this.setState({
        isDetailsShown: bool? false :true
        })
        
    }


    getDocument = (action) => {
        let url = CONSTANTS.REMOTE_API + 'File/';
        if(action == 'bordereau' && this.props.data.fileA4){
            url += 'Bordereau/' + this.props.data.fileA4;
            window.open(url, '_blank').focus();
        }
        if(action == 'facture' && this.props.data.facture){
            url += 'Facture/' + this.props.data.facture;
            window.open(url, '_blank').focus();
        }

    }

    



    render(){
        return(
            
            
                            <div class="table-row-accueil">

                            {this.state.showPhotos && (
                                      <>
                                        <div className={this.state.showPhotos ? "ui-overlay open" : "ui-overlay"}>
                                          <div className={"ui-overlay-content photos-overlay col-md-5"}>
                                            <button
                                              data-package-id="0"
                                              onClick={() => this.setState({showPhotos: false})}
                                              className={"ui-overlay-close btn btn-danger btn-sm"}
                                            >
                                              <FontAwesomeIcon icon={faTimes} />
                                            </button>

                                            <div className={"task-photos-wrap"}>
                                              {this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos &&
                                                this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos.map(photo => (
                                                  <div className={"task-photos-element"}>
                                                    <a href={CONSTANTS.REMOTE_API + "/" + photo} target={"_blank"}>
                                                      <img src={CONSTANTS.REMOTE_API + "/" + photo} />
                                                    </a>
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                            )}

                            {this.state.showTentative && (
                              <>
                                <div className={this.state.showTentative ? "ui-overlay open" : "ui-overlay"}>
                                  <div className={"ui-overlay-content photos-overlay col-md-5"} style={{ textAlign: 'left' }}>
                                    <button
                                      data-package-id="0"
                                      onClick={() => this.setState({ showTentative: false })}
                                      className={"ui-overlay-close btn btn-danger btn-sm"}
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </button>

                                    <div style={{ textAlign: 'left' }}>
                                      <h3> Tentatives Échouée</h3>
                                      {Object.entries(this.props.data.tasks).map(([taskKey, taskData]) => {
                                        const subtasks = taskData.subtasks;
                                        return (
                                          <div key={taskKey}>
                                            {Object.entries(subtasks).map(([subtaskKey, subtaskData]) => {
                                              const tentatives = subtaskData.tentatives || [];
                                              if (tentatives.length > 0) {
                                                return (
                                                  <div key={subtaskKey}>
                                                    <ul style={{ textAlign: 'left' }}>
                                                      {tentatives.map((tentative, index) => (
                                                        <li key={index}>
                                                          <span className="detail-label" style={{ textAlign: 'left' }}>
                                                            <b>Tentative:</b>
                                                            <br />
                                                            Motif: {tentative.motif}
                                                            <br />
                                                            Note: {tentative.note}
                                                            <br />
                                                            Created at: {tentative.created_at}
                                                            <br />
                                                            Address : {subtaskData.subtask_address_commune}
                                                          </span>
                                                          <br/>
                                                          <br/>
                                                        </li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                                );
                                              }
                                            }
                                            )
                                            }
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                                 {this.state.showHistory && (
                                    <>
                                    <div className={this.state.showHistory ? "ui-overlay open" : "ui-overlay"}>
                                            <div className={"ui-overlay-content photos-overlay col-md-5"}>
                                            <button
                                                data-package-id="0"
                                                onClick={() => this.setState({showHistory: false})}
                                                className={"ui-overlay-close btn btn-danger btn-sm"}
                                              >
                                                <FontAwesomeIcon icon={faTimes} />
                                              </button>

                                              <div className={""}>
                                                <h3> Historique </h3>

                                                {this.props.data.tarifHist.map((item, i) => (
                                                  
                                                    <span className={"detail-label"}>
                                                      {/* <b>Tentative : {tentative.number} </b> */}
                                                      <br/><b>Date:</b>  {item.history_date}  
                                                      <br/><b>Motif:</b>  {item.history_action}
                                                      <hr/>
                                                      {/* <br/>Modifier par {item.history_by} */}
                                                    </span>
                                                  
                                                ))}
                                              </div>
                                            </div>
                                            
                                            

                                            
                                      </div>
                                    </>
                                  )
                                }
                                <div class="col col-2">
                                    {this.props.data.bordereau_code} <br></br>
                                    {/* {this.props.data.contact_phone.includes(this.props.filters) && */}
                                    <FontAwesomeIcon icon={faMobileAlt}/> {this.props.data.contact_phone} 
                                      {/* } */}
                                    
                                    {this.props.data.isDone && this.props.data.status_bordereau !== 'echèc' && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Done </div>}
                                    {!this.props.data.isDone && !this.props.data.status_bordereau && <div style={{ border: 'solid 1px #f0ad4e' , borderRadius : '3px', textAlign: 'center' , color: '#f0ad4e' , width: '60px'}}> Waiting... </div>}
                                    {this.props.data.status_bordereau === 'echèc' && <div style={{ border: 'solid 1px #bb2124' , borderRadius : '3px', textAlign: 'center' , color: '#bb2124' , width: '60px'}}> Echèc </div>} 
                                    {this.props.data.tasks['a_999'] && this.props.data.tasks['a_999'].subtasks['a_999'].tentatives.length > 0 && <a href="#" onClick={e => this.setState({showTentative:true})}>
                                         Tentatives...
                                       </a>}       

                                      {this.props.data.tarifHist.length > 0 && <a href="#" onClick={e => this.setState({showHistory:true})}>
                                        Historique <FontAwesomeIcon icon={faHistory} />
                                      </a>  }            
                                </div>
                                {this.props.user.role === "commercial" && <div class="col col-1">{this.props.data.client}</div>}
                                <div class="col col-5" >
                                   
                                    {this.props.data.status_bordereau === 'echèc' ? <ul class="progress-tracker progress-tracker--text">
                                      {Object.keys(this.props.data.tasks).map((idx, i) => 
                                            
                                            Object.keys(this.props.data.tasks[idx].subtasks)
                                            .reverse()
                                            .map((subtaskKey, i)=> 
                                                
                                                    <li class={"progress-step is-stop" }>
                                                        <span class="progress-marker"></span>
                                                        <span class="progress-text" >
                                                            
                                                            {this.props.data.tasks[idx].subtasks[subtaskKey].subtask_address_commune}
                                                        </span>
                                                    </li>
                                            )
                                        )}
                                      </ul>
                                      :
                                      <ul class="progress-tracker progress-tracker--text">
                                    {Object.keys(this.props.data.tasks).map((idx, i) => 
                                     
                                        Object.keys(this.props.data.tasks[idx].subtasks)
                                        .reverse()
                                        .map((subtaskKey, i)=> 
                                            
                                                <li class={""+ this.props.data.tasks[idx].subtasks[subtaskKey].subtask_status === 'started' ? "progress-step is-active" : (this.props.data.tasks[idx].subtasks[subtaskKey].subtask_status === 'done' ? "progress-step is-complete"  : "progress-step")}>
                                                    <span class="progress-marker"></span>
                                                    <span class="progress-text">
                                                        <h6 class="progress-title">{subtaskKey == 'a_-1'? 'Enlevement': subtaskKey == 'a_999'? 'Arrivée' : ''}</h6>
                                                        {this.props.data.tasks[idx].subtasks[subtaskKey].subtask_address_commune}<br/>
                                                        {this.props.data.tasks[idx].subtasks[subtaskKey].subtask_status == null ? 'waiting' : this.props.data.tasks[idx].subtasks[subtaskKey].subtask_status == 'started' ? 'START' : 'DONE'}<br/>
                                                        {this.props.data.tasks[idx].subtasks[subtaskKey].subtask_end_datetime}
                                                    </span>
                                                </li>
                                                
                                            
                                        )
                                    )}
                                    </ul>}
                                </div>
                                {/* <div className="col col-2"></div> */}
                                {/* <div class={this.props.user.role === "commercial"? "col col-2" : "col col-3"}><p class="font-weight-bold" style={{fontSize: "17px"}}>{this.props.data.tarif} DZD</p></div> */}
                                <div class={ "col col-1" }><p class="font-weight-bold" style={{fontSize: "17px"}}>{this.props.data.tarif} DZD</p></div>
                                {this.props.user.role === "client" &&  <div class={ "col col-1" }>
                                    {(this.props.data.collect !== 0 &&  this.props.data.due === '0' &&  !this.props.data.remise) && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Collecté <br/></div>}
                                    {(this.props.data.collect !== 0 &&  this.props.data.due === '0' &&  this.props.data.remise) && <div style={{ border: 'solid 1px #22bb33' , borderRadius : '3px', textAlign: 'center' , color: '#22bb33' , width: '50px'}}> Remis <br/></div>}
                                    <p class="font-weight-bold" style={{fontSize: "17px"}}>{this.props.data.collect} DZD</p>
                                </div> }
                                <div class="col col-3">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <button type="button" class="btn" onClick={e => this.getDocument('bordereau')}><FontAwesomeIcon  className={""} icon={faBox} color={this.state.bordereauIconColor} size="2x"/></button> 
                                            
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" class="btn" onClick={e => this.getDocument('facture')}><FontAwesomeIcon  className={""}   icon={faFileInvoice}  color={this.state.factureIconColor} size="2x"/></button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" class="btn" onClick={e => this.setState({showPhotos:true})}><FontAwesomeIcon  className={""}   icon={faImage}  color={this.state.photoIconColor} size="2x"/></button> 
                                            </div>

                                            {/* <div className="col-md-3"> */}
                                            {/* <button type="button" class="btn" onClick={e => this.setState({showTentative:true})}><FontAwesomeIcon  className={""}   icon={faExclamationTriangle}  color={this.state.photoIconColor} size="2x"/></button> */} 
                                        {/* </div> */}

                                        <div className="col-md-1 mt-4">
                                            <input
                                              name="apply"
                                              type="checkbox" 
                                              className="text-right"
                                              onChange={e => this.props.handlers.fillBOnAction(e, this.props.data.key_bordereau)}
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            Bordereau
                                            
                                        </div>
                                        <div className="col-md-3">
                                            Facture
                                        </div>
                                        <div className="col-md-3">
                                            Photos
                                        </div>
                                        {/* {!!this.props.data.tasks['a_999'].subtasks['a_999'].subtask_last_update && (
                                            <span className={"subtask-status-detail"}>
                                            <a href="#" onClick={e => this.setState({showPhotos:true})}>
                                                {" "}
                                                <b>
                                                {this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos && (
                                                    <>{this.props.data.tasks['a_999'].subtasks['a_999'].subtask_photos.length} photos</>
                                                )}
                                                </b>
                                            </a>{" "}
                                            
                                            </span>
                                        )} */}
                                       
                                        </div>

                                        <div class="col col-3">
                                          <br/>
                                        <div class="row">
                                          <button type="button" class="btn" onClick={e => this.setState({showTentative:true})}><FontAwesomeIcon  className={""}   icon={faExclamationTriangle}  color={this.state.photoIconColor} size="2x"/></button>
                                        </div>

                                        <div class="row">
                                          Tentative
                                        </div>

                                        </div>

                                      
                                     
                                </div>
                            </div>
        )
    }


}export default withNotifications(connect(
    mapStateToProps,
    mapDispatchToProps
  )(CommandeRow));
  
