import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ManagerSideMenu from './ManagerSideMenu';
import AgentSideMenu from './AgentSideMenu';
import GuestSideMenu from './GuestSideMenu';
import CommercialSideMenu from './CommercialSideMenu';
import logo from '../../../Logo.PNG'

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

class SideMenu extends Component {

	constructor(props) {
		super(props);


	}

	componentDidMount() {
		
	}

	render() {

		
		return (
			<div>
			{/* <span className={"logo-title"}>
				<span>WiTrack</span>
				<span style={{color: "#ed1d24"}} >
					<span style={{backgroundColor:"yellow"}}>A</span>nderson
				</span>
			</span> */}

			<img src={logo} alt="logo" />	
			
			
			{this.props.auth ? 
				this.props.user.role === 'manager' 
				? (<ManagerSideMenu />)
				: (<CommercialSideMenu />)
			: (<GuestSideMenu />)
			}

				
			</div>
		)
		
	}
}



export default connect(mapStateToProps, null, null, { pure: false})(SideMenu)
/*
<div className="list-group">
					<Link className="list-group-item list-group-item-action" to={"/items"}>Items</Link>
					<Link className="list-group-item list-group-item-action" to={"/packages"}>Packages</Link>
					<Link className="list-group-item list-group-item-action" to={"/roles"}>Roles</Link>
					<Link className="list-group-item list-group-item-action" to={"/security"}>Security</Link>
					<Link className="list-group-item list-group-item-action" to={"/config"}>Configuration</Link>

				</div>
				*/