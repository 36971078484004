import React, { Component } from 'react';
import { connect, connectAdvanced } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import { setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { useTranslation, Trans } from "react-i18next";
import AgentCollectRow from './AgentCollectRow';



const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}


const mapDispatchToProps = dispatch => {
	return {
		setAuth: user => dispatch(setAuthAction(user)),
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}
class AgentCollect extends Component {

	constructor(props){
        super(props)

		

        this.state = {
            userInfo: this.props.info
		}
		// this.loadInfo()
        // this.props.isLoading();
        
    }

	// loadInfo = () => {
	// 	let that = this
	// 	api.get('/api/user/'+this.props.id+'?tab=collect&token='+this.props.user.token)
	// 	.then(function(response) {

	// 		if(!response.data.success) {
	// 			// this.props.doneLoading();
	// 			that.setState({
	// 				userInfo: response.data.user
	// 			})
	// 			return true;
	// 		}

            

	// 		// this.props.doneLoading();
			
	// 	});
	// }
    render() {
        return <div>
			
            <div className={"col-md-8"}>
  				<h4>Liste des collects</h4>

					<div className={"row act-row"}>

						<div className={"col-md-4"}>
							Code
						</div>

						<div className={"col-md-4"}>
							Collect
						</div>

						<div className={"col-md-4"}>
						 	Due 
						</div>

					</div>

  				{this.state.userInfo.map(info =>(
				

  				<AgentCollectRow info ={info} /> 
  				))}


  			</div>

        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentCollect)