import React, {useState, Component } from "react";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../Components/Bordereau/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../Utils/socket-context";
import api from "../Utils/Api";
import Pagination from "../Utils/Pagination";
import { loadCommonData, buildUriParams } from "../Utils/Helpers";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo, faTimesCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import BordereauNewContainer from "./BordereauNewContainer";
import communes from "../../assets/communes.json";
import BordereauRow from "./BordereauRow";

import WTNotifications, { withNotifications } from "react-socket-notification";
import ClientInput from "../Components/Task/subcomponents/ClientInput";
import equal from 'fast-deep-equal'
import MultiSelect from "react-multi-select-component";
import * as CONSTANTS from "../Utils/Constants"
import {ExportCSV}  from '../Components/Rapport/ExportCSV';
import Modal from 'react-bootstrap/Modal';
import * as actions from '../Redux/actions/index';
import Alert from 'react-bootstrap/Alert';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'



const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList,
    filters: state.filters,
    taskStatus: state.taskStatus,
    task_status: state.task_status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(actions.setFlash(f)),
    closeFlash: (f) => dispatch(actions.closeFlash(f))
  };
}

const moment = require("moment");





class BordereauListContainer extends Component {
  constructor(props) {
    super(props);



    this.props.isLoading({
      message: "Loading bordereaux",
      animate: true
    });

    this.state = {
      isAlert:false,
      message: '',
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      clients: [],
      zones: [],
      bordereaux: [],
      isDetailsShown: false,
      rows: [],
      current: 1,
      pages: 1,
      montantTotal: 0,
      limit: 60,
      showImport: false,
      file: [],
      filters: {
        state_global:'null'
      },
      showRecu: false,
      value: '',
      bordereauxSelected: [],
      bordereauxOnAction: [],
      selectedActon: null,
      sel:false
      // valueDefault: {
      //   agents: {label: 'Tout les coursiers ...', key : ''},
      // },
      // selected: { 
      //   agent: null
      // }
    };

    this.changeLimit = this.changeLimit.bind(this);

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks,
      fillBOnAction: this.fillBOnAction,
    };
    //console.log('client id =>' + this.props.match.params.clientid)
    
  }

  updateFilter = (event, nameVal, isDate = false ,arr = false, isSelect = false) => {
    let that = this;
    console.log(event);
    let value = "";

    // if(isSelect){
    //   this.setState({
    //     selected: {
    //       [nameVal]: event
    //     }
    //   })
    // }

    if (isDate) {
      value = event;
    } else {
      value = event.target ? event.target.value : event.value;
    }
    if(!arr){
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: value
          }
        }
      );
    }
      
    
     

  };



  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showNewTask: false });
    }
  };

  handleEnterKey = (event) => {
    if (event.keyCode === 13) {
      document.getElementById('filter').click();
      event.preventDefault();
    }
  }


  componentDidMount() {
    if(this.props.match.params.clientid){
			let that = this 
      let params = this.props.match.params.clientid;
      that.state.filters['clientid'] = params
      that.state.filters['state_global'] = null
			// that.setState({
			// 	filters:{
			// 		clientid: this.props.match.params.clientid
			// 	}
			// })
		}
    this.searchTaskById();
    document.addEventListener("keyup", this.handleEscape);
    loadCommonData();

    let that = this;

    if (this.props.taskList.length > 0) {
      this.setState({ tasks: this.props.taskList });
    } else {
      this.props.isLoading();
      this.loadTasks();
    }

    if (this.props.socket) {
      console.log("socket connected");

      this.props.socket.on("task_status", function(data) {
        console.log("event: ", data);
        if (data.type && data.type === "task_status") {
          that.props.isLoading();
          that.loadTasks()
        };
      });
    } else {
      console.log("not connected");
    }

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success) {
        if(response.data.client){
          that.setState({
            clients: [{ label: "All", value: 0 }, ...response.data.client]
          })
        }
        that.setState(
          {
            agents: [{ label: "All", value: 0 }, ...response.data.agents],
            types: [{ label: "All", value: 0 }, ...response.data.types],
            status: [{ label: "All", value: 0 }, ...response.data.status],
          });

        if (response.data.managers) {
          that.setState({
            managers: [{ label: "All", value: 0 }, ...response.data.managers]
          });
        }
      }
    });

    api.get('/api/form/zones').then(result => {
      if(result.data.success){
        that.setState({
          zones: result.data.zones
        })
      }
    })
  }

  loadTasks = (p = this.state.current, e, redirect=false) => {
    if(e)
      e.preventDefault()

    //this.props.isLoading();
    const params = buildUriParams(this.state.filters);
    console.log(params);
    const crits = "limit=" + this.state.limit + params;

    


    api.get("/api/bordereau/fetch?page=" + p + "&" + crits).then((response) => {
      if (response.data.success) {
        this.setState({
          tasks: response.data.tasks,
          pages: response.data.pages,
          total: response.data.total,
          bordereaux: response.data.bordereaux,
          montantTotal: response.data.montantTotal,
        });
      } else {
        if (response.data.errcode === 101) {
          this.props.setAuth(false);
        }
      }

      this.props.doneLoading();
      // console.log(this.state.tasks);
    });
    if(redirect){
      this.props.history.push("/bordereau");
    }
    
    return false;
  };

  


  componentDidUpdate(prev) {
    
    if(!equal(this.props.match.params.id, prev.match.params.id)){
      this.searchTaskById();
    }
    const that = this;

    if (this.props.socket && !prev.socket) {
      this.props.socket.on("task_status", function(data) {
        if (data.type && data.type === "task_status") {
          that.props.isLoading();
          that.loadTasks();
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("task_status");
    }
    document.removeEventListener("keyup", this.handleEscape);
  }

  loadForm(ent) {
    let that = this;

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success)
        that.setState({
          agents: response.data.agents,
          types: response.data.types
        });
    });
  }

  changeLimit = e => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  selectPage = p => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  formatDate = date => (date ? new Date(date) : undefined);

  afterPost = () => {
    this.props.isLoading();
    this.loadTasks();
    this.setState({
      showNewTask: false,
      is_duplicate: false
    });
  };

  selectDate = when => {
    if (when === "yesterday") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(-2, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(-1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "today") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment().format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "tomorrow") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(2, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    }
  };

  resetFilters = () => {
     //this.myFormRef.reset();
     //this.selectRef.select.clearValue();
    window.location.reload(false);
    //this.forceUpdate();
    //$( "#here" ).load(window.location.href + " #here" );
    //this.setState(state => ({ ...state, filters: {} }), this.loadTasks);
    
  };


  duplicateTask = id => {
    this.setState({
      showNewTask: true,
      selected_task: id,
      is_duplicate: true
    })
  }

  searchTaskById = ()=> {
    let taskId = 'task_id' 
    let params = this.props.match.params.id;
    this.state.filters['task_id'] = params

    // this.props.isLoading();
    // const querys = buildUriParams(this.state.filters);
    // console.log(querys);
    // const crits = "limit=" + this.state.limit + querys;


    // api.get("/api/v2/task/fetch?page=" + this.state.current + "&" + "limit=" + this.state.limit + "&" + "task_id=" + this.props.match.params.id).then((response) => {
    //   if (response.data.success) {
    //     this.setState({
    //       tasks: response.data.tasks,
    //       pages: response.data.pages,
    //       total: response.data.total
    //     });
    //   } else {
    //     if (response.data.errcode === 101) {
    //       this.props.setAuth(false);
    //     }
    //   }

    //   this.props.doneLoading();
    // });
    this.props.isLoading();

    this.loadTasks();
  }

  multiSelectHandler = (event, nameVal) =>{
    
    let arr = []
    console.log("event not option =>",event)
    for (var i = 0; i < event.length; i++ ){
      console.log("for loop =>",event[i].value)
      arr = arr.concat([event[i].value])
      console.log(arr)
      
    }
    this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: arr
          }
        }
      );
      
    
  }

  showDetails = () => {
    console.log("hello "+ this.state.isDetailsShown )
    let bool = this.state.isDetailsShown 
    this.setState({
      isDetailsShown: bool? false :true
    })
  }

  export = () => {
    //this.exportPDF.action = CONSTANTS.REMOTE_API + 'api/bordereau/fetch?page=1&limit=60';
    this.exportPDF.action = CONSTANTS.REMOTE_API + '/File/ECM21MAY03_file.pdf';
    
    this.exportPDF.submit();

  }
  addCode = (e) => {
		const value = e.target ? e.target.value : e.value;
		this.setState({
			value:value
		})
		

		if(!this.state.bordereauxSelected.includes(value) && value.length === 14){
			this.setState(prev => ({
				bordereauxSelected: [...prev.bordereauxSelected,  value],
				value:''
			}))
		}
		
	}
  deleteCode = (item) =>{
		let array =  this.state.bordereauxSelected
		const index = array.indexOf(item);
		if (index > -1) {
			array.splice(index, 1);
			this.setState({
				bordereauxSelected: array
			})
		}

	}
  receive = () => {
		api.post("/api/bordereau/receive", this.state.bordereauxSelected).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
				this.setState({
					showRecu: false,
					bordereauxSelected: []
          })
        this.loadTasks();
			}else{
				//this.props.doneLoading();
				this.setState({
          //showRecu: false,
          bordereauxSelected: []
        })

        //window.location.reload(false);
        console.log(result.data.error);
        this.setState({
          isAlert:true,
          error:result.data.error,
          message: result.data.error,
          variant:'danger'
        })

        this.setState({ error: result.data.error, loading: false });


			}

		})
	}


  submitForm = (e) => {
    e.preventDefault();
    //this.setState({ error: "", notImported: [], loading: true });
    this.setState({loading: true})
    const data = new FormData(e.target);
    api.post('api/bordereau/import', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(res => {
        if(res.data.error){
          this.setState({showImport: false, loading: false})
        }
        
        if(res.data.success){
          this.props.setFlash({
            active: true,
            message: "Action done",
            classes: "success",
            style: {}
          })
          this.setState({showImport: false, loading: false})
        }


    })
    .catch(err => {
        this.setState({ error: "Une erreur est survenu, veuillez contactez l'administrateur.", loading: false });
    })
  }


  fillBOnAction = (event, id) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let tab = this.state.bordereauxOnAction
    if (value && this.state.bordereauxOnAction.indexOf(id) === -1){ 
      tab.push(id)
      this.setState({
        bordereauxOnAction: tab
      })
    }else if(!value){
      const index = tab.indexOf(id);
      if (index > -1) {
        tab.splice(index, 1);
        this.setState({
          bordereauxSelected: tab
        })
      }
    }
    console.log(this.state.bordereauxOnAction)
  }

  applyAction = () => {
    //console.log(this.state.selectedActon.value)
    if(!this.state.selectedActon)
      return
    // if(this.state.selectedActon.value === 'print'){
    //     const action = CONSTANTS.REMOTE_API +'api/bordereau/actions?action='+this.state.selectedActon.value+'&token='+this.props.user.token;
    //     //const action =  api.get("/api/bordereau/actions?action="+this.state.selectedActon.value, this.state.bordereauxOnAction)
    //     window.location = action;
    //     return 
    // }
    api.post("/api/bordereau/actions?action="+this.state.selectedActon.value, this.state.bordereauxOnAction).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
        if(this.state.selectedActon.value === 'print' || this.state.selectedActon.value === 'printA4'){
          let url = CONSTANTS.REMOTE_API + result.data.file;
          //url += 'Zip/' + result.data.file;
          window.location = url;
          return
        }
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
				this.setState({
					showRecu: false,
					bordereauxOnAction: []
				})
        setTimeout(() => {
          window.location.reload(false);
        }, "1 second")
        
			}else{
				//this.props.doneLoading();
				this.setState({
					showRecu: false,
					bordereauxOnAction: []
				})

			}
		})
  }
  selectAll = (type) => {
    let tab = []
    if(type === 'select'){
      const slicedArray = this.state.bordereaux.slice(0, this.state.limit == 0 ? undefined : this.state.limit);
      slicedArray.map(item => {
        
        tab.push(item.key_bordereau)
        
      })
      this.setState({
          bordereauxOnAction: tab,
          sel:true
        })
    }else{
      this.setState({
        bordereauxOnAction: tab,
        sel:false
      })
    }


      // console.log(tab)
    // if (value && this.state.bordereauxOnAction.indexOf(id) === -1){ 
      
    // }
  }
  render() {
    
    const locations = communes.communes.map(item => ({
      label: item.nom + " (" + item.code_postal + ")",
      value: item.nom
    }));
    const {filters} = this.state;
    var selectedValue = [];
    var v = 2;

    let that = this
    const zones = Object.entries(that.state.zones).map(([key, i]) => ({
      label: key,
      value: key
    }))

    const state = [
      // {label: "All", value: " "},
      // {label: "Green", value: "done"},
      // {label: "Orange", value: "started"},
      // {label: "Grey", value: "null"},
      {label: "All", value: " "},
      {label: "Attente enlèvement", value: "done"},
      {label: "Attente réception (station)", value: "started"},
      {label: "Attente livraison ", value: "null"},
      {label: "En cours de livraison ", value: "livraison"},

    ];

    const stateGlobal = [
      {label: "All", value: " "},
      {label: "Waiting", value: "null"},
      {label: "Done", value: "done"},
    ];

    const retour = [
      {label: "All", value: " "},
      {label: "Retour", value: "R"},
      {label: "Non Retour", value: "NotR"},
    ];

    var today = new Date(),
    now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    return (
      <React.Fragment>
        <div className={"container-header"}>
          {/* <form method="post" ref={ref => this.exportPDF = ref}>
            <button onClick={e => this.export()}>get pdf</button>
            <input type="hidden" name={"x-sts-token"} ref={ref => this.userInfo = ref} value={this.props.user.token} />
          </form> */}
          <Trans i18nKey="label_bordereau" />

          {!this.props.auth ? <Redirect to="/login" /> : ""}

          {this.props.user &&
          (this.props.user.role === "manager" ||
            this.props.user.role === "commercial" ||
            this.props.user.role === "client" ||
            this.props.user.role === "sup ops" ||
            this.props.user.role === "admin" || 
            this.props.user.role === "superviseur") ? (
            <React.Fragment>
              <div>
                <button type="button" onClick={() => this.setState({ showNewTask: true })} className={"cbtn"}>
                  Ajouter un nouveau bordereau
                </button>
                <button type="button" onClick={() => this.setState({ showRecu: true ,error : ''})} className={"cbtn"}>
                  Bordereaux Reçu
                </button>
                <button type="button" onClick={() => this.setState({ showImport: true })} className={"cbtn"}>
                  Import
                </button>
              </div>
              
            </React.Fragment>
          ) : ('')}
        </div>

        <React.Fragment>
          {this.props.user.role === "admin" ? (
            <div className={"row mb-5"}>
              <div className={"col-md-3"}>
                <label>Manager</label>
                <Select
                  classNamePrefix={"custom-select"}
                  className={"custom-select-c "}
                  options={this.state.managers}
                  placeholder={"Created by"}
                  onChange={e => this.updateFilter(e, "author")}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"form ui-rect np"} id='here'>
    
            <form onSubmit={e => {this.props.isLoading(); this.loadTasks(1, e,true)}} ref={(el) => this.myFormRef = el}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <Select
                  // ref={ref => {
                  //   this.selectRef = ref;
                  // }}
                  //value ={!this.state.filters.agent ?  this.state.valueDefault.agents : this.state.selected.agent}
                  classNamePrefix={"custom-select"}
                  options={this.state.agents}
                  placeholder="Coursier"
                  onChange={e => this.updateFilter(e, "agent", false, false, true)}
                  
                />
              </div>

              <div className={"form-group col-md-3"}>
                <ClientInput
                      placeholder={"Client"}
                      required={false}
                      defaultValue={filters.clients || "" }
                      selectClient={sug => this.updateFilter({value: sug.client_name}, "client_name")}
                      type="text"
                      name="client_name"
                      className={"form-control col-md"}
                    />
              </div >
              <div className={"form-group col-md-3"}>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tout", value: "" },
                    { label: "colis", value: "colis" },
                    { label: "documents", value: "documents" }
                  ]}
                  placeholder="type"
                  onChange={e => this.updateFilter(e, "type")}
                />
                    
                </div>

              <div className={"form-group col-md-3"}>
                  <input
                    placeholder={"Code Bordereau"}
                    onChange={e => this.updateFilter(e, "code_bordereau")}
                    value={filters.name}
                    name="code_bordereau"
                    type="text"
                    className={"form-control"}
                  />
                
              </div>

            </div>
            <div className="row">
            <div className="col-md-2">
                    <Select
                      placeholder={"Zone Départ"}
                      classNamePrefix={"custom-select"}
                      name='zone_depart'
                      onChange={e => this.updateFilter(e, 'zone_depart')}
                      // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                      options={zones}
                    />
              </div>
              <div className="col-md-2">
                    <Select
                      placeholder={"Zone Arrivé"}
                      classNamePrefix={"custom-select"}
                      name='zone_arrive'
                      onChange={e => this.updateFilter(e, 'zone_arrive')}
                      // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                      options={zones}
                      size={5}
                    />
              </div>
              <div className="col-md-2">
                    <Select
                      placeholder={"Status"}
                      classNamePrefix={"custom-select"}
                      name='state'
                      onChange={e => this.updateFilter(e, 'state')}
                      options={state}
                      
                    />
              </div>
              <div className="col-md-2">
                    <Select
                      defaultValue={[{label: "Waiting", value: "null"}]}
                      placeholder={"Status global"}
                      classNamePrefix={"custom-select"}
                      name='state_global'
                      onChange={e => this.updateFilter(e, 'state_global')}
                      options={stateGlobal}
                    />
              </div>
              <div className={"col-md-2 form-group"}>
                  
                    <ReactDatePicker
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Date début (Creation)"}
                      timeFormat={"HH:mm"}
                      showTimeSelect={false}
                      className={"form-control"}
                      selected={this.formatDate(filters.date_debut)}
                      onChange={e =>
                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut", true)
                      }
                      selectsStart
                      startDate={this.formatDate(filters.date_debut)}
                      endDate={this.formatDate(filters.date_fin)}
                      wrapperClassName={"col-md-12"}
                    />
                </div>
                <div className={"col-md-2 form-group"}>
                  <ReactDatePicker
                    timeFormat={"HH:mm"}
                    dateFormat={"yyyy-MM-dd"}
                    placeholderText={"Date fin (Creation)"}
                    showTimeSelect={false}
                    className={"form-control"}
                    selected={this.formatDate(filters.date_fin)}
                    onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                    selectsEnd
                    startDate={this.formatDate(filters.date_debut)}
                    endDate={this.formatDate(filters.date_fin)}
                    minDate={this.formatDate(filters.date_debut)}
                    wrapperClassName={"col-md-12"}
                  />
                </div>
            </div>

            <div className={"row"}> 
              <div className="col-md-2">
                      <Select
                        //defaultValue={[{label: "Waiting", value: "null"}]}
                        placeholder={"Select Retour"}
                        classNamePrefix={"custom-select"}
                        name='retour'
                        onChange={e => this.updateFilter(e, 'retour')}
                        options={retour}
                      />
                </div>
              <div className={"col-md-2 form-group"}>
                    
                    <ReactDatePicker
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Date début (Assignation)"}
                      timeFormat={"HH:mm"}
                      showTimeSelect={false}
                      className={"form-control col-md-12"}
                      selected={this.formatDate(filters.date_debut_assign)}
                      onChange={e =>
                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut_assign", true)
                      }
                      selectsStart
                      startDate={this.formatDate(filters.date_debut_assign)}
                      endDate={this.formatDate(filters.date_fin_assign)}
                      wrapperClassName={"col-md-12"}
                    />
              </div>
              <div className={"col-md-2  form-group"}>
                <ReactDatePicker
                  timeFormat={"HH:mm"}
                  dateFormat={"yyyy-MM-dd"}
                  placeholderText={"Date fin (Assignation)"}
                  showTimeSelect={false}
                  className={"form-control col-md-12"}
                  selected={this.formatDate(filters.date_fin_assign)}
                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin_assign", true)}
                  selectsEnd
                  startDate={this.formatDate(filters.date_debut_assign)}
                  endDate={this.formatDate(filters.date_fin_assign)}
                  minDate={this.formatDate(filters.date_debut_assign)}
                  wrapperClassName={"col-md-12"}
                />
              </div>

              <div className={"col-md-2 form-group"}>
                    
                    <ReactDatePicker
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Date début (Delivered)"}
                      timeFormat={"HH:mm"}
                      showTimeSelect={false}
                      className={"form-control col-md-12"}
                      selected={this.formatDate(filters.date_debut_end)}
                      onChange={e =>
                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut_end", true)
                      }
                      selectsStart
                      startDate={this.formatDate(filters.date_debut_end)}
                      endDate={this.formatDate(filters.date_fin_end)}
                      wrapperClassName={"col-md-12"}
                    />
              </div>
              <div className={"col-md-2  form-group"}>
                <ReactDatePicker
                  timeFormat={"HH:mm"}
                  dateFormat={"yyyy-MM-dd"}
                  placeholderText={"Date fin (Delivered)"}
                  showTimeSelect={false}
                  className={"form-control col-md-12"}
                  selected={this.formatDate(filters.date_fin_end)}
                  onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin_end", true)}
                  selectsEnd
                  startDate={this.formatDate(filters.date_debut_end)}
                  endDate={this.formatDate(filters.date_fin_end)}
                  minDate={this.formatDate(filters.date_debut_end)}
                  wrapperClassName={"col-md-12"}
                />
              </div>
             
{/* 
              <div className='col-md-4'>
                  
              </div> */}
              </div>
              <div className="row" onKeyDown={this.handleEnterKey}>
                <div className={"col-md-12 text-right"}>
                  
                      
                  <ExportCSV  csvData={this.state.bordereaux} fileName={'Bordereau' + now} classBtn = {'btn btn-sm btn-outline-success mr-2'}/>
                  
                  <button type="button" onClick={this.resetFilters} className={"btn btn-sm btn-outline-primary"}>
                    Reinitialiser les filtres
                  </button>
                  <button id="filter" onClick={() => this.loadTasks(1)} className={"btn btn-sm btn-primary ml-2"}>
                    Filtrer
                  </button>
                  
                  {/* <input type="reset" value="reset button" /> */}
                </div>
            </div>
            </form>
          </div>
        </React.Fragment>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-3 items-found"}>
            <p>
              <b>{this.state.total}</b> Bordereaux trouvés
            </p>
          </div>
          <div className={"col-md-3 items-found"}>
            <p>
              Total montant bordereaux <b> {this.state.montantTotal} DZD</b> 
            </p>
          </div>

          <div className={"col-md-3 per-page-wrap"}>
          
           
            
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>
          <div className="col-md-3 ">
            <div className="row text-right">
                <div className="col-8"> 
                    <Select
                      classNamePrefix={"custom-select"}
                      onChange={e => this.setState({selectedActon: e})}
                      //className={"per-page nm"}
                      options={[{label: 'Actions groupées', value: ''}, 
                                {label: 'Supprimer', value: 'delete'},
                                {label: 'Echec', value: 'echec'},
                                {label: 'Imprimer A6', value: 'print'},
                                {label: 'Imprimer A4', value: 'printA4'},
                              ]}
                      value={this.state.selectedActon ? this.state.selectedActon : {label: 'Actions groupées', value: ''}}
                    />
                </div>
                <div className="col-4">
                  <button  className={"btn  btn-primary mt-1"} onClick={e => this.applyAction()}>
                        Appliquer
                  </button>
                  {/* <button  className={"btn  btn-primary"} onClick={e => this.applyAction()}>
                        Appliquer
                  </button> */}
                </div>
            </div>
          </div>
        </div>
        

        <WTNotifications
          connection={this.props.connection}
          event={'new'}
          condition={(message) => message === "new_tasks"}
          component={(message, active, close) => <RenderNotification
                                                    refresh={() => this.loadTasks(1)}
                                                    active={active}
                                                    close={close}
                                                    message={message}
                                                  />}
          alwaysActive
        />

        <div className="ui-rect np">
            <div className="table .table-striped m-1">
              <div className={"row  table-row m-2"} >
                <div className={"col-md-2 "}><strong>CODE</strong></div>
                <div className={"col-md-1 "}><strong>Client</strong></div>
                <div className={"col-md-3"}><strong>Trajet</strong></div>
                {/* <div className={"col-md-2 "}><strong>Arrivée</strong></div> */}
                <div className={"col-md-1 "}><strong>Type</strong></div>
                <div className={"col-md-1"}><strong>Ajouté par</strong></div>
                <div className={"col-md-1"}><strong>Tarif/ Collect</strong></div>
                <div className={"col-md-2 text-center"}><strong>Action</strong></div>
                <div className={"col-md-1 text-center"}>
                  {!this.state.sel ? <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('select')}> 
                    <strong>Select All</strong>
                  </botton> 
                  : 
                  <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('clear')}> 
                    <strong>Clear All</strong>
                  </botton>
                  }
                 
                </div>
                
              </div>

              {this.state.bordereaux.map((bordereau, i) => (
                <BordereauRow idx = {i} bordereau = {bordereau} data ={this.state} duplicateTask={this.duplicateTask} handlers={this.handlers} />
               

                
              ))}

              {this.state.bordereaux.length === 0 && (
                <div className={"col-md-12 text-center my-3"}>
                  <Trans i18nKey={"empty_tasks"} />
                </div>
              )}
            </div>
        </div>

        {this.state.showNewTask && (
          <div className={`ui-overlay open`}>
            <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
              <button
                onClick={() => this.setState({ showNewTask: false })}
                className={"ui-overlay-close btn btn-danger btn-sm"}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

              <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} />
            </div>
          </div>
        )}

          <Modal show={this.state.showRecu} centered>
							<Modal.Header >
							    <Modal.Title>Termine la tache des bordereaux reçu au niveau du centre de tri</Modal.Title>
							</Modal.Header>
							<Modal.Body>
                  {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                  <div className={"form-group col-md-12"}>
                    <label><b>Veuillez entrer les codes bordereaux à assigner  *</b></label>
                    <input
                      type="text"
                      value={this.state.value}
                      name={`bordereau`}
                      className={"form-control"}
                      onChange={e => this.addCode(e)}
                    />
                  </div>
                  {this.state.bordereauxSelected.map(item => (
                    <div className='badge badge-light mr-2 my-2 shadow-sm'>
                      <span style={{fontSize: 15}} >{item} </span> <button className="btnem" onClick={e => this.deleteCode(item)}><FontAwesomeIcon  icon={faTimesCircle} color="#d9534f"/></button>
                      
                    </div>
                  ))

                  }

							</Modal.Body>
							<Modal.Footer>
                  <button className={"btn btn-danger"}  onClick={e => this.setState({showRecu: false, bordereauxSelected: []})}>
                    Annuler
                  </button>
                  {this.state.bordereauxSelected.length > 0  && <button type='submit' className={"btn btn-success"} onClick={e => this.receive()}>
                    Assigner 
                  </button>}
							</Modal.Footer>
					</Modal>	

          <Modal show={this.state.showImport} centered>
							<Modal.Header >
							    <Modal.Title>Importer une liste de nouveaux du nouveaux bordereaux</Modal.Title>
							</Modal.Header>
							<Modal.Body>
                <a  href={CONSTANTS.REMOTE_API+'/File/template_bordereau.xlsx'} target={"_blank"} className={"btn btn-sm btn-info"}>Télécharger le fichier template</a>
                <br/><br/>
                
                <form  onSubmit={this.submitForm}>
                  <input type="file" name="bordereau_file" className={""} />
                  
                  <button disabled={this.state.loading} type="submit" className={"btn btn-success float-right"}>
                    {this.state.loading ? <FontAwesomeIcon icon={faSpinner}  spin={true} /> : (<><FontAwesomeIcon icon={faArrowUp} className={"mr-2"} />Envoyer</>)}
                  </button>
                </form>
							</Modal.Body>
							<Modal.Footer>
                <button className={"btn btn-danger"}  onClick={e => this.setState({showImport: false})}>
                    Annuler
                  </button>

							</Modal.Footer>
					</Modal>	
      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <BordereauListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

const RenderNotification = ({message, active, close, refresh}) => {


  return (
    <>
      <div onClick={close} className={"task-notifications "+ (active && "active")}>

        <button onClick={refresh} className={"cbtn"} type="button">
          Nouvelles mise à jours disponible
          <FontAwesomeIcon className={"ml-2"} icon={faRedo} />
        </button>
      </div>
    </>
  )
}

const newComp = withNotifications(BordereauListContainer);
export default withNotifications(connect(
  mapStateToProps,
  mapDispatchToProps
)(BordereauListContainer));
