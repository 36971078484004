import React, { Component } from 'react';

export default class Footer extends Component {
	render() {
		return (
			<div className="footer-wrap">
				<div className="footer-content" >
					<center><small>Copyright © 2023 WiTrack </small></center>
				</div>
			</div>
		)
	}
}
