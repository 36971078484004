import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";
import { setFlash, closeFlash, setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';

import { faCopy} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}


 class AccountMyApp extends Component {

 	constructor(props) {
 		super(props);
 		this.formSubmit = this.formSubmit.bind(this);
 		this.toggleCheck = this.toggleCheck.bind(this);
		this.handleAppCreationSubmit = this.handleAppCreationSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		

 		this.state = {
 				name_app: null,
 				token: null,
				showModal: false,
				form: {
					username: "",
					code: "",
					nameapp: ""
				},
				warning: '',
				showAlert: false,
 		}

 	}


 	componentDidMount() {
		
		this.props.isLoading();
 		let that = this;
		 console.log('username'+ that.props.user.username)
		let s = that.state;
		s.form['username'] = that.props.user.username;
		that.setState({...s});
 		api.get('/api/user/'+this.props.user.userid+'?tab=myapp&token='+this.props.user.token)
 		.then(function(response) {
			console.log('from user'+JSON.stringify(response.data.apps[0].name_app))
 			that.setState({
				name_app: response.data.apps[0].name_app,
				token:response.data.apps[0].token
			});
			
			
 			that.props.doneLoading();
 		}).catch(err => that.props.doneLoading())
 		
 	}

 	

 	toggleCheck(e) {
 		this.setState({
 			[e.target.name]: e.target.checked
 		})
 	}

 	formSubmit(e) {
 		e.preventDefault();

 		this.props.isLoading();

 		const data = {
 			user: this.props.user,
			agent: this.state
 		}

 		let that = this;

 		api.post('/api/user/edit/'+this.props.user.userid, data)
		.then(function(response) {


			if(response.data.success) {
				that.props.setFlash({
					active: true,
					message: "Privacy settings have been succesfully updated.",
					classes: "success",
					style: {}
				})
			}

			that.props.doneLoading();

		})

 		
 	}
	 handleInputChange(e) {
		let s = this.state;
		s.form[e.target.name] = e.target.value;
		this.setState({...s});
	}


	 handleAppCreationSubmit(e){
		e.preventDefault();
		let that = this;
		this.props.isLoading({message:'Creation', animate:true});
		var nameapp = that.state.form.nameapp

		api.post('/api/clientapps/new', this.state.form)
		.then(function(response) {
			

			if(response.data && response.data.success === true) {
				
				console.log('token'+response.data.token)
				that.setState({
					//showModal:true,
					name_app: nameapp,
					token:response.data.token
				})			

			} else {
				if(response.data && response.data.success === false)
					that.setState({
						warning: response.data.error,
						showAlert: true
					})

			}
				
				

			that.props.doneLoading();
		})
		.catch(error => {
			console.log(error);
			that.props.doneLoading();
		})

	} 

	render() {
		let that = this
		return(

			<div className='col-md-6'>
				<div className='row'>
					<div className='col-8'>Télécharger la documention <a href="https://api.mars.witrack.digital/Documentation.pdf"  target={"_blank"}>ici</a> afin de pouvoir utiliser l'API</div>
					<div className='col-4'>
						<button class="btn btn-outline-primary" type="button" onClick={() => {this.setState({showModal:true})}}>
							Nouvelle Apllication
						</button>
					</div>
				</div>
				<br/>
				<div className='row ui-rect np'>
					<div className='col-4'>Nom Application</div>
					<div className='col-8'>Token</div>
				</div>

				
				{this.state.name_app !== null  && <div className='row ui-rect np'>
					<div className='col-4'>{this.state.name_app}</div>
					<div className='col-8'>{this.state.token}</div>
				</div>}
{/* 
				{this.state.name_app === null  && <div className='row ui-rect np text-center'>
					<div className='col-12'>Aucune Application à afficher </div>
					
				</div>} */}

				<Modal show={this.state.showModal} centered onHide={() => {this.setState({showModal:false}) }}>
                        <Modal.Header closeButton>
                          <Modal.Title>Creaction de l'application</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							{this.state.name_app !== null  && <div>
								Vous êtes prêt à commencer à utiliser notre API
								<br/>
								<br/>
								<label class="font-weight-light">LA CLE DE  L'API EST</label>
								<div class="input-group mb-3">
									<input type="text" class="form-control" value={this.state.token} aria-label="Recipient's username" aria-describedby="basic-addon2" id="token" disabled/>
									<div class="input-group-append">
										<button class="btn btn-outline-secondary" type="button" onClick={() => {navigator.clipboard.writeText(this.state.token)}}>
											<FontAwesomeIcon  className={"ml-2"} icon={faCopy} />
										</button>
									</div>
								</div>
								Télécharger la documention <a href="https://api.mars.witrack.digital/Documentation.pdf"  target={"_blank"}>ici</a> afin de pouvoir utiliser l'API
								
								<button className={"shadow cbtn btn-primary float-right large"} onClick={() => this.setState({showModal:false})}>Terminer</button>
							</div>}
							{this.state.name_app === null && <div>
								{this.state.showAlert && <Alert onClose={() => this.setState({showAlert:false})} dismissible variant='warning'>
									{this.state.warning}
								</Alert>}
								<form onSubmit={this.handleAppCreationSubmit}  name="loginAppForm">
										<div class="form__group field">
											<input class="form__field"     name="username"  onChange={this.handleInputChange} value={this.state.form.username}  disabled />
											<label for="name" class="form__label" ><Trans i18nKey="user_username" /> </label>
										</div>
										<br/>
										<div class="form__group field">
											<input class="form__field"     name="nameapp"  onChange={this.handleInputChange} value={this.state.form.nameapp}  required />
											<label for="name" class="form__label" ><Trans i18nKey="label_appname" /> </label>
										</div>

										<br/>

										


										<div className={"c-form-row form-group clearfix"}>
											
											<button className={"shadow cbtn btn-primary float-right large"} type="submit">Créer</button>
										</div>
										<div className="clear"></div>
								
								</form>
							</div>}
                        </Modal.Body>
                        
                    </Modal>
			</div>

			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMyApp);