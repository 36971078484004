import React from "react";
import { Link } from "react-router-dom";
import { faTimes , faCoins, faHandPointLeft, faHistory} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import * as CONSTANTS from "../../Utils/Constants";
import MoreInfoDialog, { Item } from "../../Utils/MoreInfoDialog";
import { connect } from "react-redux";

import Modal from 'react-bootstrap/Modal';
import api from "../../Utils/Api";
import Alert from 'react-bootstrap/Alert';
//import { modalTemplate } from '../Modal/modalTemplate';
import { ModalTemplate } from "../Modal/ModalTemplate";
import { ExportCSV } from "../../Components/Rapport/ExportCSV";


class CollectRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: {},
      showDetails: !!this.props.show,
      showPhotos: false,
      selectedIdx: false,
      edit: false,
      showModel: false,
      variant: 'success',
      message: '',
      isAlert:false,
      data:{
				collect: '',
			},
      isStatusShown :false,
      isRemiseShown : false,
      sel:false
    };
  }

  toggleTask = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  showPhotos = idx => this.setState({ showPhotos: true, selectedIdx: idx });


  hidePhotos = () => this.setState({ showPhotos: false, selectedIdx: false });


  changeColorBadge = (item) =>{
    if(item == 1){
      return "badge badge-success badge-pill";
    }else if(item == 0){
      return "badge badge-danger badge-pill";
    }
    return  "badge badge-light badge-pill";
  }
  changeColorBadgeDoc = (item) =>{
    if(item == 'true'){
      return "badge badge-success badge-pill";
    }
      return "badge badge-danger badge-pill";
    
  }
  changeColorSubTask = (item) =>{
    if(item == 'done')
      return 'text-success'
    if(item == 'started')
      return 'text-warning'
    return 'text-muted'
  }

  DoneTask = (taskid) => {
    api.post('/api/task/done/'+taskid).then(result => {
      if(result.data.success){
        
        this.setState({
          isAlert:true,
          message: 'Action Done Successfully',
        })
        setTimeout(() => {
          this.setState({
            showModel: false
          })
        }, 3000);
        
        window.location.reload(false);
      }else{
        this.setState({
          isAlert:true,
          message: result.data.error,
          variant:'danger'
        })
      }
    })
  }

  changeStatus = () => {
		
		// const formData = new FormData(this.clientForm);
		// let data = {};
		// for (var [key, value] of formData.entries()) data[key] = value;
		
		api.post('/api/bordereau/'+ this.props.collect.key_bordereau + '/collect',this.state.data).then(result =>{
		  
		  if(result.data.success){
			this.setState({
			  isStatusShown:false
			})
			window.location.reload(false);
			
		  }else{
			this.setState({error: result.data.error})
      //return result.data.error
			console.log('error'+ result.data.error)
		  }
		})
	}

	updateData = (event,name) =>{
	console.log(event)
	this.setState({
		data:{
		...this.state.data,
		[name]: event.target ? event.target.value : event.value
		}
	});
	}
  
  // remise = () => {
   
  //   api.post('/api/collect/remise/'+ this.props.collect.key_bordereau).then(result =>{
		  
		  // if(result.data.success){
			
			 // window.location.reload(false);
			
		  // }
		// })
  // }

  doRemise = () => {
		
		// const formData = new FormData(this.clientForm);
		// let data = {};
		// for (var [key, value] of formData.entries()) data[key] = value;
		
		api.post('/api/bordereau/'+ this.props.collect.key_bordereau + '/remise',this.state.data).then(result =>{
		  
		  if(result.data.success){
			this.setState({
			  isRemiseShown:false
			})
			window.location.reload(false);
			
		  }else{
			this.setState({error: result.data.error})
      //return result.data.error
			console.log('error'+ result.data.error)
		  }
		})
    }


  showPdf = () => {
    console.log("clicked");
    api.post('/api/bordereau/'+ this.props.collect.key_bordereau + '/getRemiseFile',this.state.data).then(result =>{
      if(result.data.success){
        let url = CONSTANTS.REMOTE_API + 'File/Collect/' ;
        url +=  result.data.file + '.pdf';
        window.open(url, '_blank');
      }else{
        this.setState({error: result.data.error})
      }
    });
    
  }



  // changeStatus = () => {
		
	// 	// const formData = new FormData(this.clientForm);
	// 	// let data = {};
	// 	// for (var [key, value] of formData.entries()) data[key] = value;
		
	// 	api.post('/api/bordereau/'+ this.props.collect.key_bordereau + '/collect',this.state.data).then(result =>{
		  
	// 	  if(result.data.success){
	// 		this.setState({
	// 		  isStatusShown:false
	// 		})
	// 		window.location.reload(false);
			
	// 	  }else{
	// 		this.setState({error: result.data.error})
	// 		console.log('error'+ result.data.error)
	// 	  }
	// 	})
	//   }

  render() {
    const { collect, idx } = this.props;

    console.log('remis=> '+collect.remise)
    

    return (
      <>

        <React.Fragment>
          <div
            data-row-index={idx}
            className="row task-row no-gutters py-3"

          >
              <div  className={"col-md-12"} >
                  <div className={"row table-row mt-2"}>
                    {/* ----------------to display Date --------------- */}
                    <div className={"col-md-2"}>
                     
                      {collect.bordereau_code}
                    </div>

                    <div className={"col-md-1"}>
                      {collect.client}
                    </div>

                    {/*---------- to display Trajet  ------------*/}
                    
                    <div className="col-md-1">
                      {collect.author}
                    </div>
                    <div className="col-md-1">
                      {collect.agent}
                    </div>
                    <div className="col-md-1">
                      {collect.collect}
                    </div>
                    <div className="col-md-1">
                      {collect.tarif}
                    </div> 
                    <div className="col-md-1">
                       {collect.somme}
                    </div>
                    <div className="col-md-1">
                       {collect.due}
                    </div>
                    <div className="col-md-1">
                        {(collect.isDone  && collect.due != 0 )&& <div style={{ border: 'solid 1px #d9534f' , borderRadius : '3px', textAlign: 'center' , color: '#d9534f' , width: '50px'}}> A collecté </div>}
                        {(collect.isDone && collect.due == 0 && !collect.remise) && <div style={{ border: 'solid 1px #f0ad4e' , borderRadius : '3px', textAlign: 'center' , color: '#f0ad4e' , width: '50px'}}> A remettre </div>}
                        {(collect.isDone && collect.due == 0 && collect.remise == 'encours') && <div onClick={e => this.showPdf(e)} style={{ border: 'solid 1px #f0ad4e' , borderRadius : '3px', textAlign: 'center' , color: '#f0ad4e' , width: '50px'}}> Encours </div>}
                        {(collect.isDone && collect.due == 0 && collect.remise == 'true') && <div style={{ border: 'solid 1px #5cb85c' , borderRadius : '3px', textAlign: 'center' , color: '#5cb85c' , width: '50px'}}> Done </div>}
                    </div>
                    <div className={"col-md-1 text-center"}>
                        {/* {(collect.isDone  && collect.due != 0 )&& <ModalTemplate action= {this.changeStatus} /> }  */}
                        {(collect.isDone  && collect.due != 0 )&& <button type="button" class="btn btn-primary" onClick={e=> (this.setState({isStatusShown: true}))}><FontAwesomeIcon className={"ml-2"} icon={faCoins} color="#E5C100 "/>Collecter</button>}
                        {(collect.isDone && collect.due == 0 && (!collect.remise || collect.remise == 'encours') && this.props.user.role !== "commercial")&& <button type="button" class="btn " onClick={e=> (this.setState({isRemiseShown: true}))}><FontAwesomeIcon className={"ml-2"} icon={faHandPointLeft} color="#f0ad4e"/> Remettre</button>}
                        {/* {((collect.isDone && collect.due == 0 && collect.remise == 'true')  && this.props.user.role !== "commercial")&& <button type="button" class="btn " onClick={e=> (this.setState({isHistoricShown: true}))}><FontAwesomeIcon className={"ml-2"} icon={faHistory} color="#f0ad4e"/> Historique</button>} */}

                        {(this.props.user.role !== "commercial")&& <button type="button" class="btn " onClick={e=> (this.setState({isHistoricShown: true}))}><FontAwesomeIcon className={"ml-2"} icon={faHistory} color="#f0ad4e"/> Historique</button>}
                        {/* <input
                              name="apply"
                              type="checkbox" 
                              className="text-right ml-2"
                              onChange={e => this.props.handlers.fillBOnAction(e, collect.key_bordereau)}
                            /> */}
                           
                        </div>
                        {console.log('collect.code:', collect.bordereau_code)}
                        {console.log('collect.isDone:', collect.isDone)}
                        {console.log('collect.remise:', collect.remise)}
                        {console.log('collect.due:', collect.due)}
                        {console.log('this.props.user.role:', this.props.user.role)}
                    <div className={"col-md-1 text-center"}>
                    {this.props.data.bordereauxOnAction.includes(collect.key_bordereau) ? <input
                              name={"apply"}
                              type="checkbox" 
                              //className="text-right ml-2"
                              checked
                              onChange={e => this.props.handlers.fillBOnAction(e, collect.key_bordereau)}
                            /> :

                            <input
                              name={"apply"}
                              type="checkbox" 
                              //className="text-right ml-2"
                              onChange={e => this.props.handlers.fillBOnAction(e, collect.key_bordereau)}
                            />
                            }
                    </div>
                      
                    
              </div>
            </div>


            <Modal show={this.state.isStatusShown} centered>
                    <Modal.Header >
                      <Modal.Title>Modifier la collect de  {collect.bordereau_code}</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    {Boolean(this.state.error) && <span className={"alert alert-danger d-block"}>{this.state.error}</span>}
                      <form >
                        
                          <p class="text-center"><b>Entrer le montant collecté par le coursier</b></p>
                          <input 
                            type="text"
                            name="collect"
                            className={"form-control"}   
                            onChange={e => this.updateData(e, 'collect')}
                          />
                      
                      </form>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e=> (this.setState({isStatusShown: false}))}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.changeStatus(e)}>
                        Collecter
                      </button>
                      
                    </Modal.Footer>
              </Modal>

              <Modal show={this.state.isRemiseShown} centered>
                    <Modal.Header >
                      <Modal.Title>Remis de  la collect pour le client</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                      Cliquer sur confirmer pour confirmer la remise de la collecte au client
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e=> (this.setState({isRemiseShown: false}))}>
                        Annuler
                      </button>
                      <button type='submit' className={"btn btn-primary"}  onClick={e => this.doRemise(e)}>
                        Confirmer
                      </button>
                      
                    </Modal.Footer>
              </Modal>


              <Modal show={this.state.isHistoricShown} centered>
                    <Modal.Header >
                      <Modal.Title>Historique collect et Remise</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                    <div className="row">
                      <div className="col-md-3">
                        Actions
                        </div>                        
                      <div className="col-md-3">
                        Valeur
                      </div>

                      <div className="col-md-3">
                       Ajouté par
                       </div>
                       <div className="col-md-3">
                        Date de creation
                      </div>

                    </div>
                    <hr/>
                    {collect.historyCollectRemis && collect.historyCollectRemis.map(item => <div className="row">
                      <div className="col-md-3">
                        {item.action}
                      </div>

                      <div className="col-md-3">
                        {item.valeur}
                      </div>

                      <div className="col-md-3">
                        {item.created_by}
                      </div>

                      <div className="col-md-3">
                        {item.created_at}
                      </div>

                    </div>)}
                    </Modal.Body>
                    <Modal.Footer>
                      
                      <button type='submit' className={"btn btn-primary"}   onClick={e=> (this.setState({isHistoricShown: false}))}>
                        Fermer
                      </button>
                      
                    </Modal.Footer>
              </Modal>


          </div>
        </React.Fragment>
      </>
    );
  }
}

const mapState = state => ({ user: state.user });

export default connect(mapState)(CollectRow);
