import React, { Component } from "react";
import _ from "lodash";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./lib/Components/Header";
import Body from "./lib/Components/Body";
import Footer from "./lib/Components/Footer";
import SideMenu from "./lib/Components/Menus/SideMenu";
import AppRouter from "./lib/Components/Router";
import openSocket from "socket.io-client";
import { connect } from "react-redux";
import SocketContext from "./lib/Utils/socket-context";

import getNotifications from "./lib/Utils/getNotifications.js";



import {
  updateData,
  updateNotifications,
  setAuthAction,
  doneLoading,
  setOverlay,
  setSidebarVisible
} from "./lib/Redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import "./lib/i18n/index";
import audioLink from "./lib/plucky.mp3";
import api from "./lib/Utils/Api";
import * as CONSTANTS from "./lib/Utils/Constants";

import {RealTime, Connection} from "react-socket-notification";


var io;

const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    notifications: state.notifications,
    notificationsHash: state.notificationsHash,
    loading: state.loading,
    overlay: state.overlay,
    loadingMessage: state.loadingMessage,
    sidebarHidden: state.sidebarHidden
  };
};

function mapDispatchToProps(dispatch) {
  return {
    doneLoading: user => dispatch(doneLoading()),
    setOverlay: e => dispatch(setOverlay(e)),
    updateNotifications: e => dispatch(updateNotifications(e)),
    setAuth: e => dispatch(setAuthAction(e)),
    updateData: e => dispatch(updateData(e)),
    setSidebarVisible: e => dispatch(setSidebarVisible(e))
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsHash: this.props.notificationsHash,
      loadingAnimate: "...",
      loading: this.props.loading,
      overlay: this.props.overlay,
      loadingMessage: {
        message: this.props.loadingMessage.message,
        animate: this.props.loadingMessage.animate
      },
      inlineNotificationActive: false,
      inlineNotification: ""
    };

    this.hideOverlay = this.hideOverlay.bind(this);

    this.animationInterval = "";
    this.animateLoading = this.animateLoading.bind(this);

    this.audio = new Audio(audioLink);
    this.notificationsPoll = _.debounce(this.notificationsPoll.bind(this), 200);

    this.getNotifications = getNotifications.bind(this);


    this.connection = new Connection('https://api.witrack.tictechs.tech:85');
   // this.connection = new Connection('https://api.witrack.tictechs.tech');
  }

  animateLoading() {
    const that = this;
    this.animationInterval = setInterval(function() {
      if (that.state.loadingAnimate.length !== 3) {
        that.setState({ loadingAnimate: that.state.loadingAnimate + "." });
      } else {
        that.setState({ loadingAnimate: "" });
      }
    }, 600);
  }

  hideOverlay() {
    this.props.setOverlay(0);
  }

  componentWillReceiveProps(nextProp) {
    if (
      nextProp &&
      (!(this.props.loading === nextProp.loading) ||
        !(this.props.overlay.enabled === nextProp.overlay.enabled))
    ) {
      if (nextProp.loading === true && nextProp.loadingMessage.animate)
        this.animateLoading();
      else {
        clearInterval(this.animationInterval);
      }

      this.setState({
        loading: nextProp.loading,
        loadingMessage: nextProp.loadingMessage,
        overlay: nextProp.overlay
      });
    } else {
      clearInterval(this.animationInterval);
    }

    if (
      nextProp &&
      this.props.notificationsHash !== nextProp.notificationsHash
    ) {
      if (this.state.notificationsHash.length === 0) this.notificationsPoll();
      //const that = this;
      this.setState({ notificationsHash: nextProp.notificationsHash });
    }

    if (nextProp && this.props.notifications !== nextProp.notifications) {
      const diff = nextProp.notifications.filter(x => {
        return (
          this.props.notifications.length > 0 &&
          this.props.notifications.filter(v => v.id === x.id).length === 0
        );
      });

      if (diff[0]) this.displayNotification(diff);
    }

    //this.notificationsPoll(nextProp.notificationsHash);

    if (nextProp && this.props.auth !== nextProp.auth && nextProp.auth) {
      if (nextProp.user.token) this.getNotifications(nextProp.user.token);
    }
  }

  displayNotification = notification => {
    this.setState({
      inlineNotification: notification[0],
      inlineNotificationActive: true
    });

    const that = this;

    setTimeout(function() {
      that.setState({ inlineNotificationActive: false });
    }, 5000);
  };

  notificationsPoll() {
    const that = this;
    const fetchNotifications = _.debounce(this.getNotifications, 5000);

    if (this.props.auth) { 
      fetchNotifications();

      _.debounce(() => {
        var source = new EventSource(
          CONSTANTS.REMOTE_API +
            "api/notification/stream?hash=" +
            this.state.notificationsHash +
            "&token=" +
            that.props.user.token
        );

        const handleMessage = function(event) {
          const resp = JSON.parse(event.data);

          if (resp.success & (resp.success === true)) {
            that.getNotifications().then(() => that.audio.play());
          }

          if (resp.deb === 2) {

            var newSource = new EventSource(
              CONSTANTS.REMOTE_API +
              "api/notification/stream?hash=" +
              that.state.notificationsHash +
              "&token=" +
              that.props.user.token
            );
            newSource.onmessage = handleMessage;
            event.target.close();
          }
        };

        source.onmessage = handleMessage;
      }, 10000)();
    }
  }

  componentDidMount() {
    if (this.props.user.role == "admin" || this.props.user.role == "manager"){
      this.notificationsPoll();
    }

    const that = this;

    if (this.props.auth) {
      /**
       * Uncommenct to enable SocketIO connection, more realtime updates
       */
      // io = openSocket(CONSTANTS.REMOTE_SOCKET, {
      // 	secure: true,
      // 	query: {
      // 		token: this.props.user.token,
      // 		userid: this.props.user.userid
      // 	}
      // });

      // , {transports: ['websocket'], upgrade: false});

      //this.getNotifications();
    }
    //_.debounce(getNotifications.bind(this), 150)();
  }
  render() {

    

    return (
      <BrowserRouter>

        <div className={"appRootEl"}>
      

          
          <div
            className={
              this.state.inlineNotificationActive
                ? "notification-wrapper active"
                : "notification-wrapper"
            }
          >
            <div className={"notification-body"}>
              <span className={"notification-content"}>
                {this.state.inlineNotification.content
                  ? this.state.inlineNotification.content
                  : ""}
              </span>
            </div>
          </div>

          <div
            className={
              "loading-overlay" + (this.state.overlay.enabled ? " open" : "")
            }
          >
            <div className={"text"}>
              {this.state.overlay.label ? (
                <FontAwesomeIcon icon={faCheck} className={"overlay-success"} />
              ) : (
                ""
              )}
              {this.state.overlay.message}

              {this.state.overlay.button ? (
                <div className={"overlay-button"}>
                  <a
                    target={
                      this.state.overlay.button.type === "link" ? "_blank" : ""
                    }
                    href={this.state.overlay.button.link}
                    onClick={this.hideOverlay}
                  >
                    <button
                      type="button"
                      className={"btn btn-primary btn-success mt-3"}
                    >
                      {this.state.overlay.button.label}
                    </button>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            className={"loading-overlay" + (this.state.loading ? " open" : "")}
          >
            <div className={"text"}>
              {this.state.loadingMessage.message + this.state.loadingAnimate}
            </div>
          </div>

          <div className={"row no-gutters app-wrap "}>

            {/* {this.props.sidebarHidden && (<div className={"sidebar-handle open"}>
              <button onClick={e => this.props.setSidebarVisible(true)}><FontAwesomeIcon icon={faArrowRight} /></button>
            </div>)} */}

            {/* <Switch>
              <Route exact path="/login" component={() => <></>} />
              <Route
                path="/"
                component={() => (
                  <div className={"sidebar"+ (this.props.sidebarHidden ? " hidden" : "")} >
                    <div className={"sidebar-handle hide"}>
                      <button onClick={e => this.props.setSidebarVisible(false)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                    <div className={"sideMenu"}>
                      

                      <SideMenu />
                    </div>
                  </div>
                )}
              />
            </Switch> */}

            <div className={"no-gutters mainContainer"}>
              <SocketContext.Provider value={io}>
                <Switch>
                  <Route exact path="/login" component={() => <></>} />
                  <Route path="/" component={Header} />
                </Switch>

                {/* <Header /> */}
                <Body>
                  <RealTime connection={this.connection}>
                    <AppRouter />
                  </RealTime>
                </Body>
                <Footer />
              </SocketContext.Provider>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
