import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { setAuthAction, startLoading, doneLoading } from './../../Redux/actions/index';
import api from '../../Utils/Api';



function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
  };
}


 class AccountPlan extends Component {

 	constructor(props) {
		super(props);
		 

 		this.state = {}

 	}


 	componentDidMount() {
 		this.props.isLoading();
 		let that = this;

 		api.get('/api/user/'+this.props.user.userid+'?tab=plan&token='+this.props.user.token)
 		.then(function(response) {
 			console.log(response.data);
 			that.setState(response.data);
 			that.props.doneLoading();
 		}).catch(err => that.props.doneLoading())
 	}

 	

	render() {

		return (

			 
				<React.Fragment>

				{this.state.plan_name && (

					
					<React.Fragment>
						{this.state.plan_name === 'free' && (<Link to={"/plan/new"} className={"btn-sm btn-success"}>Upgrade my plan</Link>)}
						{this.state.plan_name === 'prepaid' && (<Link to={"/manager/fill"} className={"btn-sm btn-success"}>Refill balance</Link>)}
						<h6 className={"mt-3"}>Current plan : {this.state.plan_name} </h6>
						<br />

						{this.state.plan_name === 'free' && (
						<div className={"row mt-5"}>
							

							<div className={"col-md-4"}>
								<b>Agents limit :</b> <br/>
								Created {this.state.used_agents+" agents out of "+this.state.plan_agent} maximum
								
							</div>

							<div className={"col-md-4"}>
								<b>Task limit :</b> <br/>
								Used {this.state.used_tasks+" out of "+this.state.plan_task}
							</div>

						</div>)}

						{this.state.plan_name === 'prepaid' && (
							<div className={"row mt-5"}>
								<div className={"col-md-4"}>
									<b>Balance :</b> <br/>
									{this.state.balance} DZD
									
								</div>
							</div>
						)}

					</React.Fragment>
				)}

				</React.Fragment>
			
			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPlan);