import React, {useState, Component } from "react";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../Components/Bordereau/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../Utils/socket-context";
import api from "../Utils/Api";
import Pagination from "../Utils/Pagination";
import { loadCommonData, buildUriParams } from "../Utils/Helpers";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo, faTimesCircle, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import BordereauNewContainer from "./BordereauNewContainer";
import communes from "../../assets/communes.json";
//import BordereauRow from "./BordereauRow";
import CollectRow from "../Components/Collect/CollectRow";

import WTNotifications, { withNotifications } from "react-socket-notification";
import ClientInput from "../Components/Task/subcomponents/ClientInput";
import equal from 'fast-deep-equal'
import MultiSelect from "react-multi-select-component";
import * as CONSTANTS from "../Utils/Constants"
import {ExportCSV}  from '../Components/Rapport/ExportCSV';
import Modal from 'react-bootstrap/Modal';
import * as actions from '../Redux/actions/index';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'



const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList,
    filters: state.filters,
    taskStatus: state.taskStatus,
    task_status: state.task_status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e)),
    setFlash: (f) => dispatch(actions.setFlash(f)),
    closeFlash: (f) => dispatch(actions.closeFlash(f))
  };
}

const moment = require("moment");





class CollectListContainer extends Component {
  constructor(props) {
    super(props);



    this.props.isLoading({
      message: "Loading collects",
      animate: true
    });

    this.state = {
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      types: [],
      clients: [],
      zones: [],
      collects:[],
      isDetailsShown: false,
      isFetchStatusShown: false,
      rows: [],
      current: 1,
      pages: 1,
      limit: 60,
      dueTotal: 0,
      collectTotal: 0,
      remis: 0,
      checked: false,
      remisWithoutApply: 0, 
      bordereauxSelected: [],
      showModalStatus: false,
      action: '',
      filters: {
        state_global:'done',
        remise: this.props.user.role === 'finance' ? 'true' : ''
      },
      bordereauxOnAction: [],
      selectedActon: null
    };

    this.changeLimit = this.changeLimit.bind(this);

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks,
      fillBOnAction: this.fillBOnAction,
    };
    
  }

  updateFilter = (event, nameVal, isDate = false ,arr = false, isSelect = false) => {
    let that = this;
    console.log(event);
    let value = "";

  

    if (isDate) {
      value = event;
    } else {
      value = event.target ? event.target.value : event.value;
    }
    if(!arr){
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: value
          }
        }
      );
    }
      
    
     

  };



  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showNewTask: false });
    }
  };

  handleEnterKey = (event) => {
    if (event.keyCode === 13) {
      document.getElementById('filter').click();
      event.preventDefault();
    }
  }



  componentDidMount() {
    if(this.props.match.params.clientid){
			let that = this 
      let params = this.props.match.params.clientid;
      that.state.filters['clientid'] = params
			// that.setState({
			// 	filters:{
			// 		clientid: this.props.match.params.clientid
			// 	}
			// })
		}
    this.searchTaskById();
    document.addEventListener("keyup", this.handleEscape);
    loadCommonData();

    let that = this;

    if (this.props.taskList.length > 0) {
      this.setState({ tasks: this.props.taskList });
    } else {
      this.loadTasks();
    }

    if (this.props.socket) {
      console.log("socket connected");

      this.props.socket.on("task_status", function(data) {
        console.log("event: ", data);
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    } else {
      console.log("not connected");
    }

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success) {
        if(response.data.client){
          that.setState({
            clients: [{ label: "All", value: 0 }, ...response.data.client]
          })
        }
        that.setState(
          {
            agents: [{ label: "All", value: 0 }, ...response.data.agents],
            types: [{ label: "All", value: 0 }, ...response.data.types],
            status: [{ label: "All", value: 0 }, ...response.data.status],
          });

        if (response.data.managers) {
          that.setState({
            managers: [{ label: "All", value: 0 }, ...response.data.managers]
          });
        }
      }
    });

    api.get('/api/form/zones').then(result => {
      if(result.data.success){
        that.setState({
          zones: result.data.zones
        })
      }
    })
  }

  loadTasks = (p = this.state.current, e, redirect=false) => {
    if(e)
      e.preventDefault()

    this.props.isLoading();
    const params = buildUriParams(this.state.filters);
    console.log(params);
    const crits = "limit=" + this.state.limit + params;

    


    api.get("/api/collect/fetch?page=" + p + "&" + crits).then((response) => {
      if (response.data.success) {
        
        this.setState({
          //tasks: response.data.tasks,
          pages: response.data.pages,
          total: response.data.total,
          collects: response.data.collects,
          collectTotal: response.data.collectTotal,
          dueTotal: response.data.dueTotal,
          remis : this.state.checked ? response.data.remis  - ( (response.data.remis * 1.5) / 100) : response.data.remis ,
          remisWithoutApply: response.data.remis

        });
      } else {
        if (response.data.errcode === 101) {
          this.props.setAuth(false);
        }else{
          this.setState({
            isFetchStatusShown : true,
				  })

        }
      }

      this.props.doneLoading();
      // console.log(this.state.tasks);
    });
    if(redirect){
      this.props.history.push("/collect");
    }
    
    return false;
  };

  


  componentDidUpdate(prev) {
    
    if(!equal(this.props.match.params.id, prev.match.params.id)){
      this.searchTaskById();
    }
    const that = this;

    if (this.props.socket && !prev.socket) {
      this.props.socket.on("task_status", function(data) {
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    }
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("task_status");
    }
    document.removeEventListener("keyup", this.handleEscape);
  }

  loadForm(ent) {
    let that = this;

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success)
        that.setState({
          agents: response.data.agents,
          types: response.data.types
        });
    });
  }

  changeLimit = e => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  selectPage = p => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  formatDate = date => (date ? new Date(date) : undefined);

  afterPost = () => {
    this.loadTasks();
    this.setState({
      showNewTask: false,
      is_duplicate: false
    });
  };

  selectDate = when => {
    if (when === "yesterday") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(-2, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(-1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "today") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment().format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "tomorrow") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(2, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    }
  };

  resetFilters = () => {
     //this.myFormRef.reset();
     //this.selectRef.select.clearValue();
    window.location.reload(false);
    //this.forceUpdate();
    //$( "#here" ).load(window.location.href + " #here" );
    //this.setState(state => ({ ...state, filters: {} }), this.loadTasks);
    
  };


  duplicateTask = id => {
    this.setState({
      showNewTask: true,
      selected_task: id,
      is_duplicate: true
    })
  }

  searchTaskById = ()=> {
    let taskId = 'task_id' 
    let params = this.props.match.params.id;
    this.state.filters['task_id'] = params

    // this.props.isLoading();
    // const querys = buildUriParams(this.state.filters);
    // console.log(querys);
    // const crits = "limit=" + this.state.limit + querys;


    // api.get("/api/v2/task/fetch?page=" + this.state.current + "&" + "limit=" + this.state.limit + "&" + "task_id=" + this.props.match.params.id).then((response) => {
    //   if (response.data.success) {
    //     this.setState({
    //       tasks: response.data.tasks,
    //       pages: response.data.pages,
    //       total: response.data.total
    //     });
    //   } else {
    //     if (response.data.errcode === 101) {
    //       this.props.setAuth(false);
    //     }
    //   }

    //   this.props.doneLoading();
    // });
    this.loadTasks();
  }

  multiSelectHandler = (event, nameVal) =>{
    
    let arr = []
    console.log("event not option =>",event)
    for (var i = 0; i < event.length; i++ ){
      console.log("for loop =>",event[i].value)
      arr = arr.concat([event[i].value])
      console.log(arr)
      
    }
    this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: arr
          }
        }
      );
      
    
  }

  showDetails = () => {
    console.log("hello "+ this.state.isDetailsShown )
    let bool = this.state.isDetailsShown 
    this.setState({
      isDetailsShown: bool? false :true
    })
  }

  export = () => {
    //this.exportPDF.action = CONSTANTS.REMOTE_API + 'api/bordereau/fetch?page=1&limit=60';
    this.exportPDF.action = CONSTANTS.REMOTE_API + '/File/ECM21MAY03_file.pdf';
    
    this.exportPDF.submit();

  }

  addCode = (e) => {
		const value = e.target ? e.target.value : e.value;
		this.setState({
			value:value
		})
		

		if(!this.state.bordereauxSelected.includes(value) && value.length === 14){
			this.setState(prev => ({
				bordereauxSelected: [...prev.bordereauxSelected,  value],
				value:''
			}))
		}
		
	}

  deleteCode = (item) =>{
		let array =  this.state.bordereauxSelected
		const index = array.indexOf(item);
		if (index > -1) {
			array.splice(index, 1);
			this.setState({
				bordereauxSelected: array
			})
		}

	}
  changeAction = (event) => {
    let value = event.target ? event.target.value : event.value;
    this.setState({
      action: value
    })
  }
  collectBatch = () => {
    api.post("/api/collect/batch/" + this.state.action, this.state.bordereauxSelected).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
				this.setState({
					showModalStatus: false,
					bordereauxSelected: []
				})
			}else{
				//this.props.doneLoading();
				this.setState({
					showModalStatus: false,
					bordereauxSelected: []
				})
			}

		})
  }

  apply = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    console.log('check box => '+ value)


    if(value){
      var apply = this.state.remis - ( (this.state.remisWithoutApply * 1.5) / 100)
    }else{
      var apply =  this.state.remisWithoutApply
    }
    

    this.setState({
      remis: apply,
      checked: value
    })

  }

  exportPDF = () => {
    let data = {apply: this.state.checked, collect: this.state.collects, iscollect: this.state.filters['status_collect'] , agent: this.state.filters['agent'] , client : this.state.filters['client_name'] }
    api.post("/api/collect/exportpdf", data).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				console.log('exported')
        let url = CONSTANTS.REMOTE_API + 'File/Collect/' ;
        console.log('url => '+ url)
			  url +=  result.data.file;
        console.log('url => '+ url)
        window.open(url, '_blank');
      }else{
        console.log(result.data.error);
        this.setState({message:result.data.error,
				alertClass: "alert alert-warning d-block"
			})

      }

		})
  }

  fillBOnAction = (event, id) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let tab = this.state.bordereauxOnAction
    if (value && this.state.bordereauxOnAction.indexOf(id) === -1){ 
      tab.push(id)
      this.setState({
        bordereauxOnAction: tab
      })
    }else if(!value){
      const index = tab.indexOf(id);
      if (index > -1) {
        tab.splice(index, 1);
        this.setState({
          bordereauxSelected: tab
        })
      }
    }
    console.log(this.state.bordereauxOnAction)
  }

  applyAction = () => {
    //console.log(this.state.selectedActon.value)
    if(!this.state.selectedActon)
      return
    
    api.post("/api/collect/actions?action="+this.state.selectedActon.value, this.state.bordereauxOnAction).then(result => {
			//this.props.doneLoading();
			if(result.data.success){
				this.props.setFlash({
					active: true,
					message: "Action done",
					classes: "success",
					style: {}
				})
				this.setState({
					showRecu: false,
					bordereauxOnAction: []
				})
        setTimeout(() => {
          window.location.reload(false);
        }, "1 second")
        
			}else{
				//this.props.doneLoading();
				this.setState({
					showRecu: false,
					bordereauxOnAction: []
				})
        this.props.setFlash({
					active: true,
					message: result.data.error,
					classes: "danger",
					style: {}
				})

			}
		})
  }

  selectAll = (type) => {
    let tab = []
    if(type === 'select'){
      const slicedArray = this.state.collects.slice(0, this.state.limit == 0 ? undefined : this.state.limit);
      slicedArray.map(item => {
        
        tab.push(item.key_bordereau)
        
      })
      this.setState({
          bordereauxOnAction: tab,
          sel:true
        })
    }else{
      this.setState({
        bordereauxOnAction: tab,
        sel:false
      })
    }
    
    console.log(tab)

  }

  render() {
    
    const locations = communes.communes.map(item => ({
      label: item.nom + " (" + item.code_postal + ")",
      value: item.nom
    }));
    const {filters} = this.state;
    var selectedValue = [];
    var v = 2;

    let that = this
    const zones = Object.entries(that.state.zones).map(([key, i]) => ({
      label: key,
      value: key
    }))

    const state = [
      {label: "All", value: " "},
      {label: "Green", value: "done"},
      {label: "Orange", value: "started"},
      {label: "Grey", value: "null"},
      // {label: "All", value: " "},
      // {label: "Attente enlèvement", value: "done"},
      // {label: "Attente réception", value: "started"},
      // {label: "Attente livraison ", value: "null"},

    ];

    const stateGlobal = [
      {label: "All", value: " "},
      {label: "Waiting", value: "null"},
      {label: "Done", value: "done"},
    ];

    const status_collect = [
      {label: "All", value: " "},
      {label: "A collecter", value: "none"},
      {label: "A remettre", value: "collected"},
      {label: "En cours", value: "encours"},
      {label: "Done", value: "remis"},
    ];

    const actions = [
      // {label: "Actions Groupées", value: " "},
      {label: "Collecte", value: "collecte"},
       {label: "remis", value: "remise"},
    ] ;

    var today = new Date(),
    now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    return (
      <React.Fragment>
				{Boolean(this.state.message) && <span className={this.state.alertClass}>{this.state.message}</span>}
        <div className={"container-header"}>
          {/* <form method="post" ref={ref => this.exportPDF = ref}>
            <button onClick={e => this.export()}>get pdf</button>
            <input type="hidden" name={"x-sts-token"} ref={ref => this.userInfo = ref} value={this.props.user.token} />
          </form> */}
          <Trans i18nKey="label_collect" />

          {!this.props.auth ? <Redirect to="/login" /> : ""}

          {this.props.user &&
          (this.props.user.role === "manager" ||
            this.props.user.role === "commercial" ||
            this.props.user.role === "client" ||
            this.props.user.role === "sup ops" ||
            this.props.user.role === "admin" ||
            this.props.user.role === "superviseur") ? (
            <React.Fragment>
              {/* <button type="button" onClick={() => this.setState({ showModalStatus: true })} className={"cbtn"}>
                Changer le status de la collect
              </button> */}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <React.Fragment>
          {this.props.user.role === "admin" ? (
            <div className={"row mb-5"}>
              <div className={"col-md-3"}>
                <label>Manager</label>
                <Select
                  classNamePrefix={"custom-select"}
                  className={"custom-select-c "}
                  options={this.state.managers}
                  placeholder={"Created by"}
                  onChange={e => this.updateFilter(e, "author")}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"form ui-rect np"} id='here'>
            <form onSubmit={e => this.loadTasks(1, e,true)} ref={(el) => this.myFormRef = el}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <Select
                  // ref={ref => {
                  //   this.selectRef = ref;
                  // }}
                  //value ={!this.state.filters.agent ?  this.state.valueDefault.agents : this.state.selected.agent}
                  classNamePrefix={"custom-select"}
                  options={this.state.agents}
                  placeholder="Coursier"
                  onChange={e => this.updateFilter(e, "agent", false, false, true)}
                  
                />
              </div>

              <div className={"form-group col-md-3"}>
                <ClientInput
                      placeholder={"Client"}
                      required={false}
                      defaultValue={filters.clients || "" }
                      selectClient={sug => this.updateFilter({value: sug.client_name}, "client_name")}
                      type="text"
                      name="client_name"
                      className={"form-control col-md"}
                    />
              </div >
              <div className={"form-group col-md-3"}>
                <Select
                  classNamePrefix={"custom-select"}
                  options={[
                    { label: "Tout", value: "" },
                    { label: "colis", value: "colis" },
                    { label: "documents", value: "documents" }
                  ]}
                  placeholder="type"
                  onChange={e => this.updateFilter(e, "type")}
                />
                    
                </div>

              <div className={"form-group col-md-3"}>
                  <input
                    placeholder={"Code Bordereau"}
                    onChange={e => this.updateFilter(e, "code_bordereau")}
                    value={filters.name}
                    name="code_bordereau"
                    type="text"
                    className={"form-control"}
                  />
                
              </div>

            </div>
            <div className="row">
            <div className="col-md-2">
                    <Select
                      placeholder={"Zone Départ"}
                      classNamePrefix={"custom-select"}
                      name='zone_depart'
                      onChange={e => this.updateFilter(e, 'zone_depart')}
                      // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                      options={zones}
                    />
              </div>
              <div className="col-md-2">
                    <Select
                      placeholder={"Zone Arrivé"}
                      classNamePrefix={"custom-select"}
                      name='zone_arrive'
                      onChange={e => this.updateFilter(e, 'zone_arrive')}
                      // defaultValue={listCommunes.filter(item => item.value === data.subtask_address_commune)}
                      options={zones}
                      size={5}
                    />
              </div>
              <div className="col-md-2">
                    {/* <Select
                      placeholder={"Status"}
                      classNamePrefix={"custom-select"}
                      name='state'
                      onChange={e => this.updateFilter(e, 'state')}
                      options={state}
                      
                    /> */}
                    <Select
                      //defaultValue={[{label: "Done", value: "done"}]}
                      placeholder={"Status"}
                      classNamePrefix={"custom-select"}
                      name='status_collect'
                      onChange={e => this.updateFilter(e, 'status_collect')}
                      options={status_collect}
                    />
              </div>
              <div className="col-md-2">
                    <Select
                      defaultValue={[{label: "Done", value: "done"}]}
                      placeholder={"Status global"}
                      classNamePrefix={"custom-select"}
                      name='state_global'
                      onChange={e => this.updateFilter(e, 'state_global')}
                      options={stateGlobal}
                    />
              </div>
              <div className={"col-md-2 form-group"}>
                  
                    <ReactDatePicker
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Date début"}
                      timeFormat={"HH:mm"}
                      showTimeSelect={false}
                      className={"form-control"}
                      selected={this.formatDate(filters.date_debut)}
                      onChange={e =>
                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut", true)
                      }
                      selectsStart
                      startDate={this.formatDate(filters.date_debut)}
                      endDate={this.formatDate(filters.date_fin)}
                      wrapperClassName={"col-md-12"}
                    />
                </div>
                <div className={"col-md-2 form-group"}>
                  <ReactDatePicker
                    timeFormat={"HH:mm"}
                    dateFormat={"yyyy-MM-dd"}
                    placeholderText={"Date fin"}
                    showTimeSelect={false}
                    className={"form-control"}
                    selected={this.formatDate(filters.date_fin)}
                    onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                    selectsEnd
                    startDate={this.formatDate(filters.date_debut)}
                    endDate={this.formatDate(filters.date_fin)}
                    minDate={this.formatDate(filters.date_debut)}
                    wrapperClassName={"col-md-12"}
                  />
                </div>
            </div>

            <div className={"row"}>
            {/* <div className="col-md-3">
                    <Select
                      //defaultValue={[{label: "Done", value: "done"}]}
                      placeholder={"Status"}
                      classNamePrefix={"custom-select"}
                      name='status_collect'
                      onChange={e => this.updateFilter(e, 'status_collect')}
                      options={status_collect}
                    />
              </div> */}

              <div className={"col-md-12 text-right"} onKeyDown={this.handleEnterKey}>
              <button onClick={() => this.exportPDF()} className={"btn btn-sm btn-outline-primary mr-2"} type="button">
                PDF Export 
                <FontAwesomeIcon className={"ml-2"} icon={faFilePdf} />
              </button>
                <ExportCSV csvData={this.state.collects} fileName={'Collects' + now} classBtn = {'btn btn-sm btn-outline-success mr-2'}/>
                <button type="button" onClick={this.resetFilters} className={"btn btn-sm btn-outline-primary"}>
                  Reinitialiser les filtres
                </button>
                <button id="filter" onClick={() => this.loadTasks(1)} className={"btn btn-sm btn-primary ml-2"}>
                  Filtrer
                </button>
                {/* <input type="reset" value="reset button" /> */}
              </div>
            </div>
            </form>
          </div>
        </React.Fragment>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-1 items-found"}>
            <p>
              <b>{this.state.total}</b> Collects trouvés
            </p>
          </div>
          <div className={"col-md-1 items-found"}>
            <p>
              Total  collecte<b> {this.state.collectTotal} DZD</b> 
            </p>
          </div>
          <div className={"col-md-2 items-found"}>
            <p>
              Total A remettre <b> {this.state.remis} DZD</b> 
            </p>
          </div>
          <div className={"col-md-2 items-found"}>
             
            {/* <input */}
            {/*   name="apply" */}
            {/*   type="checkbox" */} 
            {/*   onChange={e => this.apply(e)} */}
            {/* /> */}
            {/* &nbsp; &nbsp; Appliquer le 1.5% */}
            
          </div>

          <div className={"col-md-3 per-page-wrap"}>
          
           
            
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>

          <div className="col-md-3 ">
            <div className="row text-right">
                <div className="col-8"> 
                    <Select
                      classNamePrefix={"custom-select"}
                      onChange={e => this.setState({selectedActon: e})}
                      //className={"per-page nm"}
                      options={[{label: 'Actions groupées', value: ''}, 
                                {label: 'Collecter', value: 'collecte'},
                                {label: 'Remettre', value: 'remise'},
                              ]}
                      value={this.state.selectedActon ? this.state.selectedActon : {label: 'Actions groupées', value: ''}}
                    />
                </div>
                <div className="col-4">
                  <button  className={"btn  btn-primary mt-1"} onClick={e => this.applyAction()}>
                        Appliquer
                  </button>
                </div>
            </div>
            
            
          </div>
        </div>

        <WTNotifications
          connection={this.props.connection}
          event={'new'}
          condition={(message) => message === "new_tasks"}
          component={(message, active, close) => <RenderNotification
                                                    refresh={() => this.loadTasks(1)}
                                                    active={active}
                                                    close={close}
                                                    message={message}
                                                  />}
          alwaysActive
        />

        <div className="ui-rect np">
            <div className="table .table-striped m-1">
              <div className={"row  table-row"} >
                <div className={"col-md-2 "}><strong>CODE</strong></div>
                <div className={"col-md-1 "}><strong>Client</strong></div>
                <div className={"col-md-1"}><strong>Ajouté par</strong></div>
                <div className={"col-md-1"}><strong>Coursier</strong></div>
                <div className={"col-md-1 "}><strong>Collect</strong></div>
                <div className={"col-md-1"}><strong>Tarif</strong></div>
                <div className={"col-md-1"}><strong>Tarif + Collect</strong></div>
                <div className={"col-md-1"}><strong>Due</strong></div>
                <div className={"col-md-1"}><strong>Status</strong></div>
                <div className={"col-md-1 text-center"}> <strong>Action</strong>  </div>
                <div className={"col-md-1 text-center"}>
                  {!this.state.sel ? <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('select')}> 
                      <strong>Select All</strong>
                    </botton> 
                    : 
                    <botton className={"btnoutlineprimary"} onClick={e => this.selectAll('clear')}> 
                      <strong>Clear All</strong>
                    </botton>
                    }  
                </div>
                
              </div>

              {this.state.collects.map((collect, i) => (
                <CollectRow idx = {i}  data ={this.state} collect ={collect} handlers={this.handlers}/>
              ))}

              {this.state.collects.length === 0 && (
                <div className={"col-md-12 text-center my-3"}>
                  <Trans i18nKey={"empty_tasks"} />
                </div>
              )}
            </div>
        </div>

        {this.state.showNewTask && (
          <div className={`ui-overlay open`}>
            <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
              <button
                onClick={() => this.setState({ showNewTask: false })}
                className={"ui-overlay-close btn btn-danger btn-sm"}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

              {/* <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} /> */}
            </div>
          </div>
        )}

        <Modal show={this.state.showModalStatus} centered>
              <Modal.Header >
                  <Modal.Title>Changer le station de la collect par batch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className={"form-group col-md-12"}>
                    <label><b>Merci de Selectionnez l'action a faire *</b></label>
                    <Select
                      classNamePrefix={"custom-select"}
                      onChange={e => this.changeAction(e)}
                      placeholder='Actions Groupées'
                      options={actions}
                      cacheOptions={true}
                      //value={{ label: this.state.limit, value: this.state.limit }}
                    />
                    <label><b>Veuillez entrer les codes bordereaux à assigner  *</b></label>
                    <input
                      type="text"
                      value={this.state.value}
                      name={`bordereau`}
                      className={"form-control"}
                      onChange={e => this.addCode(e)}
                    />
                  </div>
                  {this.state.bordereauxSelected.map(item => (
                    <div className='badge badge-light mr-2 my-2 shadow-sm'>
                      <span style={{fontSize: 15}} >{item} </span> <button className="btnem" onClick={e => this.deleteCode(item)}><FontAwesomeIcon  icon={faTimesCircle} color="#d9534f"/></button>
                      
                    </div>
                  ))

                  }

              </Modal.Body>
              <Modal.Footer>
                  <button className={"btn btn-danger"}  onClick={e => this.setState({showModalStatus: false, bordereauxSelected: []})}>
                    Annuler
                  </button>
                  {this.state.bordereauxSelected.length > 0  && <button type='submit' className={"btn btn-success"} onClick={e => this.collectBatch()}>
                    Confirmer 
                  </button>}
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.isFetchStatusShown} centered>
                    <Modal.Header >
                      <Modal.Title>Erreur</Modal.Title>
                    </Modal.Header>
                    
                    <Modal.Body>
                      vous n'êtes autorisé à voir que les collectes de bordereau avec wilaya départ ou arrivée = votre wilaya
                    </Modal.Body>
                    <Modal.Footer>
                      <button className={"btn btn-secondary"}  onClick={e=> (this.setState({isFetchStatusShown: false}))}>
                        Ok
                      </button>
                    </Modal.Footer>
              </Modal>

      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <CollectListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

const RenderNotification = ({message, active, close, refresh}) => {


  return (
    <>
      <div onClick={close} className={"task-notifications "+ (active && "active")}>

        <button onClick={refresh} className={"cbtn"} type="button">
          Nouvelles mise à jours disponible
          <FontAwesomeIcon className={"ml-2"} icon={faRedo} />
        </button>
      </div>
    </>
  )
}

const newComp = withNotifications(CollectListContainer);
export default withNotifications(connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectListContainer));
