import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router'
import { closeFlash, setFlash, setAuthAction, startLoading, doneLoading } from '../../Redux/actions/index';
import api from '../../Utils/Api';
import { useTranslation, Trans } from "react-i18next";
import {Helmet} from 'react-helmet';
import logo from '../../../logo_or.PNG'
import m2m from '../../../M2M.png'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

import { faCopy} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user
	}
}

function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    doneLoading: () => dispatch(doneLoading()),
    isLoading: (p) => dispatch(startLoading(p)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}



class RegisterBoxApp extends Component {

	constructor(props) {

		super(props);


		this.props.doneLoading();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleAppCreationSubmit = this.handleAppCreationSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.passwordRef = React.createRef();

		this.state = {
			messageSpan: "",
			confirmation: false,
			form: {
				username: "",
				password: "",
				code: "",
				nameapp: ""
			},
			warning: '',
			showAlert: false, 
			identified: false,
			token: '',
			showModal: false
		};

		props.closeFlash();
	}

	handleInputChange(e) {
		let s = this.state;
		s.form[e.target.name] = e.target.value;
		this.setState({...s});
	}

	handleSubmit(e) {
		e.preventDefault();
		let that = this;


		if(this.state.form.password.length < 4 || this.state.form.password.length < 4) {


			that.setState({
				warning: 'Enter valid username & password',
				showAlert: true
			})

			return 
		}

		this.props.isLoading({message:'Connecting', animate:true});

		
				

		api.post('/api/user/connect', this.state.form)
		.then(function(response) {
			

			if(response.data && response.data.success === true) {
	
				//that.props.setAuth(response.data);	
				that.setState({
					showAlert:false,
					identified: true
				})			


			} else {
				if(response.data && response.data.errcode === 102)
					that.setState({confirmation: true});

					that.setState({
						warning: response.data.error,
						showAlert: true
					})

			}
				
				

			that.props.doneLoading();
		})
		.catch(error => {
			console.log(error);
			that.props.doneLoading();
		})

		
	}

	handleAppCreationSubmit(e){
		e.preventDefault();
		let that = this;
		this.props.isLoading({message:'Creation', animate:true});


		api.post('/api/clientapps/new', this.state.form)
		.then(function(response) {
			

			if(response.data && response.data.success === true) {
	
				//that.props.setAuth(response.data);	
				that.setState({
					identified: true,
					showModal:true,
					token:response.data.token
				})			


			} else {
				if(response.data && response.data.success === false)
					that.setState({confirmation: true});

					that.setState({
						warning: response.data.error,
						showAlert: true
					})

			}
				
				

			that.props.doneLoading();
		})
		.catch(error => {
			console.log(error);
			that.props.doneLoading();
		})

	} 

	render() {
		return (
			

			<div className={"row"} style={{backgroundColor: '#28397B'}}>
			
			
				

			<Helmet>
                <style>{
						'body { background-color: #28397B; }  .mainContent { background:#28397B; border-radius: 0px;}  .bg-container { background-color: #28397B; border-radius: 0px;} .footer-content{color: #fff} '
						
						}
				</style>
            </Helmet>
			

				<div className={"col-md-12 "} style={{backgroundColor: '#28397B', textAlign:'center' }} >
					{/* <img src={logo} alt="logo" style={{visibility: 'hidden' }} />	 */}
					<img src={m2m} alt="m2m"  width="150" height="150"/>	


					
					

					{/* {(this.props.auth && <Redirect to={this.props.user.role === "manager" ? "/task" : "/task"} /> )} */}
					{(this.props.auth && <Redirect to={this.props.user.role === "client" ? "/accueil" : ( this.props.user.role === "finance" ? "/collect" :'/bordereau') } /> )}
					

					
					
					{!this.state.identified ? <div className="col-md-5 login-box ui-rect">
						<div className={"container-header login-page "}>
							S'identifier
						</div>
						{this.state.showAlert && <Alert onClose={() => this.setState({showAlert:false})} dismissible variant='warning'>
							{this.state.warning}
						</Alert>}
						<span className={"mb-4"}>{this.state.messageSpan}</span>
						<form onSubmit={this.handleSubmit}  name="loginForm">
								<input type="hidden" name="hidden" value="aze" />
								

								<div class="form__group field">
									<input class="form__field"     name="username"  onChange={this.handleInputChange} value={this.state.form.username}  required />
									<label for="name" class="form__label" ><Trans i18nKey="user_username" /> </label>
								</div>
								<br/>
								<div class="form__group field">
									<input class="form__field"     name="user_password"  onChange={this.handleInputChange} type="password" name="password" value={this.state.form.password} required />
									<label for="name" class="form__label" ><Trans i18nKey="user_password" /></label>
								</div>
								<br/>
								<span className={"float-left mb-2"}><a href="login">Revenir à la page Connexion </a></span>

								<br/>

								


								<div className={"c-form-row form-group clearfix"}>
									
									<button className={"shadow cbtn btn-primary float-right large"} type="submit">Confirmer</button>
								</div>

								<div className="clear"></div>
						
						</form>

					</div>
					: 

					<div className="col-md-5 login-box ui-rect">
						<div className={"container-header login-page "}>
							Créer l'application
						</div>
						{this.state.showAlert && <Alert onClose={() => this.setState({showAlert:false})} dismissible variant='warning'>
							{this.state.warning}
						</Alert>}
						<form onSubmit={this.handleAppCreationSubmit}  name="loginAppForm">
								<div class="form__group field">
									<input class="form__field"     name="username"  onChange={this.handleInputChange} value={this.state.form.username}  disabled />
									<label for="name" class="form__label" ><Trans i18nKey="user_username" /> </label>
								</div>
								<br/>
								<div class="form__group field">
									<input class="form__field"     name="nameapp"  onChange={this.handleInputChange} value={this.state.form.nameapp}  required />
									<label for="name" class="form__label" ><Trans i18nKey="label_appname" /> </label>
								</div>

								<br/>

								


								<div className={"c-form-row form-group clearfix"}>
									
									<button className={"shadow cbtn btn-primary float-right large"} type="submit">Créer</button>
								</div>
								<div className="clear"></div>
						
						</form>

						

					</div>
				
					}
				</div>


				<Modal show={this.state.showModal} centered>
                        <Modal.Header >
                          <Modal.Title>Vous êtes prêt </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							Vous êtes prêt à commencer à utiliser notre API
							<br/>
							<br/>
							<label class="font-weight-light">LA CLE DE  L'API EST</label>
							<div class="input-group mb-3">
								<input type="text" class="form-control" value={this.state.token} aria-label="Recipient's username" aria-describedby="basic-addon2" id="token" disabled/>
								<div class="input-group-append">
									<button class="btn btn-outline-secondary" type="button" onClick={() => {navigator.clipboard.writeText(this.state.token)}}>
										<FontAwesomeIcon  className={"ml-2"} icon={faCopy} />
									</button>
								</div>
							</div>
							Télécharger la documention <a href="https://api.mars.witrack.digital/Documentation.pdf"  target={"_blank"}>ici</a> afin de pouvoir utiliser l'API

							
                        </Modal.Body>
                        <Modal.Footer>
							<Link to="/login" className="btn btn-primary">Terminer</Link>
                        </Modal.Footer>
                    </Modal>


			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterBoxApp)