import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

class AgentSideMenu extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    if (this.props.auth)
      return (
        <ul className={"sideMenu-list"}>
          <li>
            <NavLink to={"/account"}>Account</NavLink>
            <ul className={"submenu"}>
              <li>
                <NavLink strict exact to={"/account"}>
                  Account settings
                </NavLink>
              </li>
              <li>
                <NavLink strict exact to={"/account/notification"}>
                  Notification
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to={"/task"}>Tasks</NavLink>

            <ul className={"submenu"}>
              <li>
                <NavLink strict exact to={"/task"}>
                  View tasks{" "}
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      );
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(AgentSideMenu);