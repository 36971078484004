import React, {useState, Component } from "react";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TaskList from "../Components/Bordereau/TaskList";
import { setAuthAction, startLoading, doneLoading } from "../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../Utils/socket-context";
import api from "../Utils/Api";
import Pagination from "../Utils/Pagination";
import { loadCommonData, buildUriParams } from "../Utils/Helpers";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo, faAngleDown,faAngleUp, faRoad} from "@fortawesome/free-solid-svg-icons";
import BordereauNewContainer from "./BordereauNewContainer";
import communes from "../../assets/communes.json";
import BordereauRow from "./BordereauRow";
import FacturesRow from  "../Components/Facture/FacturesRow"

import WTNotifications, { withNotifications } from "react-socket-notification";
import ClientInput from "../Components/Task/subcomponents/ClientInput";
import equal from 'fast-deep-equal'
import MultiSelect from "react-multi-select-component";
import * as CONSTANTS from "../Utils/Constants"
import {ExportCSV}  from '../Components/Rapport/ExportCSV';



// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'



const mapStateToProps = state => {
  return {
    auth: state.auth,
    user: state.user,
    taskList: state.taskList,
    filters: state.filters,
    taskStatus: state.taskStatus,
    task_status: state.task_status
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
}

const moment = require("moment");





class FactureListContainer extends Component {
  constructor(props) {
    super(props);



    this.props.isLoading({
      message: "Loading Factures",
      animate: true
    });

    this.state = {
      managers: [],
      tasks: [],
      agents: [],
      status: [],
      factType: [],
      types: [],
      clients: [],
      bordereaux: [],
      factures: [],
      toexport_facture: [],
      isDetailsShown: false,
      rows: [],
      current: 1,
      pages: 1,
      limit: 60,
      filters: {},
      methods: []
    };

    this.changeLimit = this.changeLimit.bind(this);

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks
    };
    console.log(this.props.match.params.id)
    
  }

  updateFilter = (event, nameVal, isDate = false ,arr = false) => {
    let that = this;
    console.log(event);
    let value = "";

    if (isDate) {
      value = event;
    } else {
      value = event.target ? event.target.value : event.value;
    }
    if(!arr){
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: value
          }
        }
      );
    }
      
    
     

  };



  handleEscape = (e) => {
    if (e.keyCode === 27) {
      this.setState({ showNewTask: false });
    }
  };


  componentDidMount() {
    this.searchTaskById();
    document.addEventListener("keyup", this.handleEscape);
    loadCommonData();

    let that = this;

    if (this.props.taskList.length > 0) {
      this.setState({ tasks: this.props.taskList });
    } else {
      this.loadTasks();
    }

    if (this.props.socket) {
      console.log("socket connected");

      this.props.socket.on("task_status", function(data) {
        console.log("event: ", data);
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    } else {
      console.log("not connected");
    }

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success) {
        if(response.data.client){
          that.setState({
            clients: [{ label: "All", value: 0 }, ...response.data.client]
          })
        }
        that.setState(
          {
            agents: [{ label: "All", value: 0 }, ...response.data.agents],
            types: [{ label: "All", value: 0 }, ...response.data.types],
            status: [{ label: "All", value: 0 }, ...response.data.status],
            factType: [{ label: "All", value: 0 }, ...response.data.factType],
          });

        if (response.data.managers) {
          that.setState({
            managers: [{ label: "All", value: 0 }, ...response.data.managers]
          });
        }
      }
    });

    
    api.get('/api/form/method').then(result => {
      if(result.data.success){
        that.setState({
          methods: result.data.methods
        })
      }
    })
  }

  loadTasks = (p = this.state.current, e, redirect=false) => {
    if(e)
      e.preventDefault()

    this.props.isLoading();
    const params = buildUriParams(this.state.filters);
    console.log(params);
    const crits = "limit=" + this.state.limit + params;

    


    api.get("/api/facture/fetch?page=" + p + "&" + crits).then((response) => {
      if (response.data.success) {
        this.setState({
          factures: response.data.factures,
          pages: response.data.pages,
          total: response.data.total,
        });
        this.processFacturesForExport();
      } else {
        if (response.data.errcode === 101) {
          this.props.setAuth(false);
        }
      }

      this.props.doneLoading();
      // console.log(this.state.tasks);
    });
    if(redirect){
      this.props.history.push("/factures");
    }
    
    return false;
  };

  
  


  componentDidUpdate(prev) {
    if(!equal(this.props.match.params.id, prev.match.params.id)){
      this.searchTaskById();
    }
    const that = this;

    if (this.props.socket && !prev.socket) {
      this.props.socket.on("task_status", function(data) {
        if (data.type && data.type === "task_status") that.loadTasks();
      });
    }

    
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("task_status");
    }
    document.removeEventListener("keyup", this.handleEscape);
  }

  loadForm(ent) {
    let that = this;

    api.get("/api/v2/task/form").then(function(response) {
      if (response.data.success)
        that.setState({
          agents: response.data.agents,
          types: response.data.types
        });
    });
  }

  changeLimit = e => {
    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  selectPage = p => {
    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  formatDate = date => (date ? new Date(date) : undefined);

  afterPost = () => {
    this.loadTasks();
    this.setState({
      showNewTask: false,
      is_duplicate: false
    });
  };

  selectDate = when => {
    if (when === "yesterday") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(-2, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(-1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "today") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment().format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    } else if (when === "tomorrow") {
      this.setState(
        state => ({
          ...state,
          filters: {
            ...state.filters,
            date_debut: moment()
              .add(1, "day")
              .format("YYYY-MM-DD 00:00"),
            date_fin: moment()
              .add(2, "day")
              .format("YYYY-MM-DD 00:00")
          }
        }),
        () => {
          this.props.isLoading();
          this.loadTasks();
        }
      );
    }
  };

  resetFilters = () => {
    //this.setState(state => ({ ...state, filters: {} }), this.loadTasks);
    window.location.reload(false);
  };


  duplicateTask = id => {
    this.setState({
      showNewTask: true,
      selected_task: id,
      is_duplicate: true
    })
  }

  searchTaskById = ()=> {
    let taskId = 'task_id' 
    let params = this.props.match.params.id;
    this.state.filters['task_id'] = params

    this.loadTasks();
  }


   handleEnterKey = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById('filter').click();
    }
   }

  processFacturesForExport = () => {
    const toexport_facture = this.state.factures.map(facture => {
      const totalPayment = facture.payment.reduce((sum, payment) => sum + payment.montant, 0);
      const ifPartially = (totalPayment < facture.montant && totalPayment > 0 )? 'oui' : 'non';

      return {
        ...facture,
        payment: totalPayment,
        if_partially: ifPartially
      };
    });

    this.setState({ toexport_facture });
  };



  multiSelectHandler = (event, nameVal) =>{
    
    let arr = []
    console.log("event not option =>",event)
    for (var i = 0; i < event.length; i++ ){
      console.log("for loop =>",event[i].value)
      arr = arr.concat([event[i].value])
      console.log(arr)
      
    }
    this.setState(
        {
          filters: {
            ...this.state.filters,
            [nameVal]: arr
          }
        }
      );
      
    
  }

  showDetails = () => {
    console.log("hello "+ this.state.isDetailsShown )
    let bool = this.state.isDetailsShown 
    this.setState({
      isDetailsShown: bool? false :true
    })
  }

  export = () => {
    //this.exportPDF.action = CONSTANTS.REMOTE_API + 'api/bordereau/fetch?page=1&limit=60';
    this.exportPDF.action = CONSTANTS.REMOTE_API + '/File/ECM21MAY03_file.pdf';
    
    this.exportPDF.submit();

  }
  // exportXLSX = () =>{
  //   api.post("/api/factures/export").then(response =>{
  //     if (response.data.success) {

  //       window.location.href = CONSTANTS.REMOTE_API + response.data.url;
        
  //     }});
  // }

  // exportXLSX = () => {
  // api.post("/api/factures/export", {}, { responseType: 'blob' }).then(response => {
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement('a');
  //   link.href = url;

  //   // If the server provides a filename in the response headers
  //   const contentDisposition = response.headers['content-disposition'];
  //   let fileName = 'downloaded_file';
  //   if (contentDisposition) {
  //     const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
  //     if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  //   }
  //   link.download = fileName;

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);  // Clean up the link element
  //   window.URL.revokeObjectURL(url);  // Clean up the object URL
  // });
  // }


  exportXLSX = () => {
  api.post("/api/factures/export").then(response => {
    if (response.data.success) {
      const url = CONSTANTS.REMOTE_API + response.data.url;

      // Now, fetch the file content using the URL
      api({
        url: url,
        method: 'GET',
        responseType: 'blob', // Important: expect a blob (binary data)
      }).then(fileResponse => {
        const blob = new Blob([fileResponse.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Extracting the filename from the URL or using a default
        const fileName = url.split('/').pop();
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
        window.URL.revokeObjectURL(link.href); // Clean up the object URL
      });
    }
  });
}



  // exportXLSX = () => {
  // api.post("/api/factures/export").then(response => {
  //   if (response.data.success) {
  //     const url = CONSTANTS.REMOTE_API + response.data.url;
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = url.split('/').pop();  // Extracts the file name from the URL for download
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);  // Clean up the link element
  //   }
  // });
// }
 
  render() {
    var today = new Date(),
    now = '_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    
    const locations = communes.communes.map(item => ({
      label: item.nom + " (" + item.code_postal + ")",
      value: item.nom
    }));
    const {filters} = this.state;
    var selectedValue = [];
    var v = 2;

    return (
      <React.Fragment>
        <div className={"container-header"}>
         
          Factures
          {!this.props.auth ? <Redirect to="/login" /> : ""}

          {this.props.user &&
          (this.props.user.role === "manager" ||
            this.props.user.role === "commercial" ||
            this.props.user.role === "client" ||
            this.props.user.role === "sup ops" ||
            this.props.user.role === "admin") ? (
            <React.Fragment>
            
            </React.Fragment>
          ) : (
            ""
          )}
        </div>

        <React.Fragment>
          {this.props.user.role === "admin" ? (
            <div className={"row mb-5"}>
              <div className={"col-md-3"}>
                <label>Manager</label>
                <Select
                  classNamePrefix={"custom-select"}
                  className={"custom-select-c "}
                  options={this.state.managers}
                  placeholder={"Created by"}
                  onChange={e => this.updateFilter(e, "author")}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className={"form ui-rect np"}>
            <form onSubmit={e => this.loadTasks(1, e,true)}>
            <div className={"row"}>
              <div className={"col-md-3"}>
                   <input
                      placeholder={"Code facture"}
                      onChange={e => this.updateFilter(e, "code_facture")}
                      name="code_facture"
                      type="text"
                      className={"form-control"}
                    />
              </div>

              
              <div className={"form-group col-md-3"}>
                <Select
                  classNamePrefix={"custom-select"}
                  options={this.state.factType}
                  placeholder={"Type de la facture"}
                  onChange={e => this.updateFilter(e, "type_facture")}
                />
                </div>

              <div className={"form-group col-md-3"}>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={[
                        { label: "Tout", value: "" },
                        { label: "payé", value: "payé" },
                        { label: "non payé", value: "non payé" },
                        { label: "partiellement payé", value: "partiellement payé" },
                      ]}
                      placeholder="Status de la facture"
                      onChange={e => this.updateFilter(e, "status_facture")}
                    />
                
              </div>

              <div className={"form-group col-md-3"}>
                  <ClientInput
                      placeholder={"Client"}
                      required={false}
                      defaultValue={filters.client || ""}
                      selectClient={sug => this.updateFilter({value: sug.client_name}, "client_name")}
                      type="text"
                      name="client_name"
                      className={"form-control col-md"}
                    />
              </div >

            </div>

            
            <div className={"row"}>
              <div className={"form-group col-md-3"}>
                    <input
                      placeholder={"Code Bordereau"}
                      onChange={e => this.updateFilter(e, "code_bordereau")}
                      value={filters.name}
                      name="code_bordereau"
                      type="text"
                      className={"form-control"}
                    />
                  
                </div>
              <div className={"col-md-3 form-group"}>
                  
                    <ReactDatePicker
                      dateFormat={"yyyy-MM-dd"}
                      placeholderText={"Date début"}
                      timeFormat={"HH:mm"}
                      showTimeSelect={false}
                      className={"form-control"}
                      selected={this.formatDate(filters.date_debut)}
                      onChange={e =>
                        this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_debut", true)
                      }
                      selectsStart
                      startDate={this.formatDate(filters.date_debut)}
                      endDate={this.formatDate(filters.date_fin)}
                      wrapperClassName={"col-md-12"}
                    />
                </div>
                <div className={"col-md-3 form-group"}>
                  <ReactDatePicker
                    timeFormat={"HH:mm"}
                    dateFormat={"yyyy-MM-dd"}
                    placeholderText={"Date fin"}
                    showTimeSelect={false}
                    className={"form-control"}
                    selected={this.formatDate(filters.date_fin)}
                    onChange={e => this.updateFilter(moment(new Date(e)).format("YYYY-MM-DD"), "date_fin", true)}
                    selectsEnd
                    startDate={this.formatDate(filters.date_debut)}
                    endDate={this.formatDate(filters.date_fin)}
                    minDate={this.formatDate(filters.date_debut)}
                    wrapperClassName={"col-md-12"}
                  />
                </div>
              
              <div className={"col-md-3 text-right"} onKeyDown={this.handleEnterKey}>
                {/* <button className={"btn btn-sm btn-outline-success "} onClick={this.exportToCSV}>
                      <Trans i18nKey="button_export"/>
                </button> */}
                <button type="button" onClick={() => this.exportXLSX()} className={"btn btn-sm btn-success mr-2"}>
                  Export PPC
                </button>
                <ExportCSV csvData={this.state.toexport_facture} fileName={'Factures' + now} classBtn = {'btn btn-sm btn-outline-success'}/>
                <button type="button"  onClick={this.resetFilters} className={"btn btn-sm btn-outline-primary ml-2"}>
                  Reinitialiser les filtres
                </button>
                <button onClick={() => this.loadTasks(1)} className={"btn btn-sm btn-primary ml-2"}>
                  Filtrer
                </button>
                
              </div>
            </div>
            </form>
          </div>
        </React.Fragment>

        <div className={"row ui-rect my-4"}>
          <div className={"col-md-6 items-found"}>
            <p>
              <b>{this.state.total}</b> Fcatures trouvés
            </p>
          </div>

          <div className={"col-md-6 per-page-wrap"}>
          
           
            
            <div className={"per-page-content"}>
              <p>Résultat par page</p>
              <Select
                classNamePrefix={"custom-select"}
                onChange={this.changeLimit}
                className={"per-page nm"}
                options={this.props.filters.result_per_page}
                value={{ label: this.state.limit, value: this.state.limit }}
              />
            </div>
            <Pagination data={this.state} handleClick={this.selectPage} />
          </div>
        </div>

        <WTNotifications
          connection={this.props.connection}
          event={'new'}
          condition={(message) => message === "new_tasks"}
          component={(message, active, close) => <RenderNotification
                                                    refresh={() => this.loadTasks(1)}
                                                    active={active}
                                                    close={close}
                                                    message={message}
                                                  />}
          alwaysActive
        />

        <div className="ui-rect np">
            <div className="table .table-striped m-1">
              <div className={"row  table-row"} >
                <div className={"col-md-2 "}><strong>CODE/Date facturation</strong></div>
                <div className={"col-md-2 "}><strong>Client</strong></div>
                <div className={"col-md-2 "}><strong>Type</strong></div>
                <div className={"col-md-2 "}><strong>Status/Date paiment</strong></div>
                <div className={"col-md-1 "}><strong>Total(sans TVA)</strong></div>
                <div className={"col-md-3 text-right"}><strong>Actions</strong></div>
                
              </div>

              {this.state.factures.map((facture, i) => (
                <FacturesRow idx = {i} facture = {facture} data ={this.state} duplicateTask={this.duplicateTask} handlers={this.handlers}/>
              ))}

              {this.state.factures.length === 0 && (
                <div className={"col-md-12 text-center my-3"}>
                  <Trans i18nKey={"empty_bills"} />
                </div>
              )}
            </div>
        </div>
{/* 
        <div className={"ui-rect np"}>
          <TaskList duplicateTask={this.duplicateTask} data={this.state} rows={this.state.rows} handlers={this.handlers} />
        </div> */}

        {this.state.showNewTask && (
          <div className={`ui-overlay open`}>
            <div className={"ui-overlay-content task-detail col-md-10 offset-md-1"}>
              <button
                onClick={() => this.setState({ showNewTask: false })}
                className={"ui-overlay-close btn btn-danger btn-sm"}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>

              <BordereauNewContainer taskId={this.state.selected_task} isDuplicate={this.state.is_duplicate} inline={true} afterPost={this.afterPost} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <FactureListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

const RenderNotification = ({message, active, close, refresh}) => {


  return (
    <>
      <div onClick={close} className={"task-notifications "+ (active && "active")}>

        <button onClick={refresh} className={"cbtn"} type="button">
          Nouvelles mise à jours disponible
          <FontAwesomeIcon className={"ml-2"} icon={faRedo} />
        </button>
      </div>
    </>
  )
}

const newComp = withNotifications(FactureListContainer);
export default withNotifications(connect(
  mapStateToProps,
  mapDispatchToProps
)(FactureListContainer));
