import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import { setAuthAction, startLoading, doneLoading, setFlash, closeFlash } from './../../Redux/actions/index';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";
import { faCheck, faTimes,  faCommentAlt,  faTag, faList, faChevronRight, faFileInvoiceDollar, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function mapStateToProps(state) {
  return { 
  	auth: state.auth,
  	user: state.user
  };
};


function mapDispatchToProps(dispatch) {
  return {
    setAuth: user => dispatch(setAuthAction(user)),
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: e => dispatch(doneLoading(e)),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
}


 class AccountBilling extends Component {

 	constructor(props) {
 		super(props);
 		this.state = {};
        props.isLoading();

 	}


 	componentDidMount() {

        if(this.props.match.params.message) {
            let message = this.props.match.params.message;

            if(message === 'pending') {
                this.props.setFlash({
                    active: true,
                    message: <Trans i18nKey="message_failed_payment_pending" />,
                    classes: "danger"
                })
            } else if(message === 'success') {
                this.props.setFlash({
                    active: true,
                    message: <Trans i18nKey="message_success_payment" />,
                    classes: "success"
                })
            }
            

        }

 		let that = this;

 		api.get('/api/user/'+this.props.user.userid+'?tab=billing')
 		.then(function(response) {
 			console.log(response.data);
 			that.setState(response.data);
 			that.props.doneLoading();
 		}).catch(err => that.props.doneLoading())
 	}

 	

	render() {

		return(
            

            <div className="col-md-12 ctable">
			
			
				
                <div className={"row head-titles ctable-row"}>
                    <div className={"col-md-1"}>
                        ID
                    </div>
                    <div className={"col-md-2"}>
                        Type
                    </div>
                    <div className={"col-md-3"}>
                        <Trans i18nKey="label_amount" />
                    </div>
                    <div className={"col-md-3"}>
                        <Trans i18nKey="label_datetime" />
                    </div>
                    <div className={"col-md-2"}>
                        <Trans i18nKey="label_status" />
                    </div>
                    <div className={"col-md-1"}>
                        Action
                    </div>
                </div>

                {this.state.data && this.state.data.map(bill => (

                    <div className={"row ctable-row"}>
                        <div className={"col-md-1"}>
                            {bill.id}
                        </div>
                        <div className={"col-md-2"}>
                            {bill.type}
                        </div>
                        <div className={"col-md-3"}>
                            {bill.data.amount}
                        </div>
                        <div className={"col-md-3"}>
                            {bill.created_at}
                        </div>
                        <div className={"col-md-2"}>
                            {bill.status}
                        </div>
                        <div className={"col-md-1"}>
                            {bill.status === "created" && (
                                <Link to={"/bill/"+bill.id+'/pay'} className={"ml-1 btn-sm btn-primary"} >Pay<FontAwesomeIcon className={"ml-2"} icon={faChevronRight} /></Link>
                            )}
                        </div>
                    </div>

                ))}

                
            </div>

			
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBilling);