import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import wilayas from "../../assets/wilayas.json"
import Select from "react-select";
import api from "../Utils/Api"
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { startLoading, doneLoading, setAuthAction } from "../Redux/actions";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo, faTimesCircle, faSpinner, faArrowUp } from "@fortawesome/free-solid-svg-icons";


const listWilaya =  wilayas.wilayas.map(item => ({ label: item.nom, value: item.nom , id : item.id}))

function mapStateToProps(state) {
  return { auth: state.auth };
};

function mapDispatchToProps(dispatch) {
	return {
	  isLoading: e => dispatch(startLoading(e)),
	  doneLoading: e => dispatch(doneLoading()),
	  setAuth: e => dispatch(setAuthAction(e)),
	};
}

class tarifTestContainer extends Component {

	constructor(props) {

		super(props);
		this.formSubmit = this.formSubmit.bind(this);

		this.state = {
			user: {},
			wilayaFrom: '' ,
			wilayaTo: '' ,
			devis:0,
			columns: [],
			ZoneTarif: [],
			zone: 0,
			alertClass:"alert alert-warning d-block",
      newZone:{},
      isEditModalOpen: false,
      editRowId: null,
      editedTarifCorp: 0,
      editedTarifEcom: 0
		}
		this.taskForm = null;
		this.zoneForm = null;
		this.props.isLoading();
		this.getTarif()
	}

	getTarif = () => {
		
		api.get('/api/tarif/fetch').then(result =>{
			
			if(result.data.success){
				console.log('zone'+ JSON.stringify(result.data.ZoneTarif['Client Corporate'].zone_0))
				this.setState({
					columns: result.data.grilleTarif,
					ZoneTarif: result.data.ZoneTarif,
					zone:result.data.zone
				})
				this.props.doneLoading()
			}
		})
	}

	updateWilaya = ( status, zone = '' , id = '') => {
		console.log('id => '+ id)
		status == 'from' ? this.setState({wilayaFrom: zone}) : this.setState({wilayaTo: zone, wilayaIdTo : id});
	
	}
	formSubmit = (e) => {
	    e.preventDefault();
		let data = {};
		const formData = new FormData(this.taskForm);
		for (var [key, value] of formData.entries()) data[key] = value;
		console.log(data)
		if(!data['fromWilaya'] || !data['toWilaya'] || !data['typeclient']  ) {
	
			  this.setState({
				message: "Veuillez remplir les champs obligatoire",
				alertClass: "alert alert-warning d-block"
			})
			  return false;
			
		}

		api.post("/api/bordereau/TestDevis",data).then(result =>{
			if (result.data.success){
	  
			 this.setState({
				devis : result.data.devis
			 })
			  
			}
		  })
	}


	formNewTarif = (e) => {
	    e.preventDefault();
		let data = {};
		const formData = new FormData(this.taskForm);
		for (var [key, value] of formData.entries()) data[key] = value;
		console.log(data)
		if(!data['fromWilaya'] || !data['toWilaya'] || !data['zone']  ) {
	
			  this.setState({
				message: "Veuillez remplir les champs obligatoire",
				alertClass: "alert alert-warning d-block"
			})
			  return false;
			
		}

		api.post("/api/grilletarif/new",data).then(result =>{
			if (result.data.success){
	  
			//  this.setState({
			// 	devis : result.data.devis
			//  })
			this.setState({
				message:result.data.message,
				alertClass: "alert alert-success d-block"
			})
			this.getTarif()
			  
			}else{
				this.setState({message:result.data.error,
				alertClass: "alert alert-warning d-block"
			})
			}

		})
    }

  handleEditButtonClick = (rowId) => {
   
    const selectedRow = this.state.columns.find((row) => row.id === rowId);
    const corporateValue = parseFloat(selectedRow['Client Corporate'].slice(0, -4));
    const ecommerceValue = parseFloat(selectedRow['Client Ecommerce'].slice(0, -4));
    
    this.setState({
      isEditModalOpen: true,
      editRowId: rowId,
      editedTarifCorp: corporateValue, 
      editedTarifEcom: ecommerceValue,
    });
  };

  
  handleEditSubmit = async (e) => {
  e.preventDefault();

  const { editRowId, editedTarifCorp, editedTarifEcom } = this.state;

  try {
    api.post("/api/tarif/edit", {
      id: editRowId,
      corporate: parseFloat(editedTarifCorp),
      ecom : parseFloat(editedTarifEcom),
          }).then(response=>{
      if (response.data.success) {
      this.setState({
        isEditModalOpen: false,
        editRowId: null,
        editedTarifCorp: 0,
        editedTarifEcom: 0,
        message:response.data.message,
			  alertClass: "alert alert-success d-block"

      });

      this.getTarif();
    } else {
      this.setState({message:response.data.error,
				alertClass: "alert alert-warning d-block"
			})
      console.error("API request failed:", response.data.error);
    }


    });

      } catch (error) {
    console.error("Network error:", error);
  }
};

	formnewZone = (e) => {
	    e.preventDefault();
		this.setState({loading:true})
		let data = {};
		const formData = new FormData(this.zoneForm);
		for (var [key, value] of formData.entries()) data[key] = value;
		console.log(data)
		if(!data['tarifEcom'] || !data['tarifCorp']   ) {
			
			  this.setState({
				loading:false,
				message: "Veuillez remplir les champs obligatoire",
				alertClass: "alert alert-warning d-block"
			})
			  return false;
			
		}
		data['zone'] = this.state.zone

		api.post("/api/tarif/new",data).then(result =>{
			if (result.data.success){
	  
			//  this.setState({
			// 	devis : result.data.devis
			//  })
			this.setState({
				loading:false,
				zoneCreation:false,
				message:result.data.message,
				alertClass: "alert alert-success d-block"
			})
			this.getTarif()
			  
			}else{
				this.setState({message:result.data.error,
				alertClass: "alert alert-warning d-block"
			})
			}

		})
	}


	render() {

		
		const columns = [
			{ field: 'id', headerName: 'ID', width: 70 },
			{ field: 'wilayaA', headerName: 'Wilaya A', width: 130 },
			{ field: 'wilayaB', headerName: 'Wilaya B', width: 130 },
			{ field: 'Zone', headerName: 'Zone', width: 130 },
			{ field: 'Client Corporate' , headerName: 'Client Corporate', width: 130 },
			{ field: 'Client Ecommerce', headerName: 'Client Ecommerce', width: 130 },
      //{ field: 'Action', headerName: 'Action'},
      {
        field: 'Modifier',
        headerName: 'Action',
        width: 100,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.handleEditButtonClick(params.row.id)}
          >
            Modifier
          </Button>
        ),
      },
				];
		
		
		return(
			<div>
				<div className='ui-rect np'>
					
					<div className='col-md-12'>
					{Boolean(this.state.message) && <span className={this.state.alertClass}>{this.state.message}</span>}
						<form onSubmit={this.formNewTarif} ref={(ref) => (this.taskForm = ref)} name="userForm" method="post">
							<div className='row'>
								<div className={"form-group col-md-3"}>
									<label><b>Wilaya départ *</b></label>
									<Select
									placeholder={"Wilaya Départ"}
									classNamePrefix={"custom-select"}
									name={`fromWilaya`}
									onChange={e => this.updateWilaya('from' , e.value)}
									options={listWilaya}
									/>
								</div>

								<div className={"form-group col-md-3"}>
									<label><b>Wilaya arrivée *</b></label>
									<Select
									placeholder={"Wilaya Arrivé"}
									classNamePrefix={"custom-select"}
									name={`toWilaya`}
									onChange={e => this.updateWilaya( 'to' , e.value, e.id)}
									options={listWilaya}
									/>
								</div>

								<div className={"form-group col-md-3"}>
									<label><b>Zone*</b></label>
									<Select
									placeholder={"Zone"}
									classNamePrefix={"custom-select"}
									name={`zone`}
									options={
										Array.apply(null, Array(this.state.zone)).map(function (x, i) { return { label: i, value: i }; })
										// [{ label: 'Client Corporate', value: 1 },{ label:'Client Ecommerce', value: 2}]
									}
									/>
								</div>
								<div className='form-group col-md-3 text-right mt-4'>
									<button className={"btn btn-success btn-lg "}>Ajouter</button>
								</div>
							</div>
						</form>

					</div>
					
				</div>
				<br/>
				<div className=' row '>
					<div className='col-md-3 '>
					<Card sx={{ minWidth: 275 }}>
						<CardContent>
							<div className='row'>
								<div className='col-md-8'>
									<Typography  variant="h5" color="text.secondary" gutterBottom >
										Tarification Client Corporate
									</Typography>
								</div>
								<div className='col-md-4 d-flex flex-row-reverse'>
									 <Button variant="text" onClick={e => this.setState({showCrop: !this.state.showCrop})}>Détails</Button>
								</div>
								
								
							</div>
							

							{this.state.showCrop && Array.apply(null, Array(this.state.zone)).map((idx, item) => (
							                      
								<div className='row tarifrow'>
									<div className='col-md-6'>
										Zone {item}
									</div>
									<div className='col-md-6'>
										{this.state.ZoneTarif['Client Corporate'] && this.state.ZoneTarif['Client Corporate']['zone_'+item]}
									</div>
								</div>
							))}
						</CardContent>
						</Card>
					</div>
					<div className='col-md-3'>
					<Card sx={{ minWidth: 275 }}>
						<CardContent>
							
							<div className='row'>
								<div className='col-md-8'>
									<Typography  variant="h5" color="text.secondary" gutterBottom >
										Tarification Client Ecommerce
									</Typography>
								</div>
								<div className='col-md-4 d-flex flex-row-reverse'>
									 <Button variant="text" onClick={e => this.setState({showEcom: !this.state.showEcom})}>Détails</Button>
								</div>
								
								
							</div>

							{this.state.showEcom && Array.apply(null, Array(this.state.zone)).map((idx, item) => (
							                      
								<div className='row tarifrow'>
									<div className='col-md-6'>
										Zone {item}
									</div>
									<div className='col-md-6'>
										{this.state.ZoneTarif['Client Ecommerce'] && this.state.ZoneTarif['Client Ecommerce']['zone_'+item]}
									</div>
								</div>
							))}
						</CardContent>
					</Card>
					</div>

					<div className='col-md-6 '>
						<div className=' col-md-12 d-flex flex-row-reverse '>
							<button  onClick={e => this.setState({zoneCreation:true})} className={" btn btn-success btn-lg"}>Nouvelle Zone</button>
						</div>	
					</div>
				</div>

				<br/>
				<div className='ui-rect np'>
					<div style={{ height: 400, width: '100%'}}>
						<DataGrid
							rows={this.state.columns}
							columns={columns}
							pageSize={5}
							rowsPerPageOptions={[5]}
							checkboxSelection
						/>	
					</div>
				</div>



				<Modal show={this.state.zoneCreation} centered>
					<Modal.Header >
						<Modal.Title>Ajouter Une Zone </Modal.Title>
					</Modal.Header>
					<Modal.Body>
							
							
							<form  onSubmit={this.formnewZone} ref={(ref) => (this.zoneForm = ref)}>
								<label><b>Entrez la tarification Corporate  *</b></label>
								<input 
									type="number" 
									className={"form-control"}  
									name="tarifCorp"  
									onChange={text => this.setState({newZone:{...this.state.newZone,  tarifCorp :text }})} 
								/>
								<br/>
								<label><b>Entrez la tarification Ecommerce   *</b></label>
								<input 
									type="number" 
									className={"form-control"}
									name="tarifEcom" 
									onChange={text => this.setState({newZone:{...this.state.newZone,  tarifEcom :text }})} 
								/>
								<div className='row mt-4'>
									<div className='col-md-12 d-flex flex-row-reverse'>
										<button  type="submit" className={"btn btn-success float-right"}>
													{this.state.loading ? <FontAwesomeIcon icon={faSpinner}  spin={true} /> : (<><FontAwesomeIcon icon={faArrowUp} className={"mr-2"} />Ajouter</>)}
										</button>
										<button className={"btn btn-danger"}  onClick={e => this.setState({zoneCreation: false})}>
											Annuler
										</button>
										
									</div>
								</div>
							</form>
					</Modal.Body>
					
        </Modal>	


         <Modal show={this.state.isEditModalOpen} onHide={() => this.setState({ isEditModalOpen: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier les tarifs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <label>Tarif Corporate:</label>
              <input
                type="number"
                value={this.state.editedTarifCorp}
                className={"form-control"}
                placeholder="Tapez tarif Corporate ..."
                onChange={(e) => this.setState({ editedTarifCorp: e.target.value })}
              />
              <br />
              <label>Tarif Ecommerce:</label>
              <input
                type="number"
                value={this.state.editedTarifEcom}
                className={"form-control"}
                placeholder="Tapez tarif ecommerce ..."
                onChange={(e) => this.setState({ editedTarifEcom: e.target.value })}
              />
          </Modal.Body>

            <Modal.Footer>
              <button 
                type="submit"
                className={"btn btn-primary"}
                onClick={this.handleEditSubmit}
              >
                Ok
              </button>
            </Modal.Footer>

        </Modal>
			</div>
			

			
		)
	}
}

export default connect(mapStateToProps , mapDispatchToProps)(tarifTestContainer)
